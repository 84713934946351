import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbContext from '../../services/context/Breadcrumb/breadcrumbContext';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../../services/notifyService";
import taskIcon from "./../../assets/icons/taskIcon.svg";
import axios from 'axios';
import { load } from '@amcharts/amcharts5/.internal/core/util/Net';
import { SkeletonCard } from './SkeletonCard';
import { deleteConnection, getConnector, getOneConnection } from '../../services/ConnectorService';

export const ViewConnection = () => {
    const nav = useNavigate();
    const { id } = useParams();
    const [connectionDetails, setConnectionDetails] = useState(null);
    const [loading ,setLoading] = useState(false);
    const [openDeleteModal,setOpenDeleteModel]=useState(false);
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext);
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: 'connector/connection', menuName: 'Connection', isActive: true, hasSubMenu: true },
            { slug: `/connector/connection/${id}`, menuName: "View", isActive: false, hasSubMenu: true }
        ]);
    }, [location.pathname, id]);

    const fetchConnectionDetails = async () => {
        try {
            setLoading(true);
            const response = await getOneConnection(id)
            if (response.status) {
                let connectionData = response.data[0];
    
                // Fetch connectors after fetching connection details
                const connectorResponse = await getConnector(1,20)
    
                if (connectorResponse.status) {
                    const selectedConnector = connectorResponse.data.find(c => c.id === parseInt(connectionData.connectorId));
                    connectionData = {
                        ...connectionData,
                        connectorName: selectedConnector?.connectorName
                    };
                } else {
                    toastError("Failed to fetch connectors");
                }
                // Now update the state with merged data
                setConnectionDetails(connectionData);
            } else {
                toastError("Failed to fetch connection details");
                nav('/connector/connection');
            }
        } catch (error) {
            toastError("Error fetching connection details");
        } finally{
            setLoading(false);
        }
    };
    
    // Call only fetchConnectionDetails in useEffect
    useEffect(() => {
        fetchConnectionDetails();
    }, [id]); // Depend on ID to refetch on change

    async function deleteEvent(e) {
        e.preventDefault(); 
        const connectorResponse = await deleteConnection(id)

        if (connectorResponse.status) {
            // Manually hide the modal
            window.$("#deleteEventModal").modal("hide"); 

            // Remove the modal backdrop if it persists
            document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());

            // Show success message
            toastSuccess("Data deleted successfully");

            // Navigate after a slight delay
            setTimeout(() => {
                nav('/connector/connection');
            }, 500);
        }  else {
            toastError("Failed to fetch connectors");
        }
    }

    const deleteCompanyProjectModalContent = () => {
        return (
            <div className="modal fade" id="deleteEventModal" tabIndex="-1" role="dialog" aria-labelledby="deleteEventModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteEventModalLabel">Delete Event</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove <b>"{connectionDetails?.connectionName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deleteEvent(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div id="content">
            <div className="container-fluid pt-3">
                {deleteCompanyProjectModalContent()}
            <section>
                    <div className="d-sm-flex align-items-center justify-content-between bl-action-btn-sec">
                        <div>
                        <button onClick={() => nav(`/connector/connection`)} className="d-sm-inline-block btn btn-sm btn-primary"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button> 

                        </div>
                        <div className="d-inline-flex flex-row bl-btn-grp-fixed">
                            <button 
                                className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2" 
                                onClick={() => window.$("#deleteEventModal").modal('toggle')}
                            >
                                <i className="fa fa-trash mr-1" aria-hidden="true"></i> Delete
                            </button>
                            <button onClick={() => nav(`/connector/connection/edit/${id}`)} className="d-sm-inline-block btn btn-sm btn-primary"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</button> 

                        </div>
                    </div>
                </section>
                
                <section>
                    <div className="row" id="connection">
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div className="sidebar-brand-icon mb-2">
                                <img src={taskIcon} height="70px" alt="Task Icon" />
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <h4 className="h4 text-gray-800 text-center">View Connection</h4>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <p>Review your connection details below</p>
                        </div>
                    </div>
                </section>
                {loading&&<div className="mt-4">
                                <div className="row">
                                    {Array.from({ length: 11 }).map((_, index) => (
                                        <div key={index} className="col-md-4 mb-4">
                                            <SkeletonCard />
                                        </div>
                                    ))}
                                </div>
                            </div>}
                <section>
                    {connectionDetails && (
                        <div className="row">
                            <div className="col-xl-8 col-md-8 card p-0 offset-2">
                                <div className="card-body">
                                    <h5 className="card-title">Connection Details</h5>
                                    <p className="card-text text-muted">View the connection details</p>
                                </div>
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td width="30%"><div className="text-small pl-2">Connector Name</div></td>
                                            <td width="70%">{connectionDetails.connectorName}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="text-small pl-2">Connection Name</div></td>
                                            <td>{connectionDetails.connectionName}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="text-small pl-2">Status</div></td>
                                            <td>{connectionDetails?.status ? "Enabled" : "Disabled"}</td>
                                        </tr>
                                        {connectionDetails.connectionConfigMap && (
                                            <tr>
                                                <td colSpan="2">
                                                    <div className="p-1">
                                                        <h6 className="mb-2 ">Configuration Map</h6>
                                                        <div className="row ">
                                                            {Object.entries(connectionDetails.connectionConfigMap).map(([key, value], index) => (
                                                                <div key={index} className="col-12 mb-2">
                                                                    <div className="d-flex">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control mr-2"
                                                                            value={key}
                                                                            disabled
                                                                            style={{ width: '30%', backgroundColor: '#f8f9fa' }}
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={value}
                                                                            disabled
                                                                            style={{ width: '70%', backgroundColor: '#f8f9fa' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};
