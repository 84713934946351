import moment from "moment";
import imagePlaceholder from "./../assets/img/image-placeholder.jpeg";

export const validateNumberOnTextInput = (event) => {
  if (
    !(
      (event.key >= '0' && event.key <= '9') ||
      event.key === '.' ||
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    )
  ) {
    event.preventDefault();
  }
}

export const getValueIgnoreCase = (obj, key) => {
  const normalizedKey = key.toLowerCase();
  for (const objKey in obj) {
    if (obj.hasOwnProperty(objKey) && objKey.toLowerCase() === normalizedKey) {
      return { originalKey: objKey, value: obj[objKey] };
    }
  }
  return undefined;
}

export const validateAndFormatDate = (date) => {
  if (date) {
    const dateCalendar = moment(date).calendar({
      sameDay: '[Today at] hh:mm A',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday at] hh:mm A',
      lastWeek: '[Last] dddd',
      sameElse: 'MMM DD, YYYY hh:mm A'
    })
    return dateCalendar;
  }
  return null;
}

export const generateReadableId = (id, prefix) => {
  if (id && parseInt(id) > 0) {
    if (parseInt(id) < 10) {
      return `${prefix}-00${id}`
    }
    else if (parseInt(id) >= 10 && parseInt(id) < 100) {
      return `${prefix}-0${id}`
    }
    else {
      return `${prefix}-${id}`
    }
  }

}

export const formatCurrency = (number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    number
  ).replace(/(\.|,)00$/g, '')
}

export function formatNumber(value) {
  return value.toLocaleString('en-US');
}

export const toTitleCase = (camelCaseWord) => {
  if(!camelCaseWord){
    return ''
  }
  let separateWords = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2');

  let words = separateWords
      .replace(/_/g, ' ') 
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  return words;
};

export const getFileIconUrl = (fileName, type) => {
  const extension = fileName && fileName.includes('.') ? fileName.split('.').pop().toLowerCase() : type;
  const fileType = extension ? extension : type;
  switch (fileType) {
      case 'xlsx':
      case 'xls':
          return 'https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png';
      case 'docx':
      case 'doc':
          return 'https://www.freeiconspng.com/uploads/word-icon--microsoft-office-2013-iconset--iconstoc-8.png';
      case 'pdf':
          return 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
      case 'pptx':
      case 'ppt':
          return 'https://www.freeiconspng.com/uploads/powerpoint-icon-microsoft-powerpoint-icon-network-powerpoint-icons-and-3.png';
      case 'txt':
          return 'https://www.freeiconspng.com/uploads/file-txt-icon--camill-icons--softiconsm-19.png';
      default:
          return imagePlaceholder;
  }
};

export const convertFileSizeInFormat = (fileSize) => {
  var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0; while (fileSize > 900) { fileSize /= 1024; i++; }
  return (Math.round(fileSize * 100) / 100) + ' ' + fSExt[i];
}

export const getFriendlyType = (mimeType) => {
  const mimeMapping = {
    'application/vnd.ms-powerpoint': 'Microsoft PowerPoint Presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint Presentation (OpenXML)',
    'application/msword': 'Microsoft Word Document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document (OpenXML)',
    'application/pdf': 'PDF Document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Sheet'
  };
  return mimeMapping[mimeType] || mimeType;
}

export const linkValidation = (link) => {
  if (link && (link.startsWith('http') || link.toLowerCase().includes('www.'))) {
      return link.startsWith('http') ? link : `https://${link}`;
  }
  return null
}

export const getConfidenceLevelColor = (level) => {
  if (level) {
      if (level.toLowerCase() === 'high') {
          return 'success';
      }
      else if (level.toLowerCase() === 'medium') {
          return 'warning';
      }
      else {
          return 'danger';
      }
  }
}