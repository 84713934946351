import React, { useContext, useEffect, useRef, useState } from "react";
import { AImodalTableView } from "./AIModalTable";
import insightLightIcon from "./../../assets/img/insight_light.svg";
import reportIcon from "./../../assets/img/report_icon.png";
import taskIcon from "./../../assets/img/task_icon.png";
import redditIcon from "./../../assets/img/AIModel/reddit.png";
import reviewsIcon from "./../../assets/img/AIModel/reviews.jpg";
import newsIcon from "./../../assets/img/AIModel/Google_News_icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { AIListViewAnswerComponent } from "./AIListViewAns";
import { appendThemeProperty, closeModal, convertCamelCaseToSeparateWords, getFormattedReportTime, getRelativeTime, replaceUnderscoreFromWord, themeIcons } from "../../services/commonService";
import AIContext from "../../services/context/AIAssistance/aiContext";
import moment from "moment";
import { AuthContext } from "../../services/context/Auth/authContext";
import { getAppBehavior } from "../../services/authService";
import { addUserFeedbackService, askQueryService, deleteChatHistoryByThreadService, explainForNoRecordService, getAIModelService, getChatHistoryByThreadService, getChatHistoryThreadService, getSuggestiveQuestionService, getThreadCountService, getUserSchemaService, visualizeResultService } from "../../services/aiService";
import { ListCardComponent } from "./Templates/ListCard";
import { PlainCardComponent } from "./Templates/PlainCard";
import { InnerHTMLComponent } from "./Templates/InnerHTMLIntegration";
import { toastError, toastSuccess } from "../../services/notifyService";
import { getReportListService } from "../../services/reportService";
import aiModalKeys from "../../utils/property-file/AIModal.json";
import { GraphicalRepresentationView } from "./Templates/GraphicalRepresentation";
import Rating from "react-rating";
import ThemeContext from "../../services/context/Theme/themeContext";
import noVizardImg from "./../../assets/img/no_viz_img.png"
import { MenuDataSourceElement } from "./Templates/MenuDataSourceElement";
import { ConversationHistoryComponent } from "./Templates/conversationHistory";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import aiModalJSON from "../../data/AIModel/aiModalDetails.json";
import { getConfidenceLevelColor, linkValidation, toTitleCase, validateAndFormatDate } from "../../utils/validation";
import powerBiIcon from "./../../assets/icons/app/powerbi.png";
import sharepointIcon from "./../../assets/img/sharepoint.png";
import MarkdownMermaidRenderer from "../../utils/mermaid";
import { getCanvasResultService } from "../../services/speakerBriefService";
import MyEditorWithToolbar from "../../utils/editor/markdownEditor";


export const AIModalBodyComponent = () => {

    const location = useLocation();
    const nav = useNavigate();
    const childRef = useRef();
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { aiModalType, setAIModalType, rolesModelId, selectedDataSource, rolesModelInfo, dataSourceMenu, sbModelId } = useContext(AIContext);
    const { loggedUserName, userSchema, userMail, loggedUserMail, userGroups, applicableApps } = useContext(AuthContext)
    const { theme, isOpenedInIframe } = useContext(ThemeContext)

    const [kairosIcon, setKairosIcon] = useState('')
    const [kairosLogo, setKairosLogo] = useState('')
    const [companySmallLogo, setCompanySmallLogo] = useState('')
    const [defaultContent, setDefaultContent] = useState({
        CARD_INFO: [],
        TITLE: ""
    })

    const [showMinimizeIcon, setShowMinimizeIcon] = useState(true);
    const [showMaximizeIcon, setShowMaximizeIcon] = useState(false);
    const [uploadedFileOriginalName, setUploadedFileOriginalName] = useState("");
    const [defaultCardHeader, setDefaultCardHeader] = useState('')
    const [defaultSuggestiveQuestion, setDefaultSuggestiveQuestion] = useState([])
    const [suggestiveQuestion, setSuggestiveQuestion] = useState([])
    const [suggestiveQuestionTitle, setSuggestiveQuestionTitle] = useState("")
    const [isQuestionAsked, setIsQuestionAsked] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isQueryLoaded, setIsQueryLoaded] = useState(true)
    const [assistantAnswer, setAssistantAnswer] = useState([])
    const [modalContentType, setModalContentType] = useState(aiModalType)
    const [userQuestion, setUserQuestion] = useState('')
    const [viewType, setViewType] = useState('DATA_SOURCES');
    const [showSuggestion, setShowSuggestion] = useState(false)
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [selectedSource, setSelectedSource] = useState([])
    const [top5SelectedSource, setTop5SelectedSource] = useState([])
    const [remainingSelectedSource, setRemainingSelectedSource] = useState([])
    const [threadId, setThreadId] = useState('')
    const [threadsCount, setThreadsCount] = useState(0)
    const [searchThread, setSearchThread] = useState('')
    const [isExistingThread, setIsExistingThread] = useState(false)
    const [reports, setReports] = useState([])
    const [lastAnsItem, setLastAnsItem] = useState({
        result: null, visualResult: null
    })
    const [feedback, setFeedback] = useState({
        questionId: null,
        answerId: null,
        userQuery: ''
    })
    const [aiModels, setAIModels] = useState([])
    const [defaultModel, setDefaultModel] = useState({
        aiModelId: null, displayName: ''
    })
    const [initialQuery, setInitialQuery] = useState({
        question: "",
        assistant: ""
    })
    const [questionedModelInfo, setQuestionedModelInfo] = useState({
        question: "",
        assistant: ""
    })
    const [isKairosApp, setIsKairosApp] = useState(true)
    const [applicableUserSchema, setApplicableUserSchema] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hideSuggestions, setHideSuggestions] = useState(false);
    const [aiModelList, setAIModelList] = useState([])
    const [userBasedSchema, setUserBasedSchema] = useState([])
    const [userBasedSelectedSource, setUserBasedSelectedSource] = useState([])
    const [isCanvasView, setIsCanvasView] = useState(false)

    // useEffect(() => {
    //     setAIModelList(aiModalJSON)
    // }, [aiModalJSON])

    useEffect(() => {

        if (rolesModelId) {
            // setAIModelList([])
            openNewChat()
        }
    }, [rolesModelId])

    useEffect(() => {
        setSelectedSource([])
        setTop5SelectedSource([])
        setRemainingSelectedSource([])
    }, [])

    useEffect(() => {
        getUserBasedSchema()
        const path = location.pathname.split('/');
        const pathname = path && path.length ? path[1] : '';
        setIsKairosApp(pathname === 'kairos')
        if (pathname === 'kairos') {
            setBreadcrumbMenu(
                [
                    { slug: '/kairos', menuName: 'KAIROS AI', isActive: true, hasSubMenu: false },
                ]
            )
        }
    }, [location.pathname])

    useEffect(() => {
        const path = location.pathname.split('/');
        const pathname = path && path.length ? path[1] : '';
        if (pathname === 'kairos') {
            const kairosMenuContent = document.getElementById("kairosMenuContent");
            if (kairosMenuContent && theme && theme.dashboardTopBGColor) {
                kairosMenuContent.setAttribute('style', `background-color: ${theme.dashboardTopBGColor} !important;`)
            }
        }

    }, [theme, location, viewType])

    useEffect(() => {
        const path = location.pathname.split('/');
        const pathname = path && path.length ? path[1] : '';
        if (pathname === 'kairos') {
            openNewChat()
            document.getElementById('kairosMenuContent').classList.add('bl-kairos-menu');
            const headerElem = document.getElementById('blHeader');
            if (headerElem) {
                headerElem.classList.add('bl-kairos-top-header')
            }
            document.getElementById('kairosmaincontent').classList.add('bl-kairos-main-content');

            document.getElementById('aimodalcontent').classList.add('bl-kairos-content-height');
            document.getElementById('aimodalcontent').classList.remove('vh-100');

            return () => {
                const kairosMenu = document.getElementById('kairosMenuContent');
                if (kairosMenu) {
                    kairosMenu.classList.remove('bl-kairos-menu')
                }
                const mainHeader = document.getElementById('blHeader');
                if (mainHeader) {
                    mainHeader.classList.remove('bl-kairos-top-header');
                }
                const kairosContent = document.getElementById('kairosmaincontent');
                if (kairosContent) {
                    kairosContent.classList.remove('bl-kairos-main-content');
                }
                const aimodalcontentSec = document.getElementById('aimodalcontent');
                if (aimodalcontentSec && aimodalcontentSec.classList) {
                    aimodalcontentSec.classList.add('vh-100');
                    aimodalcontentSec.classList.remove('bl-kairos-content-height');
                }
                const kairosMenuContent = document.getElementById("kairosMenuContent");
                if (kairosMenuContent) {
                    kairosMenuContent.removeAttribute('style')
                }
            }
        }
    }, [location.pathname])

    useEffect(() => {
        if (isOpenedInIframe) {
            document.getElementById('aimodalcontent').classList.remove('bl-kairos-content-height');
            document.getElementById('aimodalcontent').classList.add('vh-100');
        }
    }, [isOpenedInIframe])

    useEffect(() => {
        getAppThemeImages()
    }, [getAppBehavior])

    useEffect(() => {
        // getThreadCount()
        const appThemes = getAppBehavior();
        let themeName = "BL_THEME";
        if (appThemes && appThemes !== "undefined") {
            themeName = JSON.parse(appThemes).themeName;
        }
    }, [aiModalKeys, getAppBehavior])

    const getAppThemeImages = () => {
        const appThemes = getAppBehavior();
        if (appThemes && appThemes !== "undefined") {
            const themeName = JSON.parse(appThemes).themeName;
            const kairos_logo = themeIcons(`${themeName}_K_Logo`);
            const kairos_icon = themeIcons(`${themeName}_K_Icon`);
            const appSmallLogo = themeIcons(`${themeName}_Small_Logo`);
            setKairosIcon(kairos_icon)
            setKairosLogo(kairos_logo)
            setCompanySmallLogo(appSmallLogo)
        }
    }

    useEffect(() => {
        setIsQueryLoaded(true)
        setIsFileUploaded(false)
        setIsExistingThread(false)
        setThreadId('')
        getReportList()
        getAIModels()
    }, [])

    useEffect(() => {
        getThreadCount()
        openNewChat()
    }, [userMail])

    useEffect(() => {
        applyTheme()
    }, [viewType])

    const getUserBasedSchema = async () => {
        try {
            const resp = await getUserSchemaService();
            if (resp && resp.success) {
                setUserBasedSchema(resp.data)
            }
        }
        catch (e) {

        }
    }

    useEffect(() => {
        const applicableView = userBasedSchema
            .filter(allSourceItem =>
                userBasedSelectedSource.some(sourceItem => sourceItem.originalTableName === allSourceItem.datasetName)
            )
            .map(allSourceItem => {
                const matchedSource = userBasedSelectedSource.find(sourceItem => sourceItem.originalTableName === allSourceItem.datasetName);
                return {
                    ...allSourceItem,
                    defaultQuestions: matchedSource?.suggestiveQuestion,
                    description: matchedSource?.description
                };
            });

        setAIModelList(applicableView)
    }, [userBasedSchema, userBasedSelectedSource, rolesModelId])

    const getAIModels = async () => {
        const resp = await getAIModelService();
        if (resp && resp.success) {
            const defaultModel = resp.data.find(model => model.default);
            if (defaultModel) {
                setDefaultModel(defaultModel)
            }
            const groupedModels = groupedModelWithAction(resp.data);
            setAIModels(groupedModels)
        }
    }

    const groupedModelWithAction = (models) => {
        return models.reduce((acc, model) => {
            const index = model.isDisabled ? 1 : 0;
            if (!acc[index]) {
                acc[index] = [];
            }
            acc[index].push(model);
            return acc;
        }, []).map((arr, index) => {
            return {
                enabledGroup: index === 0,
                items: arr
            };
        });

    }

    const getReportList = async () => {
        const obj = {
            page: 1,
            pageSize: 4
        }
        const resp = await getReportListService(obj);
        if (resp && resp.success) {
            setReports(resp.data)
        }
    }

    const getThreadCount = async () => {
        const obj = {
            userEmail: userMail
        }
        const resp = await getThreadCountService(obj);
        if (resp && resp.status) {
            const count = resp.data && resp.data.length && resp.data[0].threadCount ? resp.data[0].threadCount : 0;
            setThreadsCount(count)
        }
    }

    const getChatHistoryByThread = async (threadId, e) => {
        e.preventDefault();
        setThreadId(threadId)
        setIsExistingThread(true)
        document.getElementById('pageLoaderContainer').style.display = 'flex';
        const obj = {
            page: 1, pageSize: 10, threadId: threadId, userEmail: userMail
        }
        const resp = await getChatHistoryByThreadService(obj);
        if (resp && resp.status) {
            setAssistantAnswer([])
            setVisualizedAnswerResult([])
            setNoRecordExplain([])
            const chatHistory = resp.response.map((chat) => {
                chat.answerText.graphMode = 'TABLE';
                chat.answerText.viewMode = 'RESULT';
                chat.answerText.questionId = chat.questionId;
                chat.answerText.question = chat.questionText;
                chat.answerText.answerId = chat.answerId;
                chat.answerText.executedOn = chat.createdOn;
                return {
                    question: chat.questionText,
                    assistant: chat.answerText.title ? chat.answerText.title : aiModalKeys.DEFAULT_ASSISTANT_REPLY,
                    query: chat.answerText.data?.sqlQuery || "",
                    tablesList: chat.answerText?.tableNames || [],
                    executedOn: chat.createdOn,
                    questionId: chat.questionId,
                    answerId: chat.answerId,
                    analysisStrategy: chat.answerText.analysisStrategy ? chat.answerText.analysisStrategy : "",
                    analysisStrategyHTML: chat.answerText.analysisStrategyHTML ? chat.answerText.analysisStrategyHTML : "",
                    answer: [{ viewMode: 'SERVER_RESP', items: chat.answerText }]
                }
            })
            setIsQuestionAsked(true)
            setIsLoaded(true)
            const groupedAnswer = groupedTheQuestionsThread(chatHistory);
            setAssistantAnswer(groupedAnswer)
            document.getElementById('pageLoaderContainer').style.display = 'none';
        }
    }

    useEffect(() => {
        const questionListInfo = [...assistantAnswer];
        if (questionListInfo && questionListInfo.length) {
            const latestQuestIndx = questionListInfo.length - 1;
            const latestQuestionAnswer = questionListInfo[latestQuestIndx] && questionListInfo[latestQuestIndx].items ? questionListInfo[latestQuestIndx].items : [];
            if (latestQuestionAnswer && latestQuestionAnswer.length) {
                latestQuestionAnswer.forEach(ans => {
                    if (ans.answer && ans.answer.length) {
                        ans.answer.forEach((eachAnswer) => {
                            const result = eachAnswer.items.result;
                            const isGraphEnabled = !!eachAnswer.items.visualResult && eachAnswer.items.visualResult.graphSelected;
                            if (!isGraphEnabled && result && Array.isArray(result) && result.length && Object.keys(result[0]).length > 1) {
                                const obj = {
                                    question: ans.question,
                                    questionId: ans.questionId,
                                    // sqlQuery: ans.query,
                                    answerId: eachAnswer.items.answerId,
                                    result: result,
                                    model: eachAnswer.items.modelInfo.model
                                }
                                visualizeUserQueryResult(obj);
                            }
                        })
                    }
                })
            }
            questionListInfo.forEach(questions => {
                if (questions && questions.items && questions.items.length) {
                    questions.items.forEach(answers => {
                        if (answers && answers.answer && answers.answer.length) {
                            answers.answer.forEach(eachAnswer => {
                                const result = eachAnswer.items.result;
                                const noRecordExplanation = !!eachAnswer.items?.noRecordExplanation;
                                if (answers.query && !noRecordExplanation && (!Array.isArray(result) || (Array.isArray(result) && result.length === 0))) {
                                    const noRecordObj = {
                                        question: answers.question,
                                        questionId: answers.questionId,
                                        sqlQuery: answers.query,
                                        answerId: eachAnswer.items.answerId,
                                        model: eachAnswer.items.modelInfo.model
                                    };
                                    explainForNoRecord(noRecordObj)
                                }
                            })
                        }
                    })
                }
            })
        }

    }, [assistantAnswer])

    const [visualizedAnswerResult, setVisualizedAnswerResult] = useState([])
    const [noRecordExplain, setNoRecordExplain] = useState([])

    useEffect(() => {
        if (visualizedAnswerResult && visualizedAnswerResult.length) {
            const questionAnswerList = [...assistantAnswer];
            const getQuestionAnswerList = questionAnswerList.map(question => {
                if (question && question.items && question.items.length) {
                    const questionItem = question.items.map(item => {
                        const answerItem = item.answer.map(ans => {
                            const visualizedItem = visualizedAnswerResult.find(v => v.answerId === ans.items.answerId);
                            if (visualizedItem) {
                                ans.items.visualResult = {
                                    graphSelected: true,
                                    ...visualizedItem.visualizationResult
                                };
                            }
                            return ans;
                        })
                        item.answer = answerItem;
                        return item;
                    })
                    question.items = questionItem;
                }
                return question;
            })
            setAssistantAnswer(getQuestionAnswerList)
        }
    }, [visualizedAnswerResult])

    const visualizeUserQueryResult = async (obj) => {
        const resp = await visualizeResultService(obj);
        if (resp && resp.success) {
            const visualData = resp.data;
            setVisualizedAnswerResult(prevItems => [...prevItems, visualData])
        }
    }

    useEffect(() => {
        if (noRecordExplain && noRecordExplain.length) {
            const questionAnswerList = [...assistantAnswer];
            const getQuestionAnswerList = questionAnswerList.map(question => {
                if (question && question.items && question.items.length) {
                    const questionItem = question.items.map(item => {
                        const answerItem = item.answer.map(ans => {
                            const answerItem = noRecordExplain.find(v => parseInt(v.answerId) === ans.items.answerId);
                            if (answerItem) {
                                ans.items.noRecordExplanation = answerItem.explanation;
                            }
                            return ans;
                        })
                        item.answer = answerItem;
                        return item;
                    })
                    question.items = questionItem;
                }
                return question;
            })
            setAssistantAnswer(getQuestionAnswerList)
        }
    }, [noRecordExplain])

    const explainForNoRecord = async (obj) => {
        const resp = await explainForNoRecordService(obj);
        if (resp && resp.success) {
            const visualData = resp.data;
            setNoRecordExplain(prevItems => [...prevItems, visualData])
        }
    }

    const scrollToDown = () => {
        setTimeout(() => {
            let modalElement = document.getElementById('aimodalcontent');
            if (modalElement) {
                modalElement.scrollTop = modalElement.scrollHeight;
            }

        }, 10)
    }

    const modalMinimize = () => {
        setShowMaximizeIcon(true)
        setShowMinimizeIcon(false)
        let body = document.getElementById('approot');
        body.classList.add('overflow-auto')
        let modalElement = document.getElementById('aimodalbox');
        modalElement.classList.add('bl-minimize-modal')
        let modalContent = document.getElementById('preaimodalcontent');
        modalContent.classList.add('bl-minimize-content')
        let modalBody = document.getElementById('aimodalbody');
        modalBody.classList.add('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.add('d-none')
    }

    const modalMaximize = () => {
        setShowMaximizeIcon(false);
        setShowMinimizeIcon(true)
        let body = document.getElementById('approot');
        body.classList.remove('overflow-auto')
        let modalElement = document.getElementById('aimodalbox');
        modalElement.classList.remove('bl-minimize-modal')
        let modalContent = document.getElementById('preaimodalcontent');
        modalContent.classList.remove('bl-minimize-content')
        let modalBody = document.getElementById('aimodalbody');
        modalBody.classList.remove('d-none')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    const modalClose = () => {
        setAIModalType('')
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
    }

    const getDropdownMenu = (dropdownId) => {
        return (
            <div className="dropdown-menu" aria-labelledby={dropdownId}>
                <button className="dropdown-item text-secondary pl-3" disabled>
                    <i className="fa fa-thumb-tack fa-sm mr-2"></i>
                    Pin Widget
                </button>
                <button className="dropdown-item text-secondary pl-3" disabled>
                    <i className="fa fa-print fa-sm mr-2"></i>
                    Print
                </button>
                <button className="dropdown-item text-secondary pl-3" disabled>
                    <i className="fa fa-download fa-sm mr-2"></i>
                    Download
                </button>
                <button className="dropdown-item text-secondary pl-3" disabled>
                    <i className="fa fa-share fa-sm mr-2"></i>
                    Share
                </button>
            </div>
        )
    }

    const setDefaultDataValues = () => {
        // setSelectedSource([])
    }

    const redirectToModule = (report) => {
        modalMinimize()
        const categorySlug = report.catSlug ? report.catSlug : report.categoryInfo && report.categoryInfo.length ? report.categoryInfo[0].categorySlug ? report.categoryInfo[0].categorySlug : report.categoryInfo[0].subcategorySlug : '';
        nav(`/analytics/reports/${categorySlug}/${report.reportNameSlug}`)
        // nav(path)
    }

    const openNewChat = () => {
        document.getElementById('pageLoaderContainer').style.display = 'flex';
        scrollToDown()
        setIsQueryLoaded(true)
        setIsFileUploaded(false)
        setIsExistingThread(false)
        setAIModalType('DEFAULT')
        setIsQuestionAsked(false)
        setSuggestiveQuestionTitle(`${aiModalKeys.DEFAULT_SUGGESTIVE_TEXT}!`)
        setSuggestiveQuestion(defaultSuggestiveQuestion.slice(0, 4))
        setAssistantAnswer([])
        setVisualizedAnswerResult([])
        setNoRecordExplain([])
        setModalContentType('DEFAULT')
        setThreadId('')
        childRef && childRef.current && childRef.current.resetSelectedThread('');
        setViewType(viewType)
        setTimeout(() => {
            setDefaultDataValues()
        })
        setTimeout(() => {
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }, 300)
    }

    useEffect(() => {
        setSuggestiveQuestionTitle(`${aiModalKeys.DEFAULT_SUGGESTIVE_TEXT}!`)
        setUploadedFileOriginalName("")
        setUserQuestion('')
        setDefaultDataValues()
        setModalContentType(aiModalType === 'REDDIT' ? 'DEFAULT' : aiModalType)
        setIsQuestionAsked(false)
        setAssistantAnswer([])
        modalMaximize()
        scrollToDown()
        setViewType('DATA_SOURCES')
        setShowSuggestion(false)
        getSuggestiveQuestion('DEFAULT')
    }, [aiModalType])

    const getSuggestiveQuestion = async (mode) => {
        const resp = await getSuggestiveQuestionService();
        if (resp && resp.success) {
            const ques = resp.data.map(q => { return q.suggestionQuestions })
            setSuggestiveQuestion(ques.slice(0, 4))
            setDefaultSuggestiveQuestion(ques)
        }
    }

    const handleSlide = (direction) => {
        setCurrentIndex((prevIndex) => {
            if (direction === "next") {
                return prevIndex < totalSlides - 1 ? prevIndex + 1 : 0;
            } else if (direction === "prev") {
                return prevIndex > 0 ? prevIndex - 1 : totalSlides - 1;
            }
            return prevIndex;
        });
    };

    const itemsPerSlide = 2;
    const totalSlides = Math.ceil(aiModelList.length / itemsPerSlide);

    const defaultAIContextForDefaultView = () => {
        return (
            <>
                <div className="text-center">
                    {
                        aiModelList.length ? (
                            !hideSuggestions ? (
                                <>
                                    <button onClick={() => setHideSuggestions(true)} className="d-none d-sm-inline-block btn btn-sm btn-outline-danger px-3 mr-2"> Hide This </button>
                                    <button className="btn btn-sm btn-danger px-4" onClick={() => handleSlide("next")} href="#carouselExampleControls" role="button" data-slide="next">
                                        <span>Next Suggestion ({totalSlides ? currentIndex + 1 : 0}/{totalSlides})</span>
                                        <i className="fa fa-chevron-right ml-2"></i>
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={() => setHideSuggestions(false)}
                                    className="d-none d-sm-inline-block btn btn-sm btn-outline-danger px-3"
                                >
                                    <i className="fa fa-question-circle" aria-hidden="true"></i> View Suggestions
                                </button>
                            )
                        ) : null
                    }
                </div>
                {!hideSuggestions && aiModelList.length > 0 && (
                    <div id="carouselExampleControls" className="carousel slide carousel-fade mr-n3 ml-n3 bl-helpText-containerAnchor" data-interval="false">
                        <section>
                            <div className="carousel-inner p-4">
                                {Array.from({ length: totalSlides }).map((_, slideIndex) => (
                                    <div
                                        key={slideIndex}
                                        className={`carousel-item ${slideIndex === 0 ? "active" : ""} h-auto`}
                                    >
                                        <div className="card-deck d-flex justify-content-center">
                                            {aiModelList
                                                .slice(slideIndex * itemsPerSlide, (slideIndex + 1) * itemsPerSlide)
                                                .map((item, index) => (
                                                    <div key={index} className="card mx-2 bl-helpText-borderColor bl-helpText-card-height">
                                                        <div className="card-body p-3">
                                                            <h6 className="card-title text-left">
                                                                <div className="d-flex justify-content-between align-items-center mb-1">
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="fa fa-database mr-1"></i>
                                                                        <b className="text-dark bl-truncate-line-1">{item.displayName ? item.displayName : toTitleCase(item.datasetName)}</b>
                                                                    </div>
                                                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                                        <img
                                                                            src="https://www.svgrepo.com/show/372603/pop-out.svg"
                                                                            height="13px"
                                                                            alt="Pop out icon"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <div className="bl-truncate-line-3 font-smaller font-weight-normal bl-helpText-header-height text-muted">{item?.description || item.definition}</div>
                                                                </div>
                                                            </h6>
                                                            {
                                                                getSuggestiveQuestionInCardViewContent(item)
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                )}
            </>
        );
    }

    const getSuggestiveQuestionInCardViewContent = (items) => {
        const userQuestions = items.suggestionQuestions && items.suggestionQuestions.map(q => {
            return q.question
        })
        const _questions = userQuestions && userQuestions.length ? userQuestions : items?.defaultQuestions || [];
        const questions = _questions.slice(0, 3);
        return (
            <p className="card-text">
                {
                    questions && questions.map((data, j) => (
                        <button key={`user-based-suggestive-questions-${j}`} type="button" onClick={(e) => { getQuestionInputByUser(e, data) }} className="btn btn-light btn-block d-flex justify-content-between align-items-center border-light text-left">
                            <div className="bl-truncate-line-2 bl-helpText-questionTextFont" title={data}>
                                {data.trim()}
                            </div>
                            <i className="fa fa-angle-right ml-2" aria-hidden="true"></i>
                            {/* <img src="https://icones.pro/wp-content/uploads/2021/06/icone-fleche-droite-grise.png" height="18px" alt="Arrow icon"/> */}
                        </button>
                    ))
                }
            </p>
        )
    }

    const defaultModalContentView = () => {
        return (
            <section className={`mb-3 ${!isExistingThread && 'bl-helpText-containerMargin'}`}>
                {
                    !isExistingThread ? (
                        <div className="text-center bl-pt-20">
                            <img src={companySmallLogo} height="75px" className="shadow-lg rounded-circle" />
                            {/* <h1 className="h3 text-gray-900">Welcome to <img className="img-fluid mb-1 bl-h-2" src={kairosLogo} alt="app-logo" /></h1> */}
                            <h1 className="h3 text-gray-900">What can I help with?</h1>
                        </div>
                    ) : null
                }

                {
                    // !isExistingThread ? defaultContextForDefaultView() : null
                    !isExistingThread ? defaultAIContextForDefaultView() : null
                }
                {
                    // !isExistingThread ? (
                    //     <div className="bl-mt-7rem">
                    //         {
                    //             (selectedSource && selectedSource.length > 0) || (userSchema && userSchema.length > 0) ? getSuggestiveQuestionContent(true) : null
                    //         }

                    //     </div>
                    // ) : null
                }

            </section>
        )
    }

    const getSuggestiveQuestionContent = (canShow, title, suggestion) => {
        const suggestiveQues = suggestion && suggestion.length ? suggestion : suggestiveQuestion;
        const suggestiveTitle = title ? title : suggestiveQuestionTitle;
        if (canShow) {
            return (
                suggestiveQues.length ? (
                    <section className="">
                        <p className="text-muted" dangerouslySetInnerHTML={{ __html: suggestiveTitle }}></p>
                        <div className="card-deck bl-ai-card-deck">
                            {
                                suggestiveQues.map((ques, i) => {
                                    let question = ques ? ques.title ? ques.title : ques : '';
                                    return (
                                        <div key={`question-sug-${i}`} className="card">
                                            <div className="card-body bl-body-hover p-2 d-flex" onClick={(e) => { getQuestionInputByUser(e, question) }}>
                                                {/* <i className="fa fa-question-circle-o mr-2 text-gray align-self-center" aria-hidden="true"></i> */}
                                                <p className="mb-0 font-small cp align-self-center">{question}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>) : null
            )
        }
    }

    const getQuestionFromSourceModal = (question) => {
        if (selectedSource.length > 0 && question) {
            userInputQuestionFromServer(question)
        }
    }

    const getQuestionInputByUser = (e, question) => {
        e.preventDefault();
        if (loggedUserMail.toLowerCase() === userMail.toLowerCase()) {
            if (selectedSource.length > 0 && question) {
                userInputQuestionFromServer(question)
            }
            else if (rolesModelInfo?.modelType === "CANVAS") {
                userInputQuestionFromServer(question);
            }
        }
    }

    const getLastAns = (itemListInfo, model) => {
        const ansModel = model && model.aiModelId ? model : defaultModel;
        const itemsInfo = itemListInfo.find(info => info.answer[0].items.modelInfo.aiModelId === ansModel.aiModelId);
        const items = itemsInfo && itemsInfo.answer ? itemsInfo.answer[0].items : null;
        setFeedback({
            userQuery: "",
            questionId: null,
            answerId: null
        })
        setLastAnsItem(items)
    }

    const getFeedbackContent = (items, model) => {
        return (
            <div className="card-footer p-2">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 text-muted small">Are you satisfied with the above response ?</p>
                    <div className="card bg-gray">
                        <div className="card-body px-2 py-1 d-flex align-items-center cp" onClick={() => getLastAns(items, model)} data-toggle="modal" data-target="#feedbackModal">
                            <i className="fa fa-comment text-muted mr-2" aria-hidden="true"></i>
                            <p className="mb-0 text-muted small">Raise an issue / Provide Feedback</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let latestQuestion = {
        question: "",
        loading: false,
        assistant: aiModalKeys.DEFAULT_ASSISTANT,
        answer: [{ viewMode: 'SERVER_RESP', items: {} }]
    }

    const userInputQuestionFromServer = async (question, quesId, model) => {
        if (!isQueryLoaded) {
            return
        }
        setIsQuestionAsked(true)
        setIsLoaded(false)
        setIsQueryLoaded(false)
        let answers = [...assistantAnswer];
        const answerMode = {
            question: question,
            loading: true,
            assistant: aiModalKeys.DEFAULT_ASSISTANT,
            answer: [{ viewMode: 'SERVER_RESP', items: {} }]
        }
        latestQuestion = answerMode;
        setInitialQuery({
            question: question,
            assistant: aiModalKeys.DEFAULT_ASSISTANT
        })
        if (quesId) {
            let matchedQuest = answers.findIndex(ans => ans.questionId == quesId);
            if (matchedQuest !== -1) {
                answers[matchedQuest].loading = true;
                answers[matchedQuest].assistant = aiModalKeys.DEFAULT_ASSISTANT;
            }
        }
        else {
            answers.push(answerMode);
            scrollToDown()
        };
        const answerLength = answers.length;
        setAssistantAnswer(answers);
        setUserQuestion('')
        if (question) {
            const modelId = model && model.aiModelId ? model.aiModelId : defaultModel ? defaultModel.aiModelId : null;
            setQuestionedModelInfo(model ? model : defaultModel)
            const obj = {
                threadId: typeof threadId === 'string' ? threadId : threadId.toString(),
                userQuery: question,
                questionNumber: quesId ? quesId.toString() : "",
                model: modelId ? modelId.toString() : null,
                modelId: rolesModelInfo?.modelType === 'SB_MD' ? (sbModelId) : (rolesModelInfo && rolesModelInfo.modelType && (rolesModelInfo.modelType.toUpperCase() === "SB" || rolesModelInfo.modelType.toUpperCase() === "CANVAS") ? rolesModelInfo.sbModelId : rolesModelId),
                userSchema: applicableUserSchema
            }
            if (rolesModelInfo && rolesModelInfo.modelType && rolesModelInfo.modelType.toUpperCase() === "CANVAS") {
                obj['modelType'] = "SB_MD";

            }
            else if (rolesModelInfo && (rolesModelInfo?.modelType || rolesModelInfo?.type)) {
                obj['modelType'] = rolesModelInfo?.modelType || rolesModelInfo?.type || null;
            }
            const tagList = dataSourceMenu.find(menu => menu.type === "TAGS");
            const selectedTags = [];
            tagList && tagList.items && tagList.items.length && tagList.items.forEach(tags => {                
                tags && tags.list && tags.list.forEach(tag => {                    
                    if (tag.isSelected) {
                        selectedTags.push(tag?.originalTitle || tag.title)
                    }
                });
                if (tags.isSelected) {
                    selectedTags.push(tags?.originalTitle || tags.title)
                }
            })            
            if (selectedTags && selectedTags.length) {
                obj['tags'] = selectedTags;
            }
            const resp = await askQueryService(obj);
            setIsLoaded(true)
            setIsQueryLoaded(true)
            latestQuestion.loading = false;
            if (resp && resp.data) {
                resp.graphMode = 'TABLE';
                resp.viewMode = 'RESULT';
                resp.executedOn = moment().format();
                resp.question = question;
                if (!threadId) {
                    getThreadCount()
                    if (viewType === "CONVERSATIONS") {
                        childRef && childRef.current && childRef.current.getThreadFromParent();
                    }
                }
                const allAnswers = [];
                assistantAnswer.forEach(item => {
                    if (item.items && Array.isArray(item.items)) {
                        item.items.forEach(itemAns => {
                            allAnswers.push(itemAns)
                        })
                    }
                    else {
                        allAnswers.push(item)
                    }
                })
                let indx = allAnswers.findIndex(a => a.assistant === aiModalKeys.DEFAULT_ASSISTANT);
                if (indx !== -1 && !quesId) {
                    allAnswers.splice(indx, 1)
                }
                let answerMode = {
                    question: question,
                    assistant: resp.title ? resp.title : aiModalKeys.DEFAULT_ASSISTANT_REPLY,
                    query: resp.data.sqlQuery,
                    analysisStrategy: resp.data.analysisStrategy ? resp.data.analysisStrategy : "",
                    analysisStrategyHTML: resp.data.analysisStrategyHTML ? resp.data.analysisStrategyHTML : "",
                    tablesList: resp?.tableNames || [],
                    followUpQuestions: resp.followUpQuestions,
                    executedOn: moment().format(),
                    questionId: resp.questionId,
                    answer: [{ viewMode: 'SERVER_RESP', items: resp }]
                }
                allAnswers.push(answerMode);
                const updatedAnswer = groupedTheQuestionsThread(allAnswers);
                const selectedConversation = resp.threadId ? resp.threadId : "";
                setThreadId(selectedConversation)
                childRef && childRef.current && childRef.current.resetSelectedThread(selectedConversation);
                if (quesId && model && model.aiModelId != defaultModel.aiModelId) {
                    const matchedIndx = updatedAnswer.findIndex(item => item.questionId == quesId);
                    updatedAnswer[matchedIndx].modelInfo = model;
                    updatedAnswer[matchedIndx].loading = false;
                    setAssistantAnswer(updatedAnswer);
                }
                else setAssistantAnswer(updatedAnswer);
            }
            else {
                let message = aiModalKeys.ERROR_MESSAGE.DEFAULT;
                let removeAnswerBody = false;
                if (resp.response) {
                    if (resp.response.status === 504) {
                        message = aiModalKeys.ERROR_MESSAGE.TIME_OUT;
                        removeAnswerBody = !!resp.response.data.removeAnswerBody;
                    }
                    else if (resp.response.data && resp.response.data.message) {
                        message = resp.response.data.message;
                        removeAnswerBody = !!resp.response.data.removeAnswerBody;
                    }
                }
                let allAnswers = [...assistantAnswer];
                const openAIModel = model ? model : defaultModel;
                const quesItems = {
                    question: question,
                    loading: false,
                    assistant: message,
                    modelInfo: openAIModel,
                    removeAnswerBody: removeAnswerBody,
                    answer: [{
                        viewMode: 'SERVER_RESP', items: {
                            modelInfo: openAIModel
                        }
                    }]
                }
                if (quesId) {
                    const quesIndx = allAnswers.findIndex(a => a.questionId && a.questionId.toString() === quesId.toString());
                    allAnswers[quesIndx].loading = false;
                    allAnswers[quesIndx].removeAnswerBody = removeAnswerBody;
                    allAnswers[quesIndx].items.push(quesItems)
                }
                else {
                    if (allAnswers.length === answerLength - 1 && !quesId) {
                        const answerMode = {
                            question: question,
                            loading: false,
                            assistant: message,
                            modelInfo: openAIModel,
                            removeAnswerBody: removeAnswerBody,
                            answer: [{ viewMode: 'SERVER_RESP', items: {} }],
                            items: [quesItems]
                        }
                        allAnswers.push(answerMode)
                    }
                }
                setAssistantAnswer([]);
                setTimeout(() => {
                    setAssistantAnswer(allAnswers);
                }, 100)
            }
        }
    }

    function groupByQuestionId(array) {
        return array.reduce((acc, obj) => {
            const questionId = parseInt(obj.questionId);
            if (!acc[questionId]) {
                acc[questionId] = [];
            }
            acc[questionId].push(obj);
            return acc;
        }, {});
    }

    const groupedTheQuestionsThread = (originalArray) => {
        const groupedArray = groupByQuestionId(originalArray);
        const newArray = Object.keys(groupedArray).map(questionId => {
            const items = groupedArray[questionId];
            const { question, assistant, executedOn, answerId } = items[0];
            return {
                questionId: questionId,
                modelInfo: items[0].answer[0].items.modelInfo,
                question: question,
                assistant: assistant,
                items: items,
                viewMode: items[0].answer[0].items.viewMode,
                graphMode: items[0].answer[0].items.graphMode,
                executedOn: executedOn ? executedOn : new Date(),
                answerId: answerId
            };
        });
        return newArray.sort(function (a, b) { return a.questionId - b.questionId })
    }

    const changeGraphMode = (indx, secondIndx, mode) => {
        let updatedAnswerList = [...assistantAnswer];
        let updatedAnswer = [...updatedAnswerList[indx].answer];
        updatedAnswer[secondIndx].items.graphMode = mode;
        updatedAnswerList[indx].answer = updatedAnswer;
        setAssistantAnswer(updatedAnswerList);
    }

    const changeModelWiseGraphMode = (indx, mode, model) => {
        const answerListItems = [...assistantAnswer];
        const itemListInfo = answerListItems[indx];
        const matchedAnswer = itemListInfo.items.find(item => item.answer[0].items && item.answer[0].items.modelInfo ? item.answer[0].items.modelInfo.aiModelId == model.aiModelId
            : item.modelInfo && item.modelInfo.aiModelId == model.aiModelId);
        if (matchedAnswer) {
            let updatedAnswerList = [...assistantAnswer];
            let newIndx = updatedAnswerList[indx].items.findIndex(item => item.answer[0].items && item.answer[0].items.modelInfo ? item.answer[0].items.modelInfo.aiModelId == model.aiModelId
                : item.modelInfo && item.modelInfo.aiModelId == model.aiModelId);
            updatedAnswerList[indx].items[newIndx].answer[0].items.graphMode = mode;
            setAssistantAnswer(updatedAnswerList)
        }
    }

    const changeViewMode = (itemListInfo, indx, mode, model) => {
        if (mode === 'RESULT') {
            const matchedAnswer = itemListInfo.find(item => item.answer[0].items && item.answer[0].items.modelInfo ? item.answer[0].items.modelInfo.aiModelId == model.aiModelId
                : item.modelInfo && item.modelInfo.aiModelId == model.aiModelId);
            if (matchedAnswer) {
                let updatedAnswerList = [...assistantAnswer];
                updatedAnswerList[indx].modelInfo = model;
                updatedAnswerList[indx].loading = false;
                let newIndx = updatedAnswerList[indx].items.findIndex(item => item.answer[0].items && item.answer[0].items.modelInfo ? item.answer[0].items.modelInfo.aiModelId == model.aiModelId
                    : item.modelInfo && item.modelInfo.aiModelId == model.aiModelId);
                updatedAnswerList[indx].items[newIndx].answer[0].items.viewMode = mode;
                updatedAnswerList[indx].items[newIndx].answer[0].items && updatedAnswerList[indx].items[newIndx].answer[0].items.modelInfo ? updatedAnswerList[indx].items[newIndx].answer[0].items.modelInfo = model : updatedAnswerList[indx].items[newIndx].modelInfo = model;
                setAssistantAnswer(updatedAnswerList)
            }
            else {
                if (loggedUserMail.toLowerCase() === userMail.toLowerCase()) {
                    userInputQuestionFromServer(itemListInfo[0].question, itemListInfo[0].questionId, model)
                }
            }
        }
        else {
            let updatedAnswerList = [...assistantAnswer];
            let newIndx = updatedAnswerList[indx].items.findIndex(item => item.answer[0].items.modelInfo.aiModelId == model.aiModelId);
            updatedAnswerList[indx].items[newIndx].answer[0].items.viewMode = mode;
            setAssistantAnswer(updatedAnswerList)
        }
    }

    const applyTheme = () => {
        const appThemes = getAppBehavior();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    useEffect(() => {
        applyTheme()
    }, [selectedSource])

    const serverContentView = (itemListInfo, indx, secondIndx, model) => {
        setTimeout(() => {
            applyTheme()
        }, 50)
        const ansModel = model && model.aiModelId ? model : defaultModel;
        const itemsInfo = itemListInfo && itemListInfo.find(info => info.answer[0].items && info.answer[0].items.modelInfo ? info.answer[0].items.modelInfo.aiModelId === ansModel.aiModelId
            : info.modelInfo && info.modelInfo.aiModelId === ansModel.aiModelId);
        const items = itemsInfo && itemsInfo.answer ? itemsInfo.answer[0].items : null;
        const modelInfo = items?.modelInfo || ansModel;
        const explanationCondition = items?.reddit || items?.googleReviews || items?.news || [];
        const queryFails = items && items.result ? !Array.isArray(items.result) : explanationCondition && explanationCondition.length ? false : true;
        // const queryFails = !Array.isArray(items?.result) || !(explanationCondition?.length > 0);
        const isSbResp = !!(items?.from === "SB");
        const isOtherResult = items && items.result ? false : explanationCondition && explanationCondition.length ? true : false;
        return (
            <section key={`query-result-${indx}`}>
                {
                    !queryFails ? (
                        <div>
                            {
                                !isSbResp ?
                                    <div className="row">
                                        <div className="col text-center">
                                            <div className="btn-group btn-group-sm mb-3" role="group" aria-label="Button group with nested dropdown">
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <button type="button" onClick={() => changeViewMode(itemListInfo, indx, 'RESULT', modelInfo)} className={`btn ${items?.viewMode === 'RESULT' ? 'btn-primary active' : 'btn-outline-primary'} dropdown-toggle`}>Results ({modelInfo.displayName})</button>
                                                    <button type="button" className={`btn ${items?.viewMode === 'RESULT' ? 'btn-primary active' : 'btn-outline-primary'} dropdown-toggle dropdown-toggle-view dropdown-toggle-split`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu shadow-sm" aria-labelledby="btnGroupDrop1">
                                                        <a className="px-3 py-3 muted">Select Model</a>
                                                        {
                                                            aiModels.map((model, i) => {
                                                                return (
                                                                    <React.Fragment key={`models-${i}`}>
                                                                        {
                                                                            model.enabledGroup ? null : (
                                                                                <React.Fragment key={`models-divider-${i}`}>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <a className="px-3 py-3 muted small">Not enabled for you</a>
                                                                                </React.Fragment>
                                                                            )
                                                                        }
                                                                        {
                                                                            model.items.map((item, j) => {
                                                                                return (
                                                                                    <a key={`ai-model-${i}${j}`} className={`dropdown-item ${item.isDisabled ? 'disabled' : 'cp'}`} onClick={() => changeViewMode(itemListInfo, indx, 'RESULT', item)}>
                                                                                        <div className="media">
                                                                                            <img className="align-self-center mr-3" src={item.image} data-holder-rendered="true" height={`${item.isDisabled ? '15px' : '20px'}`} />
                                                                                            <div className="media-body p-0">
                                                                                                <p className="p-0 mb-0 text-dark"><b>{item.displayName}</b></p>
                                                                                                <p className="mb-0 p-0 text-muted">{item.description}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                                <button type="button" className={`btn ml-1 ${queryFails && 'disabled'} ${items?.viewMode === 'EXPLANATION' ? 'btn-primary active' : 'btn-outline-primary'}`} onClick={() => changeViewMode(itemListInfo, indx, 'EXPLANATION', modelInfo)}>View Explanation</button>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            <div className="mb-3">
                                {
                                    items?.viewMode === 'EXPLANATION' ? serverContentResultView(items, indx, isOtherResult) :
                                        <>
                                            {(rolesModelInfo?.modelType === 'SB_MD' || rolesModelInfo?.modelType === 'CANVAS') ? '' : getQueryExplanationContent(items, indx)}
                                            {serverSideContent(items, indx, secondIndx, false, modelInfo, queryFails)}
                                            {getReportContent(items, indx)}
                                            {showCitations(items, indx)}
                                        </>
                                }
                            </div>
                        </div>
                    ) : null
                }

                {
                    items && items.followUpQuestions && items.followUpQuestions.length ? (
                        <div className="pt-2">
                            {getSuggestiveQuestionContent(true, aiModalKeys.SUGGESTIVE_TEXT, items.followUpQuestions)}
                        </div>
                    ) : null
                }

            </section>
        )
    }

    const serverContentResultView = (ans, i, isOtherResult) => {

        return (
            <section className="mb-3">
                {
                    isOtherResult ? otherResultExplanation(ans, i) : (
                        <div className="row">
                            <div className="card-deck col-12 mb-3">
                                {
                                    ans.tableNames ? <div className="card col">
                                        {queryTables(ans, i)}
                                    </div> : null
                                }

                                <div className="card col">
                                    {responseProperty(ans)}
                                </div>

                            </div>
                            <div className="card-deck col-12">
                                <div className="card col-12 border-0 p-0">
                                    <div className="card-body p-0 bl-ai-insight">
                                        {
                                            queryFormat(ans.data.sqlQuery)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </section>
        )
    }

    const otherResultExplanation = (ans, i) => {
        const items = {
            reddit: ans?.reddit || [],
            googleReviews: ans?.googleReviews || [],
            news: ans?.news || []
        }
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card border-0">
                        <div className="card-body border-0 p-0">
                            {
                                items.reddit && items.reddit.length ? (
                                    <div className={items.googleReviews && items.googleReviews.length ? 'border-bottom' : ''}>
                                        <h6 className="text-muted d-flex align-items-center"><img className="mr-2" src={redditIcon} alt="reddit-icon" height="18px" />Reddit</h6>
                                        {
                                            items.reddit.map((r, i) => {
                                                return (
                                                    <div key={`reddit-exp-${i}`} className="mb-3">
                                                        <p className="mb-0">{r.subreddit} - <span className="text-muted font-small">{getRelativeTime(r.date)}</span></p>
                                                        {
                                                            r.url ? <a href={r.url} target="_blank" className="bl-truncate-line-1">{r.title}</a> : <p className="bl-truncate-line-1 mb-0">{r.title}</p>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                items.googleReviews && items.googleReviews.length ? (
                                    <div className={items.news && items.news.length ? 'border-bottom pt-3' : 'pt-3'}>
                                        <h6 className="text-muted d-flex align-items-center"><img className="mr-2" src={reviewsIcon} alt="review icon" height="18px" />Google Reviews</h6>
                                        {
                                            items.googleReviews.map((r, i) => {
                                                return (
                                                    <div key={`review-exp-${i}`} className="mb-3">
                                                        <p className="mb-0 d-flex">{r.storeName} {
                                                            r.rated ? <Rating fractions={2} initialRating={r.rated} className="text-warning d-block ml-2" readonly emptySymbol="fa fa-star-o mr-1"
                                                                fullSymbol="fa fa-star mr-1" /> : null
                                                        } <span className="text-muted font-small">{getRelativeTime(r.reviewDate)}</span></p>

                                                        {
                                                            r.url ? <a className="bl-truncate-line-1" href={r.url} target="_blank"><span className="text-muted font-small"><i className="fa fa-user-circle mr-2"></i>{r.author}</span>: {r.comment}</a> : <p className="bl-truncate-line-1 mb-0"><span className="text-muted font-small"><i className="fa fa-user-circle mr-2"></i>{r.author}</span>: {r.comment}</p>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                items.news && items.news.length ? (
                                    <div className="pt-3">
                                        <h6 className="text-muted d-flex align-items-center"><img className="mr-2" src={newsIcon} alt="news-icon" height="18px" />Google News</h6>
                                        {
                                            items.news.map((r, i) => {
                                                return (
                                                    <div key={`new-exp-${i}`} className="mb-3">
                                                        <p className="mb-0 d-flex align-items-baseline"><span className="bl-truncate-line-1 mw-85 mr-1">{r.title} -</span><span className="text-muted font-small">{getRelativeTime(r.publishedAt)}</span></p>
                                                        {
                                                            r.url ? <a href={r.url} target="_blank" className="bl-truncate-line-1">{r.description}</a> : <p className="bl-truncate-line-1 mb-0">{r.description}</p>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table mb-0">
                                <table width={'100%'} cellPadding={'0'} cellSpacing={'0'} border="0">
                                    <tbody>
                                        <tr>
                                            <td className="border-0">
                                                <p className="text-muted mb-0 font-small">Status</p>
                                                <span className="badge badge-success">Success</span>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-muted mb-0 font-small">Response Confidence</p>
                                                <p className="mb-1">High</p>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-muted mb-0 font-small">LLM Model Used</p>
                                                <p className="mb-1">{ans.modelInfo.displayName}</p>
                                            </td>
                                            <td className="border-0">
                                                <p className="text-muted mb-0 font-small">Data as of</p>
                                                <p className="mb-1">{moment(ans.executedOn).format('MMM DD, yyyy')}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const queryTables = (ans, i) => {
        const tableUsed = ans.tableNames && ans.tableNames.length && ans.tableNames.filter(table => {
            const tables = userSchema.find(schema => schema.originalTableName === table);
            if (tables && tables.originalTableName) {
                return tables.originalTableName
            }
        })
        return (
            ans.tableNames ?
                <>
                    <div className="card-header border-0 text-muted pl-0 pb-0">
                        Table Used
                    </div>
                    <div className="card-body pl-0 row">
                        {
                            ans.tableNames && ans.tableNames.length && ans.tableNames.map((table, k) => {
                                return (
                                    <div key={`db-table-${k}-${i}`} className="col-6 d-flex px-2 pb-2">
                                        <i className="fa fa-database mr-3 pt-1" aria-hidden="true"></i>
                                        <h6 className="mb-0 font-small text-break text-capitalize">{convertCamelCaseToSeparateWords(table)}</h6>
                                    </div>
                                )
                            })
                        }
                    </div>
                </> : null
        )
    }

    const responseProperty = (ans) => {
        return (
            <div className="card-body p-0">
                <div className="table mb-0">
                    <table width={'100%'} cellPadding={'0'} cellSpacing={'0'} border="0">
                        <tbody>
                            <tr>
                                <td className="border-0">
                                    <p className="text-muted mb-0 font-small">Status</p>
                                    <span className="badge badge-success">Success</span>
                                </td>
                                <td className="border-0">
                                    <p className="text-muted mb-0 font-small">Response Confidence</p>
                                    <p className="mb-1">High</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="border-0">
                                    <p className="text-muted mb-0 font-small">LLM Model Used</p>
                                    <p className="mb-1">{ans.modelInfo.displayName}</p>
                                </td>
                                <td className="border-0">
                                    <p className="text-muted mb-0 font-small">Data as of</p>
                                    <p className="mb-1">{moment(ans.executedOn).format('MMM DD, yyyy')}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const serverSideContent = (items, indx, secondIndx, isFeedbackSection, model, queryFails) => {
        if (!items) {
            return ''
        }
        if (items.markdown) {
            const id = `markdownMermaid${indx}`
            return (
                <MarkdownMermaidRenderer contentId={id} markdownContext={items.markdown} />
            )
        }
        else if (items.analysisStrategyHTML) {
            const contId = `analysisStrategyHTML${indx}${secondIndx}`
            return (
                <>
                    <InnerHTMLComponent key={`innerhtml-part-${indx}-${secondIndx}`} htmlContent={items.analysisStrategyHTML} contentId={contId} isChartElement={false} />
                </>
            )
        }
        else if (items.analysisStrategy) {
            return (
                <>
                    <p className="mb-0">{items.analysisStrategy}</p>
                </>
            )
        }
        else if (items && items.result && items.result.length && Array.isArray(items.result)) {
            const searchString = "metrics";
            const metricsFound = items.tableNames ? items.tableNames.some(item => item.toLowerCase().includes(searchString.toLowerCase())) : false;
            if (items.result.length === 1 && Object.keys(items.result[0]).length === 1) {
                const obj = items.result[0];
                const keys = Object.keys(obj);
                const key = keys[0];
                const value = obj[key];
                return (
                    <>
                        <p className="mb-0">
                            <span className="text-capitalize">{replaceUnderscoreFromWord(key)}</span>: <b>{value}</b>
                        </p>
                    </>

                )
            }
            else if (items.result.length > 1 && Object.keys(items.result[0]).length === 1) {
                let key = "";
                const valuesArray = [];
                items.result.forEach(function (obj) {
                    key = Object.keys(obj)[0];
                    var value = obj[key];
                    valuesArray.push(value);
                });
                return (
                    <div>
                        <h6 className="text-capitalize">{replaceUnderscoreFromWord(key)}</h6>
                        <ul>
                            {
                                valuesArray.map((val, i) => {
                                    return <li key={`list-view-result-${i}`}>{val}</li>
                                })
                            }
                        </ul>

                    </div>
                )
            }
            else if (items.result.length === 1 && items.question.toLowerCase().includes(searchString) && metricsFound) {
                const listData = items.result[0];
                let listItems = [];
                for (let key in listData) {
                    if (listData.hasOwnProperty(key)) {
                        listItems.push({ key: key, val: listData[key] })
                    }
                }
                return (
                    <>
                        <table width={'80%'}>
                            <tbody>
                                {
                                    listItems.map((item, i) => {
                                        return <tr key={`metrics-view-${i}`}>
                                            <td>{replaceUnderscoreFromWord(item.key)}</td>
                                            <td><b>{item.val === null || (item.val && item.val.toLowerCase() === "none") ? 'n.a.' : item.val}</b></td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </>

                )
            }
            else {
                if (isFeedbackSection) {
                    return getListViewContent(items.result, indx)
                }
                else {
                    return (
                        <>
                            {items.visualRepresentation && (items.visualRepresentation === true || items.visualRepresentation === 'true') && getInsightsContent(items.visualResult, indx, items.graphMode, model, items.result.length > 1)}
                            {getTableViewWithHeaderContent(items, indx, model)}
                        </>
                    )
                }
            }

        }
        else if (items.noRecordExplanation) {
            return (
                <p className="mb-0">{items.noRecordExplanation}</p>
            )
        }
        else if (!queryFails && (items && !Array.isArray(items.result) || (Array.isArray(items.result) && items.result.length === 0))) {
            return (
                <span className="loader"></span>
            )
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const debounceDelay = 1000;

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchThread(searchTerm)
        }, debounceDelay);

        return () => {
            clearTimeout(timer);
        };
    }, [searchTerm])

    const searchInHistory = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value)
        childRef && childRef.current && childRef.current.searchInThread(e.target.value)
    }

    const handleKeySearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setSearchThread(searchTerm)
        }
    }

    const getInsightsContent = (item, indx, mode, model, showActionBtn) => {
        return (
            <section key={`insights-${indx}`} className="mb-3">
                <div className="bl-ai-insight p-2 d-flex justify-content-between">

                    <div className="d-flex align-items-center">
                        <img className="img-fluid mr-3" alt="insight" src={insightLightIcon} />
                        <div>
                            {
                                item.summary ? <h6 className="text-muted font-small">INSIGHT</h6> : null
                            }
                            <p className="mb-0 font-small">{item && item.summary ? item.summary : 'Generating insights and preparing visualization for you...'}</p>
                            {
                                item.summary && showActionBtn ? <div className="mt-3">
                                    <button className={`btn bl-insight-btn btn-sm ${mode === 'TABLE' ? 'bg-white' : ''}`} type="button" onClick={() => changeModelWiseGraphMode(indx, "TABLE", model)}>Data Table</button>
                                    <button className={`btn bl-insight-btn btn-sm ml-2 ${mode === 'GRAPH' ? 'bg-white' : ''}`} type="button" onClick={() => changeModelWiseGraphMode(indx, "GRAPH", model)}>View Chart</button>
                                </div> : null
                            }
                        </div>
                    </div>
                    {
                        !item.summary ? <span className="dot-loader align-self-center mr-4"></span> : null
                    }
                </div>

            </section>
        )
    }

    const handleErrorImage = (e) => {
        e.target.src = reportIcon;
    }

    const getReportContent = (items, indx) => {
        if (items.tableMasterResult && items.tableMasterResult.data && items.tableMasterResult.data.length) {
            const reports = items.tableMasterResult.data;
            return (
                <section key={`ai-report-info-${indx}`} className="my-3 p-3 bg-gray rounded">
                    <p className="text-muted">We have found some additional information that could be relevant to this conversation</p>
                    <h6>Related Reports ({reports.length})</h6>
                    <div className="card-columns mb-0">
                        {
                            reports.map((item, i) => {
                                const isPowerBi = item.displayName.toLowerCase().includes('power');
                                const icon = isPowerBi ? powerBiIcon : sharepointIcon;
                                return (
                                    <div key={`ai-report-view-${i}`} className="card bg-grey">
                                        <div className="card-body d-flex justify-content-between p-2">
                                            <div className="d-flex">
                                                <img className="img-fluid mr-2 bl-h-1-25rem align-self-center" alt="rep-ic" src={icon} onError={handleErrorImage} />
                                                <div className="align-self-center">
                                                    <a className="font-small" href={item.link} target="_blank">{item.name}</a>
                                                    <p className="mb-0"><span className={`badge badge-pill ${isPowerBi ? 'badge-warning':'badge-primary'}`}>{item.displayName}</span></p>
                                                    {/* <p className="mb-0 text-muted font-small">{'Last updated'} {validateAndFormatDate(item.lastUpdatedOn)}</p> */}
                                                </div>
                                            </div>
                                            {/* <i className={`fa fa-shield font-small text-gray align-content-center`}></i> */}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </section>
            )
        }
    }

    const getTaskContent = (items, indx) => {
        return (
            <section key={`ai-task-${indx}`}>
                <h6>Task ({items.length})</h6>
                <div className="card-deck">
                    {
                        items.map((item, i) => {
                            return (
                                <div key={`ai-report-view-${i}`} className="card">
                                    <div className="card-body d-flex bg-gray justify-content-between" title={item.type}>
                                        <div className="d-flex">
                                            <img className="img-fluid mr-3 bl-h-1-25rem align-self-center" alt="rep-ic" src={taskIcon} />
                                            <div className="align-self-center">
                                                <p className="mb-0 font-small">{item.title}</p>
                                                <p className="mb-0 text-muted font-small">{item.date}</p>
                                            </div>
                                        </div>
                                        <i className={`fa ${item.icon} font-small text-gray`}></i>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </section>
        )
    }

    const visualizedResult = (items) => {
        return Object.keys(items[0]).length >= 2;
    }

    const getListCount = (list) => {
        if (list && Array.isArray(list) && list.length >= 50) {
            return "(Showing 50 of many)"
        }
        else return ""
    }

    const showCitations = (info, index) => {
        if (info.citations && info.citations.length) {
            return (
                <div className="col-12 mt-3 d-print-none">
                    <p className="text-muted">
                        References ({info.citations.length})
                    </p>
                    {
                        info.citations.map((reference, z) => {
                            return (
                                <span key={`sb-questions-citations-${index}-${z}`} className="badge badge-pill badge-light border rounded mr-2 text-left">
                                    {
                                        linkValidation(reference.link) ? (
                                            <a href={linkValidation(reference.link)} target="_blank">
                                                <i className="fa fa-external-link mr-2" aria-hidden="true"></i> {reference.title} </a>
                                        ) : <a>
                                            <i className="fa fa-external-link mr-2" aria-hidden="true"></i> {reference.title} </a>
                                    }

                                </span>
                            )
                        })
                    }
                </div>
            )
        }

    }

    const getQueryExplanationContent = (items, indx) => {
        if (items && items?.from === "SB" && items.queryExplanation) {
            return (
                <div className="alert alert-info alert-dismissible fade show p-0" role="alert">
                    <div className="d-flex justify-content-between align-items-center px-2 py-1 cp font-small" data-toggle="collapse" data-target={`#sbQueryExplanation${indx}`} aria-expanded="false" aria-controls={`sbQueryExplanation${indx}`}>
                        <h6 className="mb-0 font-small"><i className="fa fa-info-circle mr-2" aria-hidden="true"></i>Click here to see the speaker response</h6>
                        <i className="fa fa-expand" aria-hidden="true"></i>
                    </div>
                    <div className="collapse" id={`sbQueryExplanation${indx}`}>
                        <div className="card card-body rounded-0 p-2">
                            <p>{items.queryExplanation}</p>
                        </div>
                    </div>
                </div>
            )
        }
        const queryExplanation = items && items.queryExplanation && Array.isArray(items.queryExplanation) && items.queryExplanation.length > 0 ? items.queryExplanation : null;
        if (queryExplanation) {
            return (
                <div className="alert alert-info alert-dismissible fade show p-0" role="alert">
                    <div className="d-flex justify-content-between align-items-center px-2 py-1 cp font-small" data-toggle="collapse" data-target={`#queryexplanationcollapse${indx}`} aria-expanded="false" aria-controls={`queryexplanationcollapse${indx}`}>
                        <h6 className="mb-0 font-small"><i className="fa fa-info-circle mr-2" aria-hidden="true"></i>Click here to see how the model generated the results</h6>
                        <i className="fa fa-expand" aria-hidden="true"></i>
                    </div>
                    <div className="collapse" id={`queryexplanationcollapse${indx}`}>
                        <div className="card card-body rounded-0 p-2">
                            <ol className="mb-0 font-smaller pl-3">
                                {
                                    queryExplanation.map((list, i) => {
                                        return (
                                            <li key={`queryexplanation${indx}${i}`}>{list}</li>
                                        )
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getTableViewWithHeaderContent = (items, indx, model) => {
        const tableListItem = items.result;
        let columnSet = [];
        let fElement = tableListItem[0];
        for (let key in fElement) {
            columnSet.push({
                header: replaceUnderscoreFromWord(key),
                field: key
            })
        }
        const canVisualizeResult = visualizedResult(tableListItem);
        const contId = `innerhtmlcontent${indx}`;
        const visualResultLoaded = items.visualResult && items.visualResult.graphSelected;
        const visualHTMLResult = items.visualResult && items.visualResult.html ? items.visualResult.html : items.visualResult.graphHTML;
        const mode = items.graphMode;

        return (
            <section key={`ai-card-table-header-${indx}`}>
                <div className="card mb-3">
                    <div className="card-header d-flex justify-content-between">
                        <h6 className="mb-0 align-self-center">{`Result ${getListCount(tableListItem)}`}</h6>
                        <div className="d-flex align-item-center">

                            {
                                canVisualizeResult && items.result.length > 1 ? (
                                    <div className="d-flex">
                                        <div className="mt-1">
                                            <label className="bl-switch mb-0">
                                                <input checked={mode === 'GRAPH'} type="checkbox" onChange={() => changeModelWiseGraphMode(indx, mode === "TABLE" ? "GRAPH" : "TABLE", model)} />
                                                <span className="bl-slider round"></span>
                                            </label>
                                        </div>
                                        <label className="mb-0 mx-2">Visualize This</label>
                                    </div>
                                ) : null
                            }
                            <button className="btn btn-link btn-sm p-0 ml-3 openAIModal d-none" id="aicardheadertableview" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                            {
                                getDropdownMenu(`aicardheadertableview`)
                            }
                        </div>
                    </div>
                    <div className="card-body p-0 align-content-center">
                        {
                            canVisualizeResult && mode === 'GRAPH' ?
                                visualHTMLResult ?
                                    <InnerHTMLComponent key={`innerhtml-chart-part-${indx}`} htmlContent={visualHTMLResult} contentId={contId} isChartElement={true} /> :
                                    visualResultLoaded ?
                                        <div className="col text-center">
                                            <img src={noVizardImg} alt="no-vizard" className="img-fluid" />
                                            <h6 className="mt-n5 mb-5">Unable to visualize the results!</h6>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center bl-minh-15rem">
                                            <div className="col">
                                                <span className="loader"></span>
                                            </div>
                                        </div>
                                : <AIListViewAnswerComponent list={tableListItem} columnSet={columnSet}></AIListViewAnswerComponent>
                        }

                    </div>
                </div>
            </section>
        )
    }

    const getListViewContent = (items, indx, removeCard) => {
        let columnSet = [];
        let fElement = items[0];
        for (let key in fElement) {
            columnSet.push({
                header: convertCamelCaseToSeparateWords(key),
                field: key
            })
        }
        return (
            <div key={`list-view-${indx.toString()}`} className={removeCard ? '' : 'card'}>
                <div className="card-body p-0">
                    <AIListViewAnswerComponent list={items} columnSet={columnSet}></AIListViewAnswerComponent>
                </div>
            </div>
        )
    }

    const getAssistantQuestionAndTitle = (items, model, type) => {
        if (!items) {
            if (type === "QUESTION") {
                return initialQuery.question
            }
            else {
                return initialQuery.assistant
            }
        }
        const modelInfo = model && model.aiModelId ? model : defaultModel;
        const item = items.find(ans => ans.answer[0].items && ans.answer[0].items.modelInfo ? ans.answer[0].items.modelInfo.aiModelId == modelInfo.aiModelId
            : ans.modelInfo && ans.modelInfo.aiModelId === modelInfo.aiModelId);
        if (item) {
            if (type === "QUESTION") {
                return item.question
            }
            else {
                return item.assistant
            }
        }
        else {
            return ''
        }
    }

    const getAssistantSecBgColor = (items) => {
        // const confidenceLevel = items && items.length ? items[0]?.answer[0]?.items?.confidenceLevel || null : null;
        // const color = confidenceLevel ? getConfidenceLevelColor(confidenceLevel) : null;
        // return confidenceLevel ? `bg-${color}` : 'bl-assistant-sec';
        return 'bl-assistant-sec'
    }

    const assistantAnswerContent = () => {
        setTimeout(() => {
            applyTheme()
        }, 50)
        return (
            <section>
                {
                    assistantAnswer.map((ans, i) => {
                        const model = ans.modelInfo;
                        return (
                            <div key={`answer-card-view-${i}`} className="card shadow-lg mb-3 bl-answer-mode">
                                <div className="card-header border-0 d-flex px-2">
                                    <span className="bl-profile-circle bl-mh-2rem text-uppercase p-2 mr-2">{getUserNameProfile()}</span>
                                    {/* <span className="bl-profile-circle text-uppercase p-2 mr-2 border-0 text-white" style={{background: profileBGColor}}>{getUserNameProfile()}</span> */}
                                    <h6 className="m-0">
                                        <div className="font-weight-bold">{getAssistantQuestionAndTitle(ans.items, ans.modelInfo, "QUESTION")}
                                            {ans && ans.question ? (<i className="fa fa-pencil-square-o ml-2 text-gray cp d-none" aria-hidden="true"></i>) : null}
                                        </div>
                                        <div className="p-0 font-smaller font-weight-light">{moment(ans.executedOn).format('MMM DD, YYYY hh:mm A')}</div>
                                    </h6>
                                </div>
                                {
                                    <div key={`assis-ans-${i}`} className={`card-header d-flex justify-content-between px-2 ${getAssistantSecBgColor(ans.items)}`}>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div className="d-flex">
                                                <img className="img-fluid bl-h-1-25rem mr-2" src={kairosIcon} />
                                                <p className="mb-0 align-self-center">{getAssistantQuestionAndTitle(ans.items, ans.modelInfo, "ASSISTANT")}
                                                    {ans && ans.isFileUpload ? (<i className="fa fa-pencil-square-o ml-2 text-gray cp d-none" aria-hidden="true"></i>) : null}
                                                </p>
                                            </div>
                                            {
                                                checkCondForCanvas(ans) ? <span className="badge badge-pill badge-primary text-uppercase cp pt-1" onClick={() => setIsCanvasView(!isCanvasView)}><i className="fa fa-external-link fa-fw mr-1" aria-hidden="true"></i>Template Found (Open Canvas)</span> : null
                                            }
                                        </div>
                                        <div className={`px-2 align-self-center ${ans.removeAnswerBody ? 'd-none' : ''}`}>
                                            {
                                                ans && ans.query ? <button className="btn btn-outline-secondary openAIModal d-none" id={`datasourcedropdown${i}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fa fa-info-circle text-gray" aria-hidden="true"></i> View Explanation
                                                </button> : null
                                            }
                                            <div className="dropdown-menu bl-w-22rem shadow-sm" aria-labelledby={`datasourcedropdown${i}`}>
                                                <p className="mb-0 text-muted px-2">Supporting Information for this response</p>
                                                <div className="dropdown-divider"></div>
                                                {
                                                    ans.tablesList && ans.tablesList.length && ans.tablesList.map((table, k) => {
                                                        return (
                                                            <div key={`db-table-${k}-${i}`} className="d-flex px-2 pb-2 align-items-center">
                                                                <i className="fa fa-database mr-3" aria-hidden="true"></i>
                                                                <h6 className="mb-0 font-small">{table}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="px-2">
                                                    {
                                                        queryFormat(ans.query)
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                                <div className={ans.removeAnswerBody ? 'd-none' : ''}>

                                    {
                                        ans.loading ?
                                            (
                                                !questionedModelInfo.aiModelId || questionedModelInfo.aiModelId === defaultModel.aiModelId ? < span className="loader mx-4 mt-3"></span> :
                                                    <div className="loader ml-4 mb-4">
                                                        <div className="loaderText">
                                                            <div>&nbsp;</div>
                                                            <div className="loader2"></div>
                                                        </div>
                                                    </div>
                                            )
                                            : (
                                                <div className={`card-body mx-4 pt-0 mt-3`}>
                                                    {serverContentView(ans.items, i, 1000002, model)}
                                                </div>
                                            )

                                    }
                                </div>
                                {ans.executedOn && !ans.removeAnswerBody ? getFeedbackContent(ans.items, ans.modelInfo) : null}
                            </div>
                        )
                    })
                }
            </section >
        )
    }

    const queryFormat = (query) => {
        return (
            <div className="col-12 p-2 border-0">
                <h6 className="font-small text-muted">Executed Query:</h6>
                <p className="mb-0 font-smaller">{query}</p>
            </div>
        )
    }

    const conversationsSideViewContent = () => {
        return (
            <ConversationHistoryComponent ref={childRef} getChatHistoryByThread={getChatHistoryByThread} openNewChat={openNewChat} searchThread={searchThread} textColor={isKairosApp ? 'text-white' : ''} />
        )
    }

    const getMatchedItems = (source) => {

        setUserBasedSelectedSource(source)
        const applicableView = userSchema.filter((allSourceItem) =>
            source.some((sourceItem) => sourceItem.title === allSourceItem.TABLE_NAME)
        );
        const schema = applicableView.map(views => {
            return {
                TABLE_NAME: views.originalTableName,
                TABLE_SCHEMA: views.tableSchema,
                ROLE: views.role,
                ALLROLE: views.allRole,
                tableInfo: views.tableInfo
            }
        })

        setApplicableUserSchema(schema)
    };

    useEffect(() => {
        const item = selectedDataSource;
        setSuggestiveQuestion([])
        getMatchedItems(item)
        setSelectedSource(item)
        if (item) {
            setTop5SelectedSource(item.slice(0, 5))
            const remainingItem = item.slice(5);
            if (remainingItem && remainingItem.length) {
                setRemainingSelectedSource(remainingItem)
            }
            else {
                setRemainingSelectedSource([])
            }
        }
    }, [selectedDataSource])

    useEffect(() => {
        if (selectedSource && selectedSource.length) {
            const randomSuggestiveQuestion = getRandomQuestions(selectedSource);
            if (randomSuggestiveQuestion && randomSuggestiveQuestion.length) {
                setSuggestiveQuestion(randomSuggestiveQuestion)
                setDefaultSuggestiveQuestion(randomSuggestiveQuestion)
            }
        }
    }, [selectedSource])

    function getRandomQuestions(data, numQuestions = 4) {
        const allQuestions = data
            .map(item => item.suggestiveQuestion || [])
            .flat();

        const selectedQuestions = [];
        const getRandomIndex = (arr) => Math.floor(Math.random() * arr.length);

        while (selectedQuestions.length < numQuestions && allQuestions.length > 0) {
            const randomIndex = getRandomIndex(allQuestions);
            selectedQuestions.push(allQuestions.splice(randomIndex, 1)[0]);
        }

        return selectedQuestions;
    }

    const dataSourceContentView = () => {
        return <MenuDataSourceElement reports={reports} textColor={isKairosApp ? 'text-white' : ''} getQuestionFromSourceModal={getQuestionFromSourceModal} />
    }

    const getUserNameProfile = () => {
        const username = loggedUserName;
        let splitName = username ? username.split(' ') : '';
        if (splitName.length > 1) {
            return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`
        }
        else return `${splitName[0].charAt(0)}${splitName[0].charAt(1)}`
    }

    const setComments = (comment) => {
        const info = {
            questionId: lastAnsItem.questionId,
            answerId: lastAnsItem.answerId,
            userQuery: comment
        }
        setFeedback(info)
    }

    const submitFeedback = async () => {
        try {
            if (!feedback.userQuery) {
                return toastError("Please enter your comment!");
            }
            const resp = await addUserFeedbackService(feedback);
            if (resp && resp.success) {
                setFeedback({
                    userQuery: "",
                    questionId: null,
                    answerId: null
                })
                toastSuccess("Thanks for giving us the feedback!");
                closeModal('#feedbackModal');
            }
        }
        catch (error) {
            if (error.status < 500) {
                toastError(error?.message || 'Something went wrong, Please try again!')
            }
        }
    }

    const feedbackModalContent = () => {
        return (
            <div className="modal fade bl-static-modal" id="feedbackModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="feedbackModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content shadow-lg">
                        <div className="modal-header">
                            <h5 className="modal-title" id="feedbackModalLabel">Raise an Issue or provide feedback</h5>
                            <button className="close" type="button" onClick={() => closeModal('#feedbackModal')} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating">
                                <textarea className="form-control border-0 shadow-none" onChange={(e) => setComments(e.target.value)} value={feedback.userQuery} placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                            </div>
                            <div className="card mt-3">
                                <div className="card-header">
                                    <h6 className="mb-0">{lastAnsItem?.question || ''}</h6>
                                    <p className="mb-0 font-small text-muted">LLM Model Used: {lastAnsItem?.modelInfo?.displayName || ''}</p>
                                </div>
                                <div className="card-body bl-answer-length">
                                    {serverSideContent(lastAnsItem, 10001, 20001, true)}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary secondary-link" type="button" onClick={() => closeModal('#feedbackModal')}>Cancel</button>
                            <button className="btn btn-primary primary-link" onClick={() => { submitFeedback() }}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const showSourceBasedSuggestiveQuestion = (source) => {
        if (source && source.suggestiveQuestion && source.suggestiveQuestion.length) {
            setSuggestiveQuestionTitle(`${aiModalKeys.DEFAULT_SUGGESTIVE_TEXT} for <b>${source.title}</b>`)
            setSuggestiveQuestion(source.suggestiveQuestion.slice(0, 4))
        }
    }

    useEffect(() => {
        if(location.pathname.includes('kairos')){
            const headerElement = document.getElementById("blHeader");
            const modalContent = document.getElementById("kairosmaincontent");
            const menuContent = document.getElementById("kairosMenuContent");
            if (headerElement && modalContent) {
    
                if (isCanvasView) {
                    headerElement.classList.remove('bl-kairos-top-header')
                    modalContent.classList.remove("modal-content-wrapper", "bl-kairos-main-content")
                }
                else {
                    headerElement.classList.add('bl-kairos-top-header')
                    modalContent.classList.add("modal-content-wrapper", "bl-kairos-main-content")
                    menuContent.classList.add("bl-kairos-menu")
                }
            }
        }
        
    }, [location.pathname, isCanvasView])

    const checkCondForCanvas = (ans) => {
        const isSbAnswer = ans.items && ans.items.length && ans?.items[0]?.answer && ans?.items[0]?.answer.length && ans?.items[0]?.answer[0]?.items?.from === 'SB';
        return !isCanvasView && canvasResp && rolesModelInfo?.modelType == 'CANVAS' && isSbAnswer;
    }

    useEffect(() => {
        if (rolesModelInfo?.modelType == 'CANVAS') {
            getCanvasResult()
        }
    }, [rolesModelInfo])

    const [canvasResp, setCanvasResp] = useState(null)
    const [isCanvasEditMode, setIsCanvasEditMode] = useState(false)

    const getCanvasResult = async () => {
        try {
            const obj = {
                page: 1, limit: 1
            }
            const resp = await getCanvasResultService(obj);
            if (resp && (resp.status || resp.success)) {
                setCanvasResp(resp?.data[0]?.markdownData)
            }
        }
        catch (e) {

        }
    }

    const handleContentChange = (sectionInfo) => {
        if(sectionInfo.manualOverrideText){
            setCanvasResp(sectionInfo.manualOverrideText)
        }
    };

    const canvasViewContent = () => {
        return (
            <div className="col-7 bl-vh-93 overflow-auto border-left pl-0 pr-3">
                <div className="header d-flex justify-content-between border-bottom px-3 sticky-top bg-white align-items-center">
                    <h4>Canvas View</h4>
                    <div className="d-flex">
                        {
                            isCanvasEditMode ? <i className="fa fa-1x fa-save cp" aria-hidden="true" onClick={()=>setIsCanvasEditMode(false)}></i>
                            : <i className="fa fa-1x fa-pencil cp" aria-hidden="true" onClick={()=>setIsCanvasEditMode(true)}></i>
                        }
                        <i className="fa fa-1x fa-download text-muted ml-2" aria-hidden="true"></i>
                        <i className="fa fa-1x fa-window-close-o cp ml-3" aria-hidden="true" onClick={() => setIsCanvasView(false)}></i>
                    </div>
                </div>
                <div className="body px-3">
                    {
                        isCanvasEditMode ?
                            <MyEditorWithToolbar markdown={canvasResp} onContentChange={handleContentChange} summaryId={'canvasViewEditor'} /> :
                            <MarkdownMermaidRenderer contentId={'canvasText'} markdownContext={canvasResp} />
                    }

                </div>
            </div>
        )
    }

    return (
        <div className="modal-wrapper">
            {
                !isCanvasView ? <div className="modal-sidebar">
                    <ul className="navbar-nav bg-white sidebar sidebar-light shadow-lg bl-ai-sidebar pb-3" id="kairosMenuContent" >
                        <div className="d-flex justify-content-between p-3">
                            {
                                isKairosApp ? (
                                    <div className="btn-group bl-w-fill-available mr-3" role="group" aria-label="Basic example">
                                        <button className={`btn btn-sm border ${viewType === 'CONVERSATIONS' ? 'bl-kairos-menu-btn active' : 'bg-transparent'}`} onClick={() => setViewType('CONVERSATIONS')}>Conversations {threadsCount ? `(${threadsCount})` : ''}</button>
                                        <button className={`btn btn-sm border ${viewType === 'DATA_SOURCES' ? 'bl-kairos-menu-btn active' : 'bg-transparent'}`} onClick={() => setViewType('DATA_SOURCES')}>Data Sources</button>
                                    </div>
                                ) : (
                                    <div className="btn-group bl-w-fill-available mr-3" role="group" aria-label="Basic example">
                                        <button className={`btn btn-sm border ${viewType === 'CONVERSATIONS' ? 'btn-primary active' : 'btn-outline-primary'}`} onClick={() => setViewType('CONVERSATIONS')}>Conversations {threadsCount ? `(${threadsCount})` : ''}</button>
                                        <button className={`btn btn-sm border ${viewType === 'DATA_SOURCES' ? 'btn-primary active' : 'btn-outline-primary'}`} onClick={() => setViewType('DATA_SOURCES')}>Data Sources</button>
                                    </div>
                                )
                            }
                            <button className={isKairosApp ? 'btn bg-gray btn-sm' : 'btn btn-danger btn-sm'} onClick={() => openNewChat()}><i className="fa fa-fw fa-plus" aria-hidden="true"></i></button>
                        </div>
                        {
                            viewType === 'CONVERSATIONS' ? (
                                <div className="px-3 pt-0">
                                    <div className="input-group border-bottom">
                                        <input type="text" className="form-control h-auto border-0 shadow-none px-0 bg-transparent" value={searchTerm} onChange={searchInHistory} onKeyDown={handleKeySearch} placeholder={viewType === 'CONVERSATIONS' ? 'Search Conversations' : 'Search Docs'} />
                                        <div className="input-group-append">
                                            <span className="input-group-text bg-transparent border-0">
                                                <i type="submit" className="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                        <div className=" overflow-y-auto pb-3">
                            {
                                viewType === 'CONVERSATIONS' ? conversationsSideViewContent() : dataSourceContentView()
                            }
                        </div>
                    </ul>
                </div> : null
            }

            <div id="kairosmaincontent" className="modal-content-wrapper">
                <div id="aimodalcontent" className={`row ${isCanvasView ? '' : 'justify-content-center vh-100 overflow-auto'}`}>
                    <div className={`${isCanvasView ? 'col-5 bl-vh-93 overflow-auto pr-4 pl-5' : 'col-9'} d-flex flex-column-reverse`}>
                        <div id="aicardvisibility" className="bl-card-visibility mb-4">
                            {
                                modalContentType === 'DEFAULT' || modalContentType === 'OPEN_DEFAULT' ? defaultModalContentView() :
                                    <div className="card shadow-lg pb-3">
                                        <div className="card-header border-0 d-flex px-2">
                                            <img className="img-fluid bl-h-1-25rem mr-2" src={kairosIcon} />
                                            <p className="mb-0 align-self-center">Selected Context <b>- {defaultCardHeader}</b></p>
                                        </div>
                                    </div>
                            }
                            {
                                isQuestionAsked ? assistantAnswerContent() : null
                            }
                            <div id="aifooter" className="bl-search-footer">
                                {
                                    selectedSource.length ? (
                                        <div className="row d-none">
                                            <div className="col-12">
                                                <div className="d-flex mb-2 p-2 bl-selected-source text-nowrap">
                                                    <div className={`d-flex overflow-hidden ${remainingSelectedSource && remainingSelectedSource.length && 'bl-selected-source-sec'}`}>
                                                        Selected Sources (<b>{selectedSource.length}</b>):
                                                        {
                                                            top5SelectedSource.map((s, i) => {
                                                                return (
                                                                    <span key={`selected-source-${i}`} onMouseOver={() => showSourceBasedSuggestiveQuestion(s)} title={convertCamelCaseToSeparateWords(s.title)} className="badge bl-filter-chip bl-bg-lightpink bl-limited-text d-block px-2 ml-2 cp">{convertCamelCaseToSeparateWords(s.title)}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        remainingSelectedSource && remainingSelectedSource.length ? (
                                                            <div className="dropup">
                                                                <span className="badge bl-filter-chip bl-bg-lightpink bl-w-1rem ml-2 px-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                </span>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    {
                                                                        remainingSelectedSource.map((s, i) => {
                                                                            return <a key={`source-dropdown-${i}`} onMouseOver={() => showSourceBasedSuggestiveQuestion(s)} className="dropdown-item cp">{convertCamelCaseToSeparateWords(s.title)}</a>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <form className="shadow-lg" onSubmit={(e) => getQuestionInputByUser(e, userQuestion)}>
                                    {rolesModelInfo?.modelType === 'CANVAS' ? (
                                        <div className={`input-group border rounded`}>
                                            <div className={`input-group-prepend`}>
                                                <span className="input-group-text bg-white border-0 p-2">
                                                    <img className="img-fluid bl-h-1-25rem" src={kairosIcon} />
                                                </span>
                                            </div>
                                            <input type="text" onChange={(e) => { setUserQuestion(e.target.value) }} value={userQuestion} className="form-control h-auto border-0 shadow-none" placeholder="Start by typing your question here or click a suggestion from the list above." />
                                            <div className="input-group-append">
                                                <button type="submit" className="input-group-text bg-white border-0">
                                                    <i className="fa fa-share bl-rotate-175" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`input-group border rounded ${selectedSource.length === 0 || loggedUserMail.toLowerCase() !== userMail.toLowerCase() ? 'disabled' : ''}`}>
                                            <div className={`input-group-prepend`}>
                                                <span className="input-group-text bg-white border-0 p-2">
                                                    <img className="img-fluid bl-h-1-25rem" src={kairosIcon} />
                                                </span>
                                            </div>
                                            <input type="text" onChange={(e) => { setUserQuestion(e.target.value) }} value={userQuestion} className="form-control h-auto border-0 shadow-none" placeholder={`${selectedSource.length === 0 ? "Chat not enabled for you" : "Start by typing your question here or click a suggestion from the list above."}`} />
                                            <div className="input-group-append">
                                                <button type="submit" className="input-group-text bg-white border-0">
                                                    <i className="fa fa-share bl-rotate-175" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>

                    </div>
                    {
                        isCanvasView ? canvasViewContent() : null
                    }
                </div>
            </div>
            {feedbackModalContent()}
        </div >
    )
}