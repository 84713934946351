import { gql } from "@apollo/client";

export const GET_COMPANIES_LIST = gql`
query GET_COMPANIES_LIST {
  companies {
    legalName
    companyName
    yearFounded
    about
    companyId
    logo
    companyStatus
    primarySector
    Sectors {
      sectorName
      source
      sourceUrl
    }
    Industries {
      industryName
      source
      sourceUrl
    }
  }
}
`; 

export const GET_PARTICULAR_COMPANY_DATA = gql`
query CompanyByID($id: ID!) {
  companies(where: {companyId: $id}) {
    companyId
    companyName
    about
    legalName
    source
    sourceUrl
    yearFounded
    sicCode
    linkedinUrl
    primarySector
    website
    employeeCount
    registrationNumber
    logo
    naicsCode
    tickerSymbol
    taxId
    companyStatus
    Sectors {
      sectorName
      source
      sourceUrl
    }
    Industries {
      industryName
      source
      sourceUrl
    }
    People {
      fullName
      linkedinProfile
      source
      sourceUrl
      twitterProfile
      email
      address
      dateOfBirth
      gender
      phone
    }
    Industries {
      industryName
      source
      sourceUrl
    }
    Locations {
      address
      source
      sourceUrl
      LocationType
      isHeadquarter
      City {
        cityName
        State {
          stateName
          Country {
            countryName
          }
        }
      }
    }
    NewsConnection(sort: {node: {date: DESC}}, first: 3) {
      totalCount
      edges {
        node {
          date
          image
          title
          source
          sourceUrl
          category
        }
      }
    }
  }
}

`;

export const GET_PARTICULAR_COMPANY_NEWS = gql`
 query GetNewsByID($id : ID!) {
  companies(where: { companyId: $id }) {
  companyId
  companyName
  NewsConnection(sort: {node: {date: DESC}}) {
    totalCount
      edges {
        node {
          date
          image
          title
          text
          source
          sourceUrl
          category
        }
      }
    }
  }
}`;

export const GET_FINANCE_DATA_FOR_COMPANY = gql`
query GetNewsByID($id : ID!) {
  companies(where: {companyId: $id }) {
    companyId
    legalName
    Investment {
      investmentId
      investmentName
      investmentType
      isControl
      isPublic
      acquisitionDate
      exitDate
      holdingPeriodEndDate
      isWriteOff
      istoeHold
      realizationStatus
      Fund {
        fundId
        fundName
        fundStrategy
        fundShortName
        managementFeeInCommitmentPeriod
        initalCloseDate
        carriedInterest
        effectiveDate
        endOfInitialTerm
        endOfInvestmentPeriod
        finalSubsequentCloseDate
        finalCloseDate
        isCoinvest
        prefferedReturn
        source
        sourceUrl
        vintageDate
        managementFeeOutsideCommitmentPeriod
      }
    } 
  }
}
`;

export const GET_ECONOMIC_INDICATOR = gql`
  query MyQuery($limit: Int!, $offset: Int!) {
    cybersynMetrics(options: { limit: $limit, offset: $offset }) {
      CybersynMetricValues {
        variable
        value
        unit
        geoId
        date
      }
      variable
      variableName
      measure
    }
  }
`;

export const GET_INDUSTRY_LIST = gql`
query MyQuery {
  uniqueIndustries
}
`;

export const GET_ECONOMIC_INDICATOR_FILTER_BY_MEASURE = gql`
query MyQuery($measures: [String!], $industryName:[String!]) {
  cybersynMetrics(where: { measure_IN: $measures, industryName_IN: $industryName}) {
    CybersynMetricValues {
      variable
      value
      unit
      geoId
      date
    }
    variable
    variableName
  }
}
`;

export const GET_MEASURES_LIST = gql`
  query fetchMeasuresList($industryName: String!) {
  uniqueMeasures(industryName: $industryName) 
  }
`;
