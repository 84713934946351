import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import missingImg from "./../../../../assets/img/logoplaceholder.png";
import { BoldPieChart } from "../../../Graph/boldPieChart";
import { SummarizerContext } from "../../../../services/context/summarizerContext";
import { toastSuccess } from "../../../../services/notifyService";
import { removeProjectService } from "../../../../services/summarizerService";
import { AnalyzeCompanyContext } from "../../../../services/context/analyzeCompanyContext";
import ThemeContext from "../../../../services/context/Theme/themeContext";
import { getAppBehavior } from "../../../../services/authService";

export const DealbookCompanyTitle = () => {

    const { projectInfo, projectCompanyInfo } = useContext(SummarizerContext)
    const { setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const { theme } = useContext(ThemeContext)

    const nav = useNavigate();
    const location = useLocation();
    const [projectBasicInfo, setProjectBasicInfo] = useState({
        createdBy: "", createdOn: "", lastModifiedDate: "", projectId: null, projectName: "", projectProcessingStatus: "", publishedStatus: "", totalScore: null,
        companyName: "", logo: null, website: "", lastModifiedUserName: ""
    })
    const [maxScore, setMaxScore] = useState(20)
    const [referenceImages, setReferenceImages] = useState([])
    const [defaultPage, setDefaultPage] = useState(null)
    const [chartProgressColor, setChartProgressColor] = useState('')

    const getChartColor = () => {
        let progressColor = "#fdb02a";
        if (theme) {
            if(theme.pageRefHoverBGColor){
                progressColor = theme.pageRefHoverBGColor;
            }
        }
        else {
            const appThemes = getAppBehavior();
            if (appThemes && appThemes !== "undefined") {
                const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
                if (appTheme) {
                    if(appTheme.pageRefHoverBGColor){
                        progressColor = appTheme.pageRefHoverBGColor;
                    }
                }
            }
        }        
        setChartProgressColor(progressColor)
    }

    useEffect(() => {
        getChartColor()
    }, [theme])

    useEffect(() => {
        window.$(document).on('click', '.open-reference-modal', function () {
            setReferenceImages([])
            const info = window.$(this).data('info');
            setDefaultPage(info)
            const imageArrayList = window.$(this).data('images');
            setTimeout(() => {
                setReferenceImages(imageArrayList)
            }, 50)
            const slideObjInfo = imageArrayList.find(img => img.page == info);
            if (slideObjInfo) {
                window.$('#pageReferanceModalTitle').text(`Page: ${slideObjInfo.page}`);
            }
        });

    }, [])

    useEffect(() => {
        window.$('#carouselExampleControls').on('slid.bs.carousel', function (e) {
            const activeItem = window.$(e.relatedTarget);
            const slideInfo = {
                index: activeItem.data('index'),
                page: activeItem.data('page'),
                imageUrl: activeItem.find('img').attr('src')
            };
            if (slideInfo && slideInfo.imageUrl) {
                const slideObjInfo = referenceImages.find(img => img.image === slideInfo.imageUrl);
                if (slideObjInfo) {
                    window.$('#pageReferanceModalTitle').text(``);
                    window.$('#pageReferanceModalTitle').text(`Page: ${slideObjInfo.page}`);
                }
            }
        });
    }, [referenceImages])

    useEffect(() => {
        if (projectInfo) {
            setMaxScore(projectInfo.maxScore ? projectInfo.maxScore : 25)
            setProjectBasicInfo(projectInfo)
        }
    }, [projectInfo, location.pathname])

    const deleteCompany = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const resp = await removeProjectService(projectBasicInfo.projectId);
        if (resp && resp.success) {
            setAnalyzedCompanyInfo({ message: "Company Project removed", projectId: projectBasicInfo.projectId })
            window.$("#deletecompanyprojectmodal").modal('toggle');
            toastSuccess("Company Project removed successfully!")
            nav('/dealbook/home')
        }
        if (pageLoader) {
            pageLoader.style.display = 'none'
        }
    }

    const deleteCompanyProjectModalContent = () => {
        return (
            <div className="modal fade" id="deletecompanyprojectmodal" tabIndex="-1" role="dialog" aria-labelledby="deleteCompanyProjectModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteCompanyProjectModalLabel">Delete Company Project</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove <b>"{projectBasicInfo.companyName ? projectBasicInfo.companyName : projectBasicInfo.projectName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deleteCompany(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
    }

    const displayReferenceImageInModal = () => {
        return (
            <div className="modal fade" id="pageReferanceImageModal" tabIndex="-1" role="dialog" aria-labelledby="pageReferanceModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <h6 className="modal-title" id="pageReferanceModalTitle"></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="carouselExampleControls" className="carousel slide" data-touch="false" data-interval="false">
                                <div className="carousel-inner">
                                    {
                                        referenceImages && referenceImages.map((info, i) => {
                                            return (
                                                <div key={`reference-images-${i}`} className={`carousel-item ${defaultPage === info.page ? 'active' : ''}`}>
                                                    <img src={info.image} className="d-block w-100" alt="..." />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <button className="carousel-control-prev text-dark border-0 bg-transparent justify-content-start" type="button" data-target="#carouselExampleControls" data-slide="prev">
                                    <span className="btn-circle bg-dark shadow-sm"><i className="fa fa-3x fa-angle-left" aria-hidden="true"></i></span>
                                </button>
                                <button className="carousel-control-next text-dark border-0 bg-transparent justify-content-end" type="button" data-target="#carouselExampleControls" data-slide="next">
                                    <span className="btn-circle bg-dark shadow-sm"><i className="fa fa-3x fa-angle-right" aria-hidden="true"></i></span>
                                </button>
                            </div>
                            {/* <img id="modal-image" src="" alt="Dynamic Content" className="img-fluid" /> */}
                            <p id="modal-body-content"></p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const companyWebsite = () => {
        if (projectCompanyInfo.website && (projectCompanyInfo.website.startsWith('http') || projectCompanyInfo.website.toLowerCase().includes('www.'))) {
            return projectCompanyInfo.website.startsWith('http') ? projectCompanyInfo.website : `https://${projectCompanyInfo.website}`;
        }
        return null
    }

    const getProjectStatus = () => {
        return (
            projectBasicInfo.publishedStatus && projectBasicInfo.publishedStatus.toLowerCase() === 'draft' ? (
                <span className="ml-2"><i className="fa fa-pencil-square-o" aria-hidden="true"></i><span className="text-danger ml-2">[Draft]</span></span>
            ) : projectBasicInfo.publishedStatus && projectBasicInfo.publishedStatus.toLowerCase() === 'published' ? <span className="badge badge-info ml-2 d-print-none">Published</span> : null
        )
    }

    const formatDateToLocal = (date) => {
        if (date) {
            date = date.toLowerCase().includes('z') ? date : `${date}Z`;
            return moment(date).format('MMM DD, YYYY hh:mm A')
        }
        return '';
    }

    return (
        <>
            <section className="mb-3">
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <div className="sidebar-brand-icon mb-0">
                        {/* <div className="card shadow-sm mr-3 bl-w-5rem">
                                <div className="card-body text-center p-2">
                                    <BoldPieChart height="50px" textScore={projectBasicInfo.score} score={projectBasicInfo.score * 100 / 10}></BoldPieChart>
                                    <p className="card-text">BL Score</p>
                                </div>
                            </div> */}
                        <div className=" p-0 bl-btn-doughnut">
                            {
                                projectBasicInfo.totalScore && chartProgressColor ? <BoldPieChart usedColor={chartProgressColor} height="70px" textScore={projectBasicInfo.totalScore} score={parseInt(projectBasicInfo.totalScore) > maxScore ? 100 : parseInt(projectBasicInfo.totalScore) * 100 / maxScore} /> : <b>0</b>
                            }
                        </div>


                        {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJntksKr9cKXgW2iDMRwfftB72U_XGwvbSA&s" height="70px" /> */}
                    </div>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-0">
                    <h4 className="h4 text-gray-800 text-center pb-0 mb-0">{projectBasicInfo.projectName}</h4>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-0">
                    <p className="bl-font-larger mb-1">{projectCompanyInfo.companyName} {projectCompanyInfo.companyName && companyWebsite() ? <span className="text-muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span> : null} {companyWebsite() ? <a href={companyWebsite()} target="_blank" rel="noopener noreferrer">{projectCompanyInfo.website} <i className="fa fa-external-link font-smaller ml-1" aria-hidden="true"></i></a> : null}</p><br />
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-0">
                    <p className="bl-truncate-line-1 font-small">
                        {projectBasicInfo.lastModifiedUserName ? <span><span className="text-muted mr-2">Last Modified By</span>{projectBasicInfo.lastModifiedUserName ? projectBasicInfo.lastModifiedUserName : projectBasicInfo.lastModifiedBy} on {formatDateToLocal(projectBasicInfo.lastModifiedDate)} <span className="text-muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span></span> : null}
                        <span className="text-muted mr-2">Created By</span>{projectBasicInfo.createdUserName ? projectBasicInfo.createdUserName : projectBasicInfo.createdBy} on {formatDateToLocal(projectBasicInfo.createdOn)} {getProjectStatus()}</p>
                </div>
            </section>
            {
                deleteCompanyProjectModalContent()
            }
            {displayReferenceImageInModal()}
        </>
    )
}