import React, { useContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { PortcoContext } from "../../../services/context/portcoContext";
import { useLocation } from "react-router-dom";
import { GET_ECONOMIC_INDICATOR, GET_ECONOMIC_INDICATOR_FILTER_BY_MEASURE, GET_INDUSTRY_LIST, GET_MEASURES_LIST } from "../../../services/graphql/queries";
import { EmptyCardsContent } from "../../Common/emptyCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency, formatNumber } from "../../../utils/validation";

export const PortcoCompanyEconomicIndicatorComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext);
    const { portcoCompanyInfo } = useContext(PortcoContext);
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: "/portco-monitoring/all-companies", menuName: "Portco Monitoring", isActive: false, hasSubMenu: true, },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true, },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/economic-indicators`, menuName: "Economic Indicators", isActive: true, hasSubMenu: true, }
        ]);
    }, [location.pathname, portcoCompanyInfo]);

    const [measureData, setMeasureData] = useState([])
    const [selectedMeasure, setSelectedMeasure] = useState("")
    const [measureItemList, setMeasureItemList] = useState([])
    const [industryList, setIndustryList] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [dateColumn, setDateColumn] = useState([])

    const { loading: loadingIndustry, error: errorMeasure, data: dataIndustry } = useQuery(GET_INDUSTRY_LIST, { fetchPolicy: "network-only",});

    const { loading: loadingIndicator, error: errorIndicator, data: dataIndicator } = useQuery(GET_ECONOMIC_INDICATOR_FILTER_BY_MEASURE, {
            variables: { measures: selectedMeasure, industryName:selectedIndustry},
            skip: !selectedMeasure || !selectedIndustry,
            fetchPolicy: "network-only",
        }
    );

    const { loading: loadingMeasureList, error: errorMeasuresList, data: dataMeasure } = useQuery(GET_MEASURES_LIST, {
            variables: selectedIndustry ? { industryName: selectedIndustry } : {},
            skip: !selectedIndustry,
            fetchPolicy: "network-only",
        }
    );
    
    useEffect(() => {
        if (dataIndustry) {
            setIndustryList(dataIndustry?.uniqueIndustries);
            const industryListData = dataIndustry?.uniqueIndustries;
            setSelectedIndustry(industryListData[0]);
        }
    }, [dataIndustry])

    useEffect(() => {
        if (dataMeasure) {
            const uniqueMeasures = [...dataMeasure.uniqueMeasures]?.sort();
            setMeasureData(uniqueMeasures)
            setSelectedMeasure(uniqueMeasures[0])
        }
    }, [dataMeasure])
    
    useEffect(() => {
        const pageLoader = document.getElementById("pageLoaderContainer");
        if (pageLoader && loadingIndicator || pageLoader && loadingIndustry || pageLoader && loadingMeasureList) {
            pageLoader.style.display = "flex";
        } else if (pageLoader) {
            pageLoader.style.display = "none";
        }
    }, [loadingIndicator, loadingMeasureList, loadingIndustry]);

    function getUniqueDates(metrics) {
        const uniqueDates = new Set();
        metrics.forEach(metric => {
            if (Array.isArray(metric.CybersynMetricValues)) {
                metric.CybersynMetricValues.forEach(item => {
                    if (item.date) {
                        uniqueDates.add(item.date);
                    }
                });
            }
        });
        return Array.from(uniqueDates).sort();
    }

    useEffect(() => {
        if (dataIndicator) {
            const filterItem = dataIndicator?.cybersynMetrics;
            const data = filterItem?.filter((i)=> i?.CybersynMetricValues?.length > 0)
            setMeasureItemList(data)
            const dateHeader = getUniqueDates(filterItem);
            setDateColumn(dateHeader.sort())
        }
    }, [dataIndicator])

    const formatCurrency = (value) => {
        if (value >= 1000000000) {
            return `$${(value / 1000000000).toFixed(2).replace(/\.00$/, '')}B`;
        } else if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(2).replace(/\.00$/, '')}M`;
        } else if (value >= 1000) {
            return `$${(value / 1000).toFixed(2).replace(/\.00$/, '')}K`;
        }
        return `$${value.toFixed(2).replace(/\.00$/, '')}`;
    };

    const displayDateWiseValueAndUnit = (items, date) => {
        if (items && items.CybersynMetricValues) {
            const item = items.CybersynMetricValues.find(metric => metric.date === date);
            if (item) {
                let values = item?.value || "";
                if (values) {
                    if (item.unit.toLowerCase().includes('usd')) {
                        values = formatCurrency(parseFloat(item.value));
                    }
                    else if (item.unit.toLowerCase().includes('percent')) {
                        values = `${(parseFloat(item.value) * 100).toFixed(2)}%`;
                    }
                    else {
                        values = formatNumber(parseInt(item.value));
                    }
                }
                return (
                    <div>
                        {values} <br />
                        <small className="mb-0 font-small text-muted">({item?.unit})</small>
                    </div>
                )
            }
            return ''
        }

    }


    return (
        <div id="content">
            <div className="container-fluid mt-3">
                <section className="pb-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 mb-4">
                            <div className="card shadow-sm">
                                <div className="card-header pl-3">
                                    <h6>List of Economic Indicators</h6>
                                    <div className="mt-3 d-flex justify-content-start mb-1">
                                        <div className="d-flex">
                                        <h6 className="mr-2 mb-0 align-content-center">Industry: </h6>
                                            <select className="form-control w-auto" value={selectedIndustry} onChange={(e) => setSelectedIndustry(e.target.value)}>
                                                {
                                                    industryList && Array.from(industryList).map((item, i) => (
                                                        <option key={`industry-item-${i}`} value={item}>{item}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <h6 className="mr-2 ml-3 mb-0 align-content-center">Measures: </h6>
                                            <select className="form-control w-auto" value={selectedMeasure} onChange={(e) => setSelectedMeasure(e.target.value)}>
                                                {
                                                    measureData && measureData.length && measureData.map((item, i) => {
                                                        return <option key={`measures-item-${i}`}>{item}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    {measureItemList.length > 0 ?
                                        <div>
                                            <DataTable value={measureItemList} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm">
                                                <Column field="variableName" className="w-25" header="Variable" sortable></Column>
                                                {
                                                    dateColumn && dateColumn.map((col, i) => {
                                                        return (
                                                            <Column key={`economic-table-column-${i}`} field="" header={col} body={(e) => displayDateWiseValueAndUnit(e, col)}></Column>
                                                        )
                                                    })
                                                }
                                            </DataTable>
                                        </div>
                                        : !loadingIndicator ? (
                                            <div className="mt-3">
                                                <EmptyCardsContent title="No record found" buttonView={false} />
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};