import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Mention from '@tiptap/extension-mention';
import Link from '@tiptap/extension-link';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import './editorStyle.css';
import MarkdownIt from 'markdown-it';
import AlignmentDropdown from './alignmentDropdown';
import TurndownService from 'turndown';
import { gfm } from 'turndown-plugin-gfm';

const MyEditorWithToolbar = ({ markdown, onContentChange, summaryId, section }) => {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  }).enable(['table', 'strikethrough']);

  const initialHtml = md.render(markdown);
  const [selectedTextStyle, setSelectedTextStyle] = useState('paragraph');
  const [selectedAlignment, setSelectedAlignment] = useState('left');
  const [markdownContent, setMarkdownContent] = useState('');
  const [isModified, setIsModified] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Table.configure({ resizable: true }),
      TableRow,
      TableCell,
      TableHeader,
      Image,
      // ListItem,
      // OrderedList,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Link.configure({
        openOnClick: true,
      }),
      Mention.configure({
        HTMLAttributes: {
          className: 'mention',
        },
        suggestion: {
          items: (query) => {
            return ['Alice', 'Bob', 'Charlie', 'Dave'].filter((item) =>
              item.toLowerCase().includes(query.toLowerCase())
            );
          },
        },
      }),
      Color,
      Highlight,
    ],
    content: initialHtml,
    onUpdate: ({ editor }) => {
      updateSelectedTextStyle(editor);
      updateSelectedAlignment(editor);
      getTextContentFromNodes(editor);
    },
    onSelectionUpdate: ({ editor }) => {
      updateSelectedTextStyle(editor);
      updateSelectedAlignment(editor);
    },
  });

  const getTextContentFromNodes = () => {
    const htmlContent = editor.getHTML();
    const turndownService = new TurndownService();
    turndownService.use(gfm);
    turndownService.addRule('preserveTables', {
      filter: (node) => {
        return node.nodeName === 'TABLE' || node.nodeName === 'TR' || node.nodeName === 'TD' || node.nodeName === 'TH';
      },
      replacement: (content, node) => {
        // Keep the original HTML tag for tables
        return node.outerHTML;
      },
    });
    turndownService.addRule('inlineStyles', {
      filter: (node) => {
        return (
          node.nodeType === 1 && // Element nodes only
          node.hasAttribute('style') // Must have a style attribute
        );
      },
      replacement: (content, node) => {
        const style = node.getAttribute('style');
        if (style) {
          // Retain the HTML tag to preserve the style for Markdown
          return `<${node.nodeName.toLowerCase()} style="${style}">${content}</${node.nodeName.toLowerCase()}>`;
        } else {
          return content;
        }
      },
    });
    const updatedMarkdown = turndownService.turndown(htmlContent);
    setMarkdownContent(updatedMarkdown);
    setIsModified(markdown !== updatedMarkdown)
  }

  useEffect(() => {
    if (onContentChange) {
      onContentChange({ manualOverrideText: markdownContent, summaryId, isModified });
    }
  }, [markdownContent, onContentChange, summaryId, isModified]);

  const updateSelectedTextStyle = (editor) => {
    if (editor.isActive('heading', { level: 1 })) {
      setSelectedTextStyle('1');
    } else if (editor.isActive('heading', { level: 2 })) {
      setSelectedTextStyle('2');
    } else if (editor.isActive('heading', { level: 3 })) {
      setSelectedTextStyle('3');
    } else if (editor.isActive('heading', { level: 4 })) {
      setSelectedTextStyle('4');
    } else if (editor.isActive('heading', { level: 5 })) {
      setSelectedTextStyle('5');
    } else if (editor.isActive('heading', { level: 6 })) {
      setSelectedTextStyle('6');
    } else {
      setSelectedTextStyle('paragraph');
    }
  };

  const updateSelectedAlignment = (editor) => {
    if (editor.isActive({ textAlign: 'left' })) {
      setSelectedAlignment('left');
    } else if (editor.isActive({ textAlign: 'center' })) {
      setSelectedAlignment('center');
    } else if (editor.isActive({ textAlign: 'right' })) {
      setSelectedAlignment('right');
    } else if (editor.isActive({ textAlign: 'justify' })) {
      setSelectedAlignment('justify');
    } else {
      setSelectedAlignment('left');
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <div>
      <div className="editor-toolbar">
        {/* Text Style Dropdown with Normal and Headers */}
        <select value={selectedTextStyle}
          onChange={(event) => {
            const value = event.target.value;
            if (value === 'paragraph') {
              editor.chain().focus().setParagraph().run();
            } else {
              editor.chain().focus().toggleHeading({ level: parseInt(value) }).run();
            }
          }}
        >
          <option value="paragraph">Normal</option>
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
          <option value="4">Heading 4</option>
          <option value="5">Heading 5</option>
          <option value="6">Heading 6</option>
        </select>

        {/* Formatting Buttons */}
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <b><i className="fa fa-bold" aria-hidden="true"></i></b>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <i><i className="fa fa-italic" aria-hidden="true"></i></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? 'is-active' : ''}
        >
          <u><i className="fa fa-underline" aria-hidden="true"></i></u>
        </button>

        {/* List Buttons */}

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <i className="fa fa-list" aria-hidden="true"></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <i className="fa fa-list-ol" aria-hidden="true"></i>
        </button>

        {/* Alignment Buttons */}
        <AlignmentDropdown
          editor={editor}
          selectedAlignment={selectedAlignment}
          setSelectedAlignment={setSelectedAlignment}
        />


        {/* Additional Formatting */}
        {/* <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <i className="fa fa-quote-right" aria-hidden="true"></i>
        </button> */}
        {/* <button
          onClick={() => editor.chain().focus().setColor('red').run()}
        >
          <span style={{ color: 'red' }}>A</span>
        </button> */}
        <button
          onClick={() => editor.chain().focus().toggleHighlight().run()}
        >
          <span style={{ backgroundColor: 'yellow' }}>Highlight</span>
        </button>

        {/* Link Button */}
        {/* <button
          onClick={() => {
            const url = window.prompt('Enter the URL');
            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
            }
          }}
          className={editor.isActive('link') ? 'is-active' : ''}
        >
          <i className="fa fa-link" aria-hidden="true"></i>
        </button> */}

        {/* Table Actions */}
        <button
          onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run()}
        >
          <i className="fa fa-table" aria-hidden="true"></i>
        </button>
        <button onClick={() => editor.chain().focus().addColumnBefore().run()}>
          Add Column Before
        </button>
        <button onClick={() => editor.chain().focus().addColumnAfter().run()}>
          Add Column After
        </button>
        <button onClick={() => editor.chain().focus().deleteColumn().run()}>
          Delete Column
        </button>
        <button onClick={() => editor.chain().focus().addRowBefore().run()}>
          Add Row Before
        </button>
        <button onClick={() => editor.chain().focus().addRowAfter().run()}>
          Add Row After
        </button>
        <button onClick={() => editor.chain().focus().deleteRow().run()}>
          Delete Row
        </button>
        <button onClick={() => editor.chain().focus().deleteTable().run()}>
          Delete Table
        </button>
      </div>

      {/* Editor Content */}
      <EditorContent editor={editor} className='px-3 border-0' />
    </div>
  );
};

export default MyEditorWithToolbar;
