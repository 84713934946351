import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PortcoContext } from "../../../services/context/portcoContext";
import { GET_PARTICULAR_COMPANY_DATA } from "../../../services/graphql/queries";
import { useQuery } from "@apollo/client";
import { toastError } from "../../../services/notifyService";

export const PortcoCompanyComponent = () => {
    const { setPortcoCompanyInfo } = useContext(PortcoContext);
    const location = useLocation();
    const [companyId, setCompanyId] = useState(null);

    useEffect(() => {
        getIdFromPath();
    }, [location.pathname]);

    const getIdFromPath = () => {
        const pathArray = location.pathname.split('/');
        const slug = pathArray[3] ? pathArray[3].split('-')[1] : null;
        setCompanyId(slug ? parseInt(slug) : null);
    }; 

    const { loading, error, data } = useQuery(GET_PARTICULAR_COMPANY_DATA, {
        variables: { id: Number(companyId) },
        skip: !companyId,  
        fetchPolicy: "network-only", 
    });
    
    if(error){
        toastError(error?.data?.message || error?.response?.message || error?.message || "An error occurred. Please try again!")
    }

    useEffect(() => {
        if (data) {
            setPortcoCompanyInfo(data?.companies[0]);
        }
    }, [data, setPortcoCompanyInfo]);
 
  
    useEffect(() => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (loading && pageLoader) {
            pageLoader.style.display = 'flex';
        } else if (pageLoader) {
            pageLoader.style.display = 'none';
        }
    }, [loading]);

    return <Outlet />;
};
