import React, { useContext, useEffect, useState } from "react";
import { PortcoCompanyTitle } from "./Common/companyTitle";
import { EmptyCardsContent } from "../../Common/emptyCard";
import { CardDropdownContent } from "../../Common/cardDropdown";
import { PortcoContext } from "../../../services/context/portcoContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import imagePlaceholder from "../../../assets/img/image-placeholder.jpeg";

export const PortcoCompanyOverviewComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()
    const [companyInfo, setCompanyInfo] = useState(null)

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/overview`, menuName: "Overview", isActive: true, hasSubMenu: true }
        ])
        setCompanyInfo(portcoCompanyInfo)
    }, [portcoCompanyInfo])

    const employeeData = companyInfo?.employeeCountByCountry
        ? JSON.parse(companyInfo?.employeeCountByCountry?.replace(/'/g, '"'))
        : [];

    const getEmptyScreen = () => {
        return (
            <div className="row">
                <div className="col text-center mt-5">
                    <EmptyCardsContent title='No record found' desc="" buttonView={false} />
                </div>
            </div>
        )
    }
   
    const profileSection = () => {
        return (
            <section className="mb-3">
                <div className="row bl-profile-highlights">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <h6>About {companyInfo?.source && <span className="badge bl-source-badge ml-1">
                            <i className="fa fa-database" aria-hidden="true"></i>{companyInfo?.source}</span>} </h6>
                        <p className="bl-truncate-line-5">{companyInfo?.about || 'NA'}</p>
                        <p><i className="fa fa-fw fa-laptop mr-1" aria-hidden="true"></i>{companyInfo?.companyName || 'NA'}</p>
                        <p><i className="fa fa-fw fa-link mr-1" aria-hidden="true"></i>
                            <a href={`${companyInfo?.website?.startsWith("https") ? "" : "https://"}${companyInfo?.website}`} target="_blank" className="cp">{companyInfo?.website || 'NA'}</a>
                        </p>
                        <p><i className="fa fa-fw fa-users mr-1" aria-hidden="true"></i>{(companyInfo?.employeeCount + "+ employees") || 'NA'}</p>
                        <p><i className="fa fa-fw fa-location-arrow mr-1" aria-hidden="true"></i>{companyInfo?.Locations?.find((data) => data.isHeadquarter)?.address || "NA"}</p>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <h6><i className="fa fa-fw fa-bolt" aria-hidden="true"></i> Highlights</h6>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-6">
                                <p className="bl-truncate-line-4">Sector <br /><b>{companyInfo?.primarySector || 'NA'}</b></p>
                                <p>Sub Sector <br /><b className="bl-truncate-line-1">{companyInfo?.Sectors?.find((i)=> i.sectorName)?.sectorName || 'NA'}</b></p>
                                <p>Registration Number <br /><b className="bl-truncate-line-1">{companyInfo?.registrationNumber || 'NA'}</b></p>
                                <p>Tax ID <br /><b className="bl-truncate-line-1">{companyInfo?.taxId || 'NA'}</b></p>
                                <p>Year founded <br /> <b className="bl-truncate-line-1">{companyInfo?.yearFounded || ''}</b></p>
                            </div>
                            <div className="col-xl-6 col-md-6 col-6">
                                <p>Industry  {companyInfo?.Industries[0]?.source && <span className="badge bl-source-badge ml-1">
                                    <i className="fa fa-database" aria-hidden="true"></i>
                                    { companyInfo?.Industries[0].sourceUrl? (
                                        <a href={`${companyInfo?.Industries[0]?.sourceUrl?.startsWith("https") ? "" : "https://"}${companyInfo?.Industries[0]?.sourceUrl}`} target="_blank" className="cp">{companyInfo?.Industries[0]?.source|| ''}</a>
                                    ):(
                                        companyInfo?.Industries[0]?.source|| ''
                                    )} 
                                    {}</span>} 
                                    <br />
                                    <b className="bl-truncate-line-1">{companyInfo?.Industries[0]?.industryName || 'NA'}</b></p>
                                <p>NAICS Code <br /><b className="bl-truncate-line-1">{companyInfo?.naicsCode || 'NA'}</b></p>
                                <p>SIC Code <br /><b className="bl-truncate-line-1">{companyInfo?.sicCode || 'NA'}</b></p>
                                <p>Ticker Symbol <br /><b className="bl-truncate-line-1">{companyInfo?.tickerSymbol || 'NA'}</b></p>
                                <p>Legal Name <br /><b className="bl-truncate-line-1">{companyInfo?.legalName || 'NA'}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <h6><i className="fa fa-fw fa-rss-square" aria-hidden="true"></i> Recent News</h6>
                        <div className="pl-0 pr-1 pt-1 pb-1">
                            {companyInfo?.NewsConnection?.edges?.length > 0 ? (
                                companyInfo.NewsConnection.edges.map((item, id) => (
                                    <div key={id} className="bl-activity py-1">
                                        <img
                                            className="img-fluid align-self-center bl-w-h-4rem"
                                            src={item?.node?.image ? item?.node?.image : imagePlaceholder} 
                                            alt="news logo"
                                            onError={(e) => { e.target.onerror = null; e.target.src = imagePlaceholder; }}
                                        />
                                        <div>
                                            <p className="mb-0">
                                                <small className="text-muted">{item?.node?.category}</small>
                                            </p>
                                            <a href={`${(item?.node?.sourceUrl?.startsWith("https")) ? "" : "https://"}${item?.node?.sourceUrl}`} target="_blank" className="bl-desc-truncate-line-2 p-0 text-left">
                                                {item?.node?.title} 
                                            </a>
                                            <p className="text-muted font-bold bl-desc-truncate-line-1 mb-0">
                                                {item?.node?.date} | {item?.node?.source} 
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <EmptyCardsContent
                                    title="No News found"
                                    desc="Unable to build widget. Additional data sources required"
                                    buttonView={false}
                                />
                            )}
                        </div>

                    </div>
                </div>
            </section>
        )
    }

    const widgetSectionsContent = () => {
        return (
            <>
                <section className="mb-2">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Locations</h6>
                                            {companyInfo?.Locations.length > 0 ? (
                                                        <span className="badge bl-source-badge ml-1">
                                                        <i className="fa fa-database" aria-hidden="true"></i>
                                                        {companyInfo?.Locations?.map((location, index) => {
                                                          return (
                                                              location?.source ? (
                                                                  <span key={index}>
                                                                      {location?.sourceUrl && location?.sourceUrl.startsWith("https") ? (
                                                                          <a href={location?.sourceUrl} target="_blank" className="cp">
                                                                              {location?.source}
                                                                          </a>
                                                                      ) : (
                                                                          <span>{location?.source}</span>
                                                                      )}
                                                                      {index - 1 ? ", ": ""} 
                                                                  </span>
                                                                ) : null
                                                            )
                                                        }
                                                    )}
                                                    </span>
                                            ) : null}
                                        </div>
                                        <CardDropdownContent cardId="profileLocationCard" />
                                    </div>
                                    <div className="card-body p-0">
                                        {companyInfo?.Locations?.length > 0 ? (
                                            <DataTable value={companyInfo?.Locations} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" scrollable  scrollHeight="200px">
                                                <Column field="address" header="Address" sortable
                                                body={(rowData)=>{ 
                                                       return rowData?.sourceUrl ? (
                                                        <a href={`${rowData?.sourceUrl?.startsWith("https") ? "" : "https://"}${rowData?.sourceUrl}`} target="_blank" rel="noopener noreferrer" className="cp">
                                                             {rowData?.address || 'NA'}
                                                        </a>) : rowData?.address || 'NA'
                                                }}
                                                ></Column>
                                                <Column header="Type" className="text-center" sortable
                                                body={(row)=> row?.isHeadquarter? "Headquater":"Branch"
                                                }></Column>
                                                <Column header="City" className="text-center" sortable
                                                body={(row)=> row?.City?.cityName || 'NA'}></Column>
                                                <Column header="State" className="text-center" sortable
                                                body={(row)=> row?.City?.State?.stateName || 'NA'}></Column>
                                                <Column header="Country" className="text-center" sortable
                                                body={(row)=> row?.City?.State?.Country?.countryName || 'NA'}></Column>                                                  
                                            </DataTable>
                                        ) : (
                                            <EmptyCardsContent title="No record found" buttonView={false} />
                                        )}
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Transaction Profile</h6>
                                        </div>
                                        <CardDropdownContent cardId="profileTransactionCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">ESG Scorecard</h6>
                                        </div>
                                        <CardDropdownContent cardId="profileESGCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card-deck">

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Financial Summary Over Time</h6>
                                        </div>
                                        <CardDropdownContent cardId="profileFinancialCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Over Time</h6>
                                        </div>
                                        <CardDropdownContent cardId="profileValuationCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Vendor Spends</h6>
                                        </div>
                                        <CardDropdownContent cardId="profileVendorCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Employee Count</h6>
                                        </div>
                                        <CardDropdownContent cardId="profileEmployeeCard" cardType={'EMPLOYEE_COUNT'} />
                                    </div>
                                    {employeeData.length > 0 ? (
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-group list-group-flush overflow-auto bl-user-list">
                                                        {Object.entries(employeeData).map(([country, count]) => (
                                                            <li key={country} className="list-group-item d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <i className="fa fa-users text-gray mr-3" aria-hidden="true"></i>
                                                                    {country.charAt(0).toUpperCase() + country.slice(1)}
                                                                </div>
                                                                <span className="badge badge-primary badge-pill">{count}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="card-body">
                                            <EmptyCardsContent title="No record found" buttonView={false} />
                                        </div>
                                    )}
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0 align-self-center">Key People</h6>
                                            {companyInfo?.People?.length > 0 ? (
                                                <span className="badge bl-source-badge ml-1">
                                                    <i className="fa fa-database" aria-hidden="true"></i>
                                                    {[...new Map(
                                                            companyInfo?.People
                                                                ?.filter((person) => person?.source)
                                                                ?.map((person) => [person?.source, person])
                                                        )?.values()]?.map((person, index, array) => (
                                                            <span key={index}>
                                                                {person?.sourceUrl && person?.sourceUrl.startsWith("https") ? (
                                                                    <a href={person?.sourceUrl} target="_blank" className="cp">
                                                                        {person?.source}
                                                                    </a>
                                                                ) : (
                                                                    <span>{person?.source}</span>
                                                                )}
                                                                {index < array.length - 1 ? ", " : ""}
                                                            </span>
                                                        ))}
                                                </span>
                                            ) : null}
                                        </div>
                                        <CardDropdownContent cardId="profileKeyPeopleCard" cardType={'KEY_PEOPLE'} />
                                    </div>
                                    <div className="card-body p-0">
                                        <DataTable value={companyInfo?.People} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single"  scrollable  scrollHeight="200px">
                                            <Column field="fullName" header="Name" sortable></Column> 
                                            <Column field={(e)=> e?.role || 'NA' } header="Role" className="text-center" sortable></Column>
                                            <Column
                                                field="linkedinUrl"
                                                header="Social"
                                                sortable
                                                body={(rowData) => (
                                                    <div className="d-flex justify-content-center gap-2">
                                                        {rowData.linkedinProfile && (
                                                            <a href={`${rowData?.linkedinProfile?.startsWith("https") ? "" : "https://"}${rowData?.linkedinProfile}`}
                                                                target="_blank" rel="noopener noreferrer" className="cp mr-2">
                                                                <i className="fa fa-fw fa-linkedin" aria-hidden="true"></i>
                                                            </a>
                                                        )}
                                                        {rowData.twitterUrl && (
                                                            <a href={`${rowData?.twitterUrl?.startsWith("https") ? "" : "https://"}${rowData?.twitterUrl}`}
                                                                target="_blank" rel="noopener noreferrer" className="cp">
                                                                <i className="fa fa-fw fa-twitter" aria-hidden="true"></i>
                                                            </a>
                                                        )}
                                                    </div>
                                                )}
                                                className="text-center"
                                            />
                                        </DataTable>
                                        {
                                            companyInfo?.Peoples?.length === 0 ? getEmptyScreen() : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <PortcoCompanyTitle />
                </section>
                {profileSection()}
                {widgetSectionsContent()}
            </div>
        </div>
    )
}