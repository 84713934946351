import { ApolloClient, InMemoryCache } from "@apollo/client";
import apiInstance from "../serviceMaster";
import {getToken} from '../authService'; 

const apiKey = process.env.REACT_APP_BASE_URL;
  const apiGateway = "/bl-pt-api-gateway/"; 
  const { protocol, hostname } = window.location;  
  const baseUrl = hostname.includes('localhost') ? apiKey : `${protocol}//${hostname}${apiGateway}` 

const client = new ApolloClient({
  uri: `${baseUrl}graphql`,
  cache: new InMemoryCache(),
  headers: {
    Authorization:getToken(),
  }
});
export default client;

export async function graphQlService(graphQLParams) {
  try {
    return await apiInstance.post('/graphql', graphQLParams);
  } 
  catch (e) {
    return e;
  }
};
