import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { PageLayout } from './Components/pageLayout';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import LoginComponent from './Components/login';
import { PageNotFoundComponent } from './Components/pageNotFound';
import AIAssistanceContextProvider from './services/context/AIAssistance/aiContextProvider';
import BreadcrumbContextProvider from './services/context/Breadcrumb/breadcrumbContextProvider';
import SearchContextProvider from './services/context/Search/searchContextProvider';
import SystemDownCompoent from './Components/Errors/systemdown';
import { AuthContextProvider } from './services/context/Auth/authContext';
import { AnalyzeCompanyContextProvider } from './services/context/analyzeCompanyContext';
import { HomePageComponent } from './Components/home';
import { ToastContainer } from 'react-toastify';
import { DashboardComponent } from './Components/dashboard';
import { AnalyticsOverviewComponent } from './Components/Analytics/overview';
import { AnalyticsReportListComponent } from './Components/Analytics/reportList';
import { AnalyticsSelfServiceComponent } from './Components/Analytics/selfService';
import { AnalyticsCatalogComponent } from './Components/Analytics/catalog';
import { AnalyticsManageReportComponent } from './Components/Analytics/configure/manageReport';
import { AnalyticsManageReportCategoryComponent } from './Components/Analytics/configure/manageReportCategory';
import { AnalyticsViewReportComponent } from './Components/Analytics/reportView';
import AuthValidationComponent from './Components/authValidation';
import { UnauthorizeAccessComponent } from './Components/unauthorizeAccess';
import { TutorialComponent } from './Components/tutorial';
import { LogServiceComponent } from './Components/logs';
import { PDFViewerComponent } from './Components/PDFViewer';
import ThemeProvider from './services/context/Theme/themeProvider';
import { AIModalBodyComponent } from './Components/AIAssistant/AIModalBody';
import { AdminConsoleComponent } from './Components/Admin';
import { TestingHarnessComponent } from './Components/Admin/testingHarness';
import { ManageTestPlanComponent } from './Components/Admin/testingHarness/TestPlan/manage';
import { TestPlanComponent } from './Components/Admin/testingHarness/TestPlan';
import { BriefingAppComponent } from './Components/BriefingApp';
import { DealBookComponent } from './Components/Dealbook';
import { DealBookDashboardComponent } from './Components/Dealbook/dashboard';
import { DealbookCompanyManageSections } from './Components/Dealbook/Company/manageSections';
import { DealbookCompanyProfile } from './Components/Dealbook/Company/profileInfo';
import { SummarizerContextProvider } from './services/context/summarizerContext';
import { DealbookCompanyFiles } from './Components/Dealbook/Company/filesTags';
import { DealbookConfigTemplateComponent } from './Components/Dealbook/Templates/templateConfig';
import { DealBookProjectIndexComponent } from './Components/Dealbook/Company';
import { SpeakerBriefIndexComponent } from './Components/SpeakerBrief';
import { SpeakerBriefHomeComponent } from './Components/SpeakerBrief/dashboard';
import { SpeakerBriefContextProvider } from './services/context/speakerBriefContext';
import { SpeakerBriefConfigurationComponent } from './Components/SpeakerBrief/configuration';
import { SpeakerBriefEventsIndexComponent } from './Components/SpeakerBrief/Events';
import { SpeakerBriefManageEventsComponent } from './Components/SpeakerBrief/Events/manageEvents';
import { SpeakerBriefEventInformation } from './Components/SpeakerBrief/Events/eventInfo';
import { DataEntityComponent } from './Components/Admin/dataEntity';
import { DataEntityHomeComponent } from './Components/Admin/dataEntity/home';
import { DataEntityManageSectionsComponent } from './Components/Admin/dataEntity/manageEntity/sections';
import { ModelEntityContextProvider } from './services/context/entityContext';
import { DataEntityManageComponent } from './Components/Admin/dataEntity/manageEntity';
import { TestingHarnessHomeComponent } from './Components/Admin/testingHarness/home';
import { TestPlanInfoComponent } from './Components/Admin/testingHarness/TestPlan/testPlanInfo';
import { DealBookTemplates } from './Components/Dealbook/Templates';
import { DealBookTemplatesHomeComponent } from './Components/Dealbook/Templates/home';
import { AIAgentComponent } from './Components/agents';
import { AIAgentHomeComponent } from './Components/agents/home';
import { DataSpheresComponent } from './Components/dataSphere';
import { DataSpheresHomeComponent } from './Components/dataSphere/home';
import { PortcoContextProvider } from './services/context/portcoContext';
import { PortcoMonitoringComponent } from './Components/Portfolio';
import { PortcoMonitoringHomeComponent } from './Components/Portfolio/home';
import { PortcoCompanyComponent } from './Components/Portfolio/Companies';
import { PortcoCompanyOverviewComponent } from './Components/Portfolio/Companies/overview';
import { PortcoCompanyFinancialComponent } from './Components/Portfolio/Companies/finance';
import { PortcoCompanyTransactionProfileComponent } from './Components/Portfolio/Companies/transactionProfile';
import { PortcoCompanyValuationsComponent } from './Components/Portfolio/Companies/valuation';
import { PortcoCompanyVendorSpendsComponent } from './Components/Portfolio/Companies/vendorSpends';
import { PortcoCompanyESGComponent } from './Components/Portfolio/Companies/esgInfo';
import { PortcoCompanyPeopleComponent } from './Components/Portfolio/Companies/peopleInfo';
import { PortCoCompanyDocumentsAndFilesComponent } from "./Components/Portfolio/Companies/DataLibrary/documentsAndFiles";
import { PortcoCompaniesDataLibraryComponent } from './Components/Portfolio/Companies/DataLibrary';
import { PortcoCompaniesKnowledgeGraphComponent } from './Components/Portfolio/Companies/DataLibrary/knowledgeGraph';
import { SpeakerBriefFilesAndDocumentsComponent } from './Components/SpeakerBrief/filesAndDocuments';
import { KairosChatComponent } from './Components/AIAssistant';
import { ConnectorComponent } from './Components/Connector/ConnectorComponent';
import { ConnectionComponent } from './Components/Connector/ConnectionComponent';
import { ManageConnection } from './Components/Connector/ManageConnection';
import { ConnectorIndex } from './Components/Connector';
import { EditConnection } from './Components/Connector/EditConnection';
import { ViewConnection } from './Components/Connector/ViewConnection';
import GraphQl from './Components/graphQl';
import { PortcoCompanyAllNewsComponent } from './Components/Portfolio/Companies/newsEvents';
import LboModel from './Components/lboModel';
import { ClientAppContextProvider } from './services/context/clientAppContext';
import { PortcoCompanyEconomicIndicatorComponent } from './Components/Portfolio/Companies/economicIndicators';


function App() {

  return (
    <Router>
      <ThemeProvider>
        <AuthContextProvider>
          <ClientAppContextProvider>
            <BreadcrumbContextProvider>
              <SearchContextProvider>
                <AIAssistanceContextProvider>
                  <AnalyzeCompanyContextProvider>
                    <SummarizerContextProvider>
                      <SpeakerBriefContextProvider>
                        <ModelEntityContextProvider>
                          <PortcoContextProvider>
                            <Routes>
                              <Route element={<PageLayout />}>
                                <Route path='/home' element={<HomePageComponent />} />
                                <Route path='/dashboard' element={<DashboardComponent />} />
                                <Route path="/explorer" element={<GraphQl />} />
                                <Route path="/lbo-model" element={<LboModel />} />
                                <Route path='/log' element={<LogServiceComponent />} />
                                <Route path='/analytics/overview' element={<AnalyticsOverviewComponent />} />
                                <Route path='/analytics/reports/:id' element={<AnalyticsReportListComponent />} />
                                <Route path='/analytics/reports/category/:categoryId/:reportId' element={<AnalyticsReportListComponent />} />
                                <Route path='/analytics/self-service' element={<AnalyticsSelfServiceComponent />} />
                                <Route path='/analytics/catalog' element={<AnalyticsCatalogComponent />} />
                                <Route path='/analytics/manage-report' element={<AnalyticsManageReportComponent />} />
                                <Route path='/analytics/manage-report-category' element={<AnalyticsManageReportCategoryComponent />} />
                                <Route path='/analytics/reports/:catId/:reportId' element={<AnalyticsViewReportComponent />} />
                                <Route path='/analytics/reports/:catId/:subCatId/:reportId' element={<AnalyticsViewReportComponent />} />
                                <Route element={<KairosChatComponent />}>
                                  <Route path='/kairos' element={<AIModalBodyComponent />} />
                                </Route>
                                <Route path='/pdf/:company' element={<PDFViewerComponent />} />
                                <Route path='/unauthorize' element={<UnauthorizeAccessComponent />} />
                                <Route element={<AdminConsoleComponent />}>
                                  <Route element={<TestingHarnessComponent />}>
                                    <Route path='/admin/testing-harness' element={<TestingHarnessHomeComponent />} />
                                    <Route element={<TestPlanComponent />}>
                                      <Route path='/admin/testing-harness/manage' element={<ManageTestPlanComponent />} />
                                      <Route path='/admin/testing-harness/manage/:id' element={<ManageTestPlanComponent />} />
                                      <Route path='/admin/testing-harness/summary/:id' element={<TestPlanInfoComponent />} />
                                    </Route>
                                  </Route>
                                  <Route element={<DataEntityComponent />} >
                                    <Route path='/admin/data-entity-configuration' element={<DataEntityHomeComponent />} />
                                    <Route element={<DataEntityManageComponent />}>
                                      <Route path='/admin/data-entity-configuration/:id' element={<DataEntityManageSectionsComponent />} />
                                    </Route>
                                  </Route>
                                </Route>
                                <Route path='/briefing-app' element={<BriefingAppComponent />} />
                                <Route path='/dealbook' element={<DealBookComponent />} >
                                  <Route path='/dealbook/home' element={<DealBookDashboardComponent />} />
                                  <Route path='/dealbook/project/:id' element={<DealBookProjectIndexComponent />}>
                                    <Route path='/dealbook/project/:id/:slug' element={<DealbookCompanyProfile />} />
                                    <Route path='/dealbook/project/:id/:slug/manage' element={<DealbookCompanyManageSections />} />
                                    <Route path='/dealbook/project/:id/:slug/files' element={<DealbookCompanyFiles />} />
                                  </Route>
                                  <Route element={<DealBookTemplates />}>
                                    <Route path='/dealbook/templates' element={<DealBookTemplatesHomeComponent />} />
                                    <Route path='/dealbook/templates/:id' element={<DealbookConfigTemplateComponent />} />
                                  </Route>
                                </Route>
                                <Route path='/speaker-brief' element={<SpeakerBriefIndexComponent />}>
                                  <Route path='/speaker-brief/dashboard' element={<SpeakerBriefHomeComponent />} />
                                  <Route path='/speaker-brief/configuration' element={<SpeakerBriefConfigurationComponent />} />
                                  <Route path='/speaker-brief/files-documents' element={<SpeakerBriefFilesAndDocumentsComponent />} />
                                  <Route path='/speaker-brief/events' element={<SpeakerBriefEventsIndexComponent />}>
                                    <Route path='/speaker-brief/events/manage' element={<SpeakerBriefManageEventsComponent />} />
                                    <Route path='/speaker-brief/events/:id/info' element={<SpeakerBriefEventInformation />} />
                                    <Route path='/speaker-brief/events/:id/event-details' element={<SpeakerBriefManageEventsComponent />} />
                                  </Route>
                                </Route>
                                <Route path='/ai-agents' element={<AIAgentComponent />}>
                                  <Route path='/ai-agents/all' element={<AIAgentHomeComponent />} />
                                </Route>
                                <Route path='/data-spheres' element={<DataSpheresComponent />}>
                                  <Route path='/data-spheres/all' element={<DataSpheresHomeComponent />} />
                                </Route>

                                <Route path='/connector' element={<ConnectorIndex />}>
                                  <Route path='/connector' element={<ConnectorComponent />} />
                                  <Route path='/connector/connection' element={<ConnectionComponent />} />
                                  <Route path='/connector/connection/add' element={<ManageConnection />} />
                                  <Route path='/connector/connection/edit/:id' element={<EditConnection />} />
                                  <Route path='/connector/connection/:id' element={<ViewConnection />} />

                                </Route>

                                <Route path='/portco-monitoring' element={<PortcoMonitoringComponent />}>
                                  <Route path='/portco-monitoring/all-companies' element={<PortcoMonitoringHomeComponent />} />
                                  <Route path='/portco-monitoring/companies/:id' element={<PortcoCompanyComponent />} >
                                    <Route path='/portco-monitoring/companies/:id/overview' element={<PortcoCompanyOverviewComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/financial' element={<PortcoCompanyFinancialComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/transaction-profile' element={<PortcoCompanyTransactionProfileComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/valuation' element={<PortcoCompanyValuationsComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/news' element={<PortcoCompanyAllNewsComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/vendor-spends' element={<PortcoCompanyVendorSpendsComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/esg' element={<PortcoCompanyESGComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/people' element={<PortcoCompanyPeopleComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/economic-indicators' element={<PortcoCompanyEconomicIndicatorComponent />} />
                                    <Route path='/portco-monitoring/companies/:id/data-library' element={<PortcoCompaniesDataLibraryComponent />}>
                                      <Route path='/portco-monitoring/companies/:id/data-library/knowledge-graph' element={<PortcoCompaniesKnowledgeGraphComponent />} />
                                      <Route path='/portco-monitoring/companies/:id/data-library/documents-files' element={<PortCoCompanyDocumentsAndFilesComponent />} />
                                    </Route>
                                  </Route>
                                </Route>
                              </Route>
                              <Route path='/' element={<AuthValidationComponent />} />
                              <Route path='/login' element={<LoginComponent />} />
                              <Route path='/system-down' element={<SystemDownCompoent />} />
                              <Route path='/tutorial' element={<TutorialComponent />} />
                              <Route path='/*' element={<PageNotFoundComponent />} />
                            </Routes>
                            <ToastContainer theme="colored" />
                          </PortcoContextProvider>
                        </ModelEntityContextProvider>
                      </SpeakerBriefContextProvider>
                    </SummarizerContextProvider>
                  </AnalyzeCompanyContextProvider>
                </AIAssistanceContextProvider>
              </SearchContextProvider>
            </BreadcrumbContextProvider>
          </ClientAppContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App;
