import React, { useContext, useEffect, useState } from "react";
import testIcon from "./../../../assets/icons/testIcon.svg";
import templateLabel from "../../../utils/property-file/dealbook/template.json";
import buttonsLabel from "../../../utils/property-file/buttons-label.json";
import { useLocation, useNavigate } from "react-router-dom";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import { addNewSubSectionService, getTemplateInfoService, getTemplateListService, removeCustomSubSectionService, updateTemplateSubCategoryPromptsService, addNewTemplateService } from "../../../services/summarizerService";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { toastError, toastSuccess, toastWarning } from "../../../services/notifyService";
import { generateReadableId } from "../../../utils/validation";


export const DealbookConfigTemplateComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    const [action, setAction] = useState('NEW')
    const [templateName, setTemplateName] = useState('')
    const [breadcrumbName, setBreadcrumbName] = useState('')
    const [breadcrumbId, setBreadcrumbId] = useState('')
    const [templateDescription, setTemplateDescription] = useState('')
    const [keyPromptsSec] = useState({
        key: "", prompts: ""
    })
    const [keyPrompts, setKeyPrompts] = useState([keyPromptsSec])

    const [isLoading, setIsLoading] = useState(false)
    const [sections, setSections] = useState([])
    const [subSections, setSubSections] = useState([])
    const [templateId, setTemplateId] = useState(null)
    const [isScorecardSection, setIsScorecardSection] = useState(false)
    const [selectedSectionId, setSelectedSectionId] = useState(null)
    const [subSectionKey, setSubSectionKey] = useState("")
    const [subSectionPrompt, setSubSectionPrompt] = useState("")
    const [subSectionInfo, setSubSectionInfo] = useState({
        categoryId: null, name: "", parentId: null, description:""
    })
    const [selectedSection, setSelectedSection] = useState(null);
    const [sectionName, setSectionName] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [isNewTemplate, setIsNewTemplate] = useState(false); 

    useEffect(() => {
        setIsLoading(false)
        setTemplateName('')
        setTemplateDescription('')
        setTemplateId(null)
        setSelectedSection(null)
        setSelectedSectionId(null)
        getTemplateIdFromPath()
    }, [location.pathname])

    useEffect(() => {
        setAction(breadcrumbId === 'configure' ? "NEW" : "UPDATE")
        const pathId = breadcrumbId === 'configure' ? 'configure' : generateReadableId(templateId, 'TP');
        const pathName = breadcrumbId === 'configure' ? 'Configure' : templateName;
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/templates`, menuName: "Templates", isActive: false, hasSubMenu: true },
            { slug: `/dealbook/templates/${pathId}`, menuName: pathName, isActive: false, hasSubMenu: true }
        ])
    }, [breadcrumbId, templateId, templateName])

    const getTemplateIdFromPath = () => {
        const pathArray = location.pathname.split('/');
        const isTemplateIdExist = !!location?.state?.templateId; 
        setIsNewTemplate(isTemplateIdExist);  
        const pathTemplateId = pathArray[3] ? pathArray[3] === "configure" ? location.state.templateId : pathArray[3].split('-')[1] : null;
        if (pathTemplateId) {
            setBreadcrumbId(pathArray[3] === "configure" ? 'configure' : pathTemplateId)
            setTemplateId(parseInt(pathTemplateId))
        }      
    }

    useEffect(() => {
        if (templateId) {
            getTemplateInfo()
        }
    }, [templateId])

    const getTemplateInfo = async () => {
        setIsLoading(true);
        const resp = await getTemplateInfoService(templateId);
        setIsLoading(false);
        if (resp && resp.success) {
            const templateInfo = resp.data.template;
            setTemplateName(templateInfo.templateName);
            setTemplateDescription(templateInfo.templateDescription);
            const categories = templateInfo.categories || [];
            if (!isNewTemplate) {
                setIsDefault(resp.data.template.isDefault);
            }
            let lastSelectedCategory = selectedSection && categories.some(cat => cat.categoryId === selectedSection) ? selectedSection : 0;
            const updatedSections = [
                { name: "Template details", categoryId: 0, selected: lastSelectedCategory === 0, subCategories: [] },
                ...categories.map(cat => ({
                    ...cat,
                    selected: cat.categoryId === lastSelectedCategory,
                })),
            ];
            
            setSections(updatedSections);
            const subSectionsIndex = categories.findIndex(cat => cat.categoryId === lastSelectedCategory);
            const newSubSections = categories[subSectionsIndex]?.subCategories || [];
            setSubSections(newSubSections);
            setSelectedSection(lastSelectedCategory);
            setSelectedSectionId(lastSelectedCategory);
            setIsScorecardSection(!!(categories[0]?.sectionIdentifier === "scoreCard"));
        }
    };
    
    const setTabName = (value)=>{
            setSections((prevSections) =>
                prevSections.map((sec) =>
                    sec.categoryId === selectedSection ? { ...sec, name: value } : sec
                )
            );
    }
    const setDescription = (newDescription) => {   
        setSections((prevSections) =>
            prevSections.map((sec) =>
                sec.categoryId === selectedSection ? { ...sec, categoryDescription: newDescription } : sec
            )
        );
    };

    const setPrompts = (newPrompt) => {
        setSections((prevSections) =>
            prevSections.map((sec) =>
                sec.categoryId === selectedSection ? { ...sec, prompt:newPrompt } : sec
            )
        );
    }

    const updateTemplateList = async () => {
        try {
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const prevSecPrompts = subSections.map(subSec => ({ ...subSec }));
                if (!isNewTemplate){   
                    const updatedSections = sections.map(secItem => {
                        return {
                            ...secItem,
                            subCategories: secItem.categoryId === prevSecPrompts[0]?.parentId ? prevSecPrompts : secItem.subCategories
                        };
                    });
                    const updateInfo = updatedSections.flatMap(sec =>
                        sec.subCategories.map(sub => ({
                            categoryId: sub.categoryId,
                            prompt: sub.prompt
                        }))
                    );
                    const resp = await updateTemplateSubCategoryPromptsService({ prompts: updateInfo }, templateId)
                    if (resp && resp.success) {
                        toastSuccess("Prompts updated successfully!");
                        nav('/dealbook/templates');
                    }
                }
                else {
                    const removeFirstSections = sections.filter((section, index) => 
                        !(section.categoryId === 0 && index === 0)
                    );
                    const transformedData = transformData(removeFirstSections); 
                    const res = await addNewTemplateService(transformedData);
                    if (res.success) {
                        toastSuccess("Template created successfully!");
                        nav('/dealbook/templates');
                    }
                }
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch(e){
            toastError("Something went wrong")
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
      
    };

    const transformData = (data) => {   
        return {
            templateName: templateName,
            templateDescription: templateDescription,
            categories: data ? data.map((category, categoryIndex) => {
              return {
                parentPosition: categoryIndex + 1,
                name: category.name,
                categoryDescription: category.categoryDescription,
                prompt: category.prompt,
                sectionIdentifier: null,
                subCategories:category.subCategories ? category.subCategories.map((subCategory, subCategoryIndex) => {
                  return {
                    positionInParent: subCategoryIndex + 1,
                    name: subCategory.name,
                    categoryDescription: subCategory.categoryDescription,
                    prompt: subCategory.prompt?subCategory.prompt:[],
                    sectionIdentifier: subCategory.sectionIdentifier || null,
                  };
                }):[]
            }
        }):[]
    }
    };

    const changeSelectedSection = (sec, index) => {
        if (sec) {
            setIsScorecardSection(!!(sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard"))
            setSelectedSectionId(sec.categoryId)
        }
        const prevSecPrompts = subSections.map(subSec => ({ ...subSec }));
        let updatedSections = sections.map(secItem => {
            return {
                ...secItem,
                selected: false,
                subCategories: secItem.categoryId === prevSecPrompts[0]?.parentId ? prevSecPrompts : secItem.subCategories
            };
        });
        updatedSections[index].selected = true;
        setSelectedSection(updatedSections[index].categoryId)
        const subSectionsInfo = updatedSections[index]?.subCategories || [];
        setSections(updatedSections);
        setSubSections([]); 
        setTimeout(() => {
            setSubSections([...subSectionsInfo]);
        }, 400)
    };

    const handleInputChange = (sectionIndex, promptIndex, value) => {
        setSubSections((prevSubSections) => {
            const updatedSubSections = [...prevSubSections];
            if (promptIndex !== undefined) {
                updatedSubSections[sectionIndex] = {
                    ...updatedSubSections[sectionIndex],
                    prompt: updatedSubSections[sectionIndex].prompt.map((info, i) =>
                        i === promptIndex ? { ...info, prompt: value } : info
                    ),
                };
            } else {
                updatedSubSections[sectionIndex] = {
                    ...updatedSubSections[sectionIndex],
                    prompt: value,
                };
            }
            return updatedSubSections;
        });
    };

    const handleInputKeyChange = (sectionIndex, promptIndex, value) => {
        setSubSections((prevSubSections) => {
            const updatedSubSections = [...prevSubSections];
            if (promptIndex !== undefined) {
                updatedSubSections[sectionIndex] = {
                    ...updatedSubSections[sectionIndex],
                    name: updatedSubSections[sectionIndex].prompt.map((info, i) =>
                        i === promptIndex ? { ...info, name: value } : info
                    ),
                };
            } 
            else {
                updatedSubSections[sectionIndex] = {
                    ...updatedSubSections[sectionIndex],
                    name: value,
                };
            }
            return updatedSubSections;
        });
    }
   
    const clearSubSectionForm = () => {
        setSubSectionKey("")
        setSubSectionPrompt("")
    }

    const removeSubSection = async (e,subSectionId) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer'); 
        try {
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            if (!isNewTemplate) {
                const resp = await removeCustomSubSectionService(
                    templateId, 
                    subSectionInfo.parentId, 
                    subSectionInfo.categoryId
                );
                if (resp && resp.success) {
                    await getTemplateInfo();
                    window.$("#removeCustomSubSectionModal").modal("toggle");
                    toastSuccess("Section removed successfully!");
                }
            } 
            else { 
                setSubSections(prevSubSections => prevSubSections.filter(sub => sub.categoryId !== subSectionId));
                setSections(prevSections =>
                    prevSections.map(section =>
                        section.categoryId === selectedSectionId
                            ? {
                                ...section,
                                subCategories: section.subCategories.filter(sub => sub.categoryId !== subSectionId)
                              }
                            : section
                    )
                );
                window.$("#removeCustomSubSectionModal").modal("toggle");
            }
        } catch (e) {
            const message = e?.data?.message || "An error occurred. Please try again!";
            toastError(message);
        } finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    };

    const removeSection = (e) => {
        e.preventDefault(); 
        let updatedSections = sections.filter((ele) => ele.categoryId !== selectedSection);
        if (updatedSections.length > 0) {
            updatedSections = updatedSections.map((sec, index) => ({
                ...sec,
                selected: index === 0
            }));
        }
        setSections(updatedSections);
        setSelectedSection(updatedSections.length > 0 ? updatedSections[0].categoryId : 0);
        window.$("#removeSectionModal").modal("toggle");
    };
    
    const updateSectionsWithSubsection = (sections, selectedSectionId, newSubsection) => {
        return sections.map(section =>
            section.categoryId === selectedSectionId
                ? {
                    ...section,
                    subCategories: [...(section.subCategories || []), newSubsection]
                  }
                : section
        );
    };
    
    const addNewSubSection = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!subSectionKey.trim()) {
                return toastWarning("Please enter the key name!");
            }
            if (!subSectionPrompt.trim()) {
                return toastWarning("Please enter the prompts!");
            }
    
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
    
            const newSubSection = {
                name: subSectionKey,
                prompt: subSectionPrompt
            };
    
            if (!isNewTemplate) {
                const resp = await addNewSubSectionService(templateId, selectedSectionId, newSubSection);
                if (resp && resp.success) {
                    await getTemplateInfo();
                    toastSuccess("Section added successfully!");
                }
            } else {
                const newSubSectionWithParent = {
                    ...newSubSection,
                    parentId: selectedSectionId,
                    categoryId: Date.now()
                };
     
                setSubSections(prevSubSections => [...prevSubSections, newSubSectionWithParent]);
                setSections(prevSections => 
                    updateSectionsWithSubsection(prevSections, selectedSectionId, newSubSectionWithParent)
                );
            }
    
            setSubSectionKey("");
            setSubSectionPrompt("");
            window.$("#addSubSectionModal").modal("toggle");
        } catch (e) {
            const message = e?.data?.message || "An error occurred. Please try again!";
            toastError(message);
        } finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    };

    const addNewSection = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
    
        try {
            if (!sectionName.trim()) {
                return toastWarning("Please enter the section name!");
            }
    
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
    
            const newCategoryId = sections.length > 0 
                ? Math.max(...sections.map(sec => sec.categoryId)) + 1 
                : 1;
    
            const newSection = {
                categoryDescription: null,
                categoryId: newCategoryId,
                customSection: true,
                name: sectionName.trim(),
                parentId: null,
                parentPosition: newCategoryId,
                subCategories: [],
                positionInParent: null,
                selected: true
            };
     
            setSections(prevSections => [
                ...prevSections.map(sec => ({ ...sec, selected: false })),
                newSection
            ]);
            
            setSelectedSection(newCategoryId);
            setSelectedSectionId(newCategoryId);
            setSubSections([]);
            setSectionName("");
            
            window.$("#addNewSectionModal").modal("toggle");
        } catch (e) {
            const message = e?.data?.message || "An error occurred. Please try again!";
            toastError(message);
        } finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
            setIsScorecardSection(false);
        }
    };
    
    const addNewSubSectionContent = () => {
        return (
            <div className="modal fade" id="addSubSectionModal" tabIndex="-1" role="dialog" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addSubSectionModalLabel">Add New Sub Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Key Name:</label>
                                            <input type="text" className="form-control" value={subSectionKey} onChange={(e) => setSubSectionKey(e.target.value)} placeholder="Enter key filed name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Prompt:</label>
                                            <textarea rows={8} className="form-control" value={subSectionPrompt} onChange={(e) => setSubSectionPrompt(e.target.value)} placeholder="Enter prompts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => addNewSubSection(e)}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const removeCustomSubSectionModalContent = () => {
        return (
            <div className="modal fade" id="removeCustomSubSectionModal" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="removeCustomSubSectionModalLabel">Delete Sub Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove sub section <b>"{subSectionInfo.name}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button"
                                onClick={() => document.getElementById("closeModalButton")?.focus()}
                                data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => removeSubSection(e, subSectionInfo.categoryId)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const removeSectionModalContent = () => {
        return (
            <div className="modal fade" id="removeSectionModal" tabIndex="-1" role="dialog"  
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="removeCustomSubSectionModalLabel">Delete Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove section <b>"{sections.find((sec) => sec.categoryId === selectedSection)?.name}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => removeSection(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                    </div>
            </div>
        )
    }

    const changeSection = (direction) => {
        const currentIndex = sections.findIndex((section) => section.selected);
        let newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;        
        if (newIndex >= 0 && newIndex < sections.length) {
            const updatedSections = sections.map((section, index) => ({
                ...section,
                selected: index === newIndex,
            }));
            changeSelectedSection(updatedSections[newIndex], newIndex)
            setSelectedSection(updatedSections[newIndex].categoryId)
        }
    };

    const addNewSectionContent = () => {
        return (
            <div className="modal fade" id="addNewSectionModal" tabIndex="-1" role="dialog" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addNewSectionModalLabel">Add New Section</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Section Name:</label>
                                            <input type="text" className="form-control" value={sectionName} onChange={(e) => setSectionName(e.target.value)} placeholder="Enter section name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => addNewSection(e)}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="d-inline-flex flex-row bl-btn-grp-fixed bl-right-unset">
                    <button onClick={() => nav('/dealbook/templates')} className="d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-arrow-left"></i></button>
                </div>
                {
                    sections && sections.length ? (
                        <div className="d-inline-flex flex-row bl-btn-grp-fixed">
                            {selectedSection !== sections[0]?.categoryId && (
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary mr-2"
                                    onClick={() => changeSection("previous")}
                                >
                                    <i className="fa fa-arrow-left"></i>{" "}
                                    {buttonsLabel.PREVIOUS}
                                </button>
                            )}  
                            {selectedSection !== sections[sections.length - 1]?.categoryId && (
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary mr-2"
                                    onClick={() => changeSection("next")}
                                >
                                    <i className="fa fa-arrow-right"></i>{" "}
                                    {buttonsLabel.SAVE_PROCEED}
                                </button>
                            )}
                            {selectedSection === sections[sections.length - 1]?.categoryId && (
                                <button
                                    className="d-sm-inline-block btn btn-sm btn-primary mr-2"
                                    onClick={updateTemplateList}
                                >
                                    <i className="fa fa-floppy-o mr-1"></i>{" "}
                                    {buttonsLabel.SAVE_PUBLISH}
                                </button>
                            )}

                        </div>
                    ) : null
                }

                {/* <div className={"d-inline-flex flex-row bl-btn-grp-fixed"}>
                    <button className="d-sm-inline-block btn btn-sm btn-primary mr-2" onClick={() => updateTemplateList()}><i className="fa fa-floppy-o mr-1"></i> {buttonsLabel.SAVE}</button>
                </div> */}

                <div className="row" id="bio">
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon mb-2">
                            <img src={testIcon} height="70px" />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <h4 className="h4 text-gray-800">{action === "NEW" ? templateLabel.TITLE : templateLabel.TITLE_UPDATE}</h4>
                    </div>

                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <p>{action === "NEW" ? templateLabel.DESCRIPTION : templateLabel.DESCRIPTION_UPDATE}</p>
                    </div>
                    <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                        <div className="d-flex px-1 py-3 overflow-auto">
                            {
                                sections && sections.map((sec, i) => {
                                    return (
                                        <>
                                            <div className="d-flex" key={sec.categoryId}>
                                            <span key={`sections-badge-${i}`} className={`badge bl-sb-datatableTabs cp bl-chip-btn mr-2 ${sec.selected ? 'active' : ''}`}  onClick={() => changeSelectedSection(sec, i)}>
                                                {sec.name}
                                            </span> 
                                            </div>
                                        </>
                                    )
                                })
                            }
                            {
                                action === "NEW" ? <div className="border-left pl-2 d-flex align-items-center">
                                    <span className={`badge bl-sb-datatableTabs bl-chip-btn cp`} data-toggle="modal" data-target="#addNewSectionModal"><i className="fa fa-plus"></i></span>
                                </div> : null
                            }
                        </div>
                    </div>
                    {
                        isLoading ? <div className="col text-center">
                            <img className="bl-list-loader" src={listViewLoader} />
                        </div> : (
                            <>                            
                            {selectedSection === 0 ?  (
                                           <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2" >
                                           <div className="card-body">
                                               <h5 className="card-title">{templateLabel.FORMS.BASIC.TITLE}</h5>
                                               <p className="card-text text-muted">{action === "NEW" ? templateLabel.FORMS.BASIC.DESCRIPTION : templateLabel.FORMS.BASIC.DESCRIPTION}</p>
                                           </div>
                                           <table className="table mb-0">
                                               <tbody>
                                                   <tr>
                                                       <td className="w-25"><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_NAME}</div></td>
                                                       <td>
                                                           <input type="text" value={templateName} onChange={(e) => setTemplateName(e.target.value)} className="form-control" id="planename" placeholder=""  readOnly={isNewTemplate ? false : isDefault} />
                                                       </td>
                                                       <td>
                                                           <small id="passwordHelpInline" className="text-muted">
                                                               {templateLabel.FORMS.BASIC.TEMPLATE_VALID}
                                                           </small>
                                                       </td>
                                                   </tr>
                                                   <tr>
                                                       <td><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION}</div></td>
                                                       <td>
                                                           <textarea type="text" value={templateDescription} onChange={(e) => setTemplateDescription(e.target.value)} className="form-control" id="thresholdpercentage" placeholder=""  readOnly={isNewTemplate ? false : isDefault}  />
                                                       </td>
                                                       <td>
                                                           <small id="passwordHelpInline" className="text-muted">
                                                               {templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID}
                                                           </small>
                                                       </td>
                                                   </tr>
                                               </tbody>
                                           </table>
                                       </div>
                                    )
                                : 
                                        (
                                            <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                                                <div>
                                                            <div className="d-flex justify-content-space-between">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">Section Details</h5>
                                                                    <p className="card-text text-muted">{templateLabel.FORMS.BASIC.DESCRIPTION}</p>
                                                                </div>
                                                                {
                                                                    isNewTemplate && (
                                                                        <div className="bl-delete-button-container">
                                                                        <button className="btn btn-circle btn-outline-primary btn-sm" data-toggle="modal" data-target="#removeSectionModal"><i className="fa fa-trash"></i></button>
                                                                </div>
                                                                    )
                                                                }
                                                             </div>   
                                                                <table className="table mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-25"><div className="text-small pt-2 pl-2">{templateLabel.FORMS.SECTION.SECTION_NAME}</div></td>
                                                                            <td>
                                                                                <input type="text" onChange={(e) => setTabName(e.target.value)} className="form-control" id="planename" placeholder=""
                                                                                readOnly={isNewTemplate ? false : isDefault} 
                                                                                value={sections.find((sec) => sec.categoryId === selectedSection)?.name || ""}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <small id="passwordHelpInline" className="text-muted">
                                                                                    {templateLabel.FORMS.BASIC.TEMPLATE_VALID}
                                                                                </small>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><div className="text-small pt-2 pl-2">{templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION}</div></td>
                                                                            <td>
                                                                                <textarea type="text" onChange={(e) => setDescription(e.target.value)} className="form-control" id="thresholdpercentage" placeholder=""
                                                                                readOnly={isNewTemplate ? false : isDefault} 
                                                                                value={sections.find((sec) => sec.categoryId === selectedSection)?.categoryDescription || "" }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <small id="passwordHelpInline" className="text-muted">
                                                                                    {templateLabel.FORMS.BASIC.TEMPLATE_DESCRIPTION_VALID}
                                                                                </small>
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                            <td>
                                                                                <div className="text-small pt-2 pl-2 border-none">{templateLabel.FORMS.BASIC.TEMPLATE_PROMPT}</div>
                                                                            </td>
                                                                            <td>
                                                                                <textarea type="text" onChange={(e) => setPrompts(e.target.value)} className="form-control" id="thresholdpercentage" placeholder=""
                                                                                readOnly={isNewTemplate ? false : isDefault} 
                                                                                value={sections.find((sec) => sec.categoryId === selectedSection)?.prompt || "" }
                                                                                />
                                                                            </td>
                                                                        </tr> */}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <hr className="bl-line-break" />
                                                            <div className="card-body d-flex justify-content-between align-items-end">
                                                                <div>
                                                                    <h5 className="card-title">{templateLabel.FORMS.SECTION.TITLE}</h5>
                                                                    <p className="card-text text-muted">{action === "NEW" ? templateLabel.FORMS.SECTION.DESCRIPTION : templateLabel.FORMS.SECTION.DESCRIPTION}</p>
                                                                </div>
                                                                {
                                                                    !isScorecardSection ? <button className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#addSubSectionModal" onClick={() => clearSubSectionForm()}><i className="fa fa-plus-circle mr-2" ></i>Add New Sub Section</button> : null
                                                                }

                                                    </div>
                                                {subSections && subSections.map((secInfo, index) => {
                                                    const canHeaderVisible = secInfo && secInfo.prompt && Array.isArray(secInfo.prompt);
                                                    return (
                                                        <>
                                                            { canHeaderVisible ? (
                                                                <div key={`category-sec-${index}`} className="card rounded-0">
                                                                    <div className="card-header">
                                                                        <h6 className="mb-0">{secInfo.name}</h6>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <table className="table table-bordered mb-0">
                                                                            <tbody>
                                                                                {
                                                                                    secInfo.prompt.map((info, j) => {
                                                                                        return (
                                                                                            <tr key={`template-key-prompt-sec-${index}${j}`}>
                                                                                                <td valign="top" className="w-25">
                                                                                                   <div className="text-small pt-2 pl-2">{info.name}</div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <textarea name={`prompt header ${secInfo.categoryId} ${index} ${j}`} className="form-control form-control-sm border-0" rows={8} defaultValue={info.prompt} onChange={(e) => handleInputChange(index, j, e.target.value)}></textarea>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <table key={`template-table-${index}`} className={`table table-bordered mb-0`}>
                                                                    <tbody>
                                                                        {/* <tr key={`template-key-prompt-sec-${index}`}> */}
                                                                        <tr key={secInfo.categoryId}>
                                                                            <td valign="top" className="w-25">
                                                                                {isNewTemplate || !isDefault ? (
                                                                                    <textarea 
                                                                                        className="form-control form-control-sm border-0" 
                                                                                        id={`prompt${secInfo.categoryId}${index}`} 
                                                                                        name={`prompt ${secInfo.categoryId} ${index}`} 
                                                                                        rows={8} 
                                                                                        defaultValue={secInfo.name} 
                                                                                        onChange={(e) => handleInputKeyChange(index, undefined, e.target.value)}
                                                                                        readOnly={isNewTemplate ? false : isDefault}     
                                                                                    />
                                                                                    ) : (
                                                                                    <div className="text-small pt-2 pl-2">{secInfo.name}</div>
                                                                                )}
                                                                            </td>
                                                                            <td className="border-0">
                                                                                <textarea className="form-control form-control-sm border-0" id={`prompt${secInfo.categoryId}${index}`} name={`prompt ${secInfo.categoryId} ${index}`} rows={8} defaultValue={secInfo.prompt} onChange={(e) => handleInputChange(index, undefined, e.target.value)}></textarea>
                                                                            </td>
                                                                            <td valign="top" className="border-0" width={'20px'}>
                                                                                {
                                                                                    secInfo.customSection ? <button className="btn btn-circle btn-outline-primary btn-sm mr-2" data-toggle="modal" 
                                                                                    id='closeModalButton'
                                                                                    data-target="#removeCustomSubSectionModal" onClick={() => setSubSectionInfo(secInfo)}><i className="fa fa-trash"></i></button> :
                                                                                    (
                                                                                        isNewTemplate && <button  
                                                                                        className="btn btn-circle btn-outline-primary btn-sm mr-2" data-toggle="modal" 
                                                                                        id='closeModalButton'
                                                                                        data-target="#removeCustomSubSectionModal" onClick={() => setSubSectionInfo(secInfo)}><i className="fa fa-trash"></i></button>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            )
                                                            }
                                                        </>

                                                    )
                                                })
                                                }
                                            </div>
                                        )} 
                        </>
                    )}
                </div>
            </div>
            {addNewSectionContent()}
            {addNewSubSectionContent()}
            {removeCustomSubSectionModalContent()}
            {removeSectionModalContent()}
        </div >
    )
}