import React, { useContext, useEffect, useState } from "react";
import { GridViewComponent } from "./gridView";
import { TableViewComponent } from "./tableView";
import reportsJson from "./../../data/Analytics/reports.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const AnalyticsSelfServiceComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/self-service', menuName: 'Self Service', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [viewType, setViewType] = useState('GRID');
    const [reportList, setReportList] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type', 'Shared with'
    ])

    useEffect(() => {
        setViewType('GRID');
        let reports = reportsJson.filter(r => r.createdBy === 'Apurva Pandey');
        setReportList(reports)
    }, [])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Report Created by You</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-plus mr-1" aria-hidden="true"></i>{'Create New Report'}</button>
                        <button className="btn btn-primary btn-sm mr-2" onClick={() => setViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm" onClick={() => setViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        viewType === 'GRID' ?
                            <GridViewComponent reports={reportList}></GridViewComponent> : <TableViewComponent data={reportList} reportColumn={reportColumn}></TableViewComponent>
                    }

                </section>
            </div>
        </div>
    )
}