import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "../../utils/property-file/componentOverview.json";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import { toastError, toastSuccess, toastWarning } from "../../services/notifyService";
import { ProgressBar } from "primereact/progressbar";
import imagePlaceholder from "./../../assets/img/image-placeholder.jpeg";
import { convertFileSizeInFormat, getFileIconUrl, getFriendlyType, linkValidation } from "../../utils/validation";
import { SpeakerBriefContext } from "../../services/context/speakerBriefContext";
import { deleteSBFilesAndDocumentsService, getSBFilesAndDocumentsProgressStatusService, getSBFilesAndDocumentsService, uploadSBFilesAndDocumentsService, createTagsService, getAllTagsService, trainedModelForFileService, updateFileService, getAllFileTypesService } from "../../services/speakerBriefService";
import Select, { components } from 'react-select';
import CreatableSelect from "react-select/creatable";

export const SpeakerBriefFilesAndDocumentsComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { SBModels } = useContext(SpeakerBriefContext)
    const location = useLocation()
    const fileInputRef = useRef(null);
    const maxSize = 100 * 1024 * 1024;

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [showStartPlaceholder, setShowStartPlaceholder] = useState(true);
    const [showEndPlaceholder, setShowEndPlaceholder] = useState(true);
    const [intervalDelay, setIntervalDelay] = useState(5000);
    const [selectedModel, setSelectedModel] = useState('')
    const [options, setOptions] = useState([]);
    const [formattedTags, setFormattedTags] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [selectedType, setSelectedType] = useState("All");
    const [fileType, setFileType] = useState('');
    const [appliedFilters, setAppliedFilters] = useState({ startDate: "", endDate: "" });
    const [search, setSearch] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const searchTimeoutRef = useRef(null);

    useEffect(() => {
        if (startDate) setShowStartPlaceholder(false);
        if (endDate) setShowEndPlaceholder(false);
    }, [startDate, endDate]);

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        RECOMMEND: ""
    });
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        if (homeLabel) {
            const items = homeLabel.find(l => l.FEATURE === "PORTCO").documents;
            setLabels(items)
        }
    }, [homeLabel])

    useEffect(() => {
        getAllTag(1)
        setBreadcrumbMenu([
            { slug: '/speaker-brief', menuName: 'Speaker Brief', isActive: true, hasSubMenu: true },
            { slug: `/speaker-brief/files-documents`, menuName: "Documents & Files", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const [isLoading, setIsLoading] = useState(false)
    const [isFilesSelected, setIsFilesSelected] = useState(false)

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [filter, setFilter] = useState([
        { name: 'All', val: 'recent', isActive: true },
        { name: 'Word', val: 'Trending_Companies', isActive: false },
        { name: 'Excel', val: 'In_Current_News', isActive: false },
        { name: 'PowerPoint', val: 'Unicorns', isActive: false },
        { name: 'PDF', val: 'pdf', isActive: false },
        { name: 'Link', val: 'link', isActive: false }
    ]);
    const [isLinkView, setIsLinkView] = useState(false)
    const [selectedFile, setSelectedFile] = useState({
        fileId: null, fileName: "", modelId: null
    })
    
    const handleTagChange = (option, index) => {
        const files = [...selectedFiles];
        const tagValues = option.map(item => item);
        files[index].tags = tagValues.value;
        files[index].selectiveTags = tagValues;
        setSelectedFiles(files)
    };

    const formatTagData = (data) => {
        return data.map((item) => {
            const result = {
                label: item?.name != '' ? item.name : "",
                value: Number(item?.tagId),
            };
            if (item.children && item.children.length > 0) {
                result.options = item.children.map((child) => ({
                    label: child?.name != '' ? child.name : '',
                    value: Number(child?.tagId)
                }));
            }
            return result;
        });
    }

    const getAllTag = async (page) => {
        try {
            const limit = 20;
            const obj = {
                page: page, limit: limit
            }
            const resp = await getAllTagsService(obj);
            if (resp && (resp.status || resp.success)) {
                const data = formatTagData(resp.data);
                setOptions((prev) => [...prev, ...data])
                if (data.length >= limit) {
                    const newPage = page + 1;
                    getAllTag(newPage)
                }
            }
        }
        catch (e) {
            toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
        }
    }

    const handleCreateTag = async (inputValue, fileIndex = null) => {
        const uniqueId = Math.floor(Date.now() / 1000) + Math.floor(Math.random() * 100);
        const newTag = { value: uniqueId, label: inputValue };
        setOptions(prevOptions => {
            const exists = prevOptions.some(option => option.value === newTag.value);
            return exists ? prevOptions : [...prevOptions, newTag];
        });
        if (fileIndex != null) {
            setSelectedFiles(prevFiles => {
                return prevFiles.map((file, index) => {
                    if (index === fileIndex) {
                        const updatedSelectiveTags = file.selectiveTags ? [...file.selectiveTags, newTag] : [newTag];
                        return {
                            ...file,
                            selectiveTags: updatedSelectiveTags
                        };
                    }
                    return file;
                });
            });
        }
        else {
            setFormattedTags([...formattedTags, newTag]);
            setSelectedFile((prev) => {
                const parsedTagIds = (prev?.tagIds || "[]");
                const updatedTagIds = [...parsedTagIds, uniqueId];
                const updatedTags = [...prev?.tags, inputValue];
                return {
                    ...prev,
                    tagIds: updatedTagIds,
                    tags: updatedTags
                };
            });
        }
    };

    const dropdownStyle = {
        groupHeading: (styles) => ({
            ...styles,
            padding: '5px 10px',
            margin: 0,
        }),
        control: (styles, { isFocused }) => ({
            ...styles,
            padding: "0px 0px",
            minHeight: "28px",
            boxShadow: isFocused ? "0 0 0 .2rem #4e73df40" : "none",
            border: '1px solid #d1d3e2',
            borderRadius: '3px',
            "&:hover": {
                border: 'default',
            },
        }),
        option: (styles) => ({
            ...styles,
        }),
        multiValue: (styles) => ({
            ...styles,
            margin: '2px',
        }),
        input: (styles) => ({
            ...styles,
            width: "100px",
            padding: "0"
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            display: "none",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
        }),
    };

    const fileIcons = {
        "PDF": "fa-file-pdf-o",
        "PowerPoint": "fa-file-powerpoint-o",
        "Excel": "fa-file-excel-o",
        "Word": "fa-file-word-o",
        "default": "fa-file-o"
    };

    useEffect(() => {
        setSelectedModel('')
        setIsLinkView(false)
        setIsLoading(false)
    }, [location.pathname])


    const handleMissingImage = (e) => {
        e.target.src = imagePlaceholder;
    }

    const arrangeColumnValue = (item, column, rowIndex) => {
        const compDate = item.createdOn ? item.createdOn : moment();
        const diffInHours = moment().diff(moment(compDate), 'hours', true)
        const fileStatus = !!(item.status && (item.status.toLowerCase() === 'queued' || item.status.toLowerCase() === 'processing'));
        if (Math.abs(diffInHours) < 1 && fileStatus && rowIndex >= 2 && rowIndex <= 7) {
            // item.TotalPages = 11; item.PagesProcessed = 3;
            const percentage = Math.round(item.pagesProcessed * 100 / item.totalPages);
            if (column === 'MODEL') {
                return (
                    <div className="bl-progress-cell bl-inset-auto bl-top-25">
                        <div className="font-smaller text-muted text-center">{item.fileStatus ? item.fileStatus : 'Processing file now....'} {item.totalPages ? `(Pages Processed: ${item.pagesProcessed} out of ${item.totalPages})` : null}</div>
                        <ProgressBar value={percentage ?? 5} color="green" height={'8px'} />
                    </div>
                );
            }
            else if (['UPLOADED_ON', 'SIZE', 'UPLOADED_BY', 'STATUS', 'TOTAL_PAGE', 'PROCESSED'].includes(column)) {
                return null;
            }
        }
        else {
            if (column === 'NAME') {
                const isImage = item.fileType && item.fileType.includes('image');
                const fileArray = item?.fileName ? item?.fileName?.split('____') : [];
                const fileName = fileArray && fileArray.length > 1 ? fileArray[1] : item.fileName;
                const fileURL = item.fileUrl ? linkValidation(item.fileUrl) : null;
                return (
                    <div className="d-flex justify-content-between bl-truncate-line-1">
                        <div className="d-flex align-self-center">
                            {
                                !isLinkView ? <img className="mr-2 rounded align-self-center" height="40px" alt="File icon" onError={handleMissingImage}
                                    src={isImage && item.sasUrl ? item.sasUrl : getFileIconUrl(item.fileName, item.fileType)}
                                /> : null
                            }
                            <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 font-small bl-truncate-line-1">{fileURL ? <a href={fileURL} target="_blank">{fileName}</a> : fileName}</h6>
                                <div className="mb-0 font-small text-muted bl-truncate-line-1">
                                    Tags: ({(item?.tags).join(', ') || 'No tags found'})
                                    {/* {item.tags && (
                                    <>
                                        Tags: {item.tags.split(',').map(tag => tag.trim()).slice(0, 2).join(', ')}
                                        {item.tags.split(',').map(tag => tag.trim()).length > 2 ? ` +${item.tags.split(',').map(tag => tag.trim()).length - 2} more` : ''}
                                    </>
                                )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if (column === 'UPLOADED_ON') {
                return (
                    moment(item.createdOn).format('MMM DD, YYYY hh:mm A')
                )
            }
            else if (column === 'UPLOADED_BY') {
                return (
                    item.createdBy
                )
            }
            else if (column === 'STATUS') {
                return item?.status?.toLowerCase() === "completed" && !item.isTuned ?
                    <span className={`badge badge-pill badge-secondary text-uppercase`}>
                        {
                            item?.status?.toLowerCase() === "completed" && !item.isTuned ? <a onClick={(e) => trainedModelForFile(e, item.id)} title="Tune Model">
                                <i className="fa fa-play-circle fa-fw " aria-hidden="true"></i>
                                {'Tune now'}
                            </a> : null
                        }
                    </span>
                    : <span className={`badge badge-pill badge-${getStatusColor(item.status)} text-uppercase`}>{item.status}</span>
            }
            else if (column === "SIZE") {
                return item.fileSize ? `${item.fileSize}` : '';
            }
            else if (column === "TOTAL_PAGE") {
                return item.totalPages;
            }
            else if (column === "PROCESSED") {
                return item.pagesProcessed;
            }
            else if (column === "MODEL") {
                return item.displayName;
            }
            else if (column === "ACTION") {
                return (
                    <div className="d-flex">
                        <a onClick={(e) => getFileInfo(e, item)} data-toggle="modal" data-target="#editSBFileModal" title="Edit File" className="mr-3"><i className="fa fa-edit bl-db-datable-icon" aria-hidden="true"></i></a>
                        <a onClick={(e) => getFileInfo(e, item)} data-toggle="modal" data-target="#deleteSBFileModal" title="Delete File"><i className="fa fa-trash bl-db-datable-icon" aria-hidden="true"></i></a>
                    </div>
                )
            }
        }

    }

    const getFileInfo = (e, file) => {
        e.preventDefault();
        const fileArray = file?.fileName?.split('____');
        const fileName = fileArray && fileArray.length > 1 ? fileArray[1] : file.fileName;
        setSelectedFile({ ...file, fileName })
    }

    const getStatusColor = (status) => {
        let classColor = "primary"
        if (status) {
            classColor = status === "processing" ? "warning" : status === "completed" ? "success" : status === "failed" ? "danger" : "primary";
        }
        return classColor;
    }
    useEffect(() => {
        getAllFileTypes();
    }, [])

    useEffect(() => {
        if (!hasMoreData) return;
        let throttleTimeout = null;
        const handleScroll = () => {
            if (throttleTimeout) return;
            throttleTimeout = setTimeout(() => {
                if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && !isLoading) {
                    getDocsAndFiles(nextPage);
                }
                throttleTimeout = null;
            }, 300);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (throttleTimeout) clearTimeout(throttleTimeout);
        };
    }, [nextPage, isLoading, hasMoreData]);


    const getDocsAndFiles = async (page = 1) => {
        try {
            if (page === 1) {
                setIsLoading(true);
            }
            const limit = 20;   
            const obj = { page, limit, searchTerm: searchQuery, fileType, startDate: appliedFilters.startDate, endDate: appliedFilters.endDate };
            const resp = await getSBFilesAndDocumentsService(obj);
            if (resp && (resp?.status || resp?.success)) {
                const files = Array?.isArray(resp?.data) ? resp?.data : [];
                setUploadedFiles(prevFiles => {
                    const fileIds = new Set(prevFiles.map(f => f.id));
                    const newFiles = files.filter(f => f && f.id && !fileIds.has(f.id));
                    return [...prevFiles, ...newFiles];
                });
                if (files.length < limit) {
                    setHasMoreData(false);
                    return;
                }
                setNextPage(page + 1);
            }
        } catch (e) {
            toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!");
        }
        finally {
            setIsLoading(false);
        }
    };
 
    useEffect(() => {
        const fetchUploadedFilesState = async () => {
            let allInfoAvailable = true;
            const updatedFilesList = await Promise.all(
                uploadedFiles.map(async (item) => {
                    const compDate = item.createdOn ? item.createdOn : moment();
                    const diffInHours = moment().diff(moment(compDate), 'hours', true)
                    const fileStatus = !!(item.status && (item.status.toLowerCase() === 'queued' || item.status.toLowerCase() === 'processing'));
                    if (Math.abs(diffInHours) < 1 && fileStatus) {
                        allInfoAvailable = false;
                        try {
                            const info = await getFilesProcessingStatus(item.modelId, item.fileId);
                            if (info) {
                                return { ...item, ...info };
                            }
                        } catch (error) { }
                    }
                    return item;
                })
            );
            setUploadedFiles(updatedFilesList)
        };

        const intervalId = setInterval(() => {
            fetchUploadedFilesState();
        }, intervalDelay);

        return () => clearInterval(intervalId);
    }, [intervalDelay, uploadedFiles]);

    useEffect(() => {
        getDocsAndFiles(1);
    }, [fileType, appliedFilters, searchQuery]);

    const getFilesProcessingStatus = async (modelId, fileId) => {
        try {
            const resp = await getSBFilesAndDocumentsProgressStatusService(modelId, fileId);
            if (resp && (resp.status || resp.success)) {
                return resp.data;
            }
            return null
        }
        catch (e) {

        }
    }

    const getAllFileTypes = async () => {
        try {
            const response = await getAllFileTypesService();
            if (response?.status && response?.data) {
                setFileTypes(response?.data);
            }
        }
        catch (error) {
            setFileTypes([]);
        }
    }

    const handleSelection = (type) => {
        setSelectedType(type);
        setUploadedFiles([]);
        setNextPage(1);
        setHasMoreData(true);
        if (type === "All") {
            setFileType("");
        } else {
            setFileType(type);
        }
    };

    const handleSearch = (text) => {
        if (text === ' '){
            return;
        }
        setSearch(text);
        setHasMoreData(true);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            setUploadedFiles([]);
            setSearchQuery(text);
        }, 800);
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        if (!newStartDate) {
            setStartDate("");
            return;
        }
        if (endDate && newStartDate > endDate) {
            return toastWarning('From date cannot be greater than To date');
        }
        else {
            setStartDate(newStartDate);
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        if (!newEndDate) {
            setEndDate("");
            return;
        }
        if (startDate && newEndDate < startDate) {
            return toastWarning('To date cannot be less than From date.');
        }
        else {
            setEndDate(newEndDate);
        }
    };

    const closeDropdownMenu = () => {
        document.getElementById("dropdownMenu2").click();
    };

    const handleClearDate = (e) => {
        if (appliedFilters.startDate || appliedFilters.endDate) {
            setHasMoreData(true);
            setAppliedFilters({ startDate: "", endDate: "" });
            closeDropdownMenu();
        }
        setStartDate('');
        setEndDate('');
        setShowStartPlaceholder(true);
        setShowEndPlaceholder(true);
    }

    const applyDateFilter = (e) => {
        if (!startDate || !endDate) {
            e.stopPropagation();
            return toastWarning("Please select both From and To dates.");
        }
        setHasMoreData(true);
        setUploadedFiles([]);
        setAppliedFilters({ startDate, endDate });
        closeDropdownMenu();
    }

    const uploadFilesAndDocs = async (e) => {
        e.preventDefault();
        const pageLoaderContainer = document.getElementById('pageLoaderContainer');
        try {
            if (selectedFiles.length === 0) {
                return toastWarning("Please select files before uploading.");
            }

            const formData = new FormData();
            let isModelAdded = true;
            selectedFiles.forEach((fileObj, index) => {
                if (!fileObj.modelId) {
                    isModelAdded = false;
                }
                const tags = fileObj.selectiveTags.map(t => t.label)
                formData.append("files", fileObj.file);
                formData.append(`modelId_${index}`, fileObj.modelId);
                formData.append(`link_${index}`, fileObj.link);
                formData.append(`tags_${index}`, JSON.stringify(tags));
            });
            if (!isModelAdded) {
                return toastWarning("Please select the modal")
            }
            if (pageLoaderContainer) {
                pageLoaderContainer.style.display = "flex";
            }
            const resp = await uploadSBFilesAndDocumentsService(formData);
            if (resp && (resp.status || resp.success)) {
                toastSuccess("Files uploaded successfully!");
                closeSelectedFilesSection(e);
                setUploadedFiles([]);
                setHasMoreData(true);
                getDocsAndFiles(1);
                setOptions([]);
                getAllTag(1);
            }
        }
        catch (e) {
            if (e.status < 500) {
                toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
            }
        }
        finally {
            if (pageLoaderContainer) {
                pageLoaderContainer.style.display = "none";
            }
        }
    }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const maxFiles = 5;
        const allowedExtensions = /\.(pdf|ppt|pptx|doc|docx|xls|xlsx)$/i;

        // if (newFiles.length + selectedFiles.length > maxFiles) {
        //     closeSelectedFilesSection(event);
        //     return toastWarning("You can upload a maximum of 5 files.");
        // }
        // let totalFileSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
        const validFiles = newFiles.filter((file) => {
            if (!(allowedExtensions.test(file.name))) {
                toastWarning(`Invalid file type: ${file.name}. Allowed types: PDF, PPT, DOC, & XLS.`);
                return false;
            }
            // if (!(allowedExtensions.test(file.name) || file.type.startsWith("image/"))) {
            //     toastWarning(`Invalid file type: ${file.name}. Allowed types: PDF, PPT, DOC, XLS, and Images.`);
            //     return false;
            // }
            // if (totalFileSize + file.size > maxSize) {
            //     toastWarning(`Total file size should not exceed ${convertFileSizeInFormat(maxSize)}!`);
            //     return false;
            // }
            // totalFileSize += file.size;
            return true;
        });
        const filesWithExtras = validFiles.map((file) => ({
            file,
            link: "",
            modelId: SBModels.find(model => model.default)?.modelId || '',
            tags: [],
            selectiveTags: []
        }));

        if (filesWithExtras.length > 0) {
            setIsFilesSelected(true);
            setSelectedFiles((prevFiles) => [...prevFiles, ...filesWithExtras]);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleGroupSelect = (group, index) => {
        const files = [...selectedFiles];
        const groupOptions = group.options;
        const allValues = files[index].selectiveTags.map((opt) => opt.value);
        const isAllSelected = groupOptions.every((opt) => allValues.includes(opt.value));
        const newSelectedTags = isAllSelected
            ? files[index].selectiveTags.filter((opt) => !groupOptions.some((gOpt) => gOpt.value === opt.value))
            : [...files[index].selectiveTags, ...groupOptions.filter((opt) => !allValues.includes(opt.value))];
        files[index].selectiveTags = newSelectedTags;
        setSelectedFiles(files);
    };

    const handleUpdateGroupSelect = (group) => {
        const isOptionSelected = (option) => formattedTags.some(tag => tag.value === option.value);
        if (!group.options || group.options.length === 0) {
            const newTag = { value: group.value, label: group.label };
            if (!isOptionSelected(newTag)) {
                setFormattedTags(prev => [...prev, newTag]);
                setSelectedFile(prev => ({
                    ...prev,
                    tagIds: [...prev.tagIds, newTag.value],
                    tags: [...prev.tags, newTag.label]
                }));
            }
            return;
        }
        const newOptions = group.options.filter(item => !isOptionSelected(item));
        if (newOptions.length > 0) {
            setFormattedTags(prev => [...prev, ...newOptions]);
            setSelectedFile(prev => ({
                ...prev,
                tagIds: [...prev.tagIds, ...newOptions.map(item => item.value)],
                tags: [...prev.tags, ...newOptions.map(item => item.label)]
            }));
        }
    };

    const closeSelectedFilesSection = (e) => {
        e.preventDefault();
        setIsFilesSelected(false)
        setSelectedFiles([]);
        window.$("#sbDocUpload").val('')
    }

    const mapModelWithFiles = (modelId, index) => {
        const files = [...selectedFiles];
        files[index].modelId = modelId;
        setSelectedFiles(files)
    }

    const mapExternalLinkWithFiles = (link, index) => {
        const files = [...selectedFiles]
        files[index].link = link;
        setSelectedFiles(files)
    }

    const selectedFilesPreviewContent = () => {
        return (
            <div className="row">
                <div className="col-12 d-flex align-items-center m-2">
                    <h6 className="text-nowrap mr-3 text-uppercase">Configure Selected Files</h6>
                </div>
                <div className="col-12">
                    <table className="table table-sm table-borderless text-left">
                        <thead>
                            <tr>
                                <th scope="col">File Name</th>
                                <th scope="col" width="15%">Model</th>
                                <th scope="col">External Link (Optional)</th>
                                <th scope="col" width="30%">Tags (Optional)</th>
                                <th scope="col" width='10%'>File Type</th>
                                <th scope="col">File Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedFiles && selectedFiles.map((file, i) => {
                                    return (
                                        <tr key={`sb-doc-file-upload-${i}`}>
                                            <td>{file.file.name}</td>
                                            <td>
                                                <select className="form-control form-control-sm font-small" value={file.modelId} onChange={(e) => mapModelWithFiles(e.target.value, i)}>
                                                    <option value={''} disabled>Select Model</option>
                                                    {
                                                        SBModels && SBModels.map((model, i) => {
                                                            return (
                                                                <option key={`sb-model-files-${i}`} value={model.modelId}>{model.displayName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm font-small" value={file.link} onChange={(e) => mapExternalLinkWithFiles(e.target.value, i)} />
                                            </td>
                                            <td>
                                                <CreatableSelect
                                                    isMulti
                                                    options={options}
                                                    value={file.selectiveTags}
                                                    onChange={(e) => {
                                                        handleTagChange(e, i)
                                                    }}
                                                    onCreateOption={(e) => {
                                                        handleCreateTag(e, i)
                                                    }}
                                                    formatGroupLabel={(e) => {
                                                        return (
                                                            <div
                                                                className="cp font-weight-bold primary "
                                                                onClick={() => handleGroupSelect(e, i)}>
                                                                {e.label}
                                                            </div>
                                                        )
                                                    }}
                                                    styles={dropdownStyle}
                                                    isClearable
                                                    closeMenuOnSelect={false}
                                                    placeholder="Search or Create New Tag"
                                                    formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                                />
                                            </td>
                                            <td className="text-muted text-nowrap">{getFriendlyType(file.file.type)}</td>
                                            <td>{convertFileSizeInFormat(file.file.size)}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <div className="d-inline-flex flex-row">
                        <button className="btn btn-outline-primary btn-sm mr-2 px-5" onClick={(e) => closeSelectedFilesSection(e)} type="button">Cancel</button>
                        <button type="button" className="btn btn-primary btn-sm px-5" onClick={uploadFilesAndDocs}>Proceed</button>
                    </div>
                </div>
            </div>
        )
    }

    const deletePortcoCompaniesFiles = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const resp = await deleteSBFilesAndDocumentsService(selectedFile.modelId, selectedFile.fileId);
            if (resp && (resp.status || resp.success)) {
                setUploadedFiles([]);
                setHasMoreData(true);
                getDocsAndFiles(1)
                window.$("#deleteSBFileModal").modal('hide');
                toastSuccess("Files removed successfully!")
            }
        }
        catch (e) {
            if (e.status < 500) {
                toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
            }
        }
        finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const editPortcoCompaniesFiles = async (e) => {
        try {
            e.preventDefault();
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const obj = {
                tagNames: selectedFile?.tags || '', fileUrl: (selectedFile?.fileUrl) || null, modelId: selectedFile?.modelId
            };
            const response = await updateFileService(obj, selectedFile?.modelId, selectedFile.fileId);
            if (response && response.success || response.status) {
                window.$("#editSBFileModal").modal('hide');
                setUploadedFiles([]);
                setOptions([]);
                getAllTag(1);
                setHasMoreData(true);
                getDocsAndFiles(1);
                toastSuccess(response?.data?.status ? response.data.status : "Files updated successfully!");
            }
        }
        catch (error) {
            if (error.status < 500) {
                toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
            }
        }
        finally {
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const updateModelId = (e) => {
        setSelectedFile(prev => ({
            ...prev,
            modelId: e.target.value
        }));
    };

    const updateExternalLinks = (e) => {
        setSelectedFile(prev => ({
            ...prev,
            fileUrl: e.target.value
        }));
    }

    useEffect(() => {
        setFormattedTags()
        const ids = selectedFile.tagIds || [];
        const labels = selectedFile.tags || [];
        const data = ids.map((id, index) => ({
            value: Number(id),
            label: labels[index] || ''
        }));
        setFormattedTags(data);
    }, [selectedFile]);

    const updateTags = (data) => {
        const updatedTagIds = [...new Set(data.map(tag => Number(tag.value)))];
        const updatedTags = data.map(tag => tag.label);
        setSelectedFile(prev => ({
            ...prev,
            tagIds: updatedTagIds,
            tags: updatedTags
        }));
    };

    const deleteFileModalContent = () => {
        return (
            <div className="modal fade" id="deleteSBFileModal" tabIndex="-1" role="dialog" aria-labelledby="deleteSBFileModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteSBFileModalLabel">Delete File</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove <b>"{selectedFile.fileName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deletePortcoCompaniesFiles(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const editFileModalContent = () => {
        return (
            <div className="modal fade" id="editSBFileModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h6 className="m-0">{selectedFile?.fileName}</h6>
                                <span className="text-muted"><b>({selectedFile?.fileSize})</b></span>
                            </div>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form onSubmit={(e) => editPortcoCompaniesFiles(e)}>
                            <div className="modal-body">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Model</label>
                                        <select className="form-control form-control-sm bl-select-box" value={selectedFile?.modelId || ''}
                                            onChange={(e) => updateModelId(e)}
                                        >
                                            <option value={''} disabled>Select Model</option>
                                            {
                                                SBModels && SBModels.map((model, i) => {
                                                    return (
                                                        <option key={i} value={model.modelId}>{model.displayName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>External Link (Optional)</label>
                                        <input type="text" className="form-control form-control-sm" value={selectedFile?.fileUrl || ''} onChange={(e) => updateExternalLinks(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Tags (Optional)</label>
                                    <CreatableSelect
                                        isMulti
                                        options={options}
                                        value={formattedTags}
                                        onCreateOption={(e) => {
                                            handleCreateTag(e)
                                        }}
                                        onChange={updateTags}
                                        formatGroupLabel={(e) => {
                                            return (
                                                <div
                                                    className="cp font-weight-bold primary "
                                                    onClick={() => handleUpdateGroupSelect(e)}>
                                                    {e.label}
                                                </div>
                                            )
                                        }}
                                        styles={dropdownStyle}
                                        isClearable
                                        closeMenuOnSelect={false}
                                        placeholder="Search or Create New Tag"
                                        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        )
    }

    const trainedModelForFile = async (e, mappingId) => {
        e.preventDefault();
        try {
            updateFileStatus(mappingId, true);
            const resp = await trainedModelForFileService(mappingId);
            if (resp && (resp.status || resp.success)) {
                toastSuccess("Model tuned with selected file!")
            }
            else {
                updateFileStatus(mappingId, false);
            }
        }
        catch (error) {
            updateFileStatus(mappingId, false);
            if (error.status < 500) {
                toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
            }
        }
    }

    const updateFileStatus = (mappingId, isTuned) => {
        setUploadedFiles((prevFiles) =>
            prevFiles.map((file) =>
                file.id === mappingId ? { ...file, isTuned } : file
            )
        );
    };
    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <section className="mb-3">
                            <div className="media px-3 py-2 bl-hero-background rounded">
                                <div className="media-body mr-5 ">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0 text-muted">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-0">
                                        <div className="card text-center bl-db-fileupload-border">
                                            <div className="card-body">
                                                {
                                                    isFilesSelected ? selectedFilesPreviewContent() : (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <img
                                                                    src="https://icons.veryicon.com/png/o/business/cloud-computing-background-management-icon/upload-to-folder.png"
                                                                    height="80px"
                                                                    alt="Upload"
                                                                />
                                                                {/* <p className="card-text">Click below to select file(s) from this device (upto {convertFileSizeInFormat(maxSize)} at a time)</p> */}
                                                                <p className="card-text">Click below to select file(s) from this device</p>
                                                                <button className="btn btn-outline-primary btn-sm text-nowrap" onClick={triggerFileInput}><i className="fa fa-upload mr-2" aria-hidden="true"></i> Upload Now</button>
                                                                <input type="file" id="sbDocUpload" ref={fileInputRef}
                                                                    accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx"
                                                                    multiple
                                                                    onChange={handleFileChange}
                                                                    className="d-none"
                                                                />
                                                            </div>
                                                            <div className="col-12 bl-justify-items-center pt-4 text-left">
                                                                <div className="form-group mb-0">
                                                                    <ul className="font-small mb-0 pb-0">
                                                                        <li className="text-muted font-italic">Supported file formats - 
                                                                            {
                                                                                fileTypes?.map((item) => item?.displayNameExample)?.join(', ')
                                                                            }
                                                                        </li>
                                                                        <li className="text-muted font-italic">File processing usually takes around 5-8 minutes, depending on the file size or number of pages</li>
                                                                        <li className="text-muted font-italic">You can monitor the upload and processing progress from the table below</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="my-1">All Documents & Files</h6>
                                    <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                                        <div className="d-flex flex-wrap">
                                            <label className={`badge bl-chip-btn cp bl-sb-datatableTabs ${selectedType === "All" ? "active" : ""}`}>
                                                <input
                                                    type="radio"
                                                    name="fileType"
                                                    value="All"
                                                    className="d-none"
                                                    checked={selectedType === "All"}
                                                    onChange={() => handleSelection("All")}
                                                />
                                                All
                                            </label>
                                            {fileTypes?.map((item, i) => (
                                                <label
                                                    key={i}
                                                    className={`badge bl-chip-btn cp bl-sb-datatableTabs ${selectedType === item?.mimeType ? "active" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        name="fileType"
                                                        value={item.mimeType}
                                                        className="d-none"
                                                        checked={selectedType === item?.mimeType}
                                                        onChange={() => handleSelection(item?.mimeType)}
                                                    />
                                                    <i className={`fa ${fileIcons[item?.displayNameExample] || fileIcons["default"]}`} aria-hidden="true"></i> {item?.displayNameExample}
                                                </label>
                                            ))}
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="dropdown mr-2">
                                                <button className={`btn btn-outline-secondary btn-sm dropdown-toggle ${appliedFilters.startDate || appliedFilters.endDate ? "active" : ""}`}
                                                    type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-filter" aria-hidden="true"></i></button>
                                                <div className="dropdown-menu dropdown-menu-right bl-db-sliderContainer" aria-labelledby="dropdownMenu2" data-bs-auto-close="outside" onClick={(e) => e.stopPropagation()}>
                                                    <div className="px-2">
                                                        <h6 className="fw-bolder">Filter</h6>
                                                        <h6 className="form-label text-black mb-2">Upload within</h6>
                                                        <div className="d-flex flex-column">
                                                            <input type={showStartPlaceholder ? "text" : "date"}
                                                                className="form-control date-picker mb-2" placeholder="From date"
                                                                value={startDate}
                                                                onFocus={() => setShowStartPlaceholder(false)}
                                                                onBlur={(e) => {
                                                                    if (!e.target.value) setShowStartPlaceholder(true);
                                                                }}
                                                                onChange={(e) => handleStartDateChange(e)}
                                                                onClick={(e) => e.target.showPicker()}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                            <input type={showEndPlaceholder ? "text" : "date"}
                                                                className="form-control date-picker" placeholder="To date"
                                                                onFocus={() => setShowEndPlaceholder(false)}
                                                                value={endDate}
                                                                onBlur={(e) => {
                                                                    if (!e.target.value) setShowEndPlaceholder(true);
                                                                }}
                                                                onChange={(e) => handleEndDateChange(e)}
                                                                onClick={(e) => e.target.showPicker()}
                                                                max={new Date().toISOString().split("T")[0]}
                                                            />
                                                            <div className="pt-2 d-flex justify-content-end">
                                                                <button className="btn btn-outline-primary btn btn-primary secondary-link btn-sm mr-2" type="button" data-dismiss="modal"
                                                                    onClick={(e) => handleClearDate(e)}
                                                                >Clear all</button>
                                                                <button className="btn btn-primary btn-sm" onClick={(e) => applyDateFilter(e)}>Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group flex-nowrap">
                                                <input type="text" className="form-control form-control-sm bl-search-box-lg" placeholder="Search by File Names or Tags" value={search} onChange={(e) => { handleSearch(e.target.value) }} />
                                                <div className="input-group-append"><span className="input-group-text"><i className="fa fa-search font-smaller"></i></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    {
                                        isLoading ? <div className="col text-center">
                                            <img className="bl-list-loader" src={listViewLoader} />
                                        </div> : (
                                            <DataTable value={uploadedFiles} rowClassName={"bl-source-overview-list position-relative"} className="bl-data-table cp table table-sm">
                                                <Column field="fileName" header="Name" body={(e) => arrangeColumnValue(e, 'NAME', 1)} sortable></Column>
                                                <Column field="displayName" header="Model" body={(e) => arrangeColumnValue(e, 'MODEL', 2)} sortable></Column>
                                                <Column field="fileSize" header="File Size" className="text-center" body={(e) => arrangeColumnValue(e, 'SIZE', 3)} sortable></Column>
                                                <Column field="totalPages" header="Total Pages" className="text-center" body={(e) => arrangeColumnValue(e, 'TOTAL_PAGE', 4)} sortable></Column>
                                                <Column field="pagesProcessed" header="Pages Processed" className="text-center" body={(e) => arrangeColumnValue(e, 'PROCESSED', 5)} sortable></Column>
                                                <Column field="createdOn" header="Uploaded On" body={(e) => arrangeColumnValue(e, 'UPLOADED_ON', 6)} sortable></Column>
                                                <Column field="createdOn" header="Uploaded By" body={(e) => arrangeColumnValue(e, 'UPLOADED_BY', 7)} sortable></Column>
                                                <Column field="status" header="Status" className="text-center" body={(e) => arrangeColumnValue(e, 'STATUS', 8)} sortable></Column>
                                                <Column field="" header="" body={(e) => arrangeColumnValue(e, 'ACTION', 9)}></Column>
                                            </DataTable>
                                        )
                                    }

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {deleteFileModalContent()}
            {editFileModalContent()}
        </div>
    )
}