import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import { EmptyCardsContent } from "../Common/emptyCard";

export const DataSpheresHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/data-spheres', menuName: 'Connectors', isActive: true, hasSubMenu: true },
            { slug: `/data-spheres/all`, menuName: "All", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        RECOMMEND: ""
    });

    useEffect(() => {
        if (homeLabel) {
            const items = homeLabel.find(l => l.FEATURE === "CONNECTOR").items;
            setLabels(items)
        }
    }, [homeLabel])

    const getEmptyScreen = () => {
        return (
            <div className="row">
                <div className="col text-center mt-5">
                    <EmptyCardsContent title='No record found' desc="" buttonView={false} />
                </div>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">
                <div className="row justify-content-md-center">
                    <div className="col-8">
                        <section className="mb-3 d-none">
                            <div className="media px-3 py-2 bl-hero-background rounded">
                                <div className="media-body mr-5 ">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0 text-muted">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center bl-hero-icon-opacity30" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3 d-none">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="mb-0 py-2">{labels.RECOMMEND}</h6>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            {getEmptyScreen()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="text-center">
                            <img className="align-self-center bl-hero-icon-opacity10 mb-4" src={labels.WELCOME_ICON} alt="overview-Img" height={'300px'}/>
                            <h4 className="mt-0 mb-2">{labels.EMPTYTITLE}</h4>
                            <p className="mb-0 text-muted">{labels.EMPTYDESC}</p>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}