import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import homeLabel from "../../utils/property-file/componentOverview.json";
import { EmptyCardsContent } from "../Common/emptyCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { generateReadableId, validateAndFormatDate } from "../../utils/validation";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import { GET_COMPANIES_LIST } from "../../services/graphql/queries";
import { useQuery } from "@apollo/client";
import { toastError } from "../../services/notifyService";

export const PortcoMonitoringHomeComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation();
    const nav = useNavigate()
    const [companies, setCompanies] = useState([]) 
    const { loading, error, data } = useQuery(GET_COMPANIES_LIST,{
        fetchPolicy: "network-only", 
    });

    if(error){
        toastError(error?.data?.message || error?.response?.message || error?.message || "An error occurred. Please try again!")
    }

    useEffect(() => {
        setCompanies([]);  
        if (data && data.companies) {
            setCompanies(data.companies);
        }
    }, [data, setCompanies]);

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring', menuName: 'Portco Monitoring', isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/all-companies`, menuName: "All Companies", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname])

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        RECOMMEND: ""
    });

    useEffect(() => {
        if (homeLabel) {
            const items = homeLabel.find(l => l.FEATURE === "PORTCO").items;
            setLabels(items)
        }
    }, [homeLabel])

    useEffect(() => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (loading && pageLoader) {
            pageLoader.style.display = 'flex';
        } else if (pageLoader) {
            pageLoader.style.display = 'none';
        }
    }, [loading])

    const defaultLogoUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJntksKr9cKXgW2iDMRwfftB72U_XGwvbSA&s";

    const tableValues = (info, field) => {
        const company = info;
        if (company) {
            if (field === 'NAME') {
                const companyLogo = company?.logo ? company?.logo : defaultLogoUrl;
                return (
                    <div className="d-flex align-items-center">
                        <img src={companyLogo} width="35px" className="mr-2 rounded-circle thumbnail img-responsive" onError={(e) => { e.target.onerror = null; e.target.src = defaultLogoUrl; }} />
                        <p className="mb-0 text-nowrap">
                            <span className=""><b>{company?.companyName}</b></span> <a href={company?.website} target="_blank"><i className="fa fa-external-link font-small text-muted ml-2" aria-hidden="true"></i></a>
                        </p>
                    </div>
                );
            }
            else if (field === 'SECTOR'){
                return (company?.primarySector || 'N/A')
            }
            else if (field === 'DESC'){
                return (
                    <div className="bl-truncate-line-2">
                        {company?.about || 'N/A'}
                    </div>
                )
            }
            else if(field === 'SUBSECTOR') {
                return (company?.Sectors?.[0]?.sectorName || 'N/A' )
            }
            else if(field === 'INDUSTRY') {
                return (company.Industries?.[0]?.industryName || 'N/A');
            }
        }
    };

    const getCompanyInfo = (e) => { 
        const id = generateReadableId(e?.data?.companyId, "pmo");
        nav(`/portco-monitoring/companies/${id}/overview`)
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <section className="mb-3">
                            <div className="media px-3 py-2 bl-hero-background rounded">
                                <div className="media-body mr-5 ">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0 text-muted">{labels.WELCOME_DESC}</p>
                                    <button type="button" className="btn btn-primary btn-sm my-3 disabled"><i className="fa fa-fw fa-plus-circle" aria-hidden="true"></i>Add New Company</button>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>  
                        </section>

                        <section className="mb-3">
                            <div className="card">
                                
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12 px-0">
                                            <div className="border-bottom pl-2">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            {
                                                loading ? (
                                                    <div className="col text-center">
                                                        <img className="bl-list-loader" src={listViewLoader} />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <DataTable value={companies} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm mb-1" selectionMode="single" onRowClick={getCompanyInfo}>
                                                            <Column field="companyName" header="Company Name" body={(e) => tableValues(e, 'NAME')} sortable></Column>
                                                            <Column 
                                                            body= {(e) => tableValues(e, 'SECTOR')}
                                                            header="Sector" className="text-center" sortable></Column>
                                                            <Column
                                                            body= {(e)=> tableValues(e, 'SUBSECTOR')}
                                                            header="Sub Sector" className="text-center" sortable></Column>
                                                            <Column header="Industry" 
                                                            className="text-center"
                                                            body={(e)=> tableValues(e, 'INDUSTRY')}
                                                            sortable>
                                                            </Column>
                                                            <Column field="about" header="Description" sortable 
                                                            body={(e) => tableValues(e, 'DESC')} className="w-40"></Column>
                                                        </DataTable>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}