import apiInstance from "./serviceMaster";


export const addNewCompanyService = (obj) => {
    try {
        return apiInstance.post(`linkedin/get/company/data`, obj)
    }
    catch (e) {
        return e;
    }
}

export const getCompanyListService = (page, limit) => {
    try {
        return apiInstance.get(`company/${page}/${limit}`)
    }
    catch (e) {
        return e;
    }
}

export const getActiveCompanyCountService = () => {
    try {
        return apiInstance.get(`company/active/count`)
    }
    catch (e) {
        return e;
    }
}

export const getCompanyInformationService = (slug) => {
    try {
        return apiInstance.get(`company/${slug}`)
    }
    catch (e) {
        return e;
    }
}

export const getCompanyWidgetInformationService = (slug) => {
    try {
        return apiInstance.get(`company/ai/${slug}`)
    }
    catch (e) {
        return e;
    }
}

export const updateDataSourceService = (obj) => {
    try {
        return apiInstance.post('company/dataSource/added/info/update', obj)
    }
    catch (e) {
        return e;
    }
}

export const updateDocumentUploadService = (obj) => {
    try {
        return apiInstance.post('company/documents/added/info/update', obj)
    }
    catch (e) {
        return e;
    }
}

export const getCompanyNewsService = (company) => {
    try {
        return apiInstance.get(`company/news?q=${company}&sortBy=relevance&pageSize=20`)
    }
    catch (e) {
        return e;
    }
}

export const getCompanyNewsByCategoryService = (companyId) => {
    try {
        return apiInstance.get(`company/category/news/${companyId}`)
    }
    catch (e) {
        return e;
    }
}

export const companyFileUploadService = (file) => {
    try {
        return apiInstance.post('company/upload', file)
    }
    catch (e) {
        return e;
    }
}

export const getFinanceInfoService = (companyId) => {
    try {
        return apiInstance.get(`company/finance/info/${companyId}`)
    }
    catch (e) {
        return e;
    }
}

export const getDealStagesService = () => {
    try {
        return apiInstance.get(`company/deal/stage`)
    }
    catch (e) {
        return e;
    }
}

export const getCompanyByDealStageService = (stageId, page, limit) => {
    try {
        return apiInstance.get(`company/deal/stage/list/${stageId}/${page}/${limit}`)
    }
    catch (e) {
        return e;
    }
}

export const getAllDatasourceService = (companyId) => {
    try {
        return apiInstance.get(`company/data/sourcing/list/${companyId}`)
    }
    catch (e) {
        return e;
    }
}

export const updateCompanyDatasourceService = (obj) => {
    try {
        return apiInstance.post(`company/update/sourcing`, obj)
    }
    catch (e) {
        return e;
    }
}

export const removeCompanyService = (companyId) => {
    try {
        return apiInstance.delete(`company/${companyId}`)
    }
    catch (e) {
        return e;
    }
}

// PORTCO COMPANIES API

const companiesUrl = "companies";

export const getPortcoCompaniesService = async (obj) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${companiesUrl}${queryParams}`)
    }
    catch (e) {
        return e;
    }
}

export const getPortcoCompanyInfoService = async (id) => {
    try {
        return await apiInstance.get(`${companiesUrl}/${id}`)
    }
    catch (e) {
        return e;
    }
}

export const getPortcoCompanyNeo4jInfoService = async (id, obj) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${companiesUrl}/${id}/neo4j${queryParams}`)
    }
    catch (e) {
        return e;
    }
}

export const getPortcoCompaniesFilesService = async (id, obj) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${companiesUrl}/${id}/files${queryParams}`)
    }
    catch (e) {
        return e;
    }
}

export const uploadPortcoCompaniesFilesService = async (id, obj) => {
    try {
        return await apiInstance.post(`${companiesUrl}/${id}/files`, obj)
    }
    catch (e) {
        return e;
    }
}

export const getUploadedPortcoCompaniesFilesProgressStatusService = async (id, fileId) => {
    try {
        return await apiInstance.get(`${companiesUrl}/${id}/files/${fileId}/progress-status`)
    }
    catch (e) {
        return e;
    }
}

export const deletePortcoCompaniesFilesService = async (id, fileId) => {
    try {
        return await apiInstance.delete(`${companiesUrl}/${id}/files/${fileId}`)
    }
    catch (e) {
        return e;
    }
}

export const updatePortcoCompaniesFilesService = async (id, fileId, obj) => {
    try {
        return await apiInstance.put(`${companiesUrl}/${id}/files/${fileId}`, obj)
    }
    catch (error){
        return error;
    }
}