import React, { useContext, useEffect, useState } from "react";
import { DealbookCompanyTitle } from "./Common/companyTitle";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SummarizerContext } from "../../../services/context/summarizerContext";
import { getProjectSummaryInfoService, updateProjectStatusService, updateProjectSummaryService } from "../../../services/summarizerService";
import { convertCamelCaseToSeparateWords } from "../../../services/commonService";
import { toastInfo, toastSuccess, toastWarning } from "../../../services/notifyService";
import { AnalyzeCompanyContext } from "../../../services/context/analyzeCompanyContext";
import MyEditorWithToolbar from "../../../utils/editor/markdownEditor";
import { getValueIgnoreCase, validateNumberOnTextInput } from "../../../utils/validation";


export const DealbookCompanyManageSections = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { projectSections, projectInfo, setProjectInfo, scorecard, setScorecard } = useContext(SummarizerContext)
    const { analyzedCompanyInfo, setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const location = useLocation();
    const nav = useNavigate();

    const [projectId, setProjectId] = useState(null)

    useEffect(() => {
        setIsHeaderVisible(false)
        setSubSections([])
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex'
        }
        const secInfo = getActiveSectionInfo();
        setBreadcrumbMenu([
            { slug: '/dealbook', menuName: 'DealBook', isActive: true, hasSubMenu: true },
            { slug: `/dealbook/project/${projectId}/${secInfo.slug}`, menuName: secInfo.categoryName, isActive: false, hasSubMenu: true },
            { slug: `/dealbook/project/${projectId}/${secInfo.slug}/manage`, menuName: "Manage", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname, projectId, projectSections])

    const [summaryId, setSummaryId] = useState('')
    const [isHeaderVisible, setIsHeaderVisible] = useState(false)
    const [subSections, setSubSections] = useState([])
    const [aboutCompany, setAboutCompany] = useState({
        description: "", location: "N/A", employee: "N/A", founded: "N/A", summaryId: null
    })
    const [keyHighlights, setKeyHighlights] = useState([])
    const [secReference, setSecReference] = useState([])
    const [headerSummaryId, setHeaderSummaryId] = useState(null)
    
    const [categoryId, setCategoryId] = useState(null)
    const [headerRef, setHeaderRef] = useState(null)
    const [selectedHeader, setSelectedHeader] = useState("DETAILS")
    const [selectedSectionSummary, setSelectedSectionSummary] = useState(null)
    const [maxScore, setMaxScore] = useState(25)

    const getActiveSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        const secSlug = pathArray[4];
        const secInfo = projectSections && projectSections.find(sec => sec.slug === secSlug);
        setSummaryId(secInfo && secInfo.summaryId ? secInfo.summaryId : '')
        return secInfo ? secInfo : { categoryName: '', slug: '' };
    }

    useEffect(() => {
        if (projectInfo && projectInfo.projectId) {
            setMaxScore(projectInfo.maxScore ? projectInfo.maxScore : 25)
            setProjectId(projectInfo.projectId)
        }
    }, [projectInfo])

    useEffect(() => {
        if (summaryId) {
            getProjectSummaryInfo()
        }
    }, [summaryId, analyzedCompanyInfo])

    const getProjectSummaryInfo = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        try {
            const resp = await getProjectSummaryInfoService(projectId, summaryId);
            if (resp && resp.success) {
                setProjectId(resp.data[0].projectId)
                setCategoryId(resp.data[0].categoryId)
                const headerInfoSec = resp.data[0].subCategories.find(sub => sub.subcategoryName && sub.subcategoryName.toLowerCase().includes('header details'));
                setIsHeaderVisible(!!headerInfoSec)
                const headerObject = headerInfoSec ? headerInfoSec.manualOverrideText ? headerInfoSec.manualOverrideText : headerInfoSec.kairosOutputText ? headerInfoSec.kairosOutputText : null : null;
                const isJsonContent = headerObject && !headerObject.trim().startsWith('<');
                if (headerObject && isJsonContent) {
                    setSelectedHeader("DETAILS")
                    setHeaderRef(headerInfoSec)
                    setSecReference(headerInfoSec.summaryReferences ? headerInfoSec.summaryReferences : [])
                    setHeaderSummaryId(headerInfoSec.summaryId)
                    setSelectedSectionSummary(headerInfoSec.summaryId)
                    const predefinedKeys = ['aboutthecompany', 'about the company']
                    const headerInfo = JSON.parse(headerObject);
                    if (headerInfo) {
                        const matched = {};
                        const entries = [];
                        Object.keys(headerInfo).forEach(key => {
                            if (key && predefinedKeys.includes(key.toLowerCase())) {
                                matched[key] = headerInfo[key];
                            } else {
                                entries.push({ [key]: headerInfo[key] });
                            }
                        });
                        setAboutCompany(matched)
                        setKeyHighlights(entries)
                    }
                    // if (headerInfo && headerInfo.AboutTheCompany) {
                    //     const aboutComp = headerInfo.AboutTheCompany || headerInfo['About the company'];
                    //     setAboutCompany({
                    //         description: aboutComp.Description ? aboutComp.Description : "",
                    //         location: aboutComp.Location ? aboutComp.Location : "N/A",
                    //         employee: aboutComp.Employees ? aboutComp.Employees : "N/A",
                    //         founded: aboutComp.Founded ? aboutComp.Founded : "N/A"
                    //     })
                    // }
                }
                const subSectionList = resp.data[0].subCategories.filter(sub => sub.subcategoryName && sub.subcategoryName.toLowerCase() !== "header details");
                subSectionList.sort((a, b) => a.positionInParent - b.positionInParent);
                setSubSections(subSectionList)
                if (!(headerObject && isJsonContent)) {
                    setSecReference(subSectionList[0].summaryReferences)
                    setSelectedSectionSummary(subSectionList[0].summaryId)
                }
            }

            setTimeout(() => {
                if (pageLoader) {
                    pageLoader.style.display = 'none'
                }
            }, 500)
        }
        catch (e) {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }

    }

    const handleContentChange = (sectionInfo) => {
        subSections.map(sec => {
            if (sec.summaryId === sectionInfo.summaryId) {
                sec.manualOverrideText = sectionInfo.manualOverrideText ? sectionInfo.manualOverrideText : sectionInfo.isModified ? 'N/A' : "";
                sec.isUpdated = true;
            }
        })
    };

    const sectionReferenceInfo = (referenceInfo) => {
        if (referenceInfo.summaryId !== selectedSectionSummary) {
            setSecReference([])
            setTimeout(() => {
                setSelectedSectionSummary(referenceInfo.summaryId)
                setSecReference(referenceInfo.summaryReferences ? referenceInfo.summaryReferences : [])
            }, 300)
        }

    }

    const updateProjectInfo = async (status) => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (pageLoader) {
            pageLoader.style.display = 'flex';
        }
        try {
            let mergedObject = { ...aboutCompany };
            keyHighlights.forEach(item => {
                mergedObject = { ...mergedObject, ...item };
            });
            const headerObj = {
                summaryId: headerSummaryId, manualOverrideText: JSON.stringify(mergedObject)
            };
            const updatedSec = subSections.map(sec => {
                return {
                    summaryId: sec.summaryId, manualOverrideText: sec.manualOverrideText
                }
            });
            let scores = null;
            if (scorecard && scorecard.length) {
                const updatedScores = scorecard.map(s => {
                    return {
                        name: s.name, score: s.score, criteria_desc: s.criteria_desc
                    }
                })
                const scoreSets = {
                    data: updatedScores
                }
                scores = {
                    summaryId: scorecard[0].summaryId, manualOverrideText: JSON.stringify(scoreSets)
                }
            }

            let updates = [];
            if (isHeaderVisible && scores) {
                const totalScore = getTotalScore();
                if (totalScore > maxScore) {
                    if (pageLoader) {
                        pageLoader.style.display = 'none';
                    }
                    return toastInfo(`Total score should not exceed by ${maxScore}`)
                }
                updates = [headerObj, scores, ...updatedSec]
            }
            else if (isHeaderVisible) {
                updates = [headerObj, ...updatedSec]
            }
            else {
                updates = [...updatedSec]
            }
            const obj = {
                data: updates
            }
            const resp = await updateProjectSummaryService(obj, projectId, categoryId);
            if (resp) {
                const statusObj = {
                    status: status
                }
                const statusResp = await updateProjectStatusService(projectId, statusObj);
                if (statusResp) {
                    setAnalyzedCompanyInfo({ projectId: projectId, message: "Updated Project Summary!" })
                    toastSuccess('Project summary updated successfully');
                    projectInfo.publishedStatus = status;
                    setProjectInfo(projectInfo)
                    if (status === "Published" && projectSections && projectSections[0].slug) {
                        nav(`/dealbook/project/${projectId}/${projectSections[0].slug}`)
                    }
                    // nav('/dealbook/home')
                }
            }
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const handleInputChange = (rowIndex, key, newValue) => {
        const updatedHighlights = [...keyHighlights];
        updatedHighlights[rowIndex][key] = newValue;
        setKeyHighlights(updatedHighlights);
    };

    const getAboutCompany = () => {
        const aboutComp = getValueIgnoreCase(aboutCompany, 'about the company');
        return aboutCompany.aboutTheCompany ? aboutCompany.aboutTheCompany : aboutComp && aboutComp.value ? aboutComp.value : '';
    }

    const updateAboutTheCompany = (aboutTheCompany) => {
        let companyBasic = { ...aboutCompany };
        const aboutComp = getValueIgnoreCase(aboutCompany, 'about the company');
        if(companyBasic.aboutTheCompany){
            companyBasic['aboutTheCompany'] = aboutTheCompany;
        }
        else if(aboutComp.originalKey){
            companyBasic[aboutComp.originalKey] = aboutTheCompany
        }
        setAboutCompany(companyBasic)
    }

    const updateProjectScorecard = (index, value, type) => {
        let scores = [...scorecard];
        if (type === 'score') {
            scores[index].score = value ? value : "";
            const totalScore = getTotalScore();
            if (totalScore > maxScore) {
                toastInfo(`Total score should not exceed by ${maxScore}`)
            }
        }
        else {
            scores[index].criteria_desc = value;
        }
        setScorecard(scores)
    }

    const getTotalScore = () => {
        let score = 0;
        scorecard && scorecard.forEach(s => {
            score += s.score ? parseInt(s.score) : 0;
        })
        return score;
    }

    const getHeaderSectionContent = () => {
        return (
            <div>
                {/* Scorecard Section */}
                {
                    scorecard && scorecard.length ? (
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="mb-0 align-self-center">Investment Attributes</h6>
                                        </div>
                                        <div className="card-body p-0">
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="pl-3" width="25%">Key Metric</th>
                                                        <th scope="col" width="25%">Value</th>
                                                        <th scope="col">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        scorecard && scorecard.map((score, index) => {
                                                            return (
                                                                <tr key={`db-scorecard-manage-${index}`}>
                                                                    <td className="pl-3 text-muted">{score.name}</td>
                                                                    <td className="p-0"><input type="text" className="form-control form-control-sm text-dark border-0 rounded-0" value={score.score} onKeyDown={(e) => validateNumberOnTextInput(e)} onChange={(e) => updateProjectScorecard(index, e.target.value, 'score')}></input></td>
                                                                    <td className="p-0"><input type="text" className="form-control form-control-sm text-dark  border-0 rounded-0" value={score.criteria_desc} onChange={(e) => updateProjectScorecard(index, e.target.value, 'desc')}></input></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td className="pl-3 text-bold">Total</td>
                                                        <td className="pl-2" colSpan="2"><b>{getTotalScore()}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : null
                }

                {/* Header Details Section */}
                <section className="mb-3">
                    <div className={`card mb-3 ${selectedSectionSummary === headerSummaryId && selectedHeader === 'DETAILS' ? 'shadow-sm border border-secondary' : ''}`} onClick={() => { sectionReferenceInfo(headerRef); setSelectedHeader('DETAILS') }}>
                        <div className="card-header">
                            <h6 className="mb-0 align-self-center">Key Highlights
                                {
                                    headerRef && headerRef.summaryReferences && headerRef.summaryReferences.length ? (
                                        <span className="text-primary ml-2">
                                            {
                                                headerRef.summaryReferences.map((reference, index) => {
                                                    const images = headerRef.summaryReferences.map(sec => {
                                                        return {
                                                            page: sec.pageNumber,
                                                            image: sec.imageUrl
                                                        }
                                                    });
                                                    return (
                                                        <a title="Reference page number from your file" key={`page-reference-header-${index}`} className="open-reference-modal cp px-1" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={reference.pageNumber}><span className="badge  px-1 py-1 badge-pill font-weight-light bl-db-pageBadge">P{reference.pageNumber}</span></a>
                                                    )
                                                })
                                            }
                                        </span>
                                    ) : null
                                }
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {keyHighlights && keyHighlights.map((row, rowIndex) => (
                                    <div key={rowIndex} className="col-6 d-flex justify-content-between">
                                        {Object.entries(row).map(([key, value]) => {
                                            const val = value && typeof value === 'string' && value.toLowerCase() === 'n/a' ? '' : value;
                                            return (
                                                <React.Fragment key={key}>
                                                    <p className="text-muted">{key}</p>
                                                    <p className="w-50"><input type="text" className="form-control form-control-sm text-dark" id="exampleInputEmail1" onChange={(e) => handleInputChange(rowIndex, key, e.target.value)} defaultValue={val} aria-describedby="emailHelp" /></p>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                {/* About the Company Section */}
                <section className="mb-3">
                    <div className={`card mb-3 ${selectedSectionSummary === headerSummaryId && selectedHeader === 'ABOUT' ? 'shadow-sm border border-secondary' : ''}`} onClick={() => { sectionReferenceInfo(headerRef); setSelectedHeader('ABOUT') }}>
                        <div className="card-header">
                            <h6 className="mb-0 align-self-center">About the Company
                                {
                                    headerRef && headerRef.summaryReferences && headerRef.summaryReferences.length ? (
                                        <span className="text-primary ml-2">
                                            {
                                                headerRef.summaryReferences.map((reference, index) => {
                                                    const images = headerRef.summaryReferences.map(sec => {
                                                        return {
                                                            page: sec.pageNumber,
                                                            image: sec.imageUrl
                                                        }
                                                    });
                                                    return (
                                                        <a title="Reference page number from your file" key={`page-reference-header-${index}`} className="open-reference-modal cp px-1" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={reference.pageNumber}><span className="badge  px-1 py-1 badge-pill font-weight-light bl-db-pageBadge">P{reference.pageNumber}</span></a>
                                                    )
                                                })
                                            }
                                        </span>
                                    ) : null
                                }
                            </h6>
                        </div>
                        <div className="card-body p-3">
                            <textarea className="form-control form-control-sm" rows={5} defaultValue={getAboutCompany()} onChange={(e) => updateAboutTheCompany(e.target.value)}></textarea>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const profileSection = () => {
        return (
            <section className="mb-3">
                {
                    subSections && subSections.map((sec, index) => {
                        if (sec.subcategoryName && sec.subcategoryName.toLowerCase().includes('chart')) {
                            return null
                        }
                        return (
                            <div key={`sub-sections-${index}`} className={`card mb-3 ${selectedSectionSummary === sec.summaryId ? 'shadow-sm border border-secondary' : ''}`} onClick={() => sectionReferenceInfo(sec)}>
                                <div className="card-header">
                                    <h6 className="mb-0 align-self-center">{sec.subcategoryName}
                                        {
                                            sec.summaryReferences && sec.summaryReferences.length ? (
                                                <span className="text-primary ml-2">
                                                    {
                                                        sec.summaryReferences.map((reference, index) => {
                                                            const images = sec.summaryReferences.map(sec => {
                                                                return {
                                                                    page: sec.pageNumber,
                                                                    image: sec.imageUrl
                                                                }
                                                            });
                                                            return (
                                                                <a title="Reference page number from your file" key={`page-reference-header-${index}`} className="open-reference-modal cp px-1" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={reference.pageNumber}><span className="badge  px-1 py-1 badge-pill font-weight-light bl-db-pageBadge">P{reference.pageNumber}{sec.summaryReferences.length - 1 !== index ? '' : ''}</span></a>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            ) : null
                                        }
                                    </h6>
                                </div>
                                <div className="card-body p-0">
                                    {/* <MyEditorWithToolbar markdown={sec.manualOverrideText ? sec.manualOverrideText : sec.kairosOutputText} onContentChange={handleContentChange} summaryId={sec.summaryId} section={sec.subcategoryName} /> */}
                                    <MyEditorWithToolbar markdown={sec.manualOverrideText ? sec.manualOverrideText === 'N/A'? '' : sec.manualOverrideText : sec.kairosOutputText} onContentChange={handleContentChange} summaryId={sec.summaryId} />
                                </div>
                            </div>
                        )
                    })
                }

            </section>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row bl-db-summaryContainerMargin">
                    <div className="col-12">
                        <div className="align-self-center bl-btn-grp-fixed bl-db-actionBtnMarginRight">
                            <button className="btn btn-primary btn-sm mr-2 mb-0" onClick={() => updateProjectInfo('Published')}>
                                <i className={`fa fa-fw fa-upload`} aria-hidden="true"></i> Save & Publish
                            </button>
                            {
                                projectInfo && projectInfo.publishedStatus && projectInfo.publishedStatus.toLowerCase() === 'draft' ? (
                                    <button className="btn btn-primary btn-sm mr-2 mb-0" onClick={() => updateProjectInfo('Draft')}>
                                        <i className={`fa fa-fw fa-bookmark-o`} aria-hidden="true"></i> Save as Draft
                                    </button>
                                ) : null
                            }

                            {/* <button className="btn btn-outline-primary btn-sm mr-2 mb-0">
                                <i className={`fa fa-fw fa-plus-square-o`} aria-hidden="true"></i> Add New Section
                            </button> */}
                            <button className="btn btn-outline-primary btn-sm mb-0" data-toggle="modal" data-target="#deletecompanyprojectmodal">
                                <i className={`fa fa-fw fa-trash`} aria-hidden="true"></i>
                            </button>

                        </div>

                        <section className="mb-3">
                            <div id="comptitle">
                                <DealbookCompanyTitle />
                            </div>
                        </section>
                        {/* Scorecard Section */}
                        {
                            isHeaderVisible ? getHeaderSectionContent() : null
                        }

                        {profileSection()}
                    </div>
                    <div className="bl-fixed-right-panel p-4 rounded bg-light mt-n3">
                        <h6 className="mb-4">Linked Page Preview</h6>
                        {

                            secReference && secReference.length ? secReference.map((preview, index) => {
                                const images = secReference.map(sec => {
                                    return {
                                        page: sec.pageNumber,
                                        image: sec.imageUrl
                                    }
                                });
                                return (
                                    <div key={`reference-preview-${index}`} className="text-center">
                                        <img src={preview.imageUrl} className="img-fluid p-1 mb-2 shadow-sm border border-secondary open-reference-modal cp" alt="reference preview" data-toggle="modal" data-target="#pageReferanceImageModal" data-images={JSON.stringify(images)} data-info={preview.pageNumber} />
                                        <a><span className="badge px-1 py-1 badge-pill font-weight-light bl-db-pageBadge mb-3">P{preview.pageNumber}</span></a>
                                    </div>
                                )
                            }) : <h6 className="text-muted mt-3"><i>No linked pages to show</i></h6>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}