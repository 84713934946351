import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { PortcoCompanyTitle } from "./Common/companyTitle";
import { EmptyCardsContent } from "../../Common/emptyCard";
import { PortcoContext } from "../../../services/context/portcoContext";


export const PortcoCompanyESGComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/esg`, menuName: "ESG", isActive: true, hasSubMenu: true }
        ])
    }, [location.pathname, portcoCompanyInfo])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <PortcoCompanyTitle />
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between align-self-center w-100">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">ESG Ratings compared with all peer companies</h6>
                                        </div>
                                    </div>
                                    <div className="card-body d-grid">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between align-self-center w-100">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Historical ESG Performance</h6>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}