import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { EmptyCardsContent } from "../../Common/emptyCard";
import { PortcoContext } from "../../../services/context/portcoContext";
import { GET_PARTICULAR_COMPANY_NEWS } from "../../../services/graphql/queries";
import imagePlaceholder from "../../../assets/img/image-placeholder.jpeg";

import { useQuery } from "@apollo/client";

export const PortcoCompanyAllNewsComponent = ({
    callsByOtherPlace = false,
}) => {
    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext);
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext);
    const { portcoCompanyInfo } = useContext(PortcoContext);
    const location = useLocation();

    useEffect(() => {
        if (!callsByOtherPlace) {
            setBreadcrumbMenu([
                {
                    slug: "/portco-monitoring/all-companies",
                    menuName: "Portco Monitoring",
                    isActive: false,
                    hasSubMenu: true,
                },
                {
                    slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`,
                    menuName: portcoCompanyInfo?.companyName,
                    isActive: true,
                    hasSubMenu: true,
                },
                {
                    slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/news`,
                    menuName: "News",
                    isActive: true,
                    hasSubMenu: true,
                },
            ]);
        }
    }, [location.pathname, portcoCompanyInfo]);

    const { loading, error, data } = useQuery(GET_PARTICULAR_COMPANY_NEWS, {
        variables: { id: Number(portcoCompanyInfo?.companyId) },
        skip: !portcoCompanyInfo?.companyId,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        const pageLoader = document.getElementById("pageLoaderContainer");
        if (loading && pageLoader) {
            pageLoader.style.display = "flex";
        } else if (pageLoader) {
            pageLoader.style.display = "none";
        }
    }, [loading]);

    useEffect(() => {
        const categorizedNews = getCategoryAndNews(data);
        setNewsArticles(categorizedNews);
        if (data) {
            setNewsArticles(getCategoryAndNews(data));
        }
    }, [data]);

    const [newsArticles, setNewsArticles] = useState([]);
    const sources = [...new Set(newsArticles?.map((article) => article?.node?.source).filter(Boolean)),].join(", ") || "";

    const getCategoryAndNews = (data) => {
        const categoryNewsMap = {};
        data?.companies[0]?.NewsConnection?.edges?.forEach((item) => {
            const newsItem = item?.node;
            if (newsItem?.category) {
                if (!categoryNewsMap[newsItem.category]) {
                    categoryNewsMap[newsItem.category] = [];
                }
                categoryNewsMap[newsItem.category].push(newsItem);
            }
        });
        return Object.entries(categoryNewsMap).map(([category, news]) => ({ category, news }));
    };

    return (
        <div id="content">
            <div className="container-fluid mt-3">
                <section className="pb-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 mb-4">
                            {newsArticles.length > 0 ? (
                                <div className="card-deck bl-articles-card ">
                                    {newsArticles.map(({ category, news }, index) => (
                                        <div className="card" key={index}>
                                            <div className="d-flex card-header overflow-hidden align-items-center pl-3">
                                                <h6 className="mb-0">{category}</h6>
                                                {sources ? <span className="badge bl-source-badge ml-1 text-wrap text-start"><i className="fa fa-database" aria-hidden="true"></i><p className="p-0 m-0">{sources}</p></span> : ''}
                                            </div>
                                            <div className="card-body p-3 overflow-scroll">
                                                {news.map((article, i) => (
                                                    <div key={i}>
                                                        <div className="media mb-3">
                                                            <div className="media-body mr-2 bl-truncate-line-2">
                                                                <a className="bl-truncate-line-2" href={article?.sourceUrl} target="_blank" rel="noopener">
                                                                    {article?.title}
                                                                </a>
                                                                <p className="mb-0 mt-1 bl-truncate-line-3 font-smaller text-muted">
                                                                    {article?.text || "No description available"}
                                                                </p>
                                                                <p className="mb-0"> <small className="text-muted">{article.date} | {article?.source}</small></p>
                                                            </div>
                                                            <img
                                                                className="rounded" src={article?.image || imagePlaceholder} onError={(e) => { e.target.onerror = null; e.target.src = imagePlaceholder; }} width="80px" height="80px" alt="News" />
                                                        </div>
                                                        {i !== news.length - 1 && <hr />}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="mt-5">
                                    <EmptyCardsContent
                                        title="No News Found"
                                        desc="Unable to build page. Additional data sources required"
                                        buttonView={false}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
