import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import ThemeContext from "../../services/context/Theme/themeContext";
import { getAppBehavior } from "../../services/authService";
import { appendThemeProperty, generateBriefingAppTransscriptService } from "../../services/commonService";
import { getAIModelService } from "../../services/aiService";
import { toastWarning } from "../../services/notifyService";
import modelContext from "./../../utils/property-file/speakerBrief/speakerBrief.json"
import { InnerHTMLComponent } from "../AIAssistant/Templates/InnerHTMLIntegration";


export const BriefingAppComponent = () => {

    const location = useLocation()
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        getAIModels()
        setBreadcrumbMenu(
            [
                { slug: '/briefing-app', menuName: 'Speaker Brief', isActive: true, hasSubMenu: false },
            ]
        )
    }, [location.pathname])

    useEffect(() => {
        const headerElem = document.getElementById('blHeader');
        if (headerElem) {
            headerElem.classList.add('bl-kairos-top-header')
        }
    }, [])

    const [viewType, setViewType] = useState('CONFIG')
    const [defaultModel, setDefaultModel] = useState({
        aiModelId: null, displayName: '', model: ''
    })
    const [eventDetails, setEventDetails] = useState('')
    const [discussionPoints, setDiscussionPoints] = useState('')
    const [additionalContext, setAdditionalContext] = useState('')
    const [poitns, setPoints] = useState([])
    const [speakerResponse, setSpeakerResponse] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEditableMode, setIsEditableMode] = useState(false)

    useEffect(() => {
        setIsEditableMode(false)
        setIsLoading(false)
        const speakermenucontent = document.getElementById("speakermenucontent");
        if (speakermenucontent && theme && theme.dashboardTopBGColor) {
            speakermenucontent.setAttribute('style', `background-color: ${theme.dashboardTopBGColor} !important;`)
        }
        applyTheme()
    }, [theme, location.pathname, viewType])

    const applyTheme = () => {
        const appThemes = getAppBehavior();
        if (theme) {
            appendThemeProperty(theme, true, true)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme, true, true)
            }
        }
    }

    const getAIModels = async () => {
        const resp = await getAIModelService();
        if (resp && resp.success) {
            const defaultModel = resp.data.find(model => model.default);
            if (defaultModel) {
                setDefaultModel(defaultModel)
            }
        }
    }

    const openNewChat = () => {
        setIsLoading(false)
        setPoints([])
        setSpeakerResponse([])
        setEventDetails('')
        setDiscussionPoints('')
    }

    const generateBriefingAppTransscript = async () => {
        if (!eventDetails.trim()) {
            return toastWarning("Please enter event details first!")
        }
        if (!discussionPoints.trim()) {
            return toastWarning("Please enter discussion points first!")
        }
        const obj = {
            eventDetails: eventDetails,
            discussionPoints: discussionPoints,
            additionalContext: additionalContext ? additionalContext : null,
            model: defaultModel.model
        }
        setIsEditableMode(false)
        setIsLoading(true)
        const resp = await generateBriefingAppTransscriptService(obj);
        setIsLoading(false)
        if (resp && resp.success && resp.data) {
            const transcriptInfo = resp.data;
            setPoints(transcriptInfo.speaker_brief ? transcriptInfo.speaker_brief : [])
            setSpeakerResponse(transcriptInfo.transcript ? transcriptInfo.transcript : [])
        }
    }

    const speakerBriefModalContent = () => {
        return (
            <div className="modal fade bl-static-modal" id="speakerbriefmodal" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="speakerbriefmodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xlg" role="document">
                    <div className="modal-content">
                        <div className="pr-2 bl-z-index-1">
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body mt-n4">
                            <InnerHTMLComponent htmlContent={modelContext.profile} contentId="speakerprofile" isChartElement={false} ></InnerHTMLComponent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="modal-wrapper d-flex">
            <div className="modal-sidebar">
                <ul className="navbar-nav bg-white sidebar sidebar-light shadow-lg bl-ai-sidebar pb-3 bl-kairos-menu bl-z-index-1" id="speakermenucontent">
                    <div className="d-flex justify-content-between p-3">
                        <div className="btn-group bl-w-fill-available mr-3" role="group" aria-label="Basic example">
                            <button className={`btn btn-sm border ${viewType === 'BRIEF' ? 'bl-kairos-menu-btn active' : 'bg-transparent'}`} onClick={() => setViewType('BRIEF')}>All Briefings</button>
                            <button className={`btn btn-sm border ${viewType === 'CONFIG' ? 'bl-kairos-menu-btn active' : 'bg-transparent'}`} onClick={() => setViewType('CONFIG')}>Configuration</button>
                        </div>
                        <button className="btn bg-gray btn-sm" onClick={() => openNewChat()}><i className="fa fa-fw fa-plus" aria-hidden="true"></i></button>
                    </div>
                    <div className=" overflow-y-auto pb-3">
                        <div className="sidebar-heading mt-3">
                            SELECTED MODEL
                        </div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" data-toggle="modal" data-target="#speakerbriefmodal">
                                <i className="fa fa-fw fa-bullseye"></i>
                                <span>{'KAIROS Speaker Brief RS 1.0'}</span>
                                {/* <span>{defaultModel.displayName}</span> */}
                            </a>
                        </li>

                        <div className="sidebar-heading mt-3">
                            Additional Context (OPTIONAL)
                        </div>

                        <div className="form-group p-3">
                            <textarea className="form-control text-white border-0 bl-brief-bg" id="exampleFormControlTextarea1" rows="8" placeholder="Please enter additional context if required" onChange={(e) => setAdditionalContext(e.target.value)} value={additionalContext}></textarea>
                            <div className="d-flex justify-content-between align-items-center p-2 text-right bl-brief-bg mt-n1 bl-brief-border">
                                <img src="https://cdn.iconscout.com/icon/free/png-256/free-switch-icon-download-in-svg-png-gif-file-formats--right-nova-pack-outline-miscellaneous-icons-451105.png" height="30px" />
                                <button type="button" className="btn btn-sm text-white border-white">Save</button>
                            </div>

                        </div>
                    </div>
                </ul>
            </div>
            <div className="modal-content-wrapper bl-kairos-main-content">
                <nav id="componentHeader" className="navbar navbar-expand navbar-light bg-white fixed-top mb-0 pt-0 blHeaderMargin bl-component-header blCollapsedHeaderMargin bl-left-23rem">
                    <div className="row p-2 w-100">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="textAreaContainer form-group">
                                <div className="label text-dark"><p>1. EVENT DETAILS</p></div>
                                <textarea className="form-control border-0 pt-5 bl-brief-input" id="exampleFormControlTextarea1" rows="3" placeholder="Enter/Paste event details here" onChange={(e) => setEventDetails(e.target.value)} value={eventDetails}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="textAreaContainer form-group">
                                <div className="label text-dark"><p>2. DISCUSSION POINTS</p></div>
                                <textarea className="form-control border-0 pt-5 bl-brief-input" id="exampleFormControlTextarea1" rows="3" placeholder="Enter/Paste discussion points here" onChange={(e) => setDiscussionPoints(e.target.value)} value={discussionPoints}></textarea>
                            </div>
                        </div>
                        <div className="w-100 text-center pb-1"><a type="button" className={`btn btn-secondary text-white ${isLoading ? 'disabled' : ''}`} onClick={() => generateBriefingAppTransscript()}>Generate Now</a></div>
                    </div>
                </nav>
                <div id="aimodalcontent" className="bl-pt-12rem">

                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        <h1 className="h5 mb-0 text-gray-800">
                            <div className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Talking Points</li>
                            </div>
                        </h1>
                        <div>
                            <div className="d-inline-flex flex-row">
                                {
                                    isEditableMode ? <div className="p-2">
                                        <a className="btn btn-outline-primary btn-sm mr-2 cp" onClick={() => setIsEditableMode(false)}>Cancel</a>
                                        <a className="btn btn-primary btn-sm cp" onClick={() => setIsEditableMode(false)}>Save</a>
                                    </div> : 
                                    <div className="p-2"><a className="d-sm-inline-block btn btn-sm btn-outline-primary cp" onClick={() => setIsEditableMode(true)}><i className="fa fa-edit fa-sm text-white-100"></i></a></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row bl-brief-body-height">
                        {
                            isLoading ? <span className="loader mx-4 mt-3"></span> : null
                        }
                        {
                            poitns && poitns.length ? (
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <p><b>Points</b></p>
                                    <p>
                                        <ul>
                                            {
                                                poitns && poitns.map((point, i) => {
                                                    return (
                                                        isEditableMode ? <textarea className="form-control mb-3" id={`speakerpoints${i}`} rows="3" defaultValue={point}></textarea> :
                                                            <li key={`key-points-${i}`} className="mb-3">{point}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </p>
                                </div>
                            ) : null
                        }
                        {
                            speakerResponse && speakerResponse.length ? (
                                <div className="col-lg-6 col-md-6 col-sm-12 pl-5">
                                    <p><b>Speaker Responses</b></p>
                                    {
                                        speakerResponse.map((trans, i) => {
                                            return (
                                                <div key={`speaker-resp-${i}`} className="card mb-4 border-0">
                                                    <div className="card-body p-3 rounded bl-brief-transcript-bg">
                                                        <div className="d-flex">
                                                            <img src="https://static-00.iconduck.com/assets.00/message-question-icon-2048x1963-1rljos59.png" height="25px" className="mr-3 pt-1" />
                                                            {
                                                                isEditableMode ? <textarea className="form-control mb-3" id={`speakerresponse${i}`} rows="1" defaultValue={trans.question}></textarea> : <p>{trans.question}</p>
                                                            }

                                                        </div>
                                                        <div className="d-flex">
                                                            <img src="https://e7.pngegg.com/pngimages/239/766/png-clipart-online-chat-computer-icons-chat-miscellaneous-speech-balloon-thumbnail.png" height="25px" className="mr-3 pt-1" />
                                                            {
                                                                isEditableMode ? <textarea className="form-control" id={`speakerresponse${i}`} rows="5" defaultValue={trans.answer}></textarea> : <p><i>{trans.answer}</i></p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }

                        {
                            !isLoading && poitns.length === 0 && speakerResponse.length === 0 ? (
                                <div className="col-lg-12 col-md-12 col-sm-12 text-center align-self-center">
                                    <img src="https://cdn-icons-png.freepik.com/512/8003/8003996.png" height="70px" className="mb-3" />
                                    <h5 className="text-muted">Please enter Event Details along with Discussion Points to generate Speaker Brief...</h5>
                                </div>
                            ) : null
                        }


                    </div>
                </div>
            </div>
            {speakerBriefModalContent()}
        </div>
    )
}