import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPinnedReportListService, getReportCategoryListService } from "../../services/reportService";
import pdfMenu from "./../../data/PDF/subMenu.json";
import adminMenu from "./../../data/Admin/subMenu.json";
import { SummarizerContext } from "../../services/context/summarizerContext";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import { generateCIMId } from "../../services/summarizerService";
import { generateReadableId } from "../../utils/validation";


export const SubMenuComponent = () => {

    const location = useLocation();
    const { projectInfo, projectSections, projectMode } = useContext(SummarizerContext)
    const { setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const nav = useNavigate();
    const [activeSubTab, setActiveSubTab] = useState('');
    const [activeSupSubMenu, setActiveSupSubMenu] = useState('');
    const [pinnedReport, setPinnedReport] = useState([]);
    const [analyticsSubMenuList, setAnalyticsSubMenuList] = useState([]);
    const [pdfSubMenuList, setPdfSubMenuList] = useState([]);
    const [mainMenuItem, setMainMenuItem] = useState('')
    const [dealBookProjectSlug, setDealBookProjectSlug] = useState('')

    useEffect(() => {
        getAnalyticsCategorySubMenu();
        getPinnedReportList()
    }, [])

    useEffect(() => {
        if (projectInfo && projectInfo.projectName) {
            setDealBookProjectSlug(generateCIMId(projectInfo.projectId))
        }
    }, [projectInfo, location.pathname])

    useEffect(() => {
        const path = mainPageContent();
        setMainMenuItem(path)
        setActiveSubTab(getSubMenu())
        
    }, [location])

    useEffect(() => {
        setPdfSubMenuList(pdfMenu)
    }, [pdfMenu])

    const getSubMenu = () => {
        
        setActiveSupSubMenu('')
        let pathArray = location.pathname.split('/');
        
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 4) {
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray.length === 5) {
                setActiveSupSubMenu(`${pathArray[3]}-${pathArray[4]}`)
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray[3] === 'category' && pathArray.length === 6) {
                setActiveSupSubMenu(pathArray[5])
                return pathArray[4]
            }
            else if (pathArray[2] === 'reports' && pathArray[3] !== 'category' && pathArray.length === 6) {
                setActiveSupSubMenu(pathArray[4])
                return pathArray[3]
            }
            else if (pathArray[2] === 'project' && pathArray.length >= 4) {
                return pathArray[3]
            }
           
            
            else if (pathArray[1] === 'speaker-brief' && pathArray[2] === 'events') {
                if (pathArray.length === 4) {
                    return pathArray[3]
                }
                else if (pathArray.length > 4) {
                    return pathArray[4]
                }
            }
            return pathArray[2]
        } else if (pathArray && pathArray.length === 2){
            if (pathArray[1] === 'connector' || pathArray[1] === 'connection') {
                return pathArray[1];
            }
        }
    }

    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1) {
            return pathArray[1];
        }

    }

    const getAnalyticsCategorySubMenu = async () => {
        const resp = await getReportCategoryListService();
        if (resp && resp.success) {
            const menuItem = [
                {
                    menuType: "CATEGORIES",
                    list: resp.data
                }
            ]
            setAnalyticsSubMenuList(menuItem)
        }
    }

    const getPinnedReportList = async () => {
        const obj = {
            page: 1, pageSize: 4
        }
        const resp = await getPinnedReportListService(obj);
        if (resp && resp.success) {
            setPinnedReport(resp.data)
        }
    }

    const getSubMenuContent = () => {
        const mainMenu = mainPageContent();
        if (mainMenu === 'analytics') {
            return analyticsSubMenu();
        }
        else if (mainMenu === 'pdf') {
            return pdfSubMenu()
        }
        else if (mainMenu === 'admin') {
            return adminSubMenu();
        }
        else if (mainMenu === 'dealbook') {
            return dealBookSubMenu()
        }
        else if (mainMenu === 'speaker-brief') {
            return speakerBriefSubMenu()
        }
        else if (mainMenu === 'portco-monitoring') {
            return portcoSubMenu()
        }
        else if (mainMenu === 'ai-agents') {
            return AIAgentSubMenu()
        }
        
        else if (mainMenu === 'connector') {
            return connectorMenu()
        }
        else if (mainMenu === 'connection') {
            return connectorMenu()
        } 
        else {
            return commonSubMenu();
        }
        
    }

    const getNavigationLink = (item, sectionType, mainMenu) => {
        if (sectionType === 'CATEGORIES') {
            if (mainMenu) {
                return `/analytics/reports/category/${mainMenu.slug}/${item.slug}`
            }
            return `/analytics/reports/${item.slug}`
        }
        else {
            return `/analytics/${item.slug}`
        }
    }

    const changeAccordionBGColor = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        if (childElement.classList.contains('show')) {
            const parentElement = document.getElementById(parentId);
            parentElement.classList.remove('bl-bg-black')
        }
        else {
            const parentElement = document.getElementById(parentId);
            parentElement.classList.add('bl-bg-black')
        }
    }

    const accordionMenusContent = (menu, list, indx, type, sectionType) => {
        // console.log(list,menu,list,indx,"777")
        return (
            <div key={`${type}-list-${indx}`} id={`${type}parent${indx}`} className="accordion bl-menu-accordion nav-link m-0 bl-bg-black">
                <div className="accordion-item">
                    <h6 className={`accordion-header cp ${activeSubTab === menu.slug ? 'text-white' : ''}`} id={`${type}submenuheading${indx}`} onClick={() => changeAccordionBGColor(`${type}parent${indx}`, `${type}submenucollapseitem-${indx}`)} data-toggle="collapse" data-target={`#${type}submenucollapseitem-${indx}`} aria-expanded="false" aria-controls={`${type}submenucollapseitem-${indx}`}>
                        <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                        <span className="bl-accordion-icon border-0 bl-pr-point3">
                            <i className="fa fa-angle-down accordion-button m-0" onClick={() => changeAccordionBGColor(`${type}parent${indx}`, `${type}submenucollapseitem-${indx}`)} data-toggle="collapse" data-target={`#${type}submenucollapseitem-${indx}`} aria-expanded="false" aria-controls={`${type}submenucollapseitem-${indx}`}></i>
                        </span>
                    </h6>
                    <div id={`${type}submenucollapseitem-${indx}`} className="accordion-collapse show collapse m-0" aria-labelledby={`${type}submenuheading${indx}`}>
                        <div className="accordion-body pb-2">
                            <ul className="navbar-nav">
                                {
                                    list.map((subItem, i) => {
                                        return (
                                            <li key={`${type}${subItem.slug}-${i}-${indx}`} className={activeSupSubMenu === subItem.slug ? 'nav-item mb-0 selected' : 'nav-item mb-0'}>
                                                <button key={`${type}-sub-menu-item-${indx}-${i}`} className={`nav-link d-flex justify-content-between align-items-center m-0 bl-pr-point3 ${subItem.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(subItem, sectionType, menu)); setActiveSubTab(subItem.slug) }}>
                                                    <span className="ml-3">{subItem.name}</span>
                                                    {
                                                        // sectionType === 'CATEGORIES' ? <span className="bl-report-count">{subItem.count ? `${subItem.count}` : '0'}</span> : null
                                                    }
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getAnalyticsSubMenu = (list, sectionType) => {
        return (
            <>
                {
                    list.map((menu, i) => {
                        return (
                            <li key={`analytics-sub-${menu.name}-${sectionType}`} className={activeSubTab === menu.slug ? 'nav-item selected' : 'nav-item'}>
                                {
                                    menu.list && menu.list.length ? accordionMenusContent(menu, menu.list, i, 'analytics', sectionType)
                                        : (
                                            <button className={`nav-link d-flex justify-content-between align-items-center ${menu.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(menu, sectionType)); setActiveSubTab(menu.slug) }}>
                                                <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                                                {
                                                    // sectionType === 'CATEGORIES' ? <span className="bl-report-count">{menu.count ? `${menu.count}` : '0'}</span> : null
                                                }
                                            </button>
                                        )
                                }

                            </li >
                        )
                    })
                }
            </>
        )
    }

    const analyticsSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/analytics/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>
                {
                    pinnedReport.length > 0 ? (<div className="sidebar-heading pb-2 mt-4">
                        PINNED REPORTS
                    </div>) : null
                }
                {
                    pinnedReport.map((p, i) => {
                        const reportSlug = p.categoryInfo && p.categoryInfo.length ? `${p.categoryInfo[0].categorySlug}-${p.reportNameSlug}` : ''
                        const reportPath = p.categoryInfo && p.categoryInfo.length ? `${p.categoryInfo[0].categorySlug}/${p.reportNameSlug}` : ''
                        return (
                            <li key={`pinned-report-${i + 1}`} className={activeSupSubMenu === reportSlug ? 'nav-item selected' : 'nav-item'}>
                                <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/analytics/reports/${reportPath}`); setActiveSupSubMenu(reportSlug) }}>
                                    <span><i className="fa fa-fw fa-thumb-tack"></i> {p.reportName}</span></button>
                            </li>
                        )
                    })
                }
                {
                    analyticsSubMenuList.map((items, i) => {
                        if (items.list.length) {
                            return (
                                <React.Fragment key={`analytics-sub-menu-${items.menuType}-${i + 1}`}>
                                    <div key={`analytics-sub-header-${i + 1}`} className="sidebar-heading pb-2 mt-4">
                                        {items.menuType}
                                    </div>
                                    {
                                        getAnalyticsSubMenu(items.list, items.menuType)
                                    }
                                </React.Fragment>
                            )
                        }

                    })
                }


            </ul>
        )
    }

    const pdfSubMenu = () => { 
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    COMPANY LIST
                </div>
                {
                    pdfMenu.map((pdf, i) => {
                        return (
                            <li key={`pdf-view-${i}`} className={activeSubTab === pdf.slug ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                                <button className={`nav-link d-flex justify-content-between align-items-center ${pdf.isDisabled ? 'disabled' : 'text-white'}`} onClick={() => { nav(`/pdf/${pdf.slug}`); setActiveSubTab(pdf.slug) }}>
                                    <span><i className={`fa fa-fw ${pdf.icon}`}></i> {pdf.name}</span></button>
                            </li>
                        )
                    })
                }

            </ul>
        )
    }

    const adminSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    ADMIN CONSOLE
                </div>
                {
                    adminMenu.map((pdf, i) => {
                        return (
                            <li key={`admin-view-${i}`} className={activeSubTab === pdf.slug ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                                <button className={`nav-link d-flex justify-content-between align-items-center ${pdf.isDisabled ? 'disabled' : 'text-white'}`} onClick={() => { nav(`/admin/${pdf.slug}`); setActiveSubTab(pdf.slug) }}>
                                    <span><i className={`fa fa-fw ${pdf.icon}`}></i> {pdf.name}</span></button>
                            </li>
                        )
                    })
                }

            </ul>
        )
    }

    const dealBookSubMenu = () => {
        let companyProjectActiveSec = '';
        if (dealBookProjectSlug === activeSubTab) {
            const pathArray = location.pathname.split('/');
            companyProjectActiveSec = pathArray[pathArray.length - 1];
        }
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 2 && pathArray[2] === 'project') {
            const activeCIMSection = pathArray[4];
            const id = projectSections ? generateReadableId(projectSections[0].projectId, "cim") : null;
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                    <li className={'nav-item mt-3'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/dealbook/home'); setActiveSubTab('home'); setAnalyzedCompanyInfo({ message: "Dashboard Page" }) }}>
                            <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        SECTIONS
                    </div>
                    {
                        projectSections && projectSections.map((sec, i) => {
                            if (sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard") {
                                return null
                            }
                            return (
                                <li key={`dealBook-proj-sections-${i}`} className={activeCIMSection === sec.slug ? 'nav-item selected' : 'nav-item'}>
                                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/dealbook/project/${id}/${sec.slug}/${projectMode}`) }}>
                                        <span><i className={`fa fa-fw fa-${sec.icon}`}></i> {sec.categoryName}</span></button>
                                </li>
                            )
                        })
                    }
                    <div className="sidebar-heading pb-2 mt-4">
                        MORE
                    </div>
                    <li className={activeSubTab === dealBookProjectSlug && companyProjectActiveSec === 'files' ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/dealbook/project/${id}/${dealBookProjectSlug}/files`) }}>
                            <span><i className="fa fa-fw fa-file-pdf-o"></i> View File</span></button>
                    </li>
                </ul>
            )
        }
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <a className="btn btn-outline-primary m-2 mt-3 align-self-center text-nowrap bl-w-fill-available btn-sm cp" data-toggle="modal" data-target="#analyzeNewModal">
                    <span><i className="fa fa-fw fa-plus-circle"></i> Create New Summary</span>
                </a>
                <div className="sidebar-heading pb-2 mt-4">
                    MENU
                </div>
                <li className={activeSubTab === 'home' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/dealbook/home`); setActiveSubTab('home') }}>
                        <span><i className={`fa fa-fw fa-home`}></i> Home</span></button>
                </li>
                <div className="sidebar-heading pb-2 mt-4">
                    CONFIGURATION
                </div>
                <li className={activeSubTab === 'templates' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/dealbook/templates`) }}>
                        <span><i className={`fa fa-fw fa-window-maximize`}></i> Manage Templates</span></button>
                </li>
            </ul>
        )
    }

    const speakerBriefSections = (id) => {
        return [
            {
                displayName: "Speaker Brief",
                path: `${id}/info`,
                slug: "info",
                icon: "binoculars"
            },
            {
                displayName: "Event Details",
                path: `${id}/event-details`,
                slug: "event-details",
                icon: "info-circle"
            }
        ]
    }

    const speakerBriefSubMenu = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 2 && pathArray[2] === 'events') {
            const id = pathArray.length === 5 ? pathArray[3] : null;
            const createEvents = pathArray[pathArray.length - 1] === 'manage';
            const eventSections = createEvents ? [
                {
                    displayName: "Create Event",
                    path: "manage",
                    slug: "manage",
                    icon: "plus-circle"
                }
            ] : speakerBriefSections(id);
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                    <li className={'nav-item mt-3'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/speaker-brief/dashboard'); setActiveSubTab('dashboard') }}>
                            <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                    </li>
                    {
                        eventSections && eventSections.length ? (
                            <div className="sidebar-heading pb-2 mt-4">
                                SECTIONS
                            </div>
                        ) : null
                    }
                    {
                        eventSections && eventSections.map((sec, i) => {
                            return (
                                <li key={`dealbook-proj-sections-${i}`} className={activeSubTab === sec.slug ? 'nav-item selected' : 'nav-item'}>
                                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/speaker-brief/events/${sec.path}`) }}>
                                        <span><i className={`fa fa-fw fa-${sec.icon}`}></i> {sec.displayName}</span></button>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <a className="btn btn-outline-primary cp m-2 mt-3 align-self-center text-nowrap bl-w-fill-available btn-sm" onClick={() => { nav(`/speaker-brief/events/manage`) }}>
                    <span><i className="fa fa-fw fa-plus-circle"></i> Create New Event</span>
                </a>
                <div className="sidebar-heading pb-2 mt-4">
                    MENU
                </div>
                <li className={activeSubTab === 'dashboard' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/speaker-brief/dashboard`); setActiveSubTab('dashboard') }}>
                        <span><i className={`fa fa-fw fa-calendar`}></i> Events</span></button>
                </li>
                <li className={activeSubTab === 'configuration' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/speaker-brief/configuration`) }}>
                        <span><i className={`fa fa-fw fa-cog`}></i> Configuration</span></button>
                </li>
                <li className={activeSubTab === 'files-documents' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/speaker-brief/files-documents`); setActiveSupSubMenu(`files-documents`) }}>
                        <span><i className={`fa fa-fw fa-book`}></i> Documents & Files (Beta)</span></button>
                </li>
            </ul>
        )
    }

    const portcoSubMenu = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 2 && pathArray[2] === 'companies') {
            const portcoInfoId = pathArray[3];
            const activePortcoInfoSection = pathArray.length > 5 ? pathArray[5] : pathArray[4];
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                    <li className={'nav-item mt-3'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/portco-monitoring/all-companies') }}>
                            <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        SECTIONS
                    </div>
                    <li className={activePortcoInfoSection === `overview` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/overview`) }}>
                            <span><i className="fa fa-fw fa-user"></i> Overview</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `financial` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/financial`); setActiveSupSubMenu(`financial`) }}>
                            <span><i className="fa fa-fw fa-usd"></i> Financial</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `transaction-profile` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/transaction-profile`); setActiveSupSubMenu(`transaction-profile`) }}>
                            <span><i className="fa fa-fw fa-exchange"></i> Transaction Profile</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `valuation` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/valuation`); setActiveSupSubMenu(`valuation`) }}>
                            <span><i className="fa fa-fw fa-sliders"></i> Valuation</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `vendor-spends` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/vendor-spends`); setActiveSupSubMenu(`vendor-spends`) }}>
                            <span><i className="fa fa-fw fa-money"></i> Vendor Spends</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `esg` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/esg`); setActiveSupSubMenu(`esg`) }}>
                            <span><i className="fa fa-fw fa-etsy"></i> ESG</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `people` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/people`); setActiveSupSubMenu(`people`) }}>
                            <span><i className="fa fa-fw fa-users"></i> People</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `news` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/news`); setActiveSupSubMenu(`news`) }}>
                            <span><i className="fa fa-fw fa-newspaper-o"></i> News</span></button>
                    </li>
                    <li className={activePortcoInfoSection === `economic-indicators` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/economic-indicators`); setActiveSupSubMenu(`economic-indicators`) }}>
                            <span><i className="fa fa-fw fa-bar-chart"></i> Economic Indicators</span></button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        Data Library
                    </div>
                    <li className={activePortcoInfoSection === 'knowledge-graph' ? 'nav-item selected' : 'nav-item'}>
                        <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/data-library/knowledge-graph`); setActiveSupSubMenu(`knowledge-graph`) }}>
                            <span><i className={`fa fa-fw fa-modx`}></i> Knowledge Graph</span></button>
                    </li>
                    <li className={activePortcoInfoSection === 'documents-files' ? 'nav-item selected' : 'nav-item'}>
                        <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/portco-monitoring/companies/${portcoInfoId}/data-library/documents-files`); setActiveSupSubMenu(`documents-files`) }}>
                            <span><i className={`fa fa-fw fa-book`}></i> Documents & Files</span></button>
                    </li>
                    <li className={activeSubTab === 'link' ? 'nav-item selected' : 'nav-item'}>
                        <button className={`nav-link d-flex justify-content-between align-items-center disabled`} disabled>
                            <span><i className={`fa fa-fw fa-link`}></i> External URLs</span></button>
                    </li>
                    <li className={activeSubTab === 'sources' ? 'nav-item selected' : 'nav-item'}>
                        <button className={`nav-link d-flex justify-content-between align-items-center disabled`} disabled>
                            <span><i className={`fa fa-fw fa-soundcloud`}></i> Data Sources</span></button>
                    </li>
                </ul>
            )
        }
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    MENU
                </div>
                <li className={activeSubTab === 'all-companies' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/portco-monitoring/all-companies`); setActiveSubTab('all-companies') }}>
                        <span><i className={`fa fa-fw fa-building`}></i> All Companies</span></button>
                </li>

            </ul>
        )
    }

    const AIAgentSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    MENU
                </div>
                <li className={activeSubTab === 'all' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/ai-agents/all`); setActiveSubTab('all') }}>
                        <span><i className={`fa fa-fw fa-microchip`}></i> All AI Agents</span></button>
                </li>
            </ul>
        )
    }

    
    const connectorMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    MENU
                </div>
                <li className={activeSubTab === 'connector' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/connector`); setActiveSubTab('connector') }}>
                        <span><i class="fa fa-plug"></i>Connectors</span></button>
                </li>
                <li className={activeSubTab === 'connection' ? 'nav-item selected' : 'nav-item'}>
                    <button className={`nav-link d-flex justify-content-between align-items-center`} onClick={() => { nav(`/connector/connection`); setActiveSubTab('connection') }}>
                        <span><i class="fa fa-link"></i>  Connections</span></button>
                </li>

            </ul>
        )
    }

    const commonSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading py-2">
                    General Settings
                </div>
            </ul>
        )
    }

    return (
        <div className="sub-menu-content d-print-none">
            {getSubMenuContent()}
        </div>
    )
}