import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../services/context/Auth/authContext";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { CardDropdownContent } from "./cardDropdown";
import { useNavigate } from "react-router-dom";
import { getNewsFeedService, getRelativeTime } from "../../services/commonService";


export const RightPanelComponent = () => {

  const { userGroups, applicableApps, isOtherApp } = useContext(AuthContext)
  const { setAIModalType } = useContext(AIContext);
  const nav = useNavigate();

  const [usedApps, setUsedApps] = useState([])
  const [myFeeds, setMyFeeds] = useState([])
  const [feedType, setFeedType] = useState('news')
  const [feedOptions, setFeedOptions] = useState([{name:"Google News", value:"news"}])

  useEffect(() => {
    const defaultApps = [
      {
        name: "Analytics",
        icon: "fa-pie-chart",
        path: "/dashboard"
      },
      {
        name: "AI App",
        icon: "fa-gg-circle",
        path: "/analytics/overview"
      }
    ]
    // setUsedApps(defaultApps)

    const financeFeed = []


    let feedList = [];
    if (userGroups.find(grp => grp === "bl.cloud.int.group1") !== -1) {
      feedList = financeFeed
    }
    else {
      feedList = []
    }

    
    setMyFeeds(feedList)
  }, [])

  useEffect(() => {
    const apps = applicableApps.filter(app => app.appName !== 'DASHBOARD');
    setUsedApps(apps)
  }, [applicableApps])

  useEffect(() => {
    if(isOtherApp){
      const list = [
        {name:"All", value:"all"},
        {name:"Reddit", value:"reddit"},
        {name:"Google News", value:"news"}
      ]
      setFeedOptions(list)
      getNewsFeed()
    }
  }, [feedType, isOtherApp])

  const getNewsFeed = async() => {
    const obj = {
      pageSize: 14,
      page: 1,
      source: feedType
    }
    const resp = await getNewsFeedService(obj);
    if(resp && resp.articles){
      const items = resp.articles.map(item => {
        return {
          source: "",
          title: item.title ? item.title : item.link_title,
          date: item.date ? getRelativeTime(item.date) : "Today",
          icon: item.type === "news-article" ? "fa-google-plus-official" : "fa-reddit" ,
          url: item.url ? item.url : item.link_permalink
      }
      })
      if(items && items.length){
        setMyFeeds(items)
      }
    }
  }

  const recentlyUsedAppGrid = () => {
    return (
      <div className="row">
        {
          usedApps.map((app, i) => {
            return (
              app.isModal ? (
                <div key={`app-list-${i}`} className="col-2 text-center cp font-smaller" onClick={() => setAIModalType('DEFAULT')} data-toggle="modal" data-target="#aimodalbox">
                  {
                    app.image ? <img src={app.image} alt="menu-icon" className="mb-1" width={'25px'} /> : <i className={`fa fa-2x mb-1 ${app.icon}`} aria-hidden="true"></i>
                  }
                  <p className="small mb-0 text-nowrap">{app.displayName}</p>
                </div>
              ) : (
                <div key={`app-list-${i}`} className="col-2 text-center cp font-smaller" onClick={() => nav(app.path)}>
                  {
                    app.image ? <img src={app.image} alt="menu-icon" className="mb-1" width={'25px'} /> : <i className={`fa fa-2x mb-1 ${app.icon}`} aria-hidden="true"></i>
                  }
                  <p className="small mb-0 text-nowrap">{app.displayName}</p>
                </div>
              )
            )
          })
        }
      </div>
    )
  }

  const selectFeed = (val) => {
    setFeedType(val);
  }

  return (
    <div className="row">
      <section className="col-12 bg-dark h-13rem overflow-y-auto recent-app">
        <div className="text-white">
          <div className="pt-3 text-white">
            <div className="col-12">
              <p className="font-weight-600">Recently Used Apps</p>
            </div>
            {
              recentlyUsedAppGrid()
            }

          </div>
        </div>
      </section>
      <section className="bg-gray p-3 w-100">
        <div className="row p-2">
          <div className="d-flex col-6">
            <h5 className="h5 my-0 text-black font-weight-600">My Feed</h5>
            <div className="ml-2">
              <CardDropdownContent cardId="myfeeddropdown" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="btn-group">
              <select id="contentview" value={feedType} onChange={(e) => selectFeed(e.target.value)} className="form-control form-control-sm blc_button text-black">
                {
                  feedOptions.map((option, i) => {
                    return (
                      <option key={`feed-options-${i}`} value={option.value}>{option.name}</option>
                    )
                  })
                }                
              </select>
            </div>
          </div>

        </div>
        <div className="feed">
          {
            myFeeds.map((feed, i) => {
              return (
                <div key={`my-feed-item-${i}`} className="post-item clearfix">
                  <span><i className={`fa ${feed.icon}`} aria-hidden="true"></i></span>
                  <p>{
                    feed.url ? (<a href={feed.url} target="_blank">{feed.title}</a>) : feed.title
                  }</p>
                  <p className="small">{feed.date} {feed.source ? `| ${feed.source}` : ''}</p>
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
  )
}