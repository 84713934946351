import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { PortcoContext } from "../../../services/context/portcoContext";
import { PortcoCompanyTitle } from "./Common/companyTitle";
import { EmptyCardsContent } from "../../Common/emptyCard";


export const PortcoCompanyTransactionProfileComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/transaction-profile`, menuName: "Transaction Profile", isActive: true, hasSubMenu: true }
        ])
    }, [location.pathname, portcoCompanyInfo])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <PortcoCompanyTitle />
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Sector Breakdown</h6>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No Sector Breakdown data found" buttonView={false} />
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Purchase Type</h6>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No Purchase Type found" buttonView={false} />
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">EBITDA Growth</h6>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        <EmptyCardsContent title="No EBITDA Growth found" buttonView={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Transaction Summary</h6>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <EmptyCardsContent title="No summary found" buttonView={false} />
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Deal Source Details</h6>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <EmptyCardsContent title="No details found" buttonView={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}