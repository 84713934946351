import React, { useState } from 'react';

export const PortcoContext = React.createContext();

export const PortcoContextProvider = ({ children }) => {

    const [portcoCompanyInfo, setPortcoCompanyInfo] = useState(null);

    return (
        <PortcoContext.Provider value={{ portcoCompanyInfo, setPortcoCompanyInfo }}>
            {children}
        </PortcoContext.Provider>
    );
};
