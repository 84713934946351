import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { deleteChatHistoryByThreadService, getChatHistoryThreadService } from "../../../services/aiService";
import { toastSuccess } from "../../../services/notifyService";
import { accordionDirection } from "../../../services/commonService";
import { AuthContext } from "../../../services/context/Auth/authContext";

export const ConversationHistoryComponent = forwardRef(({ getChatHistoryByThread, searchThread, openNewChat, textColor }, ref) => {

    const { userMail, loggedUserMail } = useContext(AuthContext)
    const [conversationHistory, setConversationHistory] = useState([])
    const [threads, setThreads] = useState([])
    const [selectedThreadToDelete, setSelectedThreadToDelete] = useState('')
    const [selectedThread, setSelectedThread] = useState('')
    const [isThreadSearched, setIsThreadSearched] = useState(false)
    const [isThreadLoading, setIsThreadLoading] = useState(false)

    useEffect(() => {
        getConversationData()
    }, [threads])

    useEffect(() => {
        setThreads([])
        setTimeout(() => {
            getChatThread(1)
        }, 100)
    }, [searchThread, userMail])

    const getThreadFromParent = () => {
        setThreads([])
        setTimeout(() => {
            getChatThread(1)
        }, 100)
    }

    const searchInThread = () => {
        setIsThreadSearched(true)
    }

    useImperativeHandle(ref, () => ({
        resetSelectedThread(selectiveThread) {
            setSelectedThread(selectiveThread)
        },
        getThreadFromParent,
        searchInThread
    }))

    const getChatThread = async (page) => {
        const obj = {
            page: page,
            pageSize: 20,
            searchText: searchThread,
            userEmail: userMail
        }
        setIsThreadLoading(true)
        const resp = await getChatHistoryThreadService(obj);
        if (resp && resp.status) {
            setIsThreadSearched(false)
            const newThreadList = resp.response && resp.response.map(item => {
                item.threadTitle = item.threadTitle.replace(/^\?/, '');
                return item;
            });
            setThreads(thread => [...thread, ...newThreadList])
            if (resp.response && resp.response.length >= 20) {
                getChatThread(page+1)
            }
            else {
                setIsThreadLoading(false)
            }
        }
    }

    const getThreadGroup = (threadDate) => {
        const currentDate = new Date();
        const threadDateObj = new Date(threadDate);
        const timeDiff = currentDate - threadDateObj;
        const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
        if (daysDiff < 1) {
            return "TODAY"
        }
        else if (daysDiff < 2) {
            return "YESTERDAY"
        }
        else if (daysDiff <= 7) {
            return "THIS WEEK";
        } else if (daysDiff <= 14) {
            return "LAST WEEK";
        } else {
            return "OLDER";
        }
    }

    const groupedThreads = threads.reduce((result, thread) => {
        const group = getThreadGroup(thread.createdOn);
        result[group].push(thread);
        return result;
    }, { "TODAY": [], "YESTERDAY": [], "THIS WEEK": [], "LAST WEEK": [], "OLDER": [] });

    Object.keys(groupedThreads).forEach(group => {
        groupedThreads[group] = groupedThreads[group].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
    });

    const getConversationData = () => {
        const history = [
            {
                type: 'TODAY',
                items: groupedThreads["TODAY"],
                isVisible: true
            },
            {
                type: 'YESTERDAY',
                items: groupedThreads["YESTERDAY"],
                isVisible: true
            },
            {
                type: 'THIS WEEK',
                items: groupedThreads["THIS WEEK"],
                isVisible: true
            },
            {
                type: 'LAST WEEK',
                items: groupedThreads["LAST WEEK"],
                isVisible: true
            },
            {
                type: 'OLDER',
                items: groupedThreads["OLDER"],
                isVisible: true
            }
        ];
        // const result = updateVisibility(history);
        setConversationHistory(history)
    }

    // useEffect(() => {
    //     if (conversationHistory && conversationHistory.length && !isThreadLoading) {
    //         const result = updateVisibility(conversationHistory);
    //         console.log("result ---->>>", result);
    //         setConversationHistory(result)
    //     }
    // }, [conversationHistory, isThreadLoading])

    function updateVisibility(data) {
        let availableItemCounts = data.filter(category => category.items && category.items.length > 0).length;
        if (availableItemCounts === data.length) {
            data[data.length - 1].isVisible = false;
            data[data.length - 2].isVisible = false;
            for (let i = 0; i < data.length - 2; i++) {
                data[i].isVisible = true;
            }
        } else if (availableItemCounts >= 2 && availableItemCounts < data.length) {
            data[data.length - 1].isVisible = false;
            for (let i = 0; i < data.length - 1; i++) {
                data[i].isVisible = true;
            }
        } else if (availableItemCounts === 1) {
            for (let i = 0; i < data.length; i++) {
                data[i].isVisible = true;
            }
        }

        return data;
    }

    const closeModal = (modalId) => {
        window.$(modalId).modal('hide');
    }

    const deleteThreadChatHistory = async () => {
        if(loggedUserMail.toLowerCase() !== userMail.toLowerCase()){
            return
        } 
        document.getElementById('pageLoaderContainer').style.display = 'flex';
        const obj = {
            threadId: selectedThreadToDelete
        }
        const resp = await deleteChatHistoryByThreadService(obj);
        if (resp && resp.status) {
            openNewChat()
            toastSuccess('Chat history deleted successfully!')
            closeModal('#deletethreadModal');
            setThreads([])
            setTimeout(() => {
                getChatThread(1)
            }, 100)
        }
        document.getElementById('pageLoaderContainer').style.display = 'none';
    }

    const deleteThreadContent = () => {
        return (
            <div className="modal fade bl-static-modal" id="deletethreadModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="deletethreadModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content shadow-lg">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete Conversation</h5>
                            <button className="close" type="button" onClick={() => closeModal('#deletethreadModal')} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Are you sure that you want to delete the chosen conversation?</div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-primary secondary-link" type="button" onClick={() => closeModal('#deletethreadModal')}>Cancel</button>
                            <button className="btn btn-primary primary-link" onClick={() => { deleteThreadChatHistory() }}>Yes, Delete It</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {
                !selectedThread ? (
                    <li className="nav-item active d-flex justify-content-between">
                        <a className={`nav-link py-2 w-100 bl-desc-truncate-line-1 ${textColor}`}>
                            <i className="fa fa-fw fa-comment"></i>
                            <span>New Chat</span>
                        </a>
                    </li>
                ) : null
            }
            {
                conversationHistory && conversationHistory.map((weeks, i) => {
                    const sectionId = `threadconversation${i + 1}`;
                    const angleId = `collpaseangle${i + 1}`;
                    return (
                        <React.Fragment key={`history-thread-${i}`}>
                            {
                                weeks.items.length ? <div className="sidebar-heading d-flex justify-content-between mt-3 cp" data-toggle="collapse" data-target={`#${sectionId}`} role="button" aria-expanded="false" aria-controls={sectionId} onClick={() => accordionDirection(sectionId, angleId)}>
                                    <div>
                                        {weeks.type}
                                        <span className="bl-report-count ml-2">{weeks.items.length}</span>
                                    </div>
                                    <i id={angleId} className={`fa ${weeks.isVisible ? 'fa-angle-up' : 'fa-angle-down'} accordion-button mr-1`}></i>
                                </div> : null
                            }
                            <div className={`collapse ${weeks.isVisible ? 'show' : ''}`} id={sectionId}>
                                {
                                    weeks.items.map((history, j) => {
                                        return (
                                            <li key={`history-thread-element-${j}-${i}`} className={`nav-item d-flex justify-content-between text-nowrap ${selectedThread.toString() === history.threadId.toString() && 'active'}`}>
                                                <a className={`nav-link py-2 w-100 bl-desc-truncate-line-1 ${textColor}`} onClick={(e) => { setSelectedThread(history.threadId); getChatHistoryByThread(history.threadId, e) }} title={history.threadTitle}>
                                                    <i className="fa fa-fw fa-comment"></i>
                                                    <span>{history.threadTitle}</span>
                                                </a>
                                                <i className={`fa fa-trash mr-3 align-self-center ${loggedUserMail.toLowerCase() !== userMail.toLowerCase() ? 'disabled':'cp'} ${textColor ? textColor : selectedThread.toString() !== history.threadId.toString() && 'text-gray'}`} onClick={() => setSelectedThreadToDelete(history.threadId)} data-toggle="modal" data-target="#deletethreadModal"></i>
                                            </li>
                                        )
                                    })
                                }
                            </div>

                        </React.Fragment>
                    )
                })
            }
            {
                isThreadLoading ? <div>
                    <span className="bar-loader"></span>
                </div> : null
            }
            {deleteThreadContent()}
        </div>
    )
})