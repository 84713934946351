import React, { useState } from 'react';
import AIContext from './aiContext';

const AIAssistanceContextProvider = ({ children }) => {

  const [aiModalType, setAIModalType] = useState('DEFAULT');
  const [companyProfileWidget, setCompanyProfileWidget] = useState(null);
  const [rolesModelInfo, setRolesModelInfo] = useState(null)
  const [rolesModelId, setRolesModelId] = useState("")
  const [rolesModelName, setRolesModelName] = useState("")
  const [dataSourceMenu, setDataSourceMenu] = useState([])
  const [selectedDataSource, setSelectedDataSource] = useState([])
  const [sbModelId, setSbModelId] = useState([])

  return (
    <AIContext.Provider value={{ aiModalType, setAIModalType, companyProfileWidget, setCompanyProfileWidget, rolesModelInfo, setRolesModelInfo, rolesModelId, setRolesModelId, rolesModelName, setRolesModelName, dataSourceMenu, setDataSourceMenu, selectedDataSource, setSelectedDataSource, sbModelId, setSbModelId }}>
      {children}
    </AIContext.Provider>
  );
};

export default AIAssistanceContextProvider;