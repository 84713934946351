import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { PortcoContext } from "../../../services/context/portcoContext";
import { PortcoCompanyTitle } from "./Common/companyTitle";
import { EmptyCardsContent } from "../../Common/emptyCard";


export const PortcoCompanyValuationsComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/valuation`, menuName: "Valuation", isActive: true, hasSubMenu: true }
        ])
    }, [location.pathname, portcoCompanyInfo])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <PortcoCompanyTitle />
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Over Time</h6>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        <EmptyCardsContent title="No Spend Over Time found" buttonView={false} />
                                    </div>
                                </div>

                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Trends</h6>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 text-center">
                                        <EmptyCardsContent title="No Purchase Type found" buttonView={false} />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Valuation Over Time Breakdown</h6>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-muted" scope="col">Date</th>
                                                    <th className="text-muted" scope="col">Valuation</th>
                                                    <th className="text-muted" scope="col">Key</th>
                                                    <th className="text-muted" scope="col">Growth Rate</th>
                                                    <th className="text-muted" scope="col">Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={5} className="text-center pt-4">
                                                        <EmptyCardsContent title="No record found" buttonView={false} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}