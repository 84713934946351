import React, { useEffect, useState } from 'react';
import { getTableInfoService, getUserInfo } from '../../authService';

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {

    const [loggedUserName, setLoggedUserName] = useState('');
    const [loggedUserMail, setLoggedUserMail] = useState('');
    const [userMail, setUserMail] = useState('');
    const [userGroups, setUserGroups] = useState([]);
    const [userSchema, setUserSchema] = useState([]);
    const [reddit, setReddit] = useState([]);
    const [googleReviews, setGoogleReviews] = useState([]);
    const [googleNews, setGoogleNews] = useState([]);
    const [BLRoles, setBLRoles] = useState([]);
    const [applicableApps, setApplicableApps] = useState([]);
    const [isOtherApp, setIsOtherApp] = useState(false);
    const [isIframeUser, setIsIframeUser] = useState(false);
    const [accountType, setAccountType] = useState('pe');
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const userInfo = JSON.parse(getUserInfo());
        console.log("userInfo ====>", userInfo);
        if (userInfo) {
            setIsIframeUser(userInfo?.iframeUser)
            const userSnowflakeSchema = userInfo.userSchema && Array.isArray(userInfo.userSchema) ? userInfo.userSchema : [];
            if (userSnowflakeSchema && userSnowflakeSchema.length) {
                getSFTableInfo(userSnowflakeSchema)
            }
            setLoggedUserName(userInfo.username)
            setLoggedUserMail(userInfo.email.toLowerCase())
            setUserMail(userInfo.email.toLowerCase())
            setUserGroups(userInfo.userGroups && Array.isArray(userInfo.userGroups) ? userInfo.userGroups : [])
            // setUserSchema(userSnowflakeSchema)
            userInfo.dataSource && Array.isArray(userInfo.dataSource) && userInfo.dataSource.forEach(element => {
                if (element && element.name === 'reddit') {
                    setReddit(element)
                }
                else if (element && element.name === 'news') {
                    setGoogleNews(element)
                }
                else if (element && element.name === 'googleReviews') {
                    setGoogleReviews(element)
                }
            });
            const blRoles = userInfo.BLRoles && Array.isArray(userInfo.BLRoles) ? userInfo.BLRoles : []
            setBLRoles(blRoles)
            const isOtherUser = userInfo.userType && userInfo.userType === 'other';
            setIsOtherApp(isOtherUser)
            setAccountType(userInfo.userType)
            const _isSuperAdmin = blRoles.findIndex(role => role.roles === "BL Admin");
            setIsSuperAdmin(_isSuperAdmin >= 0)
        }
    }, [])

    const getSFTableInfo = async (userSnowflakeSchema) => {
        try {
            const tableNames = userSnowflakeSchema.map(t => {
                return t.originalTableName;
            });            
            if (tableNames && tableNames.length) {
                const obj = {
                    tables: tableNames
                }
                const schemaInfo = await getTableInfoService(obj);
                let sfSchema = userSnowflakeSchema.map(item => {
                    const key = item.originalTableName;
                    if (schemaInfo[key]) {
                        item.description = schemaInfo[key];
                    }
                    return item
                });
                setUserSchema(sfSchema)
            }
        }
        catch (e) {

        }
    }

    return (
        <AuthContext.Provider value={{ loggedUserName, setLoggedUserName, loggedUserMail, setLoggedUserMail, userMail, setUserMail, userGroups, setUserGroups, userSchema, setUserSchema, BLRoles, setBLRoles, applicableApps, setApplicableApps, isOtherApp, setIsOtherApp, reddit, setReddit, googleReviews, setGoogleReviews, googleNews, setGoogleNews, isIframeUser, setIsIframeUser, accountType, setAccountType, isSuperAdmin, setIsSuperAdmin }}>
            {children}
        </AuthContext.Provider>
    );
};
