import React, { useEffect, useRef } from "react";
import mermaid from "mermaid";

export const MermaidContentComponent = ({ mermaidContext, contextId = "mermaidId" }) => {
    const containerRef = useRef(null);

    useEffect(() => {


        window.$(`#${contextId}`).html(mermaidContext).removeAttr('data-processed');
        mermaid.init(undefined, window.$(`#${contextId}`));

        // Ensure container exists and mermaidContext is provided.
        // if (!containerRef.current) return;
        // if (!mermaidContext || typeof mermaidContext !== "string") {
        //     console.error("Invalid or missing mermaidContext provided.");
        //     return;
        // }

        // const renderMermaid = async () => {
        //     // Delay slightly to ensure the DOM is fully ready.
        //     await new Promise((resolve) => setTimeout(resolve, 100));

        //     try {
        //         // Dynamically import Mermaid on the client side.
        //         const { default: mermaid } = await import("mermaid");

        //         // Initialize Mermaid (customize options as needed).
        //         mermaid.initialize({ startOnLoad: false, theme: "default" });

        //         // Render the diagram.
        //         mermaid.render(contextId, mermaidContext, (svgCode) => {
        //             if (containerRef.current) {
        //                 containerRef.current.innerHTML = svgCode;
        //             }
        //         });
        //     } catch (error) {
        //         console.error("Error during Mermaid rendering:", error);
        //     }
        // };

        // renderMermaid();
    }, [mermaidContext, contextId]);

    return <div id={contextId} />;
};
