import apiInstance from "./serviceMaster";

export const getConnector = async(page,limit) => {
    try{
        return await apiInstance.get(`/connectors?page=${page}&limit=${limit}`)
    }
    catch(e){
        return e;
    }
}

export const getConnection = async(page,limit) => {
    try{
        return await apiInstance.get(`connections?page=${page}&limit=${limit}`);
    }
    catch(e){
        return e;
    }
}
export const getOneConnection = async(id) => {
    try{
        return await apiInstance.get(`connection/${id}`);
    }
    catch(e){
        return e;
    }
}
export const editConnection = async(id,obj) => {
    try{
        return await apiInstance.patch(`connection/${id}?userId=2`,obj);
    }
    catch(e){
        return e;
    }
}
export const saveConnectionApi = async(obj) => {
    try{
        return await apiInstance.post(`/connection?userId=4`,obj);
    }
    catch(e){
        return e;
    }
}
export const deleteConnection = async(id) => {
    try{
        return await apiInstance.delete(`connection/${id}?userId=7`);
    }
    catch(e){
        return e;
    }
}
export const searchConnectorApi = async(page,input) => {
    try{
        return await apiInstance.get(`/searchConnector?connectorName=${input}&limit=10&page=${page}`);
    }
    catch(e){
        return e;
    }
}
export const searchConnection = async(page,input) => {
    try{
        return await apiInstance.get(`/searchConnection?connectionName=${input}&limit=10&page=${page}`);
    }
    catch(e){
        return e;
    }
}



