import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../services/context/Auth/authContext";
import AIContext from "../../services/context/AIAssistance/aiContext";
import sourceMenuDefaultData from "./../../data/AIModel/dataSource.json";
import redditIcon from "./../../assets/img/AIModel/reddit.png";
import reviewsIcon from "./../../assets/img/AIModel/reviews.jpg";
import newsIcon from "./../../assets/img/AIModel/Google_News_icon.png";
import OneDriveIcon from "./../../assets/img/OneDrive_icon.png";
import sharepointIcon from "./../../assets/img/sharepoint.png";
import salesforceIcon from "./../../assets/img/salesforce.png";
import snowflakeIcon from "./../../assets/img/snowflake.png";
import kgIcon from "./../../assets/img/kg.png";
import powerBiIcon from "./../../assets/icons/app/powerbi.png"
import { getModelBasedTagsService, getSourceBasedSuggestiveQuestionService } from "../../services/aiService";


export const KairosChatComponent = () => {

    const { userSchema, reddit, googleReviews, googleNews, isOtherApp, accountType } = useContext(AuthContext)
    const { rolesModelId, rolesModelName, dataSourceMenu, setDataSourceMenu, setSelectedDataSource, rolesModelInfo, sbModelId } = useContext(AIContext)

    const [sourceBasedQuestion, setSourceBasedQuestion] = useState([])
    const [tags, setTags] = useState([])

    useEffect(() => {
        getSourceBasedSuggestiveQuestion();
    }, [])

    useEffect(() => {
        setDataSourceMenu([])
        const snowflakeUserSchema = userSchema && userSchema.length && Array.isArray(userSchema) ? groupByRole(userSchema) : [];
        const reportList = [];
        const redditList = reddit && reddit.subreddit && reddit.subreddit.length && Array.isArray(reddit.subreddit) ? reddit.subreddit.map(table => {
            if (table.name) {
                return {
                    title: table.name,
                    file_url: '',
                    linkPreview: '',
                    file_image: redditIcon,
                    icon: '',
                    isSelected: true,
                    isActive: true
                }
            }
            return null
        }).filter(Boolean) : [];
        const reviews = googleReviews && googleReviews.name ? [{
            title: googleReviews.name,
            file_url: '',
            linkPreview: '',
            file_image: reviewsIcon,
            isSelected: true,
            isActive: true
        }] : [];
        const news = googleNews && googleNews.name ? [{
            title: googleNews.name,
            file_url: '',
            linkPreview: '',
            file_image: newsIcon,
            isSelected: true,
            isActive: true
        }] : [];
        const knowledgeModel = [{
            title: "Sourcing Graph",
            file_url: '',
            linkPreview: '',
            file_image: kgIcon,
            isSelected: true,
            isActive: true,
            isDisabled: true
        }]
        const tagsModel = tags && tags.length ? tags.map(tag => {
            const name = tag?.name?.toUpperCase() || "";
            const childItems = tag && tag.children && tag.children.length && tag.children.map(item => {
                const childName = item?.name?.toUpperCase() || "";
                return {
                    id: item.tagId,
                    parentId: item.parentId,
                    title: childName,
                    originalTitle: item?.name || "",
                    file_url: "",
                    linkPreview: "",
                    file_image: "",
                    icon: "fa-tag",
                    isSelected: true,
                    isActive: true,
                    isDisabled: false
                }
            })
            const obj = {
                id: tag.tagId,
                title: name,
                originalTitle: tag?.name || "",
                list: childItems && childItems.length ? childItems : [],
                file_url: "",
                linkPreview: "",
                file_image: "",
                icon: "fa-tag",
                isSelected: true,
                isActive: true,
                isDisabled: false,
                modelType: "SB"
            }
            if (childItems && childItems.length) {
                obj['category'] = name;
                obj['icon'] = "fa-tags";
            }
            return obj;
        }) : []
        let dataSourceMenuItems = sourceMenuDefaultData;
        dataSourceMenuItems = dataSourceMenuItems.map(item => {
            if (item.type === 'SNOWFLAKE') {
                return {
                    ...item,
                    items: snowflakeUserSchema && snowflakeUserSchema.length ? snowflakeUserSchema : [],
                    icon: snowflakeIcon
                };
            }
            else if (item.type === 'KG') {
                return {
                    ...item,
                    items: knowledgeModel,
                    icon: kgIcon
                }
            }
            else if (item.type === "TAGS") {
                return {
                    ...item,
                    items: tagsModel,
                    fa_icon: "fa-tags"
                }
            }
            else if (item.type === 'REDDIT') {
                return {
                    ...item,
                    items: redditList && redditList.length ? [{
                        category: "Sub Reddit",
                        list: redditList
                    }] : [],
                    icon: redditIcon
                };
            }
            else if (item.type === 'REVIEWS') {
                return {
                    ...item,
                    items: reviews,
                    icon: reviewsIcon
                }
            }
            else if (item.type === 'NEWS') {
                return {
                    ...item,
                    items: news,
                    icon: newsIcon
                }
            }
            else if (item.type === 'REPORT') {
                return {
                    ...item,
                    items: [], // reportList,
                    icon: powerBiIcon //'https://www.k2e.com/wp-content/uploads/2019/01/Power-BI-Logo.png'
                }
            }
            else if (item.type === 'ONE_DRIVE') {
                return {
                    ...item,
                    icon: OneDriveIcon
                }
            }
            else if (item.type === 'SALESFORCE') {
                return {
                    ...item,
                    icon: salesforceIcon
                }
            }
            else if (item.type === 'SHARE_POINT') {
                return {
                    ...item,
                    icon: sharepointIcon
                }
            }
            return item
        })
        if (isOtherApp) {
            const _selectedDataSource = dataSourceMenuItems.filter(item => item.type != "SNOWFLAKE" && item.type != "KG");
            setDataSourceMenu(_selectedDataSource)
        }
        else {
            if (rolesModelInfo && (rolesModelInfo?.modelType?.toUpperCase() === "SB" || rolesModelInfo?.type?.toUpperCase() === "KG")) {
                const _selectedDataSource = dataSourceMenuItems.filter(item => item.type != "REDDIT" && item.type != "REVIEWS" && item.type != "NEWS");
                setDataSourceMenu(_selectedDataSource)
            }
            else {
                const _selectedDataSource = dataSourceMenuItems.filter(item => item.type != "REDDIT" && item.type != "REVIEWS" && item.type != "NEWS" && item.type != "KG");
                setDataSourceMenu(_selectedDataSource)
            }
        }
    }, [userSchema, reddit, googleReviews, googleNews, , sourceBasedQuestion, rolesModelName, tags, rolesModelInfo])

    useEffect(() => {
        const selectedItems = dataSourceMenu.reduce((selected, currentItem) => {
            currentItem.items.forEach(itemGroup => {
                if (itemGroup.list && itemGroup.list.length) {
                    itemGroup.list.forEach(item => {
                        if (item.isSelected) {
                            selected.push(item);
                        }
                    });
                }
                else {
                    if (itemGroup.isSelected) {
                        selected.push(itemGroup);
                    }
                }

            });
            return selected;
        }, []);
        setSelectedDataSource(selectedItems)
    }, [dataSourceMenu, rolesModelId])

    useEffect(() => {
        setTags([])        
        if (rolesModelInfo && rolesModelInfo.modelType && (rolesModelInfo.modelType.toUpperCase() === "SB" || rolesModelInfo.modelType.toUpperCase() === "SB_MD")) {
            const modelId = rolesModelInfo.modelType.toUpperCase() === "SB_MD" ? sbModelId : rolesModelInfo.sbModelId;
            getModelBasedTags(1, modelId)
        }
        // else if(rolesModelInfo && rolesModelInfo.modelType && (rolesModelInfo.modelType.toUpperCase() === "KG" || rolesModelInfo.type.toUpperCase() === "KG")){
        //     getModelBasedTags(1, rolesModelInfo.sbModelId)
        // }
    }, [rolesModelInfo])

    const getModelBasedTags = async (page, modelId) => {
        try {
            const limit = 30;
            const obj = {
                page: page, limit: limit
            };
            const resp = await getModelBasedTagsService(modelId, obj);
            if (resp && resp.success) {
                const list = resp?.data || [];
                setTags((prev) => [...prev, ...list])
                if (list >= limit) {
                    getModelBasedTags(page + 1, modelId)
                }
            }
        }
        catch (e) {

        }
    }

    const getCategory = (currentItem) => {
        // Ensure both inputs are defined and uppercase them for case-insensitive matching
        const roleName = rolesModelName?.toUpperCase() || "";
        const allRole = currentItem?.allRole?.toUpperCase() || "";

        // Check for matches and return corresponding category
        if (roleName.includes("FIN") && allRole.includes("FIN")) {
            return "Performance Model";
        }
        if (roleName.includes("VCT") && allRole.includes("VCT")) {
            return "Company Presence Data";
        }
        if (roleName.includes("PA") && allRole.includes("PA")) {
            return "Company Presence Data";
        }
        if (roleName.includes("ARCV") && allRole.includes("ARCV")) {
            return "ARCV";
        }

        // Return null if no match is found
        return null;
    }

    const groupByRole = (array) => {
        const grouped = array.reduce((result, currentItem) => {
            let suggestiveQuestion = [];
            const matchedItem = sourceBasedQuestion.find(q => q.tableName === currentItem.originalTableName);
            if (matchedItem && matchedItem.suggestionQuestions) {
                suggestiveQuestion = matchedItem.suggestionQuestions
            }
            const category = getCategory(currentItem); // currentItem.allRole ? currentItem.allRole.toUpperCase().includes('FIN') ? 'Performance Model' : currentItem.allRole.toUpperCase().includes('VCT') ? 'Company Presence Data' : currentItem.allRole.toUpperCase().includes('ARCV') ? 'ARCV' : null : null;
            if (category) {
                if (!result[category]) {
                    result[category] = {
                        category: category,
                        list: []
                    };
                }
                result[category].list.push(
                    {
                        title: currentItem.TABLE_NAME,
                        file_url: '',
                        linkPreview: currentItem.url ? currentItem.url : '',
                        file_image: '',
                        icon: 'fa-info-circle',
                        isSelected: true,
                        isActive: true,
                        description: currentItem.description,
                        suggestiveQuestion: suggestiveQuestion,
                        originalTableName: currentItem.originalTableName,
                        isDisabled: true
                    }
                );
            }
            return result;
        }, {});

        return Object.values(grouped);
    };

    const getSourceBasedSuggestiveQuestion = async () => {
        const resp = await getSourceBasedSuggestiveQuestionService();
        if (resp && resp.success) {
            setSourceBasedQuestion(resp.data)
        }
    }

    return (
        <Outlet />
    )
}