import React, { useContext, useEffect, useState } from "react";
import { GraphiQL } from "graphiql";
import { explorerPlugin } from "@graphiql/plugin-explorer";
import "graphiql/graphiql.min.css";
import "@graphiql/plugin-explorer/dist/style.css";
import { graphQlService } from "../services/graphql/apolloClient";
import BreadcrumbContext from "../services/context/Breadcrumb/breadcrumbContext";
import { useLocation } from "react-router-dom";
import blLogo from '../assets/icons/BL/Small_Logo.png';

export default function GraphQl() {
  
  const { setBreadcrumbMenu } = useContext(BreadcrumbContext);
  const [plugin] = useState(explorerPlugin({ showAtStart: false }));
  const location = useLocation();
  useEffect(() => {
    setBreadcrumbMenu([{ slug: '', menuName: 'Explorer', isActive: true, hasSubMenu: false }])
  }, [location.pathname])
  const defaultQuery = `{ hello }`;
  return (
    <div className="bl-kairos-content-height">
      <GraphiQL
        fetcher={graphQlService}
        plugins={[plugin]}
        isResizable
        showQueryHistory
        shouldPersistHeaders
        defaultQuery={defaultQuery} 
      >
      <GraphiQL.Logo>
        <img 
        className="bl-w-3rem"
        src={blLogo}  
        />
      </GraphiQL.Logo>
      </GraphiQL>
    </div>
  );
}
