import apiInstance from "./serviceMaster";

export const getAppUrlService = async (appName) => {
    try {
        return await apiInstance.get(`/client/apps?appName=${appName}`)
    }
    catch (error) {
        return error;
    }
} 
