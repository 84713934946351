import React, { useContext, useEffect, useState } from "react";
import { accordionDirection, convertCamelCaseToSeparateWords } from "../../../services/commonService";
import aiModalKeys from "../../../utils/property-file/AIModal.json";
import AIContext from "../../../services/context/AIAssistance/aiContext";


export const MenuDataSourceElement = ({ reports, selectedDataSource, textColor, getQuestionFromSourceModal }) => {

    const { dataSourceMenu } = useContext(AIContext)
    const [dataSourceOrderedMenu, setDataSourceOrderedMenu] = useState([])
    const [datasourceInfo, setDatasourceInfo] = useState({
        title: "",
        description: "",
        linkPreview: "",
        suggestiveQuestion: []
    })

    useEffect(() => {        
        if (dataSourceMenu) {
            const orderedSource = mergeEmptyIntoLast(dataSourceMenu);
            setDataSourceOrderedMenu(orderedSource)
        }
    }, [dataSourceMenu])

    function mergeEmptyIntoLast(arr) {
        const withItems = arr.filter(obj => Array.isArray(obj.items) && obj.items.length > 0);
        const withoutItems = arr.filter(obj => !Array.isArray(obj.items) || obj.items.length === 0);
        return [...withItems, ...withoutItems];
    }

    const closeModal = (modalId) => {
        window.$(modalId).modal('hide');
    }

    const getAnswerFromSuggestiveQuestion = (e, question) => {
        e.preventDefault();
        closeModal("#datasourceModal");
        getQuestionFromSourceModal(question)
    }

    const getSuggestiveQuestionContentForSourceModal = () => {
        let suggestiveTitle = "";
        let suggestiveQues = [];
        if (datasourceInfo && datasourceInfo.suggestiveQuestion && datasourceInfo.suggestiveQuestion.length) {
            suggestiveTitle = `${aiModalKeys.DEFAULT_SUGGESTIVE_TEXT} for <b>${datasourceInfo.title}</b>`;
            suggestiveQues = datasourceInfo.suggestiveQuestion.slice(0, 4);
        }
        return (
            suggestiveQues.length ? (
                <section>
                    <div className="d-flex flex-row pb-2">
                        <div className="pr-3 text-nowrap align-content-center">Question Suggestion </div>
                        <div className="w-100"><hr /></div>
                    </div>
                    {/* <p className="text-muted" dangerouslySetInnerHTML={{ __html: suggestiveTitle }}></p> */}
                    <div className="card-deck bl-ai-card-deck">
                        {
                            suggestiveQues.map((ques, i) => {
                                let question = ques ? ques.title ? ques.title : ques : '';
                                return (
                                    <div key={`question-sugg-${i}`} className="card">
                                        <div className="card-body bl-body-hover p-2 d-flex" onClick={(e) => { getAnswerFromSuggestiveQuestion(e, question) }}>
                                            <p className="mb-0 font-small cp align-self-center">{question}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>) : null
        )
    }

    const dataSourceSFModalContent = () => {
        return (
            <div className="modal fade bl-static-modal" id="datasourceModal" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="datasourceModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="pr-2 bl-z-index-1">
                            <button className="close" type="button" onClick={() => closeModal('#datasourceModal')} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body mt-n4">
                            <h5 className="text-muted">{datasourceInfo.title}</h5>
                            <p>{datasourceInfo.description}</p>
                            {
                                datasourceInfo.linkPreview && <a href={datasourceInfo.linkPreview} target="_blank"><i className={`fa fa-snowflake-o fa-fw`}></i> Click here to view source data</a>
                            }
                            <div className="pt-4">
                                {getSuggestiveQuestionContentForSourceModal()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const openDataSourceModal = (subItem) => {
        if (subItem && subItem.linkPreview) {
            setDatasourceInfo(subItem)
            window.$("#datasourceModal").modal("toggle")
        }
    }

    const selectDeselectSource = (item) => {
        const tagSelected = !item.isSelected;
        if(item && item.list && item.list.length){
            item.list = item.list.map(tag => {
                tag.isSelected = tagSelected;
                return tag
            })
        }
        else if(item.parentId && tagSelected){
            dataSourceMenu.map(menu => {
                menu.items = menu.items.map(items => {
                    if(parseInt(items.id) === parseInt(item.parentId)){
                        items.isSelected = tagSelected;                        
                    }
                    return items
                })
            })
        }
        item.isSelected = !item.isSelected;             
    }

    const dataSourceAccordionItem = (item, primaryIndex, secondaryIndex) => {
        const arrowId = `angle${primaryIndex}${secondaryIndex}`;
        const collapseSecId = `datasourcecollapsefirst${primaryIndex}${secondaryIndex}`;
        return (
            <div id={`datasourceaccordion${primaryIndex}${secondaryIndex}`}>
                {
                    item.modelType ? (
                        <div className={`accordion-header cp mt-2 text-uppercase d-flex justify-content-between ${textColor ? textColor : 'text-muted'}`} >
                            <div className="d-flex justify-content-between align-items-center bl-w-95" onClick={() => accordionDirection(collapseSecId, arrowId)} data-toggle="collapse" data-target={`#${collapseSecId}`} aria-expanded="false" aria-controls={collapseSecId}>
                                <h6 className="bl-limited-text">
                                    <i className={`fa ${item.icon} mr-2 ${item.isActive && ''}`} aria-hidden="true"></i>
                                    {item.category}
                                </h6>
                                <i id={arrowId} className="fa fa-angle-up accordion-button mr-3 ml-2"></i>
                            </div>
                            <input type="checkbox" className="custom-checkbox small" onChange={(e) => selectDeselectSource(item)} checked={item.isSelected} readOnly={item.isDisabled} disabled={item.isDisabled} />
                        </div>
                    ) : (
                        <h6 className={`accordion-header cp mt-2 text-uppercase d-flex justify-content-between ${textColor ? textColor : 'text-muted'}`} onClick={() => accordionDirection(collapseSecId, arrowId)} data-toggle="collapse" data-target={`#${collapseSecId}`} aria-expanded="false" aria-controls={collapseSecId}>
                            {item.category}
                            <i id={arrowId} className="fa fa-angle-up accordion-button mr-1"></i>
                        </h6>
                    )
                }

                <div id={collapseSecId} className="collapse show m-0" >
                    <ul className="navbar-nav">
                        {
                            item.list.map((subItem, i) => {
                                const id = `datasource${i}${primaryIndex}${secondaryIndex}`;
                                return (
                                    <li key={`datasource-sub-sec-${i}`} className={`nav-item d-flex justify-content-between text-capitalize ${!subItem.isActive && 'disabled'}`} id={id} onClick={() => openDataSourceModal(subItem)}>
                                        <a className={`nav-link py-2 w-100 bl-limited-text ${subItem.isActive && ''} ${textColor}`} title={convertCamelCaseToSeparateWords(subItem.title)}>
                                            {
                                                subItem.icon ? <i className={`fa ${subItem.icon} ${subItem.isActive && ''}`} aria-hidden="true"></i> : <img src={subItem.file_image} height="18px" />
                                            }
                                            <span className="ml-2">{convertCamelCaseToSeparateWords(subItem.title)}</span>
                                        </a>
                                        <input type="checkbox" className="custom-checkbox small" onChange={(e) => selectDeselectSource(subItem)} checked={subItem.isSelected} readOnly={subItem.isDisabled} disabled={subItem.isDisabled} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div>
            {
                dataSourceOrderedMenu && dataSourceOrderedMenu.map((menu, i) => {
                    return (
                        <React.Fragment key={`data-source-element-${i}`}>
                            <div className="sidebar-heading mt-2 mb-1 d-flex justify-content-between align-items-center">
                                {menu.name}
                                {
                                    menu.items.length === 0 && menu.accessDenied ? (
                                        <div className="align-self-center">
                                            <label className="bl-switch mb-0">
                                                <input type="checkbox" disabled />
                                                <span className="bl-slider round"></span>
                                            </label>
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div>
                                {
                                    menu.items.length === 0 && menu.accessDenied ? (
                                        <li className="nav-item mb-0">
                                            <p className="nav-link py-1 w-100 mb-0 d-flex align-items-center">
                                                {
                                                    menu.icon ? <img src={menu.icon} height="18px" /> : <i className={`fa ${menu.fa_icon}`} aria-hidden="true"></i>
                                                }
                                                <span className="ml-2">{menu.accessDenied}</span>
                                            </p>
                                        </li>
                                    ) : null
                                }
                                {
                                    menu.items.map((item, j) => {
                                        return (
                                            <li key={`data-source-list-${i}${j}`} className={`nav-item ${item.list && item.list.length ? 'px-3' : item.isActive ? 'd-flex justify-content-between' : 'disabled d-flex justify-content-between'}`}>
                                                {
                                                    item.list && item.list.length ? (dataSourceAccordionItem(item, i, j)) : (
                                                        <>
                                                            {
                                                                item.linkPreview ?
                                                                    <a href={item.linkPreview} target="_blank" className={`nav-link py-2 w-100 bl-limited-text ${textColor ? textColor : item.isActive && 'text-black'}`}>
                                                                        {
                                                                            item.icon ? <i className={`fa ${item.icon} ${textColor ? textColor : item.isActive && 'text-black'}`} aria-hidden="true"></i> : <img src={item.file_image} height="18px" />
                                                                        }
                                                                        <span className="ml-2">{convertCamelCaseToSeparateWords(item.title)}</span>
                                                                    </a> :
                                                                    <a className={`nav-link py-2 w-100 bl-limited-text ${textColor ? textColor : item.isActive && 'text-black'}`}>
                                                                        {
                                                                            item.icon ? <i className={`fa ${item.icon} ${textColor ? textColor : item.isActive && 'text-black'}`} aria-hidden="true"></i> : <img src={item.file_image} height="18px" />
                                                                        }
                                                                        <span className="ml-2">{convertCamelCaseToSeparateWords(item.title)}</span>
                                                                    </a>
                                                            }
                                                            <input type="checkbox" className="custom-checkbox small mr-3" onChange={(e) => selectDeselectSource(item)} defaultChecked={item.isSelected} readOnly={item.isDisabled} disabled={item.isDisabled} />
                                                        </>
                                                    )
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </div>
                        </React.Fragment>
                    )
                })
            }
            {dataSourceSFModalContent()}
        </div>

    )
}