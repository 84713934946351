import React, { useState } from 'react';

export const ClientAppContext = React.createContext();

export const ClientAppContextProvider = ({ children }) => {
    const [clientAppName, setClientAppName] = useState('');
    return (
        <ClientAppContext.Provider value={{ clientAppName, setClientAppName}}>
            {children}
        </ClientAppContext.Provider>
    );
};
