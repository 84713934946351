import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import { PortcoContext } from "../../../../services/context/portcoContext";
import homeLabel from "../../../../utils/property-file/componentOverview.json";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import listViewLoader from "./../../../../assets/img/loading-animation.gif";
import { toastError, toastSuccess, toastWarning } from "../../../../services/notifyService";
import { deletePortcoCompaniesFilesService, getPortcoCompaniesFilesService, getUploadedPortcoCompaniesFilesProgressStatusService, updatePortcoCompaniesFilesService, uploadPortcoCompaniesFilesService } from "../../../../services/companyService";
import { ProgressBar } from "primereact/progressbar";
import imagePlaceholder from "./../../../../assets/img/image-placeholder.jpeg";
import { convertFileSizeInFormat, getFileIconUrl, getFriendlyType } from "../../../../utils/validation";
import Select, { components } from 'react-select';
import CreatableSelect from "react-select/creatable";
import { createTagsService, getAllTagsService } from "../../../../services/speakerBriefService";

export const PortCoCompanyDocumentsAndFilesComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()
    const fileInputRef = useRef(null);
    const maxSize = 100 * 1024 * 1024; // 100MB

    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        RECOMMEND: ""
    });
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        if (homeLabel) {
            const items = homeLabel.find(l => l.FEATURE === "PORTCO").documents;
            setLabels(items)
        }
    }, [homeLabel])
    useEffect(() => {
        setIsLinkView(false)
        getAllTag(1)
        if (portcoCompanyInfo) {
            setUploadedFiles([])
            getDocsAndFiles(1)
        }
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/data-library/documents-files`, menuName: "Documents", isActive: true, hasSubMenu: true }
        ])
    }, [location.pathname, portcoCompanyInfo])

    const [isLoading, setIsLoading] = useState(false)
    const [isFilesSelected, setIsFilesSelected] = useState(false)

    const [uploadedFiles, setUploadedFiles] = useState([])
    const [filter, setFilter] = useState([
        { name: 'All', val: 'recent', isActive: true },
        { name: 'Word', val: 'Trending_Companies', isActive: false },
        { name: 'Excel', val: 'In_Current_News', isActive: false },
        { name: 'PowerPoint', val: 'Unicorns', isActive: false },
        { name: 'PDF', val: 'pdf', isActive: false },
        { name: 'Link', val: 'link', isActive: false }
    ]);
    const [isLinkView, setIsLinkView] = useState(false)
    const [selectedFile, setSelectedFile] = useState({
        id: null, fileName: ""
    })
    const [intervalDelay, setIntervalDelay] = useState(5000);
    const [options, setOptions] = useState([]);
    const [formattedTags, setFormattedTags] = useState([]);

    const dropdownStyle = {
        groupHeading: (styles) => ({
            ...styles,
            padding: '5px 10px',
            margin: 0,
        }),
        control: (styles, { isFocused }) => ({
            ...styles,
            padding: "0px 0px",
            minHeight: "auto",
            boxShadow: isFocused ? "0 0 0 .2rem #4e73df40" : "none",
            border: '1px solid #d1d3e2',
            borderRadius: '3px',
            "&:hover": {
                border: 'default',
            },
        }),
        option: (styles) => ({
            ...styles,
        }),
        multiValue: (styles) => ({
            ...styles,
            margin: '2px',
        }),
        input: (styles) => ({
            ...styles,
            width: "100px",
            padding: "0"
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            display: "none",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
        }),
    };

    useEffect(() => {
        setFormattedTags()
        const ids = selectedFile.tagIds || [];
        const labels = selectedFile.tagNames || [];
        const data = ids.map((id, index) => ({
            value: Number(id),
            label: labels[index] || ''
        }));
        setFormattedTags(data);
    }, [selectedFile]);


    const formatTagData = (data) => {
        return data.map((item) => {
            const result = {
                label: item?.name != '' ? item.name : "Empty tag name",
                value: Number(item?.tagId),
            };
            if (item.children && item.children.length > 0) {
                result.options = item.children.map((child) => ({
                    label: child?.name != '' ? child.name : '',
                    value: Number(child?.tagId)
                }));
            }
            return result;
        });
    }

    const getAllTag = async (page) => {
        try {
            const limit = 20;
            const obj = {
                page: page, limit: limit
            }
            const resp = await getAllTagsService(obj);
            if (resp && resp.success) {
                const data = formatTagData(resp.data);
                setOptions((prev) => [...prev, ...data])
                if (data.length >= limit) {
                    const newPage = page + 1;
                    getAllTag(newPage)
                }
            }
        }
        catch (e) {
            toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
        }
        finally {

        }
    }

    const selectFilterItem = (e, indx) => {
        e.preventDefault();
        const filterList = filter.map(f => {
            f.isActive = false;
            return f;
        })
        filterList[indx].isActive = true;
        setFilter(filterList)
        setIsLinkView(filterList[indx].val === 'link')
    }

    const handleMissingImage = (e) => {
        e.target.src = imagePlaceholder;
    }

    const arrangeColumnValue = (item, column, rowIndex) => {
        const compDate = item.createdOn ? item.createdOn : moment();
        const diffInHours = moment().diff(moment(compDate), 'hours', true)
        const fileStatus = !!(item.status && (item.status.toLowerCase() === 'queued' || item.status.toLowerCase() === 'processing'));
        if (Math.abs(diffInHours) < 1 && fileStatus && rowIndex >= 2 && rowIndex <= 6) {
            // item.TotalPages = 11; item.PagesProcessed = 3;
            const percentage = Math.round(item.pagesProcessed * 100 / item.totalPages);
            if (column === 'SIZE') {
                return (
                    <div className="bl-progress-cell bl-inset-auto w-50 bl-top-25">
                        <div className="font-smaller text-muted">{item.fileStatus ? item.fileStatus : 'Processing file now....'} {item.totalPages ? `(Pages Processed: ${item.pagesProcessed} out of ${item.totalPages})` : null}</div>
                        <ProgressBar value={percentage ?? 5} color="green" height={'8px'} />
                    </div>
                );
            }
            else if (['UPLOADED_ON', 'UPLOADED_BY', 'STATUS', 'TOTAL_PAGE', 'PROCESSED'].includes(column)) {
                return null;
            }
        }
        else {
            if (column === 'NAME') {
                const isImage = item.fileType.includes('image');
                const fileArray = item.fileName.split('____');
                const fileName = fileArray && fileArray.length > 1 ? fileArray[1] : item.fileName;
                return (
                    <div className="d-flex justify-content-between bl-truncate-line-1">
                        <div className="d-flex align-self-center">
                            {
                                !isLinkView ? <img className="mr-2 rounded align-self-center" height="40px" alt="File icon" onError={handleMissingImage}
                                    src={isImage && item.sasUrl ? item.sasUrl : getFileIconUrl(item.fileName, item.fileType)}
                                /> : null
                            }

                            {/* <h6 className="mb-0 mr-2 align-self-center">{item.sasUrl ? <a href={item.sasUrl} target="_blank">{fileName}</a> : fileName}</h6>
                            <div className="mb-0 align-self-center font-small">
                                Tags: (No tags found) 
                                {item.tags && (
                                    <>
                                        Tags: {item.tags.split(',').map(tag => tag.trim()).slice(0, 2).join(', ')}
                                        {item.tags.split(',').map(tag => tag.trim()).length > 2 ? ` +${item.tags.split(',').map(tag => tag.trim()).length - 2} more` : ''}
                                    </>
                                )}
                            </div> */}
                            <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 font-small bl-truncate-line-1">{item.sasUrl ? <a href={item.sasUrl} target="_blank">{fileName}</a> : fileName}</h6>
                                <div className="mb-0 font-small text-muted bl-truncate-line-1">
                                    Tags: ({(item?.tagNames)?.join(', ') || 'No tags found'})
                                    {/* {item.tags && (
                                    <>
                                        Tags: {item.tags.split(',').map(tag => tag.trim()).slice(0, 2).join(', ')}
                                        {item.tags.split(',').map(tag => tag.trim()).length > 2 ? ` +${item.tags.split(',').map(tag => tag.trim()).length - 2} more` : ''}
                                    </>
                                )} */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex align-self-center">
                            <div>
                                <button className="btn btn-link p-0 openAIModal mr-2" id="companydatasourcefile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="companydatasourcefile">
                                    <button className="dropdown-item bl_H1_Color pl-3">
                                        <i className="fa fa-eye fa-sm mr-2"></i>
                                        View AI Summary
                                    </button>
                                    <button className="dropdown-item bl_H1_Color pl-3">
                                        <i className="fa fa-trash fa-sm mr-2"></i>
                                        Delete
                                    </button>
                                    <button className="dropdown-item bl_H1_Color pl-3">
                                        <i className="fa fa-download fa-sm mr-2"></i>
                                        Download
                                    </button>
                                    <button className="dropdown-item bl_H1_Color pl-3">
                                        <i className="fa fa-share fa-sm mr-2"></i>
                                        Share
                                    </button>
                                    <div className="dropdown-divider m-0"></div>
                                    <button className="dropdown-item text-danger pl-3">
                                        <img className="img-fluid mr-2" />
                                        Ask a question
                                    </button>
                                </div>
                            </div>
    
                            <button className="btn btn-link p-0 mr-2">
                                <i className="fa fa-share-square-o" aria-hidden="true"></i>
                            </button>
                            <button className="btn btn-link p-0 mr-2">
                                <i className="fa fa-star-o" aria-hidden="true"></i>
                            </button>
    
                        </div> */}
                    </div>
                )
            }
            else if (column === 'UPLOADED_ON') {
                return (
                    moment(item.createdOn).format('MMM DD, YYYY hh:mm A')
                )
            }
            else if (column === 'UPLOADED_BY') {
                return (
                    ''
                )
            }
            else if (column === 'STATUS') {
                return <span className={`badge badge-pill badge-${getStatusColor(item.status)} text-uppercase`}>{item.status}</span>
            }
            else if (column === "SIZE") {
                return item.fileSize ? `${item.fileSize} MB` : '';
            }
            else if (column === "TOTAL_PAGE") {
                return item.totalPages;
            }
            else if (column === "PROCESSED") {
                return item.pagesProcessed;
            }
            else if (column === "ACTION") {
                return (
                    <div className="d-flex">
                        {/* <a onClick={(e) => showFilePage(e, project)} title="View File"><i className="fa fa-file-pdf-o mr-2 bl-db-datable-icon" aria-hidden="true"></i></a> */}
                        <a onClick={(e) => getFileInfo(e, item)} data-toggle="modal" data-target="#editFileModal" title="Edit File" className="mr-3"><i className="fa fa-edit bl-db-datable-icon" aria-hidden="true"></i></a>
                        <a onClick={(e) => getFileInfo(e, item)} data-toggle="modal" data-target="#deleteFileModal" title="Delete File"><i className="fa fa-trash bl-db-datable-icon" aria-hidden="true"></i></a>
                    </div>
                )
            }
        }

    }

    const getFileInfo = (e, file) => {
        e.preventDefault();
        const fileArray = file.fileName.split('____');
        const fileName = fileArray && fileArray.length > 1 ? fileArray[1] : file.fileName;
        setSelectedFile({ ...file, fileName })
    }

    const getStatusColor = (status) => {
        let classColor = "primary"
        if (status) {
            classColor = status === "processing" ? "warning" : status === "completed" ? "success" : status === "failed" ? "danger" : "primary";
        }
        return classColor;
    }

    const getDocsAndFiles = async (page) => {
        try {
            if (page === 1) {
                setIsLoading(true)
            }
            const limit = 20;
            const obj = {
                page: page, limit: 20
            }
            const resp = await getPortcoCompaniesFilesService(portcoCompanyInfo.companyId, obj);
            if (resp && resp.success) {
                const files = resp?.data?.files || [];
                setUploadedFiles(file => [...file, ...files])
                if (files.length >= limit) {
                    const newPage = page + 1;
                    getDocsAndFiles(newPage)
                }
            }
        }
        catch (e) {
            toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const fetchUploadedFilesState = async () => {
            let allInfoAvailable = true;
            const updatedFilesList = await Promise.all(
                uploadedFiles && uploadedFiles.map(async (item) => {
                    const compDate = item.createdOn ? item.createdOn : moment();
                    const diffInHours = moment().diff(moment(compDate), 'hours', true)
                    const fileStatus = !!(item.status && (item.status.toLowerCase() === 'queued' || item.status.toLowerCase() === 'processing'));
                    if (Math.abs(diffInHours) < 1 && fileStatus) {
                        allInfoAvailable = false;
                        try {
                            const info = await getFilesProcessingStatus(item.id);
                            if (info) {
                                return { ...item, ...info };
                            }
                        } catch (error) {
                            console.error(`Error fetching info for ID: ${item.id}`, error);
                        }
                    }
                    return item;
                })
            );
            setUploadedFiles(updatedFilesList)
        };

        const intervalId = setInterval(() => {
            fetchUploadedFilesState();
        }, intervalDelay);

        return () => clearInterval(intervalId);
    }, [intervalDelay, uploadedFiles]);

    const getFilesProcessingStatus = async (fileId) => {
        try {
            const resp = await getUploadedPortcoCompaniesFilesProgressStatusService(portcoCompanyInfo.companyId, fileId);
            if (resp && resp.success) {
                return resp.data;
            }
            return null
        }
        catch (e) {

        }
    }

    const uploadPortcoDocs = async (e) => {
        e.preventDefault();
        const pageLoaderContainer = document.getElementById('pageLoaderContainer');
        try {
            if (selectedFiles.length === 0) {
                return toastWarning("Please select files before uploading.");
            }
            if (pageLoaderContainer) {
                pageLoaderContainer.style.display = "flex";
            }
            const formData = new FormData();
            selectedFiles.forEach((file, index) => {
                const tags = file.selectiveTags.map(t => t.label);
                formData.append("files", file.file);
                formData.append(`tags_${index}`, JSON.stringify(tags));
            });
            const resp = await uploadPortcoCompaniesFilesService(portcoCompanyInfo.companyId, formData);
            if (resp && resp.success) {
                toastSuccess("Files uploaded successfully!")
                closeSelectedFilesSection(e)
                setUploadedFiles([])
                getDocsAndFiles(1)
                setOptions([]);
                getAllTag(1);
            }
            else {
                toastError(resp?.message || resp?.data?.message || "An error occurred. Please try again!")
            }
        }
        catch (e) {
            toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
        }
        finally {
            if (pageLoaderContainer) {
                pageLoaderContainer.style.display = "none";
            }
        }
    }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const maxFiles = 5;
        const allowedExtensions = /\.(pdf|ppt|pptx|doc|docx|xls|xlsx)$/i;
        // const allowedExtensions = /\.(pdf|ppt|pptx|doc|docx|xls|xlsx|jpeg|jpg|png|gif|bmp|svg|webp)$/i;

        if (newFiles.length + selectedFiles.length > maxFiles) {
            closeSelectedFilesSection(event);
            return toastWarning("You can upload a maximum of 5 files.");
        }

        let totalFileSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);

        const validFiles = newFiles.filter((file) => {
            if (!(allowedExtensions.test(file.name))) {
                toastWarning(`Invalid file type: ${file.name}. Allowed types: PDF, PPT, DOC, & XLS.`);
                return false;
            }
            // if (!(allowedExtensions.test(file.name) || file.type.startsWith("image/"))) {
            //     toastWarning(`Invalid file type: ${file.name}. Allowed types: PDF, PPT, DOC, XLS, and Images.`);
            //     return false;
            // }

            if (totalFileSize + file.size > maxSize) {
                toastWarning(`Total file size should not exceed ${convertFileSizeInFormat(maxSize)}!`);
                return false;
            }

            totalFileSize += file.size;
            return true;
        });
        const filesWithExtras = validFiles.map((file) => ({
            file,
            selectiveTags: []
        }));

        if (filesWithExtras.length > 0) {
            setIsFilesSelected(true);
            setSelectedFiles((prevFiles) => [...prevFiles, ...filesWithExtras]);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const closeSelectedFilesSection = (e) => {
        e.preventDefault();
        setIsFilesSelected(false)
        setSelectedFiles([])
        window.$("#portcoDocUpload").val('')
    }

    const handleCreateTag = async (inputValue, fileIndex = null) => {
        const uniqueId = Math.floor(Date.now() / 1000) + Math.floor(Math.random() * 100);
        const newTag = { value: uniqueId, label: inputValue };
        setOptions(prevOptions => {
            const exists = prevOptions.some(option => option.value === newTag.value);
            return exists ? prevOptions : [...prevOptions, newTag];
        });
        if (fileIndex != null) {
            setSelectedFiles(prevFiles => {
                return prevFiles.map((file, index) => {
                    if (index === fileIndex) {
                        const updatedSelectiveTags = file.selectiveTags ? [...file.selectiveTags, newTag] : [newTag];
                        return {
                            ...file,
                            selectiveTags: updatedSelectiveTags
                        };
                    }
                    return file;
                });
            });
        }
        else {
            setFormattedTags([...formattedTags, newTag]);
            setSelectedFile((prev) => {
                const parsedTagIds = (prev?.tagIds || "[]");
                const updatedTagIds = [...parsedTagIds, uniqueId];
                const updatedTags = [...prev?.tagNames, inputValue];
                return {
                    ...prev,
                    tagIds: updatedTagIds,
                    tagNames: updatedTags
                };
            });
        }

    };

    const handleTagChange = (option, index) => {
        const files = [...selectedFiles];
        const tagValues = option.map(item => item);
        files[index].selectiveTags = tagValues;
        setSelectedFiles(files)
    };

    const handleGroupSelect = (group, index) => {
        const files = [...selectedFiles];
        const groupOptions = group.options;
        const allValues = files[index].selectiveTags.map((opt) => opt.value);
        const isAllSelected = groupOptions.every((opt) => allValues.includes(opt.value));
        const newSelectedTags = isAllSelected
            ? files[index].selectiveTags.filter((opt) => !groupOptions.some((gOpt) => gOpt.value === opt.value))
            : [...files[index].selectiveTags, ...groupOptions.filter((opt) => !allValues.includes(opt.value))];
        files[index].selectiveTags = newSelectedTags;
        setSelectedFiles(files);
    };

    const updateTags = (data) => {
        if (!data) {
            setFormattedTags([]);
            setSelectedFile(prev => ({
                ...prev,
                tagIds: [],
                tagNames: []
            }));
            return;
        }
        setFormattedTags(data);
        setSelectedFile(prev => ({
            ...prev,
            tagIds: data.map(tag => tag.value),
            tagNames: data.map(tag => tag.label)
        }));
    };

    const handleUpdateGroupSelect = (group) => {
        const isOptionSelected = (option) => formattedTags.some(tag => tag.value === option.value);
        if (!group.options || group.options.length === 0) {
            const newTag = { value: group.value, label: group.label };

            if (!isOptionSelected(newTag)) {
                setFormattedTags(prev => [...prev, newTag]);
                setSelectedFile(prev => ({
                    ...prev,
                    tagIds: [...prev.tagIds, newTag.value],
                    tagNames: [...prev.tagNames, newTag.label]
                }));
            }
            return;
        }
        const newOptions = group.options.filter(item => !isOptionSelected(item));
        if (newOptions.length > 0) {
            setFormattedTags(prev => [...prev, ...newOptions]);
            setSelectedFile(prev => ({
                ...prev,
                tagIds: [...prev.tagIds, ...newOptions.map(item => item.value)],
                tagNames: [...prev.tagNames, ...newOptions.map(item => item.label)]
            }));
        }
    };

    const selectedFilesPreviewContent = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <table className="table table-borderless text-left">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th width="40%">Tags (Optional)</th>
                                <th>File Type</th>
                                <th>File Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedFiles && selectedFiles.map((file, i) => {
                                    return (
                                        <tr key={`doc-upload-${i}`}>
                                            <td className="bl-truncate-line-1">{file.file.name}</td>
                                            <td>
                                                <CreatableSelect
                                                    isMulti
                                                    options={options}
                                                    value={file.selectiveTags}
                                                    onChange={(e) => {
                                                        handleTagChange(e, i)
                                                    }}
                                                    onCreateOption={(e) => {
                                                        handleCreateTag(e, i)
                                                    }}
                                                    formatGroupLabel={(e) => {
                                                        return (
                                                            <div
                                                                className="cp font-weight-bold primary "
                                                                onClick={() => handleGroupSelect(e, i)}>
                                                                {e.label}
                                                            </div>
                                                        )
                                                    }}
                                                    styles={dropdownStyle}

                                                    isClearable
                                                    placeholder="Search or Create New Tag"
                                                    formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                                />
                                            </td>
                                            <td className="text-muted text-nowrap">{getFriendlyType(file.file.type)}</td>
                                            <td>{convertFileSizeInFormat(file.file.size)}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <div className="d-inline-flex flex-row">
                        <button className="btn btn-outline-primary btn-sm mr-2 px-5" onClick={(e) => closeSelectedFilesSection(e)} type="button">Cancel</button>
                        <button type="button" className="btn btn-primary btn-sm px-5" onClick={uploadPortcoDocs}>Proceed</button>
                    </div>
                </div>
            </div>
        )
    }

    const deletePortcoCompaniesFiles = async (e) => {
        e.preventDefault();
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const resp = await deletePortcoCompaniesFilesService(portcoCompanyInfo.companyId, selectedFile.id);
            if (resp && resp.success) {
                setUploadedFiles([])
                getDocsAndFiles(1)
                window.$("#deleteFileModal").modal('hide');
                toastSuccess("Files removed successfully!")
            }
        }
        catch (e) {
            toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
        }
        finally {
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const deleteFileModalContent = () => {
        return (
            <div className="modal fade" id="deleteFileModal" tabIndex="-1" role="dialog" aria-labelledby="deleteFileModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteFileModalLabel">Delete File</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure, you want to remove <b>"{selectedFile.fileName}"</b>?
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button className="btn btn-primary btn-sm" onClick={(e) => deletePortcoCompaniesFiles(e)}>Yes, Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const editPortcoFiles = async (e) => {
        try {
            e.preventDefault();
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }
            const tagNames = { tagNames: selectedFile?.tagNames };
            const response = await updatePortcoCompaniesFilesService(portcoCompanyInfo?.companyId, selectedFile?.id, tagNames);
            if (response && response.success) {
                window.$("#editFileModal").modal('hide');
                getDocsAndFiles(1);
                setOptions([]);
                getAllTag(1);
                setUploadedFiles([]);
                toastSuccess(response?.data?.success ? response.data.message : "Files updated successfully!");
            }
        }
        catch (error) {
            if (error.status < 500) {
                toastError(e?.data?.message || e?.response?.message || e?.message || "An error occurred. Please try again!")
            }
        }
        finally {
            const pageLoader = document.getElementById('pageLoaderContainer');
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    }

    const editFileModalContent = () => {
        return (
            <div>
                <div className="modal fade" id="editFileModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="mt-0">
                                    <h6 className="m-0">{selectedFile?.fileName}</h6>
                                    <span className="text-muted"><b>({selectedFile?.fileSize ? `${selectedFile?.fileSize} MB` : ''})</b></span>
                                </div>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <form onSubmit={(e) => editPortcoFiles(e)}>
                                <div className="modal-body">
                                        <div className="form-group">
                                            <label>Tags (Optional)</label>
                                            <CreatableSelect
                                                isMulti
                                                options={options}
                                                value={formattedTags}
                                                onCreateOption={(e) => {
                                                    handleCreateTag(e)
                                                }}
                                                onChange={updateTags}
                                                formatGroupLabel={(e) => {
                                                    return (
                                                        <div
                                                            className="cp font-weight-bold primary "
                                                            onClick={() => handleUpdateGroupSelect(e)}>
                                                            {e.label}
                                                        </div>
                                                    )
                                                }}
                                                styles={dropdownStyle}
                                                isClearable
                                                placeholder="Search or Create New Tag"
                                                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                            />
                                        </div>
                                </div>
                                <div className="modal-footer">
                                        <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                        <button type="submit" className="btn btn-primary btn-sm">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <section className="mb-3">
                            <div className="media px-3 py-2 bl-hero-background rounded">
                                <div className="media-body mr-5 ">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0 text-muted">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-0">
                                        <div className="card text-center bl-db-fileupload-border">
                                            <div className="card-body">
                                                {
                                                    isFilesSelected ? selectedFilesPreviewContent() : (
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <img
                                                                    src="https://icons.veryicon.com/png/o/business/cloud-computing-background-management-icon/upload-to-folder.png"
                                                                    height="80px"
                                                                    alt="Upload"
                                                                />
                                                                <p className="card-text">Click below to select file(s) from this device (upto {convertFileSizeInFormat(maxSize)} at a time)</p>
                                                                <button className="btn btn-outline-primary btn-sm text-nowrap" onClick={triggerFileInput}><i className="fa fa-upload mr-2" aria-hidden="true"></i> Upload Now</button>
                                                                <input type="file" id="portcoDocUpload" ref={fileInputRef}
                                                                    accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx"
                                                                    multiple
                                                                    onChange={handleFileChange}
                                                                    className="d-none"
                                                                />
                                                            </div>
                                                            <div className="col-12 bl-justify-items-center pt-4 text-left">
                                                                <div className="form-group mb-0">
                                                                    <ul className="font-small mb-0 pb-0">
                                                                        <li className="text-muted font-italic">Supported file formats - PDF, PPTX, DOCX, XLS</li>
                                                                        <li className="text-muted font-italic">File processing usually takes around 5-8 minutes, depending on the file size or number of pages</li>
                                                                        <li className="text-muted font-italic">You can monitor the upload and processing progress from the table below</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <div>
                                        <h6 className="mb-3">All Documents & Files</h6>
                                        <div className="pb-2 d-none">
                                            {
                                                filter.map((f, i) => {
                                                    return (
                                                        <span key={`risk-cate-filter-${i + 1}`} onClick={(e) => selectFilterItem(e, i)} className={`badge bl-chip-btn cp bl-sb-datatableTabs ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        isLoading ? <div className="col text-center">
                                            <img className="bl-list-loader" src={listViewLoader} />
                                        </div> : (
                                            <DataTable value={uploadedFiles} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm">
                                                <Column field="fileName" header="Name" body={(e) => arrangeColumnValue(e, 'NAME', 1)} sortable></Column>
                                                <Column field="fileSize" header="File Size" className="text-center" body={(e) => arrangeColumnValue(e, 'SIZE', 2)} sortable></Column>
                                                <Column field="totalPages" header="Total Pages" className="text-center" body={(e) => arrangeColumnValue(e, 'TOTAL_PAGE', 3)} sortable></Column>
                                                <Column field="pagesProcessed" header="Pages Processed" className="text-center" body={(e) => arrangeColumnValue(e, 'PROCESSED', 4)} sortable></Column>
                                                <Column field="createdOn" header="Uploaded On" body={(e) => arrangeColumnValue(e, 'UPLOADED_ON', 5)} sortable></Column>
                                                <Column field="status" header="Status" className="text-center" body={(e) => arrangeColumnValue(e, 'STATUS', 6)} sortable></Column>
                                                <Column field="" header="" body={(e) => arrangeColumnValue(e, 'ACTION', 7)}></Column>
                                                {/* <Column field="" header="Uploaded By" body={(e) => arrangeColumnValue(e, 'UPLOADED_BY')} sortable></Column> */}
                                            </DataTable>
                                        )
                                    }

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {deleteFileModalContent()}
            {editFileModalContent()}
        </div>
    )
}