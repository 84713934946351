import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getConnection, searchConnection } from "../../services/ConnectorService";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { SkeletonCard } from "./SkeletonCard";
import homeLabel from "./../../utils/property-file/componentOverview.json";

export const ConnectionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext);

    const [connectors, setConnectors] = useState([]);
    const [filteredConnectors, setFilteredConnectors] = useState([]);
    const [searchEntity, setSearchEntity] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });
    const observer = useRef();

    // Set breadcrumb on mount
    useEffect(() => {
        setBreadcrumbMenu([{ slug: '/connector/connection', menuName: 'Connection', isActive: true, hasSubMenu: true }]);
    }, [location.pathname]);

    // Reset pagination when search input changes
    useEffect(() => {
        setPage(1);
        setHasMore(true);
        if (searchEntity) {
            fetchSearchResults(1);
        } else {
            fetchData(1);
        }
    }, [searchEntity]);

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "CONNECTIONS").items;
        setLabels(items)
    }, [homeLabel])

    // Fetch data when page changes, but only if not searching
    useEffect(() => {
        if (!searchEntity && hasMore) {
            fetchData(page);
        }
    }, [page]);

    // Fetch Data Function
    const fetchData = async (pageNum) => {
        if (loading || !hasMore) return;

        try {
            setLoading(true);
            const response = await getConnection(pageNum, limit);

            if (response.status) {
                setConnectors((prev) => (pageNum === 1 ? response.data : [...prev, ...response.data]));
                setFilteredConnectors((prev) => (pageNum === 1 ? response.data : [...prev, ...response.data]));
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Search Results Function
    const fetchSearchResults = async (pageNum) => {
        try {
            setLoading(true);
            const response = await searchConnection(pageNum, searchEntity.toLowerCase());

            if (response.status && response.data.length > 0) {
                setFilteredConnectors(response.data);
            } else {
                setFilteredConnectors([]);
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error searching connections", error);
        } finally {
            setLoading(false);
        }
    };

    // Last element reference for Intersection Observer
    const lastConnectorRef = useCallback(node => {
        if (loading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setPage(prev => prev + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12">

                        <section className="mb-3">
                            <div className="media px-3 py-2 bl-hero-background rounded">
                                <div className="media-body mr-5 align-self-center">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0 text-muted">{labels.WELCOME_DESC}</p>
                                    <button
                                        onClick={() => navigate("/connector/connection/add")}
                                        type="button"
                                        className="btn btn-primary btn-sm my-3"
                                    >
                                        <i className="fa fa-fw fa-plus-circle" aria-hidden="true"></i>
                                        Create New Connection
                                    </button>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>

                        {/* Search and Filter Section */}
                        <section className="mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-2 px-2">
                                <h2 className="text-lg font-medium">List of Connections</h2>
                                <div className="input-group w-100" style={{ maxWidth: "350px" }}>
                                    <input
                                        type="text"
                                        value={searchEntity}
                                        onChange={(e) => setSearchEntity(e.target.value)}
                                        className="form-control form-control-md"
                                        placeholder="Search by Connection Name"
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Connections Section */}
                        <section className="mb-3">
                            {(searchEntity ? filteredConnectors : connectors).map((connector, connectorIndex) => (
                                <div key={connector.connectorId} className="mb-4">
                                    <h5 className="p-1 fw-bold fs-4">{connector.connectorName}</h5>
                                    <div className="row g-4 px-2">
                                        {connector?.connections?.map((connection, connectionIndex) => {
                                            {console.log(connection)}
                                            // Check if this is the last connection in the last connector
                                            const isLastItem =
                                                connectorIndex === (searchEntity ? filteredConnectors : connectors).length - 1 &&
                                                connectionIndex === connector.connections.length - 1;

                                            return (
                                                <div
                                                    key={connection.connectionId}
                                                    className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4"
                                                    onClick={() => navigate(`/connector/connection/${connection.connectionId}`)}
                                                    ref={isLastItem ? lastConnectorRef : null}
                                                >
                                                    <div className="card p-3 shadow-sm h-100 d-flex flex-column" style={{ cursor: "pointer" }}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center mr-2">
                                                                    <h5 className="mb-0 text-break">{connection.connectionName}</h5>
                                                                </div>
                                                            </div>
                                                            <label style={{
                                                                position: "relative",
                                                                display: "inline-block",
                                                                width: "50px",
                                                                height: "24px"
                                                            }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={!connection?.status}
                                                                    disabled
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0
                                                                    }}
                                                                />
                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        cursor: "not-allowed",
                                                                        top: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                        backgroundColor: !connection?.status ? "#ccc" : "#2196F3",
                                                                        transition: "0.4s",
                                                                        borderRadius: "34px"
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            position: "absolute",
                                                                            content: '""',
                                                                            height: "18px",
                                                                            width: "18px",
                                                                            left: "4px",
                                                                            bottom: "3px",
                                                                            backgroundColor: "white",
                                                                            transition: "0.4s",
                                                                            borderRadius: "50%",
                                                                            transform: !connection?.status ? "translateX(0)" : "translateX(26px)"
                                                                        }}
                                                                    ></span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="d-flex mt-2 justify-content-between">
                                                            <p className="mb-0">Type: {connector?.connectorType}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </section>
                        {/* Hero Section */}
                        {(!loading && (searchEntity ? filteredConnectors : connectors).length === 0) ? (
                            <div className="row justify-content-md-center">
                            <div className="col-8">
                                <section className="text-center p-1">
                                    <div className="bl-noAgent-Img"></div>
                                    <h4 className="m-1">{labels.EMPTYTITLE}</h4>
                                    <p className="m-1 text-muted">{labels.EMPTYDESC}</p>
                                </section>
                            </div>
                        </div>
                        ) : ""}
                        {/* Loading State */}
                        {loading && (
                            <div className="mt-4">
                                <div className="row">
                                    {Array.from({ length: 10 }).map((_, index) => (
                                        <div key={index} className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <SkeletonCard />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};