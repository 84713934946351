import React, { useContext, useEffect, useState } from 'react'
import BreadcrumbContext from '../../services/context/Breadcrumb/breadcrumbContext';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { toastError, toastInfo, toastSuccess } from "../../services/notifyService";
import taskIcon from "./../../assets/icons/taskIcon.svg";
import axios from 'axios';
import { editConnection, getConnector, getOneConnection } from '../../services/ConnectorService';

export const EditConnection = () => {
    const nav = useNavigate();
    const { id } = useParams(); // Get connection ID from URL
    const [connectors, setConnectors] = useState([]);
    const [selectedConnectorId, setSelectedConnectorId] = useState('');
    const [connectionName, setConnectionName] = useState('');
    const [status, setStatus] = useState(true);

    const [configMaps, setConfigMaps] = useState({});
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/connection', menuName: 'Connection', isActive: true, hasSubMenu: true },
            { slug: `/connector/Connection/edit/${id}`, menuName: "Edit", isActive: false, hasSubMenu: true }
        ])
    }, [location.pathname, id])

    useEffect(() => {
        fetchConnectors();
        fetchConnectionDetails();
    }, []);

    const fetchConnectors = async () => {
        try {
            const response = await getConnector(1,20);
            if (response.status) {
                setConnectors(response.data);
            } else {
                toastError("Failed to fetch connectors");
            }
        } catch (error) {
            toastError("Error fetching connectors");
        }
    };

    const fetchConnectionDetails = async () => {
        try {
            const response = await getOneConnection(id)
            
            if (response.status) {
                const connectionData = response.data[0];
                setConnectionName(connectionData.connectionName);
                setSelectedConnectorId(connectionData?.connectorId?.toString());
                setConfigMaps(connectionData.connectionConfigMap);
                setStatus(connectionData.status);
            } else {
                toastError("Failed to fetch connection details");
                nav('/connector/connection');
            }
        } catch (error) {
            console.log(error);
            toastError("Error fetching connection details");
            // nav('/connector/connection');
        }
    };

    const handleConnectorChange = (e) => {
        const connectorId = e.target.value;
        setSelectedConnectorId(connectorId);
        
        if (connectorId) {
            const selectedConnector = connectors.find(c => c.id === parseInt(connectorId));
            if (selectedConnector && selectedConnector.connectorConfigMap) {
                setConfigMaps({...selectedConnector.connectorConfigMap});
            } else {
                setConfigMaps({});
            }
        } else {
            setConfigMaps({});
        }
    };

    const handleValueChange = (key, value) => {
        setConfigMaps(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const updateConnection = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (!selectedConnectorId) {
                toastInfo("Please select a connector");
                return;
            }
            if (!connectionName.trim()) {
                toastInfo("Please enter connection name");
                return;
            }
            
            // Check if all config values are filled
            const emptyValues = Object.entries(configMaps).filter(([_, value]) => !value.trim());
            if (emptyValues.length > 0) {
                toastInfo(`Please enter values for all configuration fields`);
                return;
            }

            if (pageLoader) {
                pageLoader.style.display = 'flex';
            }

            const obj = {
                connectionName: connectionName.trim(),
                connectorId: parseInt(selectedConnectorId),
                connectionConfigMap: configMaps,
                status:status?1:0
            };

            const response = await editConnection(id,obj)
           

            if (response.status) {
                toastSuccess("Connection updated successfully");
                nav('/connector/connection');
            } else {
                toastError("Failed to update connection");
            }

            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
        catch (e) {
            toastError("Something went wrong, Please try again!");
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }
    };
    const handleStatusToggle = () => {
        setStatus(!status);
    };
    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section>
                    <div className="d-sm-flex align-items-center justify-content-between bl-action-btn-sec">
                        <div className="d-inline-flex flex-row bl-btn-grp-fixed">
                            <button 
                                className="d-sm-inline-block btn btn-sm btn-outline-primary mr-2" 
                                onClick={() => nav('/connector/connection')}
                            >
                                <i className="fa fa-ban mr-1" aria-hidden="true"></i> Cancel
                            </button>
                            <button 
                                className="d-sm-inline-block btn btn-sm btn-primary mr-2" 
                                onClick={updateConnection}
                            >
                                <i className="fa fa-floppy-o mr-1" aria-hidden="true"></i> Update
                            </button>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="row" id="connection">
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div className="sidebar-brand-icon mb-2">
                                <img src={taskIcon} height="70px" alt="Task Icon" />
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <h4 className="h4 text-gray-800 text-center">Edit Connection</h4>
                        </div>
                        <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                            <p>Update your connection details below</p>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="row">
                        <div className="col-xl-8 col-md-8 card p-0 mb-4 offset-2">
                            <div className="card-body">
                                <h5 className="card-title">Connection Details</h5>
                                <p className="card-text text-muted">Update the connection details as needed</p>
                            </div>
                            <table className="table mb-0">
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <div className="text-small pl-2">Connector Name *</div>
                                        </td>
                                        <td width="70%">
                                            <select 
                                                className="form-control" 
                                                value={selectedConnectorId}
                                                onChange={handleConnectorChange}
                                            >
                                                <option value="">Select Connector</option>
                                                {connectors.map((connector) => (
                                                    <option key={connector.id} value={connector.id}>
                                                        {connector.connectorName}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="text-small pl-2">Connection Name *</div>
                                        </td>
                                        <td>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                value={connectionName}
                                                onChange={(e) => setConnectionName(e.target.value)}
                                                placeholder="Enter connection name"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="text-small pl-2">Status</div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="custom-control custom-switch" >
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="statusToggle"
                                                        checked={status}
                                                        onChange={handleStatusToggle}
                                                        style={{ width: "2.5rem", height: "1.5rem", cursor: "pointer" }}
                                                    />
                                                    <label
                                                        className="custom-control-label fw-bold ms-2"
                                                        htmlFor="statusToggle"
                                                        style={{ color: status ? "green" : "red" }}
                                                    >
                                                        {status ? "Enabled" : "Disabled"}
                                                    </label>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    {Object.keys(configMaps).length > 0 && (
                                        <tr>
                                            <td colSpan="2">
                                                <div className="card-body p-1">
                                                    <h6 className="mb-2 ">Configuration Map</h6>
                                                    <div className="row ">
                                                        {Object.entries(configMaps).map(([key, value], index) => (
                                                            <div key={index} className="col-12 mb-2">
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control mr-2"
                                                                        value={key}
                                                                        disabled
                                                                        style={{ width: '30%', backgroundColor: '#f8f9fa' }}
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={value}
                                                                        onChange={(e) => handleValueChange(key, e.target.value)}
                                                                        placeholder="Enter value"
                                                                        style={{ width: '70%' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};