import React from 'react'

export const SkeletonCard = () => {
    const skeletonStyle = {
      animation: "pulse 1.2s infinite ease-in-out",
      backgroundColor: "#f0f0f0", // Lighter gray
    };
  
    return (
      <div className="card p-3 shadow-sm border-0" >
        {/* Header Section */}
        <div className="d-flex align-items-center mb-2">
          
          <div className="ms-2 flex-grow-1">
            <div
              className=""
              style={{
                width: "75%",
                height: "12px",
                borderRadius: "4px",
                ...skeletonStyle,
              }}
            ></div>
          </div>
        </div>
  
        {/* Thumbnail Section */}
        <div
          className=""
          style={{
            width: "100%",
            height: "80px",
            borderRadius: "4px",
            ...skeletonStyle,
          }}
        ></div>
  
  
        {/* Keyframes for Pulsating Effect */}
        <style>
          {`
            @keyframes pulse {
              0% { background-color: #e0e0e0; }
              50% { background-color: #f5f5f5; }
              100% { background-color: #e0e0e0; }
            }
          `}
        </style>
      </div>
    );
  };