import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { EmptyCardsContent } from "../../Common/emptyCard";
import { PortcoCompanyTitle } from "./Common/companyTitle";
import { PortcoContext } from "../../../services/context/portcoContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


export const PortcoCompanyPeopleComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/people`, menuName: "People", isActive: true, hasSubMenu: true }
        ]);
    }, [location.pathname, portcoCompanyInfo])
    const sources = portcoCompanyInfo?.People?.map(person => person?.source).filter(Boolean);
    const uniqueSources = sources?.length > 0 ? [...new Set(sources)].join(', ') : '';

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <PortcoCompanyTitle />
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card shadow-sm">
                                <div className="card-header pl-3 d-flex">
                                    <h6 className="mb-0">People Information</h6>
                                    {
                                        uniqueSources.length > 0 ? (
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{uniqueSources}</span>
                                        ) : ('')
                                    }
                                </div>
                                <div className="card-body p-0">
                                    {portcoCompanyInfo?.People.length > 0 ?
                                        <div>
                                            <DataTable value={portcoCompanyInfo?.People} rowClassName={"bl-source-overview-list position-relative"} className="cp table table-sm" selectionMode="single" scrollable scrollHeight="300px">
                                                <Column field="fullName" header="Name" sortable></Column>
                                                <Column field="role" header="Role" className="text-center" sortable></Column>
                                                <Column field="email" header="Email" className="text-center" sortable></Column>
                                                <Column field="phone" header="Phone" className="text-center" sortable></Column>
                                                <Column field="gender" header="Gender" className="text-center" sortable></Column>
                                                <Column field="dateOfBirth" header="DOB" className="text-center" sortable></Column>
                                                <Column field="address" header="Address" className="text-center" sortable></Column>
                                                <Column
                                                    field="linkedinUrl"
                                                    header="Social"
                                                    sortable
                                                    body={(rowData) => (
                                                        <div className="d-flex justify-content-center gap-2">
                                                            {rowData.linkedinProfile && (
                                                                <a href={`${rowData?.linkedinProfile?.startsWith("https") ? "" : "https://"}${rowData?.linkedinProfile}`}
                                                                    target="_blank" rel="noopener noreferrer" className="cp">
                                                                    <i className="fa fa-fw fa-linkedin" aria-hidden="true"></i>
                                                                </a>
                                                            )}
                                                            {rowData.twitterUrl && (
                                                                <a href={`${rowData?.twitterUrl?.startsWith("https") ? "" : "https://"}${rowData?.twitterUrl}`}
                                                                    target="_blank" rel="noopener noreferrer" className="cp">
                                                                    <i className="fa fa-fw fa-twitter" aria-hidden="true"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    )}
                                                    className="text-center"
                                                />
                                            </DataTable>
                                        </div>
                                        : <div className="mt-3">
                                         <EmptyCardsContent title="No record found" buttonView={false} />
                                         </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}