import React, { useContext, useEffect } from "react";
import categoryJson from "./../../../data/Analytics/reportCategory.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";


export const AnalyticsManageReportCategoryComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/manage-report-category', menuName: 'Manage Report Category', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Published Report Categories</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm">{'Create New Category'}</button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        {
                            categoryJson.map((rc, i) => {
                                return (
                                    <div key={`analytics-man-rep-cat-${i+1}`} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card shadow-sm mb-3">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="mr-3 align-self-center">
                                                    <i className={`fa fa-fw ${rc.icon}`} aria-hidden="true"></i>
                                                    </div>
                                                    <div>
                                                        <h6 className="mb-0">{rc.categoryName}</h6>
                                                        <p className="text-gray font-small mb-0">My Reports</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        
                    </div>
                </section>
            </div>
        </div>
    )
}