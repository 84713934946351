import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import axios from "axios";
import { SkeletonCard } from "./SkeletonCard";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { useLocation } from "react-router-dom";
import { getConnector, searchConnectorApi } from "../../services/ConnectorService";
import homeLabel from "./../../utils/property-file/componentOverview.json";

export const ConnectorComponent = () => {
    const [connectors, setConnectors] = useState([]);
    const [filteredConnectors, setFilteredConnectors] = useState([]);
    const [searchEntity, setSearchEntity] = useState("");
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext);
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: [],
        RECOMMEND: ""
    });
    const location = useLocation();
    const observer = useRef();

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/connector', menuName: 'Connectors', isActive: true, hasSubMenu: true },
        ]);
    }, [location.pathname]);

    useEffect(() => {
        setPage(1);
        setHasMore(true);
        if (searchEntity) {
            fetchSearchResults(1);
        } else {
            fetchData(1);
        }
    }, [searchEntity]);

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "CONNECTOR").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        if (!searchEntity && hasMore) {
            fetchData(page);
        }
    }, [page]);

    const fetchData = async (currentPage) => {
        if (!hasMore) return;
        try {
            setLoading(true);
            const response = await getConnector(currentPage, limit);
            if (response.status && response.data.length > 0) {
                setConnectors(prev => currentPage === 1 ? response.data : [...prev, ...response.data]);
                setFilteredConnectors(prev => currentPage === 1 ? response.data : [...prev, ...response.data]);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSearchResults = async (currentPage) => {
        try {
            setLoading(true);
            const response = await searchConnectorApi(currentPage, searchEntity);
            if (response.data.length > 0) {
                setFilteredConnectors(response.data);
            } else {
                setFilteredConnectors([]);
                setHasMore(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const lastConnectorRef = useCallback(node => {
        if (loading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setPage(prev => prev + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const groupedConnectors = filteredConnectors.reduce((acc, connector) => {
        const { connectorGroup } = connector;
        if (!acc[connectorGroup]) acc[connectorGroup] = [];
        acc[connectorGroup].push(connector);
        return acc;
    }, {});

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12">
                        {/* Hero Section */}
                        <section className="mb-3">
                            <div className="media px-3 py-2 bl-hero-background rounded">
                                <div className="media-body mr-5 align-self-center">
                                    <h4 className="mt-0 mb-2">{labels.WELCOME}</h4>
                                    <p className="mb-0 text-muted">{labels.WELCOME_DESC}</p>
                                </div>
                                <img className="bl-overview-img ml-3 align-self-center" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>

                        {/* Search and Filter Section */}
                        <section className="mb-3">
                            <div className="d-flex justify-content-between align-items-center mb-2 px-2">
                                <h2 className="text-lg font-medium">List of Connectors</h2>
                                <div className="input-group w-100" style={{ maxWidth: "350px" }}>
                                    <input
                                        type="text"
                                        value={searchEntity}
                                        onChange={(e) => setSearchEntity(e.target.value)}
                                        className="form-control form-control-md"
                                        placeholder="Search by Connector Name"
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </section>

                        {/* Connector Cards Section */}
                        <section className="mb-3">
                            {Object.keys(groupedConnectors).map((group, index) => (
                                <div key={index} className="mb-4">
                                    <h5 className="p-1 fw-bold fs-4">{group}</h5>
                                    <div className="row g-4 px-2">
                                        {groupedConnectors[group].map((connector, idx) => (
                                            <div
                                                key={connector.id}
                                                className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4"
                                                ref={idx === groupedConnectors[group].length - 1 ? lastConnectorRef : null}
                                            >
                                                <div className="card p-3 shadow-sm h-100 d-flex flex-column">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center">
                                                            {connector.connectorImage && (
                                                                <img
                                                                    src={connector.connectorImage}
                                                                    alt={connector.connectorName}
                                                                    className="me-3"
                                                                    style={{ width: "30px", height: "30px" }}
                                                                />
                                                            )}
                                                            <h5 className="ml-2 mb-0 text-break">{connector.connectorName}</h5>
                                                        </div>
                                                        <label style={{
                                                            position: "relative",
                                                            display: "inline-block",
                                                            width: "50px",
                                                            height: "24px"
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={!connector.disabled}
                                                                disabled
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    cursor: "not-allowed",
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: connector.disabled ? "#ccc" : "#2196F3",
                                                                    transition: "0.4s",
                                                                    borderRadius: "34px"
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        content: '""',
                                                                        height: "18px",
                                                                        width: "18px",
                                                                        left: "4px",
                                                                        bottom: "3px",
                                                                        backgroundColor: "white",
                                                                        transition: "0.4s",
                                                                        borderRadius: "50%",
                                                                        transform: connector.disabled ? "translateX(0)" : "translateX(26px)"
                                                                    }}
                                                                ></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="flex-grow-1 mt-2">
                                                        <p className="mb-0 ">{connector.connectorDesc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </section>
                        {/* Hero Section */}
                        {(!loading && (searchEntity ? filteredConnectors : connectors).length === 0) ? (
                            <div className="row justify-content-md-center">
                                <div className="col-8">
                                    <section className="text-center p-1">
                                        <div className="bl-noAgent-Img"></div>
                                        <h4 className="m-1">{labels.EMPTYTITLE}</h4>
                                        <p className="m-1 text-muted">{labels.EMPTYDESC}</p>
                                    </section>
                                </div>
                            </div>
                        ) : ""}
                        {/* Loading State */}
                        {loading && (
                            <div className="mt-4">
                                <div className="row">
                                    {Array.from({ length: 11 }).map((_, index) => (
                                        <div key={index} className="col-sm-6 col-md-6 col-lg-4 mb-4">
                                            <SkeletonCard />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
