import React, { useEffect, useState } from "react";
import ThemeContext from "./themeContext";
import { getAppBehavior } from "../../authService";
import { appendThemeProperty } from "../../commonService";

const ThemeProvider = ({ children }) => {

    const [theme, setTheme] = useState(null);
    const [appLogo, setAppLogo] = useState("");
    const [loaderIcon, setLoaderIcon] = useState("");
    const [isOpenedInIframe, setIsOpenedInIframe] = useState(false);

    const applyThemeProperty = (newTheme = null) => {
        if (newTheme) {
            appendThemeProperty(newTheme, true, true);
            setTheme(newTheme);
        } else {
            const appConfigBehavior = getAppBehavior();
            if (appConfigBehavior && appConfigBehavior !== "undefined") {
                const behaviorInfo = JSON.parse(appConfigBehavior);
                if (behaviorInfo) {
                    const themeInfo = JSON.parse(behaviorInfo.behaviour);
                    appendThemeProperty(themeInfo, true, true);
                    setTheme(themeInfo);
                    setAppLogo(behaviorInfo.logo);
                }
            }
        }
    };

    useEffect(() => {
        applyThemeProperty();
    }, []);

    return (
        <ThemeContext.Provider value={{
            theme,
            setTheme,
            appLogo,
            setAppLogo,
            isOpenedInIframe,
            setIsOpenedInIframe,
            applyThemeProperty,
            loaderIcon, setLoaderIcon
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
