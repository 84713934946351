import React, { useContext, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { getEmbedTokenService, getReportDetailsService } from "../../services/reportService";
import { useLocation, useNavigate } from "react-router-dom";
import reportLabel from "./../../utils/property-file/reports/report-lebel.json";
import emptyIcon from "./../../assets/img/report-icon.svg";
import unauthorizeIcon from "./../../assets/img/unauthorize.png";
import { appendThemeProperty, capitalizeFirstLetter } from "../../services/commonService";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import reportJson from "./../../data/Analytics/reports.json";
import { getAppBehavior } from "../../services/authService";
import ThemeContext from "../../services/context/Theme/themeContext";

export const AnalyticsViewReportComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { breadcrumbMenu, setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { theme } = useContext(ThemeContext)
    const location = useLocation();
    const nav = useNavigate();

    useEffect(() => {
        const reportCatName = getReportType();
        const modules = reportCatName.split(',');
        setPowerBiReport('')
        setEmbedUrl(null)
        let breadcrumbItems = [];
        if (modules.length === 3) {
            breadcrumbItems = [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}`, menuName: capitalizeFirstLetter(modules[0]), isActive: false, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}/${modules[1]}`, menuName: capitalizeFirstLetter(modules[1]), isActive: false, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}/${modules[1]}/${modules[2]}`, menuName: capitalizeFirstLetter(modules[2]), isActive: true, hasSubMenu: true }
            ]
        }
        else {
            breadcrumbItems = [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}`, menuName: capitalizeFirstLetter(modules[0]), isActive: false, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}/${modules[1]}`, menuName: capitalizeFirstLetter(modules[1]), isActive: true, hasSubMenu: true }
            ]
        }
        setBreadcrumbMenu(breadcrumbItems)
    }, [location])

    const [embedToken, setEmbedToken] = useState(null);
    const [embedUrl, setEmbedUrl] = useState(null);
    const [reportDetails, setReportDetails] = useState({ reportId: null });
    const [reportPage, setReportPage] = useState([]);
    const [reportPageName, setReportPageName] = useState("");
    const [reportNotFound, setReportNotFound] = useState(false);
    const [authorizedReport, setAuthorizeReport] = useState(true);
    const [activePath] = useState(useLocation().pathname)
    const [powerBiReport, setPowerBiReport] = useState('')

    const getReportType = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports') {
                return pathArray.length === 5 ? `${pathArray[3]}, ${pathArray[4]}` : pathArray.length === 6 ? `${pathArray[3]}, ${pathArray[4]}, ${pathArray[5]}` : pathArray[2]
            }
            return pathArray[2]
        }
        return ''
    }

    useEffect(() => {
        setReportNotFound(false);
        setAuthorizeReport(true)
        let paths = activePath.split('/');
        let id = paths[paths.length - 1]
        getReportInfo(id)
    }, [activePath])

    useEffect(() => {
        const activeReportPage = reportPage.find(p=>p.isActive);
        if(activeReportPage && activeReportPage.displayName){
            setReportPageName(activeReportPage.displayName)
        }
    }, [reportPage])

    const getReportInfo = async (id) => {
        const reportInfo = await getReportDetailsService(id);
        if (reportInfo.success) {
            if (reportInfo.data && reportInfo.data.reportId) {
                setReportNotFound(false);
                setAuthorizeReport(true);
                const report = reportInfo.data;
                setReportDetails(report)

                const groupId = report.powerBiGroupId;
                const reportId = report.powerBiReportId;
                setPowerBiReport(report)
                if (reportId && groupId) {
                    const url = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`;
                    // const url = "https://app.powerbi.com/rdlEmbed?reportId=9b262196-0869-4293-a722-6d46f9fa64f2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d"
                    setEmbedUrl(url)
                }
                const res = await getEmbedTokenService(groupId, reportId);
                console.log("token serv --->", res);
                if (res && res.statusCode && res.statusCode === 403) {
                    setAuthorizeReport(false)
                }
                else if (res && res.data && res.data.token) {
                    setEmbedUrl(res.data.report.embedUrl)
                    setAuthorizeReport(true)
                    setEmbedToken(res.data.token)
                    // setEmbedToken("H4sIAAAAAAAEAC1UxQ7z6BV9l3_rSmaqNIvEFDPjzswxw-eq7950NLrbcxcH__PHSsEwpcWff__Z87t-QpJfNT-cUIkbeMLW1Qk59UCJDNROOYV-HoZhzsY53blbP_yXXrKokseCNc9E8z8NMZ2miZaxzyDwqvO4BPCSYDmP2AzT2vlgUfFwCFB47ayKRy40eu59hwRHxJLH1zty2nMxN6cvit6BAHp9v5e-kJu8yAHtx8jWoohZXD1IjZG7-0Gob1Xt1GKNDK2JBNG2tKqq1I_X6vf2HT9j6dDM9p4hORyejjNtog59MjU9BGIyXIb7EBo77yZJ2k30co3OXa_mmfNhHUie-F2jbd5nISwhgyYcp4Esjn83r4jq6MSbRhEbrGASX24_EBtTE6TjdERNwwhkMM2wWIaiZuv2hnkOcNruKW-igIuRDISmpXsMwpPaj6oosNyxZlockFIrueylGY0nbJ9bwF88QcGzV1fe70wQWEQ-ntRqa8DVMnV60UiSmyJ21hu3wxN6hNLC1GxCzCaHDlAX1jmkWkFEah1bqjyEIcATVpusdMO1zjbGMjZJzLboxBLi9rev7i8X6ZdmWom9UgcuoHqsS_jvqLNmp3WXTow-vBQBhyQL3ubKB3jgcAyDHwkE1h9OrvYyiF9ZEy4kqrPNWThKAF3sA7obtzl_0XTzGMDHaCd8ijO2ltou7J6PfbZ2XYsd-FFS-tqeFWi-0Jau0mbopGq4usy8SOPlZgVjBAvys5yYaQoTybWctuMAj90vSiYlwrdTmYphmwOyjEqN2sXyHCSuzKkeh42kooI1nN3CEEyWPV8dS_IgcALCUBSe1EqAKESAYImk7GCnltUjSyWtLOuFQHHgvenHhrVX-Om8YzXfbpm3Ja9GEeKI0eo8RC1pmCKY2_ZVfjGqH-iexjlDQ_uvv_786w-3gnmf1BL8arY51LsKIR27iJtRIPQm9WEg-TooGZcpYfGVjE-6UihLK_1ETLlcSUKETokX0lG0CUjnb4f1VUuZrGx4ngSGFf3BUHxnKc4qMLOod9hd1AeHesyEPWXSS_aM2kAziV10TWgSZ5_WzECrXNkYQ1cfkwI6VzqbHLX9ij6xyBRyvo-hxg4fJ-B1QljGW99ZcNonc3x-rbq-A3rIGHo0u7gn29z7Or-n_Tw-S14KoT2uL_4o4TVNBUJgl-HOttFXYbLP9HGDoprGPLiMjddsX3bQdUFSkX2TTW8QPaQ99Rv5ReEBPGvVc0tegLyBauQUeC5yy0fciQtvIBjxTYBUFSHT0Ilh_8gM5qZc5eCn8ozxBAbGpqObuL2taaQUc6n_Rrlt_U33Yy1_MHnUr7ymugsHTqPP08WpA-4xA7keDAa7ucQmOVTrpj2rnrq9rDBfEWBlku9K8cDEPTYBLnxTPGJQ7mdmfy-PuJQ1VNlOVViyBrkJx8ZGoOEWNz6DJPvwVuBYYqPjrDMZnCCE3cBZJ-wfhzX9JaLDc2VyJAzRT7Ux7FWtF7L91tirU9kmtiYwBwWfp_O61x4mYElOhWCDr05I1zayJcAs8dByp_a9wB4IonGVHko951oVGOXSxPcoVQZFHrX2DbXE4wwvOzh927uAol_s2KCJ72R6gUngwJNZPodFBdnsQHI0KmI_eR7eKnyxIm00Vk23stLl-wEPG4bl2f155yqy88X_zfjv_wAQTPwmWgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImV4cCI6MTcyNTQ2MTkzNSwiYWxsb3dBY2Nlc3NPdmVyUHVibGljSW50ZXJuZXQiOnRydWV9")
                }
            }
            else {
                setReportNotFound(true);
            }
        }
        else {
            setAuthorizeReport(false)
        }



    }

    const applyTheme = () => {
        const appThemes = getAppBehavior();
        if (theme) {
            appendThemeProperty(theme)
        }
        else if (appThemes && appThemes !== "undefined") {
            const appTheme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (appTheme) {
                appendThemeProperty(appTheme)
            }
        }
    }

    const getReportPages = async () => {
        try {
            const pages = await window.report.getPages();
            setReportPage(pages)
            applyTheme()
        }
        catch (e) {
        }
    }

    const setReportActivatePage = async (i) => {
        try {
            const pages = await window.report.getPages();
            await pages[i].setActive();
            getReportPages()
        }
        catch (errors) { }

    }

    const powerBiEmbedConfig = () => {
        return (<PowerBIEmbed
            embedConfig={{
                type: 'report',
                id: reportDetails.powerBiReportId,
                embedUrl: embedUrl,
                accessToken: embedToken,
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        },
                        pageNavigation: false
                    }
                }
            }}

            eventHandlers={
                new Map([
                    ['loaded', function (loaded) {

                    }],
                    ['rendered', async function (report) { getReportPages(); }],
                    ['error', function (event) { }]
                ])
            }

            cssClassName={"bl-power-bi-report"}

            getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
            }}
        />)
    }

    const getEmptyScreen = () => {
        return (
            <>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mt-5">
                    <div className="sidebar-brand-icon mb-2">
                        <img src={emptyIcon} height="80px" alt="empty-icon" />
                    </div>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <h1 className="h3 text-gray-800">{reportLabel.NOT_FOUND.REPO_TITLE}</h1>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <p>{reportLabel.NOT_FOUND.REPO_DESC}</p>
                </div>
            </>
        )
    }

    const unauthorizeReportScreen = () => {
        return (
            <>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mt-5">
                    <div className="sidebar-brand-icon mb-2">
                        <img src={unauthorizeIcon} height="80px" alt="empty-icon" />
                    </div>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <h1 className="h3 text-gray-800">{reportLabel.UNAUTHORIZE.TITLE}</h1>
                </div>
                <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                    <p>{reportLabel.UNAUTHORIZE.DESC}</p>
                </div>
            </>
        )
    }

    const goBackToPreviousPage = () => {
        if(breadcrumbMenu && breadcrumbMenu.length >= 2){
            nav(breadcrumbMenu[breadcrumbMenu.length-2].slug)
        }
    }

    return (
        <>
            <div id="content">
                <nav id="componentHeader" className="navbar navbar-expand navbar-light bg-white fixed-top mb-0 pt-0 blHeaderMargin bl-component-header blCollapsedHeaderMargin">
                    <ul className="navbar-nav mr-auto align-items-end">
                        <li className="nav-item">
                            <a className="nav-link" onClick={() => goBackToPreviousPage()}><i className="fa fa-chevron-left border p-1" aria-hidden="true"></i></a>
                        </li>
                        {
                            reportPage.map((p, i) => {
                                if (p.visibility === 0) {
                                    return (
                                        <li key={`report-nav-${i}`} className={`nav-item ${p.isActive && 'active'}`}>
                                            <a onClick={() => setReportActivatePage(i)} className={`nav-link`}>{p.displayName}</a>
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                </nav>
                <div className="container-fluid mt-5">

                    <div className="d-sm-flex align-items-center justify-content-between mb-2">
                        {
                            !reportNotFound && authorizedReport ? (
                                <>
                                    <div>
                                        <h6>{reportPageName}</h6>
                                    </div>

                                    <div className="d-inline-flex flex-row">
                                        <div className="p-2 d-none"><a className="d-none d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-download fa-sm text-white-100"></i></a></div>
                                        <div className="p-2"><a onClick={() => window.report.print()} className="d-none d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-print fa-sm text-white-100"></i></a></div>
                                        <div className="p-2"><a onClick={() => window.report.fullscreen()} className="d-none d-sm-inline-block btn btn-sm btn-outline-primary"><i className="fa fa-expand fa-sm text-white-100"></i></a></div>
                                    </div>
                                </>
                            ) : null
                        }

                    </div>

                </div>
                {
                    !reportNotFound ? authorizedReport ? (
                        <div>
                            <div id="powerbi-report-section" className="embed-responsive embed-responsive-16by9 h-99">
                                {powerBiReport && powerBiEmbedConfig()}
                            </div>
                        </div>
                    ) : (unauthorizeReportScreen()) : (
                        getEmptyScreen()
                    )
                }
            </div>
        </>
    )
}













// import React, { useContext, useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import report1 from "./../../assets/img/report/Rep1_Main.png";
// import report2 from "./../../assets/img/report/Rep2_Main.png";
// import report3 from "./../../assets/img/report/Rep3_Main.png";
// import report4 from "./../../assets/img/report/Rep4_Main.png";
// import report5 from "./../../assets/img/report/Rep5_Main.png";
// import report6 from "./../../assets/img/report/Rep6_Main.png";
// import report7 from "./../../assets/img/report/Rep7_Main.png";
// import reportJson from "./../../data/Analytics/reports.json";
// import SearchContext from "../../services/context/Search/searchContext";
// import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";




// export const AnalyticsViewReportComponentV1 = () => {
//     window.scrollTo(0, 0);

//     const { globalSearch } = useContext(SearchContext)
//     const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
//     const location = useLocation();

//     useEffect(() => {
//         const reportCatName = getReportType();
//         const modules = reportCatName.split(',');
//         setBreadcrumbMenu(
//             [
//                 { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
//                 { slug: `/analytics/reports/${modules[0]}`, menuName: capitalizeFirstLetter(modules[0]), isActive: false, hasSubMenu: true },
//                 { slug: `/analytics/reports/${modules[0]}/${modules[1]}`, menuName: capitalizeFirstLetter(modules[1]), isActive: false, hasSubMenu: true }
//             ]
//         )
//     }, [location])

//     const [reports, setReports] = useState({
//         reportName: ''
//     });
//     const [reportIcon, setReportIcon] = useState(report1);
//     const [reportIcons] = useState([
//         {
//             id: 1,
//             icon: report1
//         },
//         {
//             id: 2,
//             icon: report2
//         }, {
//             id: 3,
//             icon: report3
//         }, {
//             id: 4,
//             icon: report4
//         }, {
//             id: 5,
//             icon: report5
//         }, {
//             id: 6,
//             icon: report6
//         }, {
//             id: 7,
//             icon: report7
//         }, {
//             id: 8,
//             icon: report1
//         },
//         {
//             id: 9,
//             icon: report2
//         }, {
//             id: 10,
//             icon: report3
//         }, {
//             id: 11,
//             icon: report4
//         }, {
//             id: 12,
//             icon: report5
//         }, {
//             id: 13,
//             icon: report6
//         }, {
//             id: 14,
//             icon: report7
//         }
//     ])

//     const getReportType = () => {
//         let pathArray = location.pathname.split('/');
//         if (pathArray && pathArray.length > 2) {
//             if (pathArray[2] === 'reports' && pathArray.length === 5) {
//                 let report = reportJson.find(r => r.reportId == pathArray[4]);
//                 let icon = reportIcons.find(r => r.id == pathArray[4]);
//                 if (icon) {
//                     setReportIcon(icon.icon)
//                 }
//                 else { setReportIcon(report1) }
//                 if (report) {
//                     setReports(report)
//                     return `${report.catSlug}, ${report.reportName}`
//                 }
//                 return `${pathArray[3]}, ${pathArray[4]}`
//             }
//             return pathArray[2]
//         }
//         return ''
//     }

//     return (
//         <div className="content">
//             <div className="container-fluid pt-3">
//                 <section className="mb-3 d-flex justify-content-between">
//                     <div>
//                         <h6>{reports.reportName}</h6>
//                     </div>
//                     <div>
//                         <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-area-chart" aria-hidden="true"></i>{'Edit Report'}</button>
//                         <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-download" aria-hidden="true"></i>{'Download Report'}</button>
//                         <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i></button>
//                     </div>
//                 </section>
//                 <section className="mb-3">
//                     <img className="img-fluid card-img-top" src={reportIcon} alt="reportIcon" />
//                 </section>
//             </div>
//         </div>
//     )
// }