import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { PortcoContext } from "../../../../services/context/portcoContext";


export const PortcoCompanyTitle = () => {

    const { portcoCompanyInfo } = useContext(PortcoContext)
    const [companyInfo, setCompanyInfo] = useState({
        companyName: null, lastModifiedUserName: null, modifiedBy: null, createdUserName: null, createdBy: null, createdOn: null,
        lastModifiedDate: null
    })

    useEffect(() => {
        setCompanyInfo(portcoCompanyInfo)
    }, [portcoCompanyInfo])
    
    const formatDateToLocal = (date) => {
        if (date) {
            date = date.toLowerCase().includes('z') ? date : `${date}Z`;
            return moment(date).format('MMM DD, YYYY hh:mm A')
        }
        return '';
    }
    const defaultLogo = "https://cdn-icons-png.freepik.com/256/16802/16802634.png?semt=ais_hybrid";
    return (
        <section className="mb-3">
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
                <div className="sidebar-brand-icon mb-0">
                    <img src={companyInfo?.logo?companyInfo?.logo : defaultLogo} height="70px" className="rounded-circle" 
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultLogo }}
                    />
                </div>
            </div>
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-0">
                <h4 className="h4 text-gray-800 text-center pb-0 mb-0">{companyInfo?.companyName}</h4>
                {companyInfo?.linkedinUrl && (
                        <span>
                            <a href={`${companyInfo?.linkedinUrl?.startsWith("https") ? "" : "https://"}${companyInfo?.linkedinUrl}`} target="_blank" className="cp">
                                <i className="fa fa-fw fa-linkedin" aria-hidden="true"></i>
                            </a>
                        </span>
                )}
            </div>
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center mb-0">
                <p className="bl-truncate-line-1 font-small">
                    {/* {companyInfo?.modifiedBy ? <span><span className="text-muted mr-2">Last Modified By</span>{companyInfo?.modifiedBy?.userName} on {formatDateToLocal(companyInfo?.modifiedOn)} <span className="text-muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span></span> : null}
                    <span className="text-muted mr-2">Created By</span>{companyInfo?.createdBy?.userName} on {formatDateToLocal(companyInfo?.createdOn)} */}
                    </p>
            </div>
        </section>
    )
}