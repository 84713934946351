import apiInstance from "./serviceMaster";

export const generateCIMId = (id) => {
    if (id && parseInt(id) > 0) {
        if (parseInt(id) < 10) {
            return `cim-00${id}`
        }
        else if (parseInt(id) >= 10 && parseInt(id) < 100) {
            return `cim-0${id}`
        }
        else {
            return `cim-${id}`
        }
    }

}

const summarizerProjectURL = `summarizer/projects`;
const summarizerTemplateURL = `summarizer/templates`;

export const getSummarizerKPIsService = () => {
    try{
        return apiInstance.get(`${'summarizer'}/kpis`)
    }
    catch(e){
        return e;
    }   
}

export const getProjectInfoService = (id) => {
    try{
        return apiInstance.get(`${summarizerProjectURL}/${id}`)
    }
    catch(e){
        return e;
    }
}

export const getProjectProcessingStatusService = (id) => {
    try{
        return apiInstance.get(`${summarizerProjectURL}/${id}/processing-status`)
    }
    catch(e){
        return e;
    }
}

export const getProjectSectionsService = (id) => {
    try{
        return apiInstance.get(`${summarizerProjectURL}/${id}/categories`)
    }
    catch(e){
        return e;
    }
}

export const getProjectSummaryInfoService = async(projectId, summaryId) => {
    try{
        return await apiInstance.get(`${summarizerProjectURL}/${projectId}/categories/${summaryId}`);
    }
    catch(e){
        return e;
    }
}

export const analyzeNewProjectService = (obj) => {
    try{
        return apiInstance.post(`${summarizerProjectURL}`, obj)
    }
    catch(e){
        return e;
    }
}

export const getAnalyzedProjectListService = async (obj) => {
    try{
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return await apiInstance.get(`${summarizerProjectURL}${queryParams}`)
    }
    catch(e){
        return e;
    }
}

export const removeProjectService = (id) => {
    try{
        return apiInstance.delete(`${summarizerProjectURL}/${id}`)
    }
    catch(e){
        return e;
    }
}

export const regenerateProjectSummaryService = (id) => {
    try{
        return apiInstance.get(`${summarizerProjectURL}/${id}/regenerate`)
    }
    catch(e){
        return e;
    }
}

export const updateProjectSummaryService = (obj, projectId, categoryId) => {
    try{
        return apiInstance.put(`${summarizerProjectURL}/${projectId}/categories/${categoryId}/subcategories`, obj)
    }
    catch(e){
        return e;
    }
}

export const updateProjectStatusService = (id, obj) => {
    try{
        return apiInstance.patch(`${summarizerProjectURL}/${id}`, obj)
    }
    catch(e){
        return e;
    }
}

export const updateBasicProjectInfoService = (id, obj) => {
    try{
        return apiInstance.patch(`${summarizerProjectURL}/${id}/company`, obj)
    }
    catch(e){
        return e;
    }
}

export const getTemplateListService = () => {
    try{
        return apiInstance.get(`${summarizerTemplateURL}`)
    }
    catch(e){
        return e;
    }
}

export const getTemplateInfoService = (id) => {
    try{
        return apiInstance.get(`${summarizerTemplateURL}/${id}`)
    }
    catch(e){
        return e;
    }
}

export const updateTemplateSubCategoryPromptsService = (obj, id) => {
    try{
        return apiInstance.patch(`${summarizerTemplateURL}/${id}/prompts`, obj)
    }
    catch(e){
        return e;
    }
}

export const addNewTemplateService = (obj) => {
    try {
        return apiInstance.post('/summarizer/templates', obj);
    }
    catch(e){
        return e;
    }
}

export const addNewSubSectionService = (templateId, sectionId, obj) => {
    try{
        return apiInstance.post(`${summarizerTemplateURL}/${templateId}/category/${sectionId}/subsection`, obj)
    }
    catch(e){
        return e;
    }
}

export const removeCustomSubSectionService = (templateId, sectionId, subSectionId) => {
    try{
        return apiInstance.delete(`${summarizerTemplateURL}/${templateId}/category/${sectionId}/subsection/${subSectionId}`)
    }
    catch(e){
        return e;
    }
}