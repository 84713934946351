import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import BreadcrumbContext from '../services/context/Breadcrumb/breadcrumbContext';
import { getAppUrlService } from '../services/clientAppsService';
import { ClientAppContext } from '../services/context/clientAppContext';
import { toastError, toastWarning } from '../services/notifyService';

export default function LboModel() {
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const [appUrl, setAppUrl] = useState('');
    const { clientAppName } = useContext(ClientAppContext)

    useEffect(() => {
        setBreadcrumbMenu([{ slug: '', menuName: 'LBO Model', isActive: true, hasSubMenu: false }])
    }, [location.pathname]);

    useEffect(()=>{
        if (clientAppName.length > 0) {
            getAppUrl()
        }
    },[clientAppName])

    useEffect(() => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        if (isLoading && pageLoader) {
            pageLoader.style.display = 'flex';
        } else if (pageLoader) {
            pageLoader.style.display = 'none';
        }
    }, [isLoading]);

    const getAppUrl = async () => {
        try { 
            const response = await getAppUrlService(clientAppName);
            if (response?.success && response?.data) {
                if (response?.data?.length == 0){
                    setIsLoading(false);
                    return toastWarning('Unable to load app')
                }
                const appData = response?.data?.find(item => item?.appUrl);
                const url = `${appData?.appUrl}#toolbar=0`;
                setAppUrl(url);
            }
        }
        catch (e) {
            setAppUrl('');
            setIsLoading(false);
            if (e.status < 500 ){
                toastError(e?.message ? e?.message:'Unable to load app');
            }
        }
    }
    return (<iframe src={appUrl} width="100%" className="bl-kairos-content-height border-0" onLoad={() => setIsLoading(false)}></iframe>);
}
