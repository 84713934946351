import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { PortcoCompanyTitle } from "./Common/companyTitle";
import { PortcoContext } from "../../../services/context/portcoContext";
import { EmptyCardsContent } from "../../Common/emptyCard";
import { CardDropdownContent } from "../../Common/cardDropdown";
import { GET_FINANCE_DATA_FOR_COMPANY } from "../../../services/graphql/queries";
import { useQuery } from "@apollo/client";


export const PortcoCompanyFinancialComponent = () => {

    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { portcoCompanyInfo } = useContext(PortcoContext)
    const location = useLocation()
    // const { loading, error, data } = useQuery(GET_FINANCE_DATA_FOR_COMPANY, {
    //     variables: { id: Number(portcoCompanyInfo?.companyId) },
    //     skip: !portcoCompanyInfo?.companyId,
    //     fetchPolicy: "network-only",
    // });
    // const [investment, setInvestment] = useState('');
    // useEffect(()=>{
    //     data?.companies?.map((item)=>{
    //         item?.Investment?.map((data) => {
    //             setInvestment(data)
    //         })
    //     })
    // },[data])

    // useEffect(()=>{
    //     console.log('Investment-->',investment);
    // },[investment])
    //  useEffect(() => {   
    //         const pageLoader = document.getElementById('pageLoaderContainer');
    //         if (loading && pageLoader) {
    //             pageLoader.style.display = 'flex';
    //         } else if (pageLoader) {
    //             pageLoader.style.display = 'none';
    //         }
    //     }, [loading]);

    useEffect(() => {
        setBreadcrumbMenu([
            { slug: '/portco-monitoring/all-companies', menuName: 'Portco Monitoring', isActive: false, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}`, menuName: portcoCompanyInfo?.companyName, isActive: true, hasSubMenu: true },
            { slug: `/portco-monitoring/companies/${portcoCompanyInfo?.companyId}/financial`, menuName: "Financial", isActive: true, hasSubMenu: true }
        ])
    }, [location.pathname, portcoCompanyInfo])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <PortcoCompanyTitle />
                </section>
                <section className="mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Income Statement'}</h6>
                                        </div>
                                        <CardDropdownContent cardId="financeIncomeCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No Income Statement Summary data found" buttonView={false} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Balance Sheet'}</h6>
                                        </div>
                                        <CardDropdownContent cardId="financeBalanceCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No Balance Sheet Summary data found" buttonView={false} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Cashflow'}</h6>
                                        </div>
                                        <CardDropdownContent cardId="financeCashCard" />
                                    </div>
                                    <div className="card-body">
                                        <EmptyCardsContent title="No Cash Flow Summary data found" buttonView={false} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}