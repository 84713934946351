import React, { useState, useEffect, useRef } from 'react';
import { marked } from 'marked';
import mermaid from 'mermaid';

marked.setOptions({
    gfm: true,
    tables: true,
});

mermaid.initialize({ startOnLoad: false });

const MarkdownMermaidRenderer = ({ markdownContext, contentId = "output" }) => {

    const [markdown, setMarkdown] = useState(null);

    const outputRef = useRef(null);

    useEffect(() => {
        if(markdownContext){
            setMarkdown(markdownContext)
        }
    }, [markdownContext])

    useEffect(() => {
        if(markdown){
            if (outputRef.current) {
                const html = marked.parse(markdown);
                outputRef.current.innerHTML = html;
                mermaid.run({ querySelector: '.language-mermaid' });
            }
        }
        
    }, [markdown]);

    return (
        <div className='wmde-markdown'>

            <div
                id={contentId}
                ref={outputRef}
            />
        </div>
    );
};

export default MarkdownMermaidRenderer;
