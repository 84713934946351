import BL_THEME_Full_Logo from "./../icons/BL/Full_Logo.svg";
import BL_THEME_Small_Logo from "./../icons/BL/Small_Logo.png";
import BL_THEME_Report_Logo from "./../icons/BL/Full_Logo.svg";
import BL_THEME_K_Logo from "./../icons/BL/Kairos_Logo.svg";
import BL_THEME_K_B_Icon from "./../icons/BL/Kairos_B_Icon.svg";
import BL_THEME_K_Icon from "./../icons/BL/AI_Icon.png";

import V_THEME_Full_Logo from "./../icons/V/Full_Logo.svg";
import V_THEME_Small_Logo from "./../icons/V/V_logo_sm.png";
import V_THEME_K_Logo from "./../icons/V/Kairos_Logo.svg";
import V_THEME_K_B_Icon from "./../icons/V/Kairos_B_Icon.svg";
import V_THEME_K_Icon from "./../icons/V/Kairos_Icon.svg";

import M_THEME_Full_Logo from "./../icons/M/Full_Logo.png";
import M_THEME_Small_Logo from "./../icons/M/Small_Logo.jpeg";
import M_THEME_Report_Logo from "./../icons/M/Full_Logo.png";
import M_THEME_K_Logo from "./../icons/V/Kairos_Logo.svg";
import M_THEME_K_B_Icon from "./../icons/V/Kairos_B_Icon.svg";
import M_THEME_K_Icon from "./../icons/V/Kairos_Icon.svg";

const Icons = {
    V_THEME_Full_Logo, V_THEME_Small_Logo, V_THEME_K_Logo, V_THEME_K_B_Icon, V_THEME_K_Icon,
    BL_THEME_Full_Logo, BL_THEME_Small_Logo, BL_THEME_K_Logo, BL_THEME_K_B_Icon, BL_THEME_K_Icon, BL_THEME_Report_Logo,
    M_THEME_Full_Logo, M_THEME_Small_Logo, M_THEME_K_Logo, M_THEME_K_B_Icon, M_THEME_K_Icon, M_THEME_Report_Logo
}

export default Icons;