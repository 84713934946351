import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { generateCIMId, getProjectInfoService, getProjectSectionsService, getProjectSummaryInfoService } from "../../../services/summarizerService";
import { SummarizerContext } from "../../../services/context/summarizerContext";
import { AnalyzeCompanyContext } from "../../../services/context/analyzeCompanyContext";
import { generateReadableId, getValueIgnoreCase } from "../../../utils/validation";
import { storeCIMSummarizerProjectSections } from "../../../services/authService";
import { toastInfo } from "../../../services/notifyService";
import { generateSlug } from "../../../services/commonService";

export const DealBookProjectIndexComponent = () => {

    const location = useLocation();
    const nav = useNavigate()
    const { projectSections, projectInfo, setProjectInfo, setProjectCompanyInfo, setScorecard, setProjectFiles, setProjectSections, setProjectMode } = useContext(SummarizerContext)
    const { analyzedCompanyInfo, setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)

    const [projectId, setProjectId] = useState(null)
    const [summaryId, setSummaryId] = useState(null)
    const [companyName, setCompanyName] = useState("")
    const [website, setWebsite] = useState("")
    const [navigateToFile, setNavigateToFile] = useState(false)
    const [max] = useState(20)
    const [totalScore, setTotalScore] = useState(0)
    const [activeSection, setActiveSection] = useState(null)

    useEffect(() => {
        getProjectIdFromPath()
    }, [location.pathname])

    useEffect(() => {
        if(projectId){
            getActiveSectionInfo();
        }
    }, [location.pathname, projectSections, projectId])

    const getProjectIdFromPath = () => {
        const pathArray = location.pathname.split('/');
        const projectSlug = pathArray[3] ? pathArray[3].split('-')[1] : null;
        setProjectId(parseInt(projectSlug))
        setNavigateToFile(!!location?.state?.showFilePage)
        setTotalScore(location?.state?.totalScore || 0)
        const _activeSection = pathArray[4] ? pathArray[4] : null;
        setActiveSection(_activeSection)
    }

    useEffect(() => {
        if(projectId){
            getProjectSections()
        }
    }, [projectId])

    const getActiveSectionInfo = () => {
        const pathArray = location.pathname.split('/');
        const secSlug = pathArray[4];
        const secInfo = projectSections && projectSections.find(sec => sec.slug === secSlug);
        setSummaryId(secInfo && secInfo.summaryId ? secInfo.summaryId : projectSections && projectSections.length ? projectSections[0].summaryId : null)
    }

    const getProjectSections = async () => {
        const pageLoader = document.getElementById('pageLoaderContainer');
        try {
            if (pageLoader) {
                pageLoader.style.display = 'flex'
            }
            const projResp = await getProjectInfoService(projectId);
            if (projResp && projResp.success) {
                const project = projResp.data.projectInfo;
                const resp = await getProjectSectionsService(projectId);
                if (resp && resp.success && resp.data && resp.data && resp.data.length) {
                    const icons = ['building-o', 'sitemap', 'users', 'industry', 'desktop', 'bank', 'compress', 'cubes', 'usd', 'snowflake-o', 'handshake-o', 'th-list']
                    const sections = resp.data.map((sec, i) => {
                        const availableIcon = icons[i];
                        sec.slug = generateSlug(sec.categoryName);
                        sec.icon = availableIcon ? availableIcon : icons[Math.floor(Math.random() * icons.length)];
                        return sec;
                    });
                    sections.sort((a, b) => a.parentPosition - b.parentPosition);
                    const files = projResp.data.fileMappings;
                    const projectInfo = { ...projResp.data.projectInfo, totalScore: project?.totalScore || totalScore, maxScore: max };
                    const storageObj = {
                        sections: sections, files: files, projectInfo: projectInfo
                    }
                    storeCIMSummarizerProjectSections(storageObj)
                    setProjectInfo(projectInfo);
                    setProjectFiles(files);
                    setProjectSections(sections);
                    const projectGenId = generateCIMId(projectInfo.projectId)
                    setProjectMode(project.publishedStatus.toLowerCase() === 'published' ? '' : 'manage')
                    const userFriendlyProjectId = generateReadableId(projectInfo.projectId, "cim");
                    const _activeSection = activeSection ? activeSection : sections[0].slug;
                    const path = navigateToFile ? `/dealbook/project/${userFriendlyProjectId}/${projectGenId}/files` : project.publishedStatus.toLowerCase() === 'published' ? `/dealbook/project/${userFriendlyProjectId}/${_activeSection}` : `/dealbook/project/${userFriendlyProjectId}/${_activeSection}/manage`
                    setAnalyzedCompanyInfo({ message: "Project Info Page", projectId: projectId })                    
                    nav(path)
                }
                else if (!resp.data || resp.data.length === 0) {
                    toastInfo("File processing in progress. Please wait")
                }
            }
        }
        catch (e) {
            console.log("Check any error ===>", e);
        }
        finally{
            if (pageLoader) {
                pageLoader.style.display = 'none';
            }
        }

    }

    useEffect(() => {
        if (summaryId && projectInfo && projectInfo.projectId) {
            getProjectSummaryInfo()
        }
    }, [summaryId, projectInfo, analyzedCompanyInfo])

    useEffect(() => {
        getProjectScorecard()
    }, [projectSections, analyzedCompanyInfo])

    useEffect(() => {
        const compInfo = {
            companyName:companyName, website:website
        }
        setProjectCompanyInfo(compInfo)
    }, [companyName, website])

    const getProjectSummaryInfo = async () => {
        try {
            const resp = await getProjectSummaryInfoService(projectInfo.projectId, summaryId);
            if (resp && resp.success) {
                const headerInfoSec = resp.data[0].subCategories.find(sub => sub.subcategoryName.toLowerCase().includes('header details'));
                const headerObject = headerInfoSec ? headerInfoSec.manualOverrideText ? headerInfoSec.manualOverrideText : headerInfoSec.kairosOutputText ? headerInfoSec.kairosOutputText : null : null;
                const isJsonContent = headerObject && !headerObject.trim().startsWith('<');
                if (headerObject && isJsonContent) {
                    const headerInfo = JSON.parse(headerObject);
                    if (headerInfo) {
                        const companyName = getValueIgnoreCase(headerInfo, 'company name');
                        if (headerInfo.companyName || companyName.value) {
                            setCompanyName(headerInfo.companyName || companyName.value)
                        }
                        const companyWebsite = getValueIgnoreCase(headerInfo, 'website');
                        if (headerInfo.website || companyWebsite.value) {
                            setWebsite(headerInfo.website || companyWebsite.value)
                        }
                    }
                }
            }
        }
        catch (e) {
        }

    }

    const getProjectScorecard = async() => {
        const scorecardSec = projectSections && projectSections.find(sec => sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard");
        if(scorecardSec && scorecardSec.summaryId){
            const resp = await getProjectSummaryInfoService(projectInfo.projectId, scorecardSec.summaryId);            
            if(resp && resp.success && resp.data){
                if(resp.data.length && resp.data[0].subCategories && resp.data[0].subCategories.length){
                    const scoresSummaryId = resp.data[0].subCategories[0].summaryId;
                    const scorecardCategories = resp.data[0].subCategories[0].manualOverrideText ? resp.data[0].subCategories[0].manualOverrideText : resp.data[0].subCategories[0].kairosOutputText;
                    const scorecardInfo = scorecardCategories ? JSON.parse(scorecardCategories) : [];
                    const scorecardData = scorecardInfo.data && scorecardInfo.data.map(s => {
                        s.summaryId = scoresSummaryId
                        return s;
                    })
                    setScorecard(scorecardData ? scorecardData : [])
                }
                
            }
        }
    }

    return (
        <Outlet />
    )
}