import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedReportTime, getViewReportPath, reportTypeBackground } from "../../services/commonService";
import reportPlaceholder from "./../../assets/img/report-preview.png"

export const TableViewComponent = ({ data, reportColumn, catalog, pinRemove }) => {

    const nav = useNavigate();
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        setNodes(data);
    }, [data]);

    const getReportCategory = (categories) => {
        const categoryNames = categories.map(item => item.categoryDisplayName ? item.categoryDisplayName : item.subcategoryDisplayName);
        return categoryNames.join(', ')
    }

    const arrangeColumnValue = (val, name) => {
        let report = val;
        if (name === 'REPORT') {
            return (
                <div className="d-flex align-items-center">
                    {
                       pinRemove ? '' : report.pinned ? (<i className={`fa ${catalog ? 'fa-unlock' : 'fa-thumb-tack'} mr-2`} aria-hidden="true"></i>) : (<i className={`fa ${catalog ? 'fa-lock' : 'fa-thumb-tack text-gray'} mr-2`} aria-hidden="true"></i>)
                    }

                    <img src={reportPlaceholder} alt="" className="bl-report-thumbnail" />
                    <div className="pl-2">
                        <p className="mb-0 text-nowrap"><b>{report.reportName}</b></p>
                        <p className="text-muted font-small mb-0"><i>{getFormattedReportTime(report.updatedAt)}</i></p>
                    </div>
                </div>
            )
        }
        else if (name === 'Category') {
            return (
                <span>{report.reportCategory ? report.reportCategory : report.categoryInfo ? getReportCategory(report.categoryInfo) : ''}</span>
            )
        }
        else if (name === 'Shared with') {
            return (
                <div className="d-flex flex-wrap">
                    {
                        report.sharedWith.map((u, i) => {
                            return (
                                <span key={`chip-rep-${i}`} className="badge bl-filter-chip bg-white bl-limited-text d-block px-2 mb-1" title={u}><i className="fa fa-fw fa-user-o" aria-hidden="true"></i>{u}</span>
                            )
                        })
                    }
                </div>
            )
        }
        else if (name === 'Description') {
            return (
                <span>{report.reportDescription}</span>
            )
        }
        else if (name === 'Report Type') {
            return (
                <span className={`badge badge-pill ${reportTypeBackground(report.reportType)}`}>{report.reportType}</span>
            )
        }
        else if (name === 'Created By') {
            return (
                <span>{report.createdBy}</span>
            )
        }
        else if (name === 'Action') {
            return (
                <div>
                    {
                        !report.hasAccess ? <button className="btn btn-primary btn-sm">Request Access</button> : null
                }
                </div>

            )
        }
        else if (name === 'User Groups') {
            return (
                <div className="d-flex">
                    {
                        report.userGroups.map((u, i) => {
                            return (
                                <span key={`chip-rep-${i}`} className="badge bl-chip">{u}</span>
                            )
                        })
                    }
                </div>
            )
        }
    }

    const addColumnWidth = (column) => {
        if(column === 'Report Type'){
            return {width: '7.3rem'}
        }
        else if(column === 'Category'){
            return {width: '8rem'}
        }
        else if(column === 'Action' && catalog) {
            return {width: '15rem'}
        }
        else return ''
    }

    const viewReport = (data) => {
        // window.location.href = `/#${getViewReportPath(data.data)}`
        nav(getViewReportPath(data.data))        
    }

    return (
        <DataTable value={nodes} onRowClick={viewReport} className="cp">
            <Column style={{ width: '13rem' }} field="companyName" header="Report Name" body={(e) => arrangeColumnValue(e, 'REPORT')} sortable={!catalog}></Column>
            {/* <Column style={{ width: '8rem' }} field="reportCategory" className="bl-width-8rem1" header="Category" sortable></Column> */}
            <Column style={{ width: '7rem' }} field="createdBy" className="bl-width-7rem1" header="Created By"sortable={!catalog}></Column>

            {
                reportColumn && reportColumn.map((column, i) => {
                    return (
                        <Column style={{minWidth: '10rem'}} key={`table-column-${i}`} field="sharedWith" header={column} body={(e) => arrangeColumnValue(e, column)} sortable={!catalog}></Column>
                    )
                })
            }

        </DataTable>
    )
}