import React, { useContext, useEffect, useState } from "react";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import buttonLabel from "./../../utils/property-file/buttons-label.json";
import overviewImg from "./../../assets/img/report-brand.jpg";
import appUpdatesJson from "./../../data/Analytics/appUpdates.json";
import reportsJson from "./../../data/Analytics/reports.json";
import { TableViewComponent } from "./tableView";
import { AnalyticsOverviewSidePanelComponent } from "./overviewSidePanel";
import reportCategoryJson from "./../../data/Analytics/reportCategory.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import { AuthContext } from "../../services/context/Auth/authContext";
import { getReportListService } from "../../services/reportService";
import listViewLoader from "./../../assets/img/loading-animation.gif";

export const AnalyticsOverviewComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { loggedUserName } = useContext(AuthContext)

    useEffect(() => {
        setIsLoading(true)
        getReportList()
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [updateList] = useState(appUpdatesJson);
    const [reports, setReports] = useState([]);
    const [filter, setFilter] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type', //'Shared with'
    ])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        LATEST_UPDATE: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const reportList = reportsJson.filter(r => r.isActive);
        // setReports(reportList)
    }, [reportsJson, filter])

    const getReportList = async () => {
        const obj = {
            page: 1,
            pageSize: 20
        }
        const resp = await getReportListService(obj);
        setIsLoading(false)
        if (resp && resp.success) {
            setReports(resp.data)
        }
    }

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "ANALYTICS").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        let filters = [
            {
                val: 'all', name: 'All', isActive: true
            },
            {
                val: 'isRecentlyPublished', name: 'Recently Published', isActive: false
            },
            {
                val: 'createdBy', name: 'Created by you', isActive: false
            },
            {
                val: 'isRecentlyView', name: 'Recently viewed by you', isActive: false
            }
        ];
        setFilter(filters)
    }, [])

    useEffect(() => {
        if (filter && filter.length) {
            let reportList = [];
            const activeFilterItem = filter.find(f => f.isActive).val;
            if (activeFilterItem && activeFilterItem !== 'all') {
                reportList = reportsJson.filter(r => {
                    if (activeFilterItem === 'createdBy') {
                        return r[activeFilterItem] === loggedUserName && r.isActive
                    }
                    else {
                        return r[activeFilterItem] && r.isActive
                    }
                })
            }
            else {
                reportList = reportsJson.filter(r => r.isActive)
            }
            // setReports(reportList)
        }
    }, [filter])

    const selectFilterItem = (e, indx) => {
        e.preventDefault();
        const filterList = filter.map(f => {
            f.isActive = false;
            return f;
        })
        filterList[indx].isActive = true;
        setFilter(filterList)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col d-flex">
                        <div className="bl-analytic-overview-main">
                            <section className="mb-3">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="align-self-center mr-16">
                                            <h5>{labels.WELCOME}</h5>
                                            <p className="mb-0">{labels.WELCOME_DESC}</p>
                                        </div>
                                        <img className="bl-overview-img" src={overviewImg} alt="overview-Img" />
                                    </div>
                                </div>
                            </section>

                            <section className="mb-4">
                                <div className="card-deck">
                                    <div className="card shadow-sm">
                                        <div className="card-body bl-analytic-overview-insights-1">
                                            <h6 className="card-title">{labels.ENTITY.TITLE_1}</h6>
                                            <p className="card-text bl-desc-truncate-line-2 mr-5">{labels.ENTITY.DESC_1}.</p>
                                            {/* <button className="btn btn-primary btn-sm btn-outline-primary">{buttonLabel.VIEW_NOW}</button> */}
                                        </div>
                                    </div>
                                    <div className="card shadow-sm">
                                        <div className="card-body bl-analytic-overview-insights-2">
                                            <h6 className="card-title">{labels.ENTITY.TITLE_2}</h6>
                                            <p className="card-text bl-desc-truncate-line-2 mr-4">{labels.ENTITY.DESC_2}.</p>
                                        </div>
                                    </div>
                                    <div className="card shadow-sm">
                                        <div className="card-body bl-analytic-overview-insights-3">
                                            <h6 className="card-title">{labels.ENTITY.TITLE_3}</h6>
                                            <p className="card-text bl-desc-truncate-line-2 mr-4">{labels.ENTITY.DESC_3}</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12">
                                                <h6 className="mb-3">{labels.RECOMMEND}</h6>
                                                {
                                                    isLoading ?
                                                        <div className="col text-center">
                                                            <img className="bl-list-loader" src={listViewLoader} />
                                                        </div>
                                                        : <TableViewComponent data={reports} reportColumn={reportColumn}></TableViewComponent>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="py-3 mt-n3 mob-hide bl-fixed-right-panel">
                            <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={updateList}></RecentActivityPanel>
                            <div className="text-center">
                                <button type="button" className="btn bg-gray btn-sm">Load More</button>
                            </div>
                            {/* <AnalyticsOverviewSidePanelComponent list={updateList} activityTitle={labels.YOUR_ACTIVITY} updatesTitle={labels.LATEST_UPDATE}></AnalyticsOverviewSidePanelComponent> */}
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}
