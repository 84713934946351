import React, { useContext, useEffect, useState } from "react";
import { SummarizerContext } from "../../../services/context/summarizerContext";
import { getProjectSummaryInfoService } from "../../../services/summarizerService";
import { DisplayMarkdownContent } from "../../../utils/markdownContent";
import { getAppBehavior } from "../../../services/authService";
import { themeIcons } from "../../../services/commonService";
import reportMissingLogo from "./../../../assets/icons/BL/Small_Logo.png";


export const ExportProjectSummaryPDF = () => {

    const { projectSections, projectInfo, projectCompanyInfo, scorecard } = useContext(SummarizerContext)
    
    const [sections, setSections] = useState([])
    const [keyHighlights, setKeyHighlights] = useState([])
    const [reportLogo, setReportLogo] = useState(null)
    const [appName, setAppName] = useState(null)

    useEffect(() => {
        if (projectSections && projectSections.length) {
            getFullSummaryInfo()
        }
    }, [projectSections])

    useEffect(() => {
        if (sections && sections.length) {
            setTimeout(() => {
                getAppThemeImages()
            }, 500)
        }
    }, [sections])

    const getAppThemeImages = () => {
        const appThemes = getAppBehavior();        
        if (appThemes && appThemes !== "undefined") {            
            const themeName = JSON.parse(appThemes).themeName;            
            const report_Logo = themeIcons(`${themeName}_Report_Logo`);            
            setReportLogo(report_Logo)
            const _appDomain = JSON.parse(appThemes).displayName;          
            setAppName(_appDomain ? _appDomain : 'Brownloop')
            const theme = JSON.parse(JSON.parse(appThemes).behaviour);
            if (theme) {
                const elements = document.getElementsByClassName('theme-primary-text-color');
                for (let i = 0; i < elements.length; i++) {
                    const element = elements[i];
                    if (element) {
                        element.setAttribute('style', `
                            color: ${theme.btnPrimaryBGColor} !important;
                            `);
                    }
                }
                const themeBGColorElement = document.getElementsByClassName('theme-primary-bg-color');
                for (let i = 0; i < themeBGColorElement.length; i++) {
                    const element = themeBGColorElement[i];
                    if (element) {
                        element.setAttribute('style', `
                            background-color: ${theme.btnPrimaryBGColor} !important; border-color: ${theme.btnPrimaryBorderColor} !important; color: ${theme.btnPrimaryTextColor} !important
                            `);
                    }
                }
                const themeLightElement = document.getElementsByClassName('bl-template-bg');
                for (let i = 0; i < themeLightElement.length; i++) {
                    const element = themeLightElement[i];
                    if (element) {
                        element.setAttribute('style', `
                            background-color: ${theme.btnPrimaryHoverBGColor} !important; color: ${theme.btnPrimaryHoverTextColor} !important
                            `);
                    }

                }
                const themeLightTableElement = document.getElementsByClassName('bl-template-table-th');
                for (let i = 0; i < themeLightTableElement.length; i++) {
                    const element = themeLightTableElement[i];
                    if (element) {
                        element.setAttribute('style', `
                            background-color: ${theme.btnPrimaryHoverBGColor} !important; border-right-color: ${theme.btnPrimaryHoverBGColor} !important; color: ${theme.btnPrimaryHoverTextColor} !important
                            `);
                    }
                }
                const themePrimaryBorder = document.getElementsByClassName('theme-primary-border-color');
                for (let i = 0; i < themePrimaryBorder.length; i++) {
                    const element = themePrimaryBorder[i];
                    if (element) {
                        element.setAttribute('style', `
                            border-color: ${theme.btnPrimaryBGColor} !important; color: ${theme.btnPrimaryHoverTextColor} !important
                            `);
                    }

                }
                const themeSectionsTableHeader = document.getElementsByClassName('theme-sec-table-header');
                for (let i = 0; i < themeSectionsTableHeader.length; i++) {
                    const element = themeSectionsTableHeader[i];
                    if (element) {
                        element.setAttribute('style', `
                            background-color: ${theme.btnPrimaryTextColor} !important;
                            `);
                    }

                }
            }
        }
    }

    const getFullSummaryInfo = async () => {
        const fullSummary = await Promise.all(
            projectSections.map(async (sec) => {
                if (sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard") {
                    return null
                }
                const resp = await getProjectSummaryInfoService(sec.projectId, sec.summaryId);
                if (resp && resp.success && resp.data && resp.data.length) {
                    const headerInfoSec = resp.data[0].subCategories.find(sub => sub.subcategoryName.toLowerCase().includes('header details'));
                    if (headerInfoSec) {
                        getProjectKeyPoints(headerInfoSec)
                    }
                    const subSectionList = resp.data[0].subCategories.filter(sub => sub.subcategoryName.toLowerCase() !== "header details");
                    subSectionList.sort((a, b) => a.positionInParent - b.positionInParent);
                    sec.subCategories = subSectionList;
                    return sec
                }
            })
        )
        setSections(fullSummary)
    }

    const getProjectKeyPoints = (headerInfoSec) => {
        const headerObject = headerInfoSec ? headerInfoSec.manualOverrideText ? headerInfoSec.manualOverrideText : headerInfoSec.kairosOutputText ? headerInfoSec.kairosOutputText : null : null;
        const isJsonContent = headerObject && !headerObject.trim().startsWith('<');
        if (headerObject && isJsonContent) {
            const predefinedKeys = ['aboutthecompany', 'about the company']
            const headerInfo = JSON.parse(headerObject);
            if (headerInfo) {
                const matched = {};
                const entries = [];
                Object.keys(headerInfo).forEach(key => {
                    if (predefinedKeys.includes(key.toLowerCase())) {
                        matched[key] = headerInfo[key];
                    } else {
                        entries.push({ [key]: headerInfo[key] });
                    }
                });
                setKeyHighlights(entries)
            }
        }
    }

    const getSubCategoriesContent = (subSec, i, j) => {
        return <DisplayMarkdownContent markdownText={subSec.manualOverrideText ? subSec.manualOverrideText === 'N/A' ? '' : subSec.manualOverrideText : subSec.kairosOutputText} />
    }

    const companyWebsite = () => {
        if (projectCompanyInfo.website && (projectCompanyInfo.website.startsWith('http') || projectCompanyInfo.website.toLowerCase().includes('www.'))) {
            return projectCompanyInfo.website.startsWith('http') ? projectCompanyInfo.website : `https://${projectCompanyInfo.website}`;
        }
        return null
    }

    const getTotalScore = () => {
        let score = 0;
        scorecard && scorecard.forEach(s => {
            score += s.score ? parseInt(s.score) : 0;
        })
        return score;
    }

    const handleErrorImage = (e) => {
        e.target.src = reportMissingLogo;
    }

    const getSummaryTemplate = () => {
        return (
            <div id="summarycontent" className="pl-0 pr-2">
                <div className="">
                    <table width="100%" border="0" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td><img src={reportLogo} onError={handleErrorImage} height="40px" /></td>
                                <td align="right"><h6 className="theme-primary-text-color"><b>CIM Summary</b></h6></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content">
                    <section className="mb-2">
                        <h6 className="text-dark text-uppercase text-center pb-0 mb-0"><b>{projectCompanyInfo && projectCompanyInfo.companyName} | {projectInfo && projectInfo.projectName}</b></h6>
                        {
                            companyWebsite() ? <p className="text-center mb-0 pb-0"><a href={companyWebsite()} target="_blank">{projectCompanyInfo && projectCompanyInfo.website}</a></p> : null
                        }

                    </section>

                    <section className="mb-2">
                        <table width="100%" border="1px" cellPadding="3px" cellSpacing="0px" className="text-dark">
                            <tbody>
                                {
                                    keyHighlights && keyHighlights.reduce((rows, _, index) => {
                                        if (index % 2 === 0) {
                                            const row = keyHighlights.slice(index, index + 2);
                                            rows.push(row);
                                        }
                                        return rows;
                                    }, []).map((pair, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {pair.map((item, itemIndex) => {
                                                const [key, value] = Object.entries(item)[0];
                                                const label = key
                                                    .replace(/([A-Z])/g, ' $1')
                                                    .replace(/^./, str => str.toUpperCase());

                                                return (
                                                    <React.Fragment key={itemIndex}>
                                                        <td width="15%"><b>{key}:</b></td>
                                                        <td>{value || 'N/A'}</td>
                                                    </React.Fragment>
                                                );
                                            })}
                                            {pair.length < 2 && (
                                                <>
                                                    <td></td>
                                                    <td></td>
                                                </>
                                            )}
                                        </tr>
                                    ))
                                }

                            </tbody>

                        </table>
                    </section>

                    {
                        scorecard && scorecard.length ? (
                            <section className="mb-2">
                                <div className="theme-primary-bg-color text-center text-capitalize border mb-2 py-1 font-weight-bold">{appName} Investment Attributes</div>

                                <table width="100%" border="1" cellPadding="5px" cellSpacing="0" className="text-center align-middle">
                                    <thead className="border-dark">
                                        <tr className="bl-template-bg">
                                            <th colSpan="5">Core Attributes</th>
                                            <th rowSpan="2">Total Net Score</th>
                                        </tr>
                                        <tr>
                                            {
                                                scorecard.map((score, i) => {
                                                    return <th key={`db-scorecard-print-header-${i}`} className={scorecard.length - 1 === i ? "bl-template-bg" : "bl-template-table-th border-0"}>{score.name}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {
                                                scorecard.map((score, i) => {
                                                    return (
                                                        <td key={`db-scorecard-print-body-${i}`}>
                                                            <div className="font-weight-bold mb-3 text-dark">{score.score}</div>
                                                            <div><small>{score.criteria_desc}</small></div>
                                                        </td>
                                                    )
                                                })
                                            }
                                            <td rowSpan="2" className="font-weight-bold fs-4 text-dark"><h4>{getTotalScore()}</h4></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        ) : null
                    }

                    {
                        sections && sections.length && sections.map((sec, i) => {
                            if (!sec || (sec && sec.sectionIdentifier && sec.sectionIdentifier === "scoreCard")) {
                                return null
                            }
                            return (
                                <div key={`summarycategorysec${i}`} className="bg-white">
                                    <table width="100%" border="1" cellPadding="5px" cellSpacing="0" className="text-dark">
                                        <thead className="theme-sec-table-header text-white">
                                            <tr>
                                                <th colSpan="2">{sec.categoryName}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sec.subCategories && sec.subCategories.length && sec.subCategories.map((subSec, j) => {
                                                    return (
                                                        <tr key={`summarysubcategorysubsec${i}${j}`}>
                                                            <td valign="top" width="20%">{subSec.subcategoryName}</td>
                                                            <td valign="top">
                                                                {/* <h6>Sub Section</h6> */}
                                                                {getSubCategoriesContent(subSec, i, j)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="">
                    <table width="100%" className="table-sm" border="0px" cellPadding="0" cellSpacing="0px">
                        <tbody>
                            <tr>
                                <td width="10%" className="text-left theme-primary-text-color">Innovate</td>
                                <td><hr className="theme-primary-border-color" /></td>
                                <td width="10%" className="text-center theme-primary-text-color">Integrate</td>
                                <td><hr className="theme-primary-border-color" /></td>
                                <td width="10%" className="text-right theme-primary-text-color">Grow</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            {getSummaryTemplate()}
        </div>
    )
}